
import "./index.scss"
import EImage from "../EditableComponent/EImage"
import ETextArea from "../EditableComponent/ETextArea"
import { getField } from "./getField"
import EFullFlexTextArea from "../EditableComponent/EFullFlexTextarea"
import { CSSField } from "@/types/css"
import checkIfFlex from "./checkIfFlex"
import EButton from "../EditableComponent/EButton"
import { replacePlaceholders } from "../TemplateCard/helpers"
import { checkIfSubParentFlex, fixBRs, hasBlockTag } from "./helpers"
import EVideo from "../EditableComponent/EVideo"
import CustomSubsectionsContainer from "../CustomSubsectionsContainer"
import { GenericSubsectionDocument } from "@/types/Documents"
import DeleteCircle from "@/generics/DeleteCircle"
import { SectionState } from "@/types/Section"
import { useContext } from "react"
import { SectionContext } from "../PuxMod"
import { SubsectionContext } from "../SubPuxMod"
import { deleteGenericSubsection } from "@/api/genericSubsection"
import checkIfFullFlex from "./checkIfFullFlex"
import getWidth from "./getWidth"
import MaxWidthAutoText from "@/generics/MaxWidthAutoText"
import EMaxWidthTextArea from "../EditableComponent/EMaxWidthTextArea"
import getMaxWidth from "./getMaxWidth"
import getSpecifiedMaxWidth from "./getSpecifiedMaxWidth"
import getSpecifiedWidth from "./getWidth"
import getHorizontalPadding from "./getHorizontalPadding"
import { DynamicObject } from "@/types/generic"
import { loggo } from "@/utils/dev"

    type Props = {
        element:any
        cssArray:CSSField[]
        parentMaxWidth?:number
        parentHorizontalPadding:number
        parentFlex?:boolean
        parentWidth?:number|null
        subFlex?:boolean
        subsection?:GenericSubsectionDocument
        setCSS?:()=>void
    }

    const CustomElementResolver = ({element,cssArray,parentMaxWidth,parentHorizontalPadding,subsection,subFlex,setCSS}:Props)=>{


        const subsectionState:any = useContext(SubsectionContext)
        const {isDeleted,setIsDeleted}=subsectionState
        
        const handleDeleteSubsection = ({subsectionId}:{subsectionId:string})=>{
            if(setIsDeleted){
                setIsDeleted(true)
                deleteGenericSubsection({subsectionId})
            }
        }

        const _id = element?._id
        const tag = element?.tag||""
        const src = element?.src||""
        const text = element?.text||""
        const children = element?.children||[]
        const className = element?.class||element.classString||""
        const editable = element?.editable||""
        const placeholder = element?.placeholder||""
        const type = element?.type||""
        const flex = checkIfFlex({element,cssArray})||subFlex
        const specifiedWidth = getSpecifiedWidth({element,cssArray})
        const specifiedMaxWidth = getSpecifiedMaxWidth({element,cssArray})

        const maxWidth = getMaxWidth({parentMaxWidth,parentHorizontalPadding,specifiedWidth,specifiedMaxWidth})
        const horizontalPadding = getHorizontalPadding({element,cssArray})
        const display = hasBlockTag(tag)?"block":"unset"
        const passedStyle:DynamicObject = {display}

        if(tag==="subsections"){

            return (isDeleted?<></>:<CustomSubsectionsContainer
            cssArray={cssArray}
            className={className}
            children={children}
            setCSS={setCSS}
            ></CustomSubsectionsContainer>)
        }


        if(tag==="a"){
            return<a>{children&&children.map((child:any,index:number)=>{
                if(!child){
                    return<></>
                }
                return<CustomElementResolver
                key={index}
                element={child}
                cssArray={cssArray}
                parentFlex={flex}
                parentHorizontalPadding={horizontalPadding}
                ></CustomElementResolver>
            })}</a>
        }

        if(tag==="button"&&!text.includes("{{")){
            return<EButton
            field={`text${_id}`}
            className={className}
            initialText={text}
            ></EButton>
        }

        if(tag==="button"){
            return<EButton
            field={getField(text)}
            className={className}
            ></EButton>
        }

        if(tag==="img"&&!editable){
            return<img className={className} src={src}></img>
        }
        
        if(tag==="img"){
            return<EImage
            field={getField(src)}
            classString={className}
            subsection={subsection}
            
            ></EImage>
        }

        if(tag==="youtube"){
            return<EVideo
            field={text.split("::")[0]}
            width={parentMaxWidth}
            subsection={subsection}

            ></EVideo>
        }

        if(text?.includes("{{")){
            return <EMaxWidthTextArea
            field={getField(text)}
            maxWidth={maxWidth}
            subsection={subsection}
            classString={`${tag} ${className} full-flex`}
            padding={horizontalPadding}
            passedStyle={passedStyle}
            ></EMaxWidthTextArea>
            // const isFullFlex = checkIfFullFlex({text,tag,parentFlex,width,parentWidth})
            // if(isFullFlex){
            //     return<EFullFlexTextArea
            //     field={getField(text)}
            //     classString={`${tag} ${className} full-flex`}
            //     subsection={subsection}
            // ></EFullFlexTextArea>
            // }else{
            //     return<ETextArea
            //     field={getField(text)}
            //     classString={`${tag} ${className} etext`}
            //     subsection={subsection}
            // ></ETextArea>
            // }
        }

        // if(text&&text.includes("{{")&&tag[0]==="h"&&(parentFlex)){


        // }

        // if(text&&text.includes("{{")){


        // }

        if(tag==="input"){
            return<input className={className} type={type||""} placeholder={placeholder||""}></input>
        }

        const handleDelete = ()=>{
            handleDeleteSubsection({subsectionId:subsection._id})
        }

        return <div id={_id} className={`${className} ${element.tag} custom-wrapper`}>
            {fixBRs(text)}
            {children&&children.map((child:any,index:number)=>{
                if(!child){
                    return<></>
                }
                return<CustomElementResolver
                key={index}
                element={child}
                cssArray={cssArray}
                parentFlex={flex}
                parentWidth={specifiedWidth}
                subsection={subsection}
                setCSS={setCSS}
                parentMaxWidth={maxWidth}
                parentHorizontalPadding={horizontalPadding}
                ></CustomElementResolver>
            })}
            {subsection&&<DeleteCircle
            _id={subsection._id}
            handleDelete={handleDelete}            
            ></DeleteCircle>}
        </div> 
    }
    export default CustomElementResolver