
    import "./index.scss"
    import React, { useContext, useState } from "react"
import { SectionContext } from "@/components/PuxMod"
import { PageContextType } from "@/types/Context"
import { PageContext } from "@/pages/Standard/context"
import EditClickAction from "@/components/EditClickAction"
import { SectionState } from "@/types/Section"
import ETextArea from "../ETextArea"
import EFullFlexTextArea from "../EFullFlexTextarea"

    const EButton = ({field,className,initialText,subsection}:{field:string,className?:string,initialText?:string,subsection?:boolean})=>{
        const {isEditing}:PageContextType = useContext(PageContext)
        const {fields,handleInputChange}:SectionState = useContext(SectionContext)
        const actionType = fields.actionType||""

        const handleClick = ()=>{
            if(actionType==="link"){
                window.location.href = fields.linkUrl
            }
            else if(actionType==="scrollToSection"){
                window.location.href = `#${fields.sectionScroll}`
            }
        }

        if(isEditing){

            return(<div className={`e-button-cont ${className}-cont`}>       
                <button className={`${field}_class ${className||""} e-button no-translate-animation`}>
                <EFullFlexTextArea
                field={field}
                classString={className}
                subsection={subsection}
                ></EFullFlexTextArea>
                </button>
                <EditClickAction text="Set Button Effect"></EditClickAction>
                </div>
                )
        }


        return(
            <button onClick={handleClick} className={field+"_class"+" e-button no-translate-animation"}>{fields[field]}</button>
        )
    }
    export default EButton
    
    
    