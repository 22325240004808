
    import { CustomElementObj } from "@/types/customElements"
import "./index.css"
    import React, { useContext, useEffect, useState } from "react"
import { SectionContext } from "../PuxMod"
import CustomSubsectionResolver from "../CustomSubsectionResolver"
import { GenericSubsectionDocument } from "@/types/Documents"
import { CSSField } from "@/types/css"
import { CustomElementType } from "@/types/sectionTemplate"
import { addCustomSection } from "@/api/customSubsection"
import FullWidthAdd from "@/generics/FullWidthAdd"

    type Props = {
        className:string
        children:CustomElementType[]
        cssArray:CSSField[]
        setCSS?:()=>void
    }

    const CustomSubsectionsContainer = ({className,children,cssArray,setCSS}:Props)=>{


        const {subsections,sectionId,setSubsections}:any= useContext(SectionContext)
        const filteredSubsections = subsections.filter((s:GenericSubsectionDocument)=>s.className===className)

        const handleAdd = async ()=>{
            const res = await addCustomSection({sectionId,className}) 
            setSubsections([...subsections,res])
        }

        useEffect(()=>{
            if(setCSS){
                setCSS()
            }
        },[subsections])


        return(
            <>
                {filteredSubsections.map((sub:GenericSubsectionDocument,key:number)=>{
                    return<CustomSubsectionResolver
                    className={className}
                    subsection={sub}
                    key={key}
                    cssArray={cssArray}
                    children={children}
                    ></CustomSubsectionResolver>
                })}
        <FullWidthAdd
        handleClick={handleAdd}
        text={className}
        ></FullWidthAdd>
            </>
        )
    }
    export default CustomSubsectionsContainer
    
    
    