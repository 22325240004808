
    import "./index.scss"
    import React, { useContext } from "react"
import { SectionState, SectionType } from "@/types/Section"
import PuxMod, { SectionContext } from "@/components/PuxMod"
import Video from "@/subsections/Video"
import { PageContextType } from "@/types/Context"
import { PageContext } from "@/pages/Standard/context"
import DynamicYoutube from "@/subsections/DynamicYoutube"

    const Videos = ({section}:{section:SectionType})=>{

        return(
            <PuxMod
            section={section}

            >
                <Content></Content>
                
            </PuxMod>
        )
    }
    export default Videos
    
    function Content(){
        const {stringArray,handleStringArrayAdd}:SectionState = useContext(SectionContext)
        const {isEditing}:PageContextType = useContext(PageContext)
const lastString = stringArray[stringArray.length-1]

        return<div className="videos-cont">
            {stringArray.map((url:string,index:number)=>{
                return<DynamicYoutube index={index} url={url}></DynamicYoutube>
            })}
            {!isEditing||lastString===""?<></>:<button className="add-video-button" onClick={()=>handleStringArrayAdd("")}>Add Video</button>}
            </div>
    }
    
    