export default     {
    prompt:"Heading Weight",
    field:"headingWeight",
    type:"childClass",
    child:".section-heading",
    valueType:"string",
    default:"normal",
    options:[
        {value:"weight-set-normal",text:"Normal"},
        {value:"weight-set-bold",text:"Bold"},
        {value:"weight-set-bolder",text:"Bolder"}
    ]
}