
    import { Conversation } from "@/types/Conversation"
import "./index.scss"
    import React, { useEffect, useState } from "react"
import MessageBox from "../MessageBox"
import PageMessenger from "../PageMessenger"
import { isMobile } from "@/utils/screen"
import BackButtonCont from "../BackButtonCont"

    type Props = {
        currentConversation:Conversation|null
        hidden:boolean
        handleMobileBack:()=>void
    }

    const CurrentConversation = ({currentConversation,hidden,handleMobileBack}:Props)=>{

        const [key,setKey] = useState(0)

        useEffect(()=>{
            setKey(key+1)
        },[currentConversation])

        if(!currentConversation){
            return<main></main>
        }
        
        
        return(
            <main className={hidden?"hide":""}>
                {isMobile()&&<BackButtonCont
                close={handleMobileBack}
                ></BackButtonCont>}
                <PageMessenger
                    key={key}
                    pageId={currentConversation.page}
                ></PageMessenger>
            </main>
        )
    }
    export default CurrentConversation
    
    
    