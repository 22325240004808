import uploadFileAndGetKey from "@/api/file"
import { i } from "@/utils/images"



const uploadAndSetUrl = async (files:any,handleSubmit:(arg0:string)=>void)=>{
    const key = await uploadFileAndGetKey(files)
    if(key){
        handleSubmit(i(key)||"")
    }
}

export default uploadAndSetUrl