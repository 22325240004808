import { DynamicObject } from "@/types/generic"
import "./index.scss"
    import React, { ChangeEvent, useState } from "react"
import { convertObjectIntoArray, makeFieldObject } from "@/utils/arrays"
import { Field } from "@/types/Section"
import SaveButton from "@/generics/SaveButton"

    type Props = {
        initialValueObject:DynamicObject
        handleSave:(arg0:DynamicObject)=>void
    }

    const EditBox = ({initialValueObject,handleSave}:Props)=>{

        const [fieldObject,setFieldObject] = useState(initialValueObject)

        const fieldsArray = convertObjectIntoArray(fieldObject)
        
        const handleFieldChange = (e:ChangeEvent<HTMLInputElement>)=>{
            const target:HTMLInputElement = e.target
            const {value,name} = target;
            const newFieldObject = {...fieldObject}
            newFieldObject[name]=value
            setFieldObject(newFieldObject)
        }

        return(
            <div className="edit-box">
                <div className="fields-cont">
                    {fieldsArray.map((field:Field,index:number)=>{
                        return(
                            <div>
                                <label>{field.key}</label>
                                <input name={field.key} onChange={handleFieldChange} value={field.value}></input>
                            </div>
                        )
                    })}
                </div>
                <SaveButton handleSave={()=>handleSave(fieldObject)}></SaveButton>
            </div>
        )
    }
    export default EditBox
    
    
    