const layoutObject = {prompt:"Layout?",
field:"layout",
type:"class",
default:"basic",
options:[
    {value:"normal",text:"Basic"},
    {value:"center",text:"Big and Centered"},
]
}


export default layoutObject