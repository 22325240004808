
    import "./index.css"
    import React, { useEffect, useState } from "react"
import { useDebounce } from "use-debounce"

    type Props = {
        message:string,
        setSuccess:React.Dispatch<boolean>,
        delayedCallback?:Function
    }

    const Success = ({message,setSuccess,delayedCallback}:Props)=>{
        const [mounted,setMounted] = useState(false)
        const [callingBack] = useDebounce(mounted,3000)

        useEffect(()=>{
            setMounted(true)
        },[])

        useEffect(()=>{
            if(callingBack){
                if(delayedCallback){
                    delayedCallback()
                }
            }
        },[callingBack])

        return(
            <div className="success-popup">
                <h2>
                    {message}
                </h2>
                <button onClick={()=>{setSuccess(false)}}>close</button>
                </div>
        )
    }
    export default Success
    
    
    