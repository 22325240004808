import getTimeFromDate, { getDateFromString } from "./getTimeFromDate"

const months = [
    "Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"
]
const ordinals = [
    "th","st","nd","rd","th","th","th","th","th","th"
]

export const reformatDate = (dateString:string)=>{
    const date = getDateFromString(dateString)
    const split = date.split("-")
    const monthNumber = (+split[1]-1)
    const reformattedDate = months[monthNumber]+" "+getOrdinal(split[2])
    return reformattedDate
}

export const reformatTime = ({dateString,timeString}:{dateString?:string,timeString?:string})=>{
    let time
    if(dateString){
        time = getTimeFromDate(dateString)
    }else{
        time=timeString
    }
    if(!time){
        return
    }
    let [hour,minute] = time.split(":")
    const hourNumber = +hour

    const hourNumberReformatted = (hourNumber%12===0)?12:hourNumber%12
    const reformattedMinute = minute=="00"?"":`:${minute}`
    const meridian = hourNumber<12?"AM":"PM"
    const reformattedTime = `${hourNumberReformatted}${reformattedMinute} ${meridian}`
    return reformattedTime
}

export const getOrdinal = (input:(number|string))=>{
    const num = +input;
    const onesDigit = num%10;
    let ordinal = ordinals[onesDigit]
    if(num>4&&num<21){
        ordinal = "th"
    }
    return num+ordinal
}


const lib:any = {
    color:"Text Color",
    "--headingColor":"Heading Color",
    "Multiple Choice":"select",
    "multiple-choice":"select"
}

export const t = (string:string)=>{
    if(lib[string]){
        return lib[string]
    }
    const result = string.replace(/([A-Z])/g, " $1");
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult
}

export const convertStyleVariableToURL = (variableValue:string)=>{

    if(!variableValue){
        return ""
    }
    const noURL = variableValue.replaceAll('url(','');

    const noQuotes = noURL.replaceAll("'","")
    const noCloseParens = noQuotes.replace(")","")
    return noCloseParens
}


export function reformatDateAndTime(dateString:string) {
    const date = new Date(dateString);
    const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
    const month = months[date.getMonth()];
    const day = (date.getDate() < 10 ? '0' : '') + date.getDate();
    const year = date.getFullYear();
    const hour = (date.getHours() < 10 ? '0' : '') + date.getHours();
    const minute = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
    return `${month}/${day}/${year} ${hour}:${minute}`;
  }

  export const slugify = (text:string)=>{
        return text.toLowerCase().replaceAll(" ","-")
  }

  export const hyphenateCamelcase= (str:string)=>{
    return str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
  }


  export const extractDigits = (string:string)=>{
    const digits:string[] = []
    const characters = string.split("")
    characters.forEach(c=>{
        if(parseInt(c)){
            digits.push(c)
        }
    })
    
    return digits.join("")
  }

  export function longDateFormat(dateString: string): string {
    const date = new Date(dateString);
    const options = { month: 'long', day: 'numeric', year: 'numeric' } as const;
    const formatter = new Intl.DateTimeFormat('en-US', options);
    return formatter.format(date);
  }