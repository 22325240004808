export const  handleHandlebars = (html:string)=>{
    const placeholderRegex = /{{(.*?):(.*?)}}/g;
    let processedHTML = html;

    let match = placeholderRegex.exec(html);
    while (match != null) {
        // match[1] will contain the placeholder's name, match[2] will contain the value
        const fullPlaceholder = match[0];
        const value = match[2];
        processedHTML = processedHTML.replace(fullPlaceholder, value);
        
        // Look for the next match
        match = placeholderRegex.exec(html);
    }

    return processedHTML;
}