
    import "./index.css"
    import React from "react"

    type Props = {
        isExpanded:boolean
        setIsExpanded:React.Dispatch<boolean>
    }

    const ExpanderButton = ({isExpanded,setIsExpanded}:Props)=>{

        const handleClick = ()=>{
            setIsExpanded(!isExpanded)
        }

        return(
            <button onClick={handleClick} className={`expander-btn no-translate-animation ${isExpanded?"expanded":""}`}><div></div></button>
        )
    }
    export default ExpanderButton
    
    
    