
    import "./index.scss"
    import React from "react"

    type Props = {
        children:JSX.Element|JSX.Element[]
    }

    const Bar = ({children}:Props)=>{
        return(
            <div className="bar">
                {children}
            </div>
        )
    }
    export default Bar
    
    
    