
    import "./index.scss"
    import React, { useContext } from "react"
import Toggle from "@/generics/Toggle"
import { PageContextType } from "@/types/Context"
import { PageContext } from "@/pages/Standard/context"
import { changeNavSectionField } from "@/utils/navBar"
import { SectionContext } from "@/components/PuxMod"
import { SectionState } from "@/types/Section"

type Props = {
    isOn:boolean,
    setIsOn:React.Dispatch<boolean>
}

    const ToggleOn = ({isOn,setIsOn}:Props)=>{

        const {navSections,setNavSections}:PageContextType = useContext(PageContext)
        const {sectionId}:SectionState = useContext(SectionContext)

        const handleToggle = ()=>{
            setIsOn(!isOn)
            changeNavSectionField({navSections,setNavSections,sectionId,field:"isOn",value:!isOn})
        }

        return(
            <div className="toggle-on-cont">
                <Toggle label={isOn?"visible":"hidden"} action={handleToggle} initialValue={isOn} ></Toggle>
            </div>
        )
    }
    export default ToggleOn
    
    
    