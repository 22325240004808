
export type CalculatedState = {
    headerOn:boolean,
    headerOpacity:number,
    headerHeight:number,
    headerBuffer:number
}

const getHeaderBuffer = (obj:CalculatedState)=>{
    if(obj.headerOpacity===100&&obj.headerOn){
        return 0
    }else{
        return obj.headerHeight
    }
}

export const initialCalculatedState = {
    headerOn:true,
    headerOpacity:100,
    headerHeight:0,
    headerBuffer:0
}

export type CalculatedStateAction = 
    {type:'setHeaderOn',payload:boolean}
    |{type:'setHeaderOpacity',payload:number}
    | {type:'setHeaderHeight',payload:number}

export const calculatedValuesReducer = (state:CalculatedState,action:CalculatedStateAction)=>{
    let newState
    switch(action.type){
        case 'setHeaderOn':
            newState = {...state,headerOn:action.payload}
            newState.headerBuffer = getHeaderBuffer(newState)
            return newState
        case 'setHeaderOpacity':
            newState = {...state,headerOpacity:action.payload}
            newState.headerBuffer = getHeaderBuffer(newState)
            return newState
        case 'setHeaderHeight':
            newState = {...state,headerHeight:action.payload}
            newState.headerBuffer = getHeaderBuffer(newState)
            return newState
        };
}