import { EditOrdinalsType } from "@/pages/Standard/context"
import { SectionType } from "@/types/Section"

export type SubPage = {
    name:string
    sections:SectionType[]
}

type CreateSubPagesArgs = {
    ordinals:EditOrdinalsType[]
    sections:SectionType[]
}

export const createSubPages = ({ordinals,sections}:CreateSubPagesArgs)=>{
    const orderedSections = sections.sort((a:SectionType,b:SectionType)=>{
        return a.ordinal-b.ordinal
    })
    const subPages:SubPage[] = []
    let currentSubPage:SubPage = {name:orderedSections[0].sectionName,sections:[orderedSections[0]]}
    for(let i=1;i<orderedSections.length;i++){
        const section = orderedSections[i]
        if(section.isOn&&section.inMenu){
            subPages.push(currentSubPage)
            currentSubPage = {name:section.sectionName,sections:[section]}
        }
        else{
            currentSubPage.sections.push(section)
        }
    } 
    subPages.push(currentSubPage)
    return subPages
}

export const findCurrentSubPage = ({sectionId,subpages}:{sectionId:string,subpages:SubPage[]})=>{
    for (let subpage of subpages){
        for (let section of subpage.sections){
            if(section._id===sectionId){
                return subpage
            }
        }
    }

}