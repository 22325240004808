
    import { Children } from "@/types/JSX"
import "./index.scss"
import { useEffect, useRef, useState } from "react"
import { FaArrowLeft, FaArrowRight, FaCircle } from "react-icons/fa"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircle } from "@fortawesome/free-solid-svg-icons"
import Dot from "../Dot"
import { returnStatus } from "@/utils/pageStatus"
import getWidth from "./getWidth"

    type Props = {
        children:Children
        timer?:number
    }

    const Carousel = ({children,timer}:Props)=>{

        const childArray = Array.isArray(children)?children:[children]
        const frameRef = useRef(null)
        const [activeIndex,setActiveIndex] = useState(0)
        const length = childArray.length
        const [width,setWidth] = useState(0)
        // const calcedWidth = (frameRef as any)?.current?.getBoundingClientRect()?.width||400
        const calcedWidth = getWidth({frameRef})

        useEffect(()=>{
            setWidth(calcedWidth)
        },[calcedWidth])

        const [timeBool,setTimeBool] = useState<boolean>(false)

        const frameStyle:any = {
            "--frameWidth":`${width}px`
        }

        const carouselStyle = {
            translate: `-${(activeIndex%length)*width}px`
        }

        useEffect(()=>{
            if(timer){
                setTimeout(()=>{
                    setActiveIndex(activeIndex+1)
                    setTimeBool(!timeBool)
                },timer)
            }
        },[timeBool])

        return<>
        <div ref={frameRef} className="carousel-frame"
        style={frameStyle}
        >
        <div style={carouselStyle} className="carousel">

        {childArray.map((child)=>{
            return<div className="carousel-item">
                {child}
            </div>
        })}
        </div>
        </div>
        <div className="carousel-control" >
            <button className={!activeIndex?"inactive arrow-btn":"arrow-btn"} onClick={()=>setActiveIndex((activeIndex===0?length-1:activeIndex-1))}><img src="http://www.davidharrislive.com/wp-content/themes/davidharris/images/btn-prev.png"></img></button>
            <button className="arrow-btn" onClick={()=>setActiveIndex((activeIndex+1))}><img src="http://www.davidharrislive.com/wp-content/themes/davidharris/images/btn-next.png"></img></button>

        </div>
        <div className="carousel-dots">
            {childArray.map((child:JSX.Element,index:number)=>{
                return<Dot
                filled={index===(activeIndex%length)}
                handleClick={index===(activeIndex%length)?undefined:()=>setActiveIndex(index)}
                ></Dot>
            })}
        </div>
        </>
    }
    export default Carousel
    
    
    


    // this is the old carousel that can be reinstituted if a site is relying on it

    // const Carousel = ({children}:Props)=>{

    //     const carouselRef = useRef(null)
    //     const [currentIndex,setCurrentIndex] = useState(0)

    //     useEffect(() => {
    //         const interval = setInterval(() => {
    //           handleNext();
    //         }, 2000);
    //         return () => clearInterval(interval);
    //       }, [currentIndex]);


    //     useEffect(()=>{
    //         if(!carouselRef?.current){
    //             return
    //         }
    //         const children = (carouselRef.current as HTMLElement).children
    //         Array.from(children).forEach(c=>{
    //             c.classList.remove("previous");
    //             c.classList.remove("current");
    //             c.classList.remove("next")
    //         })
            
    //         children[(currentIndex+children.length)%children.length].classList.add("current")
    //         children[(currentIndex+children.length-1)%children.length].classList.add("previous")
    //         children[(currentIndex+children.length+1)%children.length].classList.add("next")
    //     },[currentIndex])





    //     const handleNext = ()=>{
    //         setCurrentIndex(currentIndex+1)
    //     }

    //     return(
    //         <div className="carousel-cont">
    //         <button onClick={handleNext}>Next</button>

    //         <div ref={carouselRef} className="carousel">
    //             {children}
    //         </div>
    //         </div>
    //     )
    // }
    // export default Carousel
    