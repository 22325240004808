import { moveSubsection } from "@/api/genericSubsection"
import { EditOrdinalsType, EditSubOrdinalsType } from "@/pages/Standard/context"

export const getOrdinal = ({_id,ordinals}:{_id:string,ordinals:{_id:string,ordinal:number}[]})=>{
    const found = ordinals.find(o=>o._id===_id)
    return found?.ordinal||0
}

export const makeEditOrdinals = ({subsections}:{subsections:any})=>{
    const arr = []
    const availableOrds = []
    for (let i = 0;i<subsections.length;i++){
        const found = subsections.find((s:any)=>s.ordinal===i)
        if(!found){
            availableOrds.push(i)
        }
    }

    for (let i = 0;i<subsections.length;i++){
        const ordinal = subsections[i].ordinal!==undefined?subsections[i].ordinal:availableOrds.shift()
        const obj = {_id:subsections[i]._id,ordinal}
        arr.push(obj)
    }

    return arr

}

type MoveArguments = {
    subsectionOrdinals:EditSubOrdinalsType[]
    setSubsectionOrdinals:React.Dispatch<EditSubOrdinalsType[]>
    _id:string
}

export const moveUp = ({subsectionOrdinals,setSubsectionOrdinals,_id}:MoveArguments)=>{
    const sorted = subsectionOrdinals.sort((a,b)=>{
        return (a.ordinal-b.ordinal)
    })
    const found = subsectionOrdinals.find((s)=>{
        return (s._id===_id)
    })
    if(!found){
        return
    }
    const previous = subsectionOrdinals.find((s)=>{
        return (s.ordinal===(found.ordinal-1))
    })
    found.ordinal--
    if(previous){
        previous.ordinal++
    }
    setSubsectionOrdinals(sorted)
    moveSubsection({subsectionId:_id,newOrdinal:found.ordinal,direction:"up"})
}

export const moveDown = ({subsectionOrdinals,setSubsectionOrdinals,_id}:MoveArguments)=>{
    const sorted = subsectionOrdinals.sort((a,b)=>{
        return (a.ordinal-b.ordinal)
    })
    const found = subsectionOrdinals.find((s)=>{
        return (s._id===_id)
    })
    if(!found){
        return
    }
    const next = subsectionOrdinals.find((s)=>{
        return (s.ordinal===(found.ordinal+1))
    })
    found&&found.ordinal++
    next&&next.ordinal--
    setSubsectionOrdinals(sorted)
    moveSubsection({subsectionId:_id,newOrdinal:found.ordinal,direction:"down"})

}

export const addToOrdinals = ({subsectionOrdinals,setSubsectionOrdinals,_id}:MoveArguments)=>{
    const newArr = [...subsectionOrdinals]
    const newObj = {_id,ordinal:newArr.length}
    newArr.push(newObj)
    setSubsectionOrdinals(newArr)
}

export const removeFromOrdinals = ({subsectionOrdinals,setSubsectionOrdinals,_id}:MoveArguments)=>{
    const newArr = [...subsectionOrdinals].filter((s:any)=>{
        return (s._id!==_id)
    }).sort((a:any,b:any)=>{
        return (a.ordinal-b.ordinal)
    })
    for (let i = 0;i<newArr.length;i++){
        newArr[i].ordinal = i
    }
    setSubsectionOrdinals(newArr)

}