
    import { PaymentMethod } from "@/types/Stripe"
import PaymentMethods from "../PaymentComponents/PaymentMethods"
import TokenCostCalcTable from "./TokenCostCalcTable"
import "./index.scss"
    import React, { useContext, useState } from "react"
import { buyTokens } from "@/api/tokens"
import useGetIds from "@/hooks/useGetIds"
import { Context, ContextValueType } from "@/context"
import LoadingSpinner from "@/generics/LoadingSpinner"


type Props = {
    purchaseAmount:number
    setIsCheckoutOpen:React.Dispatch<boolean>
}

    const TokenPurchaseStripe = ({purchaseAmount,setIsCheckoutOpen}:Props)=>{

        const {setTokens}:ContextValueType = useContext(Context)
        const [{userId}] = useGetIds()
        const [paymentMethod,setPaymentMethod] = useState<PaymentMethod|null>(null)
        const [isChangingPaymentMethod,setIsChangingPaymentMethod] = useState(false)
        const paymentMethodId = paymentMethod?.id
        const [loading,setLoading] = useState(false)

        const handlePurchase = async ()=>{
            setLoading(true)
            if(!userId){
                return
            }
            const res = await buyTokens({userId,tokenAdd:purchaseAmount,paymentMethodId})
            if(res.error){
                alert("error")
                setLoading(false)
                return
            }
            const {totalTokens} = res
            setTokens(totalTokens)
            setLoading(false)
            setIsCheckoutOpen(false)            
        }

        if(loading){
            return<LoadingSpinner
            message="Purchasing..."
            ></LoadingSpinner>
        }

        return(
            <div className="token-purchase-stripe">
                <button onClick={()=>setIsCheckoutOpen(false)} className="center-btn">Back</button>
                <TokenCostCalcTable
                purchaseAmount={purchaseAmount}
                ></TokenCostCalcTable>
                <PaymentMethods
                    setSubscriptionPaymentMethod={setPaymentMethod}
            ></PaymentMethods>
            <div className="purchase-btn-cont">

            <button className="primary"
            onClick={handlePurchase}
            >Purchase</button>
            </div>
            </div>
        )
    }
    export default TokenPurchaseStripe
    
    
    