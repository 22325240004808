import { DynamicObject } from "@/types/generic"

type InputIncludedArgs = {
    fields:DynamicObject
    fieldName:string
    includedFields?:string[]
}

export const isInputIncluded = ({fields,fieldName,includedFields}:InputIncludedArgs)=>{
    if(fields[fieldName]!==undefined){
        return true
    }
    if((includedFields||[]).includes(fieldName)){
        return true
    }
}