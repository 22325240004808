
    import { CustomTemplateType } from "@/types/sectionTemplate"
import "./index.scss"
    import React from "react"
import PreviewCont from "../PreviewCont"
import SectionTemplatePreview from "../SectionTemplatePreview"
import { replacePlaceholders } from "./helpers"
import { addSectionFromTemplate } from "@/api/sectionTemplate"
import getSlug from "@/utils/getSlug"
import handleCustomElements from "@/pages/SectionTemplateBuilder/replaceCustomElements"

    type Props = {
        sectionTemplate:CustomTemplateType
    }

    const TemplateCard = ({sectionTemplate}:Props)=>{

        const {templateName,sectionType,html,fields,css,_id} = sectionTemplate
        
        const pageId = getSlug().substring(1)

        const handleAddToPage = async ()=>{
         const res = await   addSectionFromTemplate({sectionTemplateId:_id,pageId})
         const {pageName} = res;
         window.location.replace(`/${pageName}`)
        }

    const {newHTML,children} = handleCustomElements({html})
    const transformedHTML = replacePlaceholders({html:newHTML,fields})

        return(
            <div className="section-template-card">
                <header>
                <div className="template-name">
                    <h3>

                    {templateName}
                    </h3>
                </div>
                <div className="section-type">
                <h3>
                    {sectionType}
                    </h3> 

                </div>
                <div></div>
                </header>
                <div id={_id} className="preview-cont">
                <SectionTemplatePreview
                    html={transformedHTML}
                    styles={css}
                    parentId={_id}
                    frameHeight="600px"
            ></SectionTemplatePreview>
                </div>
                <footer>
                    <button onClick={handleAddToPage}>Add {templateName} Section to Page</button>
                </footer>
            </div>
            )
    }
    export default TemplateCard
    
    
    