
    import "./index.css"
    import "../Login/index.css"
    import React from "react"
import Header from "@/generics/Header"
import Logo from "@/generics/Logo"
import { Link } from "react-router-dom"
import Section from "@/generics/Section"
import RegisterFunctionality from "@/components/Register"

    const Register = ()=>{
        return(
            <main id="login-page" className="close-margin">
            <Header>
            <img src="./images/darklogo.png"></img>
                <Link to="/">
                <h1>⨉</h1>
                </Link>
            </Header>
            <Section sectionName="register-section">
                <h1>Register</h1>
                <h3>Already registered? <a href="/login">Log in here</a></h3>
                <div className="side-by-side">
                    <RegisterFunctionality></RegisterFunctionality>
                </div>
            </Section>
            </main>
        )
    }
    export default Register
    
    
    