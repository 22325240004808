
    import { FaTimes } from "react-icons/fa"
import "./index.scss"
    import React, { ChangeEvent } from "react"

    type Props = {
        options:string[]
        handleAdd:()=>void
        handleChange:({optionIndex,value}:{optionIndex:number,value:string})=>void
        handleDelete:({optionIndex}:{optionIndex:number})=>void
    }

    const FormOptionEditor = ({options,handleAdd,handleChange,handleDelete}:Props)=>{
        
        const handleInputChange = (e:ChangeEvent<HTMLInputElement>)=>{
            const {id,value} = e.target
            handleChange({optionIndex:parseInt(id),value})
        }

        return(
            <div className="options-box">
                {
                    options.map((option,index)=>{
                        return<div className="edit-option-line">
                            <div>
                            <span className="ordinal">{index+1}.</span>
                            <input id={index.toString()} value={option} onChange={handleInputChange}></input>
                            </div>
                            <div className="delete-cont">

                            <FaTimes onClick={()=>handleDelete({optionIndex:index})}></FaTimes>
                            </div>
                        </div>
                    })
                }
                <button onClick={handleAdd}>Add Option</button>
            </div>
        )
    }
    export default FormOptionEditor
    
    
    