export default     {
    prompt:"Heading Size?",
    field:"--sectionHeadingSize",
    type:"childClass",
    child:".section-heading",
    default:"size-set-medium",
    options:[
        {value:"size-set-small",text:"Small"},
        {value:"size-set-medium",text:"Medium"},
        {value:"size-set-large",text:"Large"}
    ]
}