
    import "./index.css"
    import React, { useContext } from "react"
import Header from "@/generics/Header"
import Logo from "@/generics/Logo"
import { Link } from "react-router-dom"
import Section from "@/generics/Section"
import LoginFunctionality from "@/components/Login"
import LoadingSpinner from "@/generics/LoadingSpinner"
import { Context, ContextValueType } from "@/context"

    const Login = ()=>{

        const {spinner}:ContextValueType = useContext(Context)

        return(
            <main id="login-page">
            <Header>
                <img src="./images/darklogo.png"></img>
                <Link to="/">
                <h1>⨉</h1>
                </Link>
            </Header>
            <Section>
                <h1>Log In</h1>
                <h3>Not registered yet? <a href="/register">Sign up</a></h3>
                <div className="side-by-side">
                    <LoginFunctionality></LoginFunctionality>
                </div>
            </Section>
            {spinner&&<LoadingSpinner></LoadingSpinner>}
            </main>
        )
    }
    export default Login
    
    
    