
    import { NavSectionType } from "@/types/Context"
import "./index.css"
    import React, { MouseEventHandler, useState } from "react"
import { getNavName } from "./helpers"
import Modal from "@/generics/Modal"
import { useDebounce } from "use-debounce"
import useSkipFirstMutation from "@/hooks/useSkipFirstMutation"
import { changeNavName } from "@/api/section"
import fixHeadingString from "./fixHeadingString"

    type Props = {
        section:NavSectionType,
        handleClick:MouseEventHandler
    }

    const NavListItem = ({section,handleClick}:Props)=>{

        const [heading,setHeading] = useState(getNavName(section))
        const [modalOpen,setModalOpen] = useState(false)
        const [delayedHeading] = useDebounce(heading,500)
        const sectionId = section.sectionId
    

        useSkipFirstMutation(()=>{
            changeNavName({sectionId,navName:delayedHeading})
        },[delayedHeading])

        const handleRightClick:MouseEventHandler = (e)=>{
            e.preventDefault()
            setModalOpen(true)
        }



        if(modalOpen){

        }

            return<>
{modalOpen&&<Modal
            setIsModalOpen={setModalOpen}
            additionalClass="nav-name-modal"
            position="center"
            >
                <div>
                <h3>Name in Navigation:</h3>
                <input onChange={(e)=>setHeading(e.target.value)} value={heading}></input>
                </div>
            </Modal>}
            <li onContextMenu={handleRightClick}>
            <a onClick={handleClick} href={`#${section.sectionId}`}>{heading!==""?fixHeadingString(heading):"SETNAME"}</a>
            </li>
            </>
    }
    export default NavListItem
    
    
    