
    import Modal from "@/generics/Modal"
import "./index.scss"
    import React, { useContext, useState } from "react"
import sectionTypeList from "@/pages/SectionTemplateBuilder/sectionTypeList"
import { submitNewTemplate } from "@/api/sectionTemplate"
import { filterCSSArray, transformHTMLForBackend } from "@/pages/SectionTemplateBuilder/helpers"
import { CSSLineType } from "../CSSEditor"
import FullPageSuccess from "../FullPageSuccess"
import InputAndLabelContainer from "@/generics/Containers/InputAndLabelContainer"
import { SectionDevelopmentContext } from "@/context/SectionDeveloperContext"

    type Props = {
        hasPage:boolean|string
        setIsModalOpen:React.Dispatch<boolean>
        cssArray:CSSLineType[]
        html:string
        pageId:string
        isDev:boolean
        submissionId?:string|null
    }


    const SectionTemplateBuilderSubmission = ({html,hasPage,setIsModalOpen,cssArray,pageId,submissionId,isDev}:Props)=>{
        
        const [name,setName] = useState("")
        const [type,setType] = useState("")
        const [success,setSuccess] = useState(false)
        const devContext:any = useContext(SectionDevelopmentContext)
        const devSubmission = devContext?.devSubmission
        const filteredCSSArray = filterCSSArray(cssArray)
        const handleSubmit = async ()=>{
            const res = await submitNewTemplate({name,cssArray:filteredCSSArray,html:transformHTMLForBackend(html),type})
            if(!res.error){
                setSuccess(true)
            }
        }
    
        const handleSubmitWithPage = async ()=>{
            const res = await submitNewTemplate({name,cssArray:filteredCSSArray,html:transformHTMLForBackend(html),type,pageId})
            const pageName = res?.page?.pageName
            if(!isDev){
                window.location.replace(`/${pageName}`)
            }
            else{
                const address = `/freelance-dev/${submissionId}`
                window.location.replace(address)
            }
        }

        if(success){
            return<FullPageSuccess
            message="Template Build Success"
            redirect="/dev-center"
            ></FullPageSuccess>
        }

        if(!hasPage){
            return<Modal additionalClass="template-validation-modal" setIsModalOpen={setIsModalOpen}>
                <>
                <InputAndLabelContainer>
                <label>Name your Section Template</label>
                <input value={name} onChange={(e)=>setName(e.target.value)}></input>
                </InputAndLabelContainer>
                <InputAndLabelContainer>
                <label>What Type is It?</label>
                <select value={type||""} onChange={(e)=>setType(e.target.value)}>
                    <option disabled></option>
                    {sectionTypeList.map((sectionType:string)=>{
                        return<option>{sectionType}</option>
                    })}
                </select>
                </InputAndLabelContainer>
                {(name!==""&&type!=="")&&<div>
                    <button className="submit" onClick={handleSubmit}>Submit Template</button>
                    </div>}
                </>
            </Modal>
        }

            return<Modal additionalClass="template-validation-modal"  setIsModalOpen={setIsModalOpen}>
                <>
                <InputAndLabelContainer>
                <label>Name your Section Template</label>
                <input value={name} onChange={(e)=>setName(e.target.value)}></input>
                </InputAndLabelContainer>
                <InputAndLabelContainer>
                <label>What Type is It?</label>
                <select value={type||""} onChange={(e)=>setType(e.target.value)}>
                    <option disabled></option>
                    {sectionTypeList.map((sectionType:string)=>{
                        return<option>{sectionType}</option>
                    })}
                </select>
                </InputAndLabelContainer>
                {(name!==""&&type!=="")&&<div>
                    <button onClick={handleSubmitWithPage}>Submit Template</button>
                    </div>}
                </>
            </Modal>
        
    }
    
    export default SectionTemplateBuilderSubmission
    
    
    