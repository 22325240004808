export default     {
    prompt:"Button Border Color?",
    field:"buttonBorderColor",
    type:"childStyle",
    child:".e-button",
    valueType:"string",
    colorPicker:true,
    default:"inherit",
    property:"borderColor",
    condition:{field:"buttonStyle",value:"button-set-box"},
}