import { SectionContext } from "@/components/PuxMod"
import { PageContext } from "@/pages/Standard/context"
import { PageContextType } from "@/types/Context"
import { ChangeEventHandler, useContext } from "react"
import "./index.scss"
import { SectionState } from "@/types/Section"

type Props = {
    text:string
    handleChange?:ChangeEventHandler<HTMLInputElement>
}

    const SectionHeading = ({text,handleChange}:Props)=>{
        const {isEditing}:PageContextType = useContext(PageContext)
        const sectionContext:SectionState = useContext(SectionContext)
        let style = {}

        if(sectionContext?.childStyles?.heading){
            style = sectionContext?.childStyles?.heading
        }
        if(handleChange&&isEditing){
            return(
            <h2 style={style} className="section-heading">

            <input onChange={handleChange} className="input-inherit" name="sectionHeading" value={text}></input>
            </h2>)

        }

        return(
            <h2  style={style} className="section-heading">{text}</h2>
        )
    }
    export default SectionHeading
    
    
    