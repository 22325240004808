const handlePadding = ({style}:any)=>{
    const defaultPadding = 50
    style.paddingLeft = `${defaultPadding}px`;
    style.paddingRight = `${defaultPadding}px`;
    if(!style["--maxWidth"]){
        return
    }
    const windowWidth = document.querySelector(".dynamic-nav-header")?.getBoundingClientRect().width
    const maxWidth = parseInt(style?.["--maxWidth"]?.replace("px",""))
    if(!windowWidth){
        return
    }
    if((maxWidth+defaultPadding*2)>=windowWidth){
        return
    }
    const restrictedPadding = (windowWidth-maxWidth)/2
    style.paddingLeft = restrictedPadding
    style.paddingRight = restrictedPadding
}

export default handlePadding