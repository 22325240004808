
    import "./index.scss"
    import React, { ChangeEvent } from "react"

    type Props = {
        label?:string
        name:string
        value:string
        handleChange:(e: ChangeEvent<HTMLInputElement>)=>void
        tooltip?:string
    }

    const InputCont = ({label,value,handleChange,name,tooltip}:Props)=>{
        return(
            <div className={`input-cont ${tooltip?"tooltip":""}`} data-tooltip={tooltip||""}>
                {!label?<></>:<label>{label}</label>}
                <input

                data-tooltip={tooltip||""} name={name} onChange={handleChange} value={value}></input>
            </div>
        )
    }
    export default InputCont
    
    
    