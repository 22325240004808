import { fexGet, fexPost, fexPut } from "@/utils/fex"

type CreateSectionSubmissionProps = {
    requestId:string
    userId:string
}

export const createSectionSubmission = async ({requestId,userId}:CreateSectionSubmissionProps)=>{
    const res = await fexPost(`api/section-submission/${requestId}`,{userId})
    return res
}

export const getSectionSubmission = async ({submissionId}:{submissionId:string})=>{
    const res = await fexGet(`api/section-submission/${submissionId}`)
    return res
}

type SubmitSectionProps = {
    submissionId:string
    sectionId:string
}

export const submitSectionSubmission = async ({submissionId,sectionId}:SubmitSectionProps )=>{
    try{
        const res = await fexPut(`api/section-submission/submit/${submissionId}`,{sectionId}) 
        return res
    }catch(err){
        return {error:true,message:(err as Error).message}
    }
}

export const acceptSubmission = async ({submissionId}:{submissionId:string} )=>{
    try{
        const res = await fexPut(`api/section-submission/accept/${submissionId}`,{}) 
        return res
    }catch(err){
        return {error:true,message:(err as Error).message}
    }
}