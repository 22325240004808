import "./index.scss"
import { useContext, useEffect, useLayoutEffect, useRef, useState } from "react"
import { NavSectionType, PageContextType } from "@/types/Context"
import { PageContext } from "@/pages/Standard/context"
import { isMobile } from "@/utils/screen"
import AddSectionDropdown from "../AddSectionDropdown"
import { DynamicObject, DynamicObjectWithNumber } from "@/types/generic"
import { parseStyles } from "@/utils/theme"
import LayoutModal from "../LayoutModal"
import { getElementFieldsWithPage } from "@/utils/layoutElements"
import Hamburger from "@/generics/buttons/Hamburger"
import HeaderName from "../HeaderName"
import ImageEditor from "@/generics/ImageEditorNew"
import route from "@/utils/route"
import { Context, ContextValueType } from "@/context"
import { changeLayoutElementFields } from "@/api/layoutElement"
import { getElementHeight } from "@/utils/elementProperties"
import useWindowResize from "@/hooks/useWindowResize"
import { getBackgroundBoolean } from "./helpers"
import { i } from "@/utils/images"
import { scrollToTop } from "@/utils/scrolling"
import handlePadding from "./handlePadding"
import getSectionsArrayFroNavBar from "./getSectionsArrayForNavBar"
import NavListItem from "./NavListItem"

    const DynamicNavBar = ({publicPage}:{publicPage?:boolean})=>{
        const windowSize = useWindowResize();
        const pageContextValues:PageContextType = useContext(PageContext)
        const [isDropDownOpen,setIsDropDownOpen] = useState(false)
        const {credentials}:ContextValueType = useContext(Context)
        const {calculatedValuesState,navStyle,isEditing,setNavStyle,page,navSections,calculatedValuesDispatch,paginated,handlePaginatedNav,editOrdinals} = pageContextValues
        const [styleString,setStyleString] = useState(navStyle)
        const [fields,setFields] = useState<DynamicObject>({})
        const [headerId,setHeaderId] = useState("0")
        const navBarRef = useRef(null)
        const [onSections,setOnSections] = useState<any>([])

        useLayoutEffect(() => {
            setTimeout(() => {
                if (navBarRef.current) {
                    const height = getElementHeight(navBarRef.current);
                    calculatedValuesDispatch({type:"setHeaderHeight",payload:height})
                }
        }, 500);
    }, []);
    // const onSections = navSections.filter((s:NavSectionType)=>(s.isOn&&s.inMenu))
    useEffect(()=>{
        const onSections = getSectionsArrayFroNavBar({sections:navSections,ordinals:editOrdinals})
        if(onSections){
            setOnSections(onSections)
        }
    },[editOrdinals])

        useEffect(()=>{
            if (navBarRef.current) {
                const height = getElementHeight(navBarRef.current);
                const headerOn = getBackgroundBoolean(styleString)
                calculatedValuesDispatch({type:"setHeaderOn",payload:headerOn})
                calculatedValuesDispatch({type:"setHeaderHeight",payload:height})
            }
        },[styleString,windowSize.width])


        useEffect(()=>setStyleString(navStyle),[navStyle])
        
        useEffect(()=>{
            if(!page){
                return
            }
            const fields = getElementFieldsWithPage({page,elementName:"header"})
            setFields(fields)

            const headerId = page.layoutElements.find((l)=>l?.elementName==="header")?._id
            if(!headerId){
                return
            }
            setHeaderId(headerId)
        },[page])
        
        if(!pageContextValues.page){
            return <></>
        }
        const handleClick = (e:React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            e.preventDefault();
            const eventTarget = e.target as HTMLAnchorElement
            const targetId = eventTarget.getAttribute("href")?.slice(1);
            if(!targetId){
                return
            }
            if(paginated){
                handlePaginatedNav(targetId)
            }
            const target = document.getElementById(targetId);
            if (!target) {
              return;
            }
            const offset = calculatedValuesState.headerHeight+(publicPage?0:40); // replace with your desired offset value
            const targetPosition = target.getBoundingClientRect().top + window.pageYOffset;
            const offsetPosition = targetPosition - offset;
            window.scrollTo({
              top: offsetPosition,
              behavior: "smooth"
            });
          };


        const handleImageURLChange = async (url:string)=>{
            const resp = await changeLayoutElementFields({elementId:headerId,fields:{logo:url}})
        }
        if(!isMobile()){

        let style:DynamicObjectWithNumber = {}
        let customClass = ""
        if(styleString){
            const addedStyles = parseStyles(styleString)
            style = {...style,...parseStyles(styleString).styles}
            customClass = addedStyles.class
            handlePadding({style})
        }

        const  getOpacity = (style:DynamicObjectWithNumber)=>{
            const background = (style.background as string)
            if(background.length===9&&background.slice(-2).toLowerCase()!=="ff"){
                return .9
            }
        
            return 1
        }


        if(customClass.includes("on__backgroundState")&&(getOpacity(style)===1)){
            style.boxShadow = "0 2px 8px -3px rgb(0 0 0 / 30%)";
            customClass+=" sticky"
        }
        else if(!customClass.includes("on__backgroundState")){
            style.position = "fixed";
        }
            return(
                <header ref={navBarRef} style={style}  className={customClass+" dynamic-nav-header"}>

                <nav>
                    <ul>
                        {
                            onSections.map((section:NavSectionType,index:number)=>{
                                return <NavListItem
                                section={section}
                                handleClick={handleClick}
                                key={index}                                
                                ></NavListItem>

                            })
                        }
                        
                    </ul>
                </nav>
                <div className="heading" onClick={()=>handlePaginatedNav("home")}>
                    <HeaderName isEditing={isEditing}                        
                    initialValue={fields?.text||""}
                    _id={headerId}
                    
                    ></HeaderName>

                    {!isEditing&&fields.logo!==""&&<img onClick={scrollToTop} src={i(fields?.logo)}></img>}
                    {isEditing&&<ImageEditor 
                    field={"logo"} 
                    credentials={credentials}
                    route={route("api/upload")}
                    handleEdit={handleImageURLChange} 
                    url={fields?.logo||""}
                    sectionId={headerId}
                    isLayoutElement={true} 
                    optional={true}
                    ></ImageEditor>}

        </div>

            </header>
        )
        }

            let style:DynamicObjectWithNumber = {}
            let customClass = ""
            if(styleString){
                const addedStyles = parseStyles(styleString)
                style = {...style,...parseStyles(styleString).styles}
                customClass = addedStyles.class
            }
    
            const  getOpacity = (style:DynamicObjectWithNumber)=>{
                const background = style.background as string
                if(background.length===9&&background.slice(-2).toLowerCase()!=="ff"){
                    return .9
                }
            
                return 1
            }
    
    
            if(customClass.includes("on__backgroundState")&&(getOpacity(style)===1)){
                style.boxShadow = "0 2px 8px -3px rgb(0 0 0 / 30%)";
                customClass+=" sticky"
            }
            else{
                style.position = "fixed";
                if(!publicPage&&!isMobile()){
                    style.transform = "translateY(68px)"
                }
                if(!publicPage&&isMobile()){
                    style.transform = "translateY(34px)"
                }
            }
            if(customClass.includes("off__backgroundState")){
                style.background = "none"
            }

    return<header style={style} className="mobile-dynamic-nav-header">
        <Hamburger open={isDropDownOpen} setIsOpen={setIsDropDownOpen}></Hamburger>
        {
            !isDropDownOpen?<></>:<div className="dropdown">
                                <nav>
                                
                    <ul>
                        {
                            onSections.map((section:NavSectionType,index:number)=>{
                                const heading = section.navName||section.heading||section.sectionName||section.sectionForm
                                return<li key={index} onClick={()=>setIsDropDownOpen(false)}>
                                <a  onClick={handleClick} href={`#${section.sectionId}`}>{heading}</a>

                                    </li>
                            })
                        }
                    </ul>
                </nav>
            </div>
        }
    </header>
    }
    export default DynamicNavBar
    
    
    