
    import "./index.scss"
    import React, { ChangeEvent, useContext, useEffect, useState } from "react"
import Modal from "@/generics/Modal"
import { parseStyles, stringifyStyles } from "@/utils/theme"
import { fonts } from "./options"
import { SketchPicker  } from 'react-color';
import { getScreenDimensions, isMobile } from "@/utils/screen"
import { PageContext } from "@/pages/Standard/context"
import { PageContextType } from "@/types/Context"
import { DynamicObject } from "@/types/generic"
import ImageEditor from "@/generics/ImageEditorNew"
import { Context, ContextValueType } from "@/context"
import route from "@/utils/route"
import { convertStyleVariableToURL } from "@/utils/stringFormatting"
import { editPageAdditionalFields } from "@/api/page"
import { addToFields } from "@/utils/fields"
import ColorSelector from "../ColorSelector"
import ThemePreview from "./ThemePreview"
import { PageDetailsType } from "@/types/Page"
import useHideMenuButtons from "@/hooks/useHideMenuButtons"
import { Field } from "@/types/Section"
import NewColorSelector from "../NewColorSelector"

    type Props = {
        style:string,
        setStyle:React.Dispatch<string>
    }


    const ThemeEditor = ({style,setStyle}:Props)=>{
        const [isOpen,setIsOpen] = useState(false)
        const {page,setPage,additionalFields,setAdditionalFields}:PageContextType = useContext(PageContext)
        const {credentials}:ContextValueType =useContext(Context)
         const getStyleVariables = (page:PageDetailsType)=>{
            const styleVariables:DynamicObject = {}
            page.additionalFields.forEach((field:Field)=>{
                const key = `--${field.key}`
                styleVariables[key] = field.value
            })
            return styleVariables
        }
        const [styleVariables,setStyleVariables] = useState(getStyleVariables(page))
        const [selectorOpen,setSelectorOpen] = useState<any>(null)

        const parsedStyles = parseStyles(style)

        const styles = parsedStyles.styles

        const classes = parsedStyles.class.split(" ")
        classes.forEach((classItem:string)=>{
            const [value,key] = classItem.split("__")
            styles[key] = value
        })
        
        const handleChange = (e:ChangeEvent<HTMLSelectElement>)=>{
            const {name,value} = e.target;
            const {type} = e.target.dataset
            const splitStyle = style.split(" ")
            let foundClass = false
            splitStyle.forEach((el,index)=>{
                if(type==="class"){
                    const match = `${name}xx`
                    if(el.includes(match)){
                        splitStyle[index] = `${name}xx${value}`
                        foundClass = true
                    }
                }
            })
            if(type==="class"&&!foundClass){
                splitStyle.push(`${name}xx${value}`)
            }
            const newStyleString = splitStyle.join(" ")
            setStyle(newStyleString)
        }



        const handleColorChange = ({color,field}:{color:string,field:string})=>{
            
            const fullColor = color
            const newParsed = {styles:{...parsedStyles.styles},class:parsedStyles.class}
            newParsed.styles[field] = fullColor
            const newString = stringifyStyles(newParsed)
            setStyle(newString)
        }

        const handleBackgroundImageChange = (image:string)=>{
            if(image===""){
                return
            }
            const fixedImage = image.replace("docUpload:",route("api/upload/"))
            const newStyleVariables = {...styleVariables}
            newStyleVariables["--backgroundImage"] = `url(${fixedImage})`
            setStyleVariables(newStyleVariables)
            editPageAdditionalFields({pageName:page.pageName,fields:{backgroundImage:fixedImage}})
            const newFields = addToFields({fields:additionalFields,newField:{key:"backgroundImage",value:fixedImage}})

            setAdditionalFields(newFields)
        }

        const {color,backgroundColor} = styles

        if(isMobile()){
            return<></>
        }
        return(
            <>
            {!isOpen&&<button  data-tooltip="This allows you to set styles for the page in general." className="tooltip down theme-btn" onClick={()=>setIsOpen(true)}>Theme</button>}
            {isOpen&&<Modal
               setIsModalOpen={setIsOpen} 
                additionalClass="theme-editor-modal"
            >
                <>
                {!selectorOpen&&<div className="all-fields">
                    <label>Font</label>
                    <select data-type="class" name="font" onChange={handleChange} value={styles.font}>
                        {fonts.map((font:string,key:number)=>{
                            return(
                                <option key={key} value={font}>{font}</option>
                                )
                            })}
                    </select>
                    <div></div>
                    <ColorSelector
                        prompt="Text Color"
                        value={color}
                        setValue={(color:string)=>handleColorChange({color,field:selectorOpen})}
                        color={styles.color}
                        backgroundColor={styles.backgroundColor}
                        setSelectorOpen={setSelectorOpen}
                        inline={true}
                        layout={"color"}
                    ></ColorSelector>
                {(styles.background==="color"||!styles.background)&&<>
                    <div></div><ColorSelector
                        prompt="Background Color"
                        value={backgroundColor}
                        setValue={(color:string)=>handleColorChange({color,field:selectorOpen})}
                        color={styles.color}
                        backgroundColor={styles.backgroundColor}
                        setSelectorOpen={setSelectorOpen}
                        layout={"backgroundColor"}
                        inline={true}
                    ></ColorSelector>
                </>}
                    <label>Background Type</label>
                    <select data-type="class" name="background" onChange={handleChange} value={styles.background}>
                        <option  value="color">Color</option>
                        <option  value="image">Image</option>
                </select>
                {(styles.background==="image")&&<>
                    <label>Background Image</label>
                    <ImageEditor 
                    credentials={credentials}
                    field="backgroundImage"
                    route={route("api/upload")}
                    url={convertStyleVariableToURL(styleVariables["--backgroundImage"])}
                    sectionId={page._id}
                    isSection={false}
                    handleEdit={handleBackgroundImageChange}
                    ></ImageEditor>
                </>}

                </div>}
                {
                    selectorOpen&&<>
                    {/* <ColorSelector
                    prompt="Background Color"
                    value={styles[selectorOpen]}
                    setValue={(color:string)=>handleColorChange({color,field:selectorOpen})}
                    color={styles[selectorOpen]}
                    backgroundColor={styles[selectorOpen]}
                    setSelectorOpen={setSelectorOpen}
                    layout={selectorOpen}
                    initialOpen={true}
                    // inline={true}
                ></ColorSelector> */}
                    <NewColorSelector
                        initialColor={styles[selectorOpen].toString()}
                        handleBack={()=>setSelectorOpen(null)}
                        handleChange={(color:string)=>{handleColorChange({color,field:selectorOpen})}}
                    ></NewColorSelector>
                </>
                }
                <ThemePreview
                styles={styles}
                styleVariables={styleVariables}
                parsedStyles={parsedStyles}
                
                ></ThemePreview>
                <Hider></Hider>
                </>
                </Modal>}
            </>
        )
    }
    export default ThemeEditor
    
    
    function Hider(){
        const hideButtonsRef = useHideMenuButtons()


        return<div ref={hideButtonsRef}>

        </div>
    }