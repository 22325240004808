import { Credentials } from "@/types/credentials"
import getJWT from "@/utils/getJWT"

type Props = {
  isSection?:boolean,
  isLayoutElement?:boolean,
  sectionId:string,
  file: File,
  setIsDropOpen?:React.Dispatch<boolean>,
  credentials:Credentials,
  field:string,
  route:string,

}

export const uploadFile = async ({isSection,sectionId,file,credentials,field,route,isLayoutElement}:Props)=>{

    let url = route

    let formData = new FormData()
  
    formData.append('image', file)
    formData.append("sectionId",sectionId)
    formData.append("field",field)
    formData.append("credentials",JSON.stringify(credentials))
    if(isSection){
      formData.append("isSection",isSection.toString())
    }
    if(isLayoutElement){
      formData.append("isLayoutElement",isLayoutElement.toString())
    }
    const bearer = getJWT()
    const resp = await fetch(url, {
      method: 'POST',
      headers:{
        'Authorization':`Bearer ${bearer}`
    },
      body: formData
    });
    const data= await resp.json()

    return data
  }


type HandleFilesArgs = {
  sectionId:string,
  isSection?:boolean,
  files:File[],
  credentials:Credentials,
  field:string,
  route:string
}

const handleFiles = async ({isSection,sectionId,files,credentials,field,route}:HandleFilesArgs)=>{
    let data;
    for (let i = 0;i<files.length;i++){
      data = await uploadFile({isSection,sectionId,file:files[i],credentials,field,route})
    }
    return data
}

export const handleDragEnter=(e:   any)=>{
    e.preventDefault();
    e.target.classList.add("highlight")
}
export const handleDragLeave=(e:    any)=>{
    e.preventDefault();
    e.target.classList.remove("highlight")
}
export const handleDragOver=(e:    any)=>{
    e.stopPropagation();
    e.preventDefault()
}

type HandleDropArgs = {
  e:  any,
  setIsDropOpen:React.Dispatch<boolean>,
  setImageUrl:React.Dispatch<string>,
  credentials:Credentials,
  handleEdit:   any,
  field:string,
  route:string,
  sectionId:string,
  isSection?:boolean
}


export const handleDrop=async ({isSection,e,setIsDropOpen,setImageUrl,handleEdit,credentials,field,route,sectionId}:HandleDropArgs)=>{

  if(!field){
      return
    }
    
    e.preventDefault()
    e.target.classList.remove("highlight")

    const dt = e.dataTransfer;
    const files = dt.files;
    const resp = await handleFiles({files,credentials,field,route,sectionId,isSection})

    if(resp.success){

        setImageUrl("docUpload:"+resp.key)
        setIsDropOpen(false)
        // At the end of this, assuming success, we pass the image path to the handler on the specific component's level.
        handleEdit(resp.key)
    }
    }

    export type HandleAddPictureArgs = {
      isSection?:boolean,
      sectionId:string,
      setIsDropOpen:React.Dispatch<boolean>
      setImageUrl:React.Dispatch<string>,
      handleEdit:Function,
      credentials:Credentials,
      field:string,
      route:string,
      setIsModalOpen:React.Dispatch<boolean>
      isLayoutElement?:boolean
    }


    export const handleAddPictureForm =async  (e:React.FormEvent<HTMLFormElement>,{isSection,sectionId,setIsDropOpen,setImageUrl,handleEdit,credentials,field,route,setIsModalOpen,isLayoutElement}:HandleAddPictureArgs)=>{
      e.preventDefault()
      const file = ((e.target as HTMLFormElement)[0] as any).files[0]
      const variables = {isSection,file,setIsDropOpen,credentials,field,route,sectionId,isLayoutElement}
      const resp = await uploadFile(variables)
      setIsModalOpen(false)
      setImageUrl("docUpload:"+resp.key)
      setIsDropOpen(false)
      // At the end of this, assuming success, we pass the image path to the handler on the specific component's level.
      if(isLayoutElement){
        handleEdit("docUpload:"+resp.key)
      return
      }
      
      handleEdit(resp.key)
  }