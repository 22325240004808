
    import Standard from "@/pages/Standard"
import "./index.scss"
    import React, { useEffect, useState } from "react"

    type Props = {
        submission:any
        requestId:string
    }

    const SectionSubmissionEvaluation = ({submission,requestId}:Props)=>{

        const [loading,setLoading] = useState(false)
        const pageName = `${submission.userId}-${requestId}`

        useEffect(()=>{
            setLoading(true)
        },[submission])

        useEffect(()=>{
            setLoading(false)
        },[loading])

        if(loading){
            return<div className="section-submission-preview">
            </div>
        }

        return(
            <div className="section-submission-preview">
            <Standard
            override={pageName}
            submission={submission}
            ></Standard>
            </div>
        )
    }
    export default SectionSubmissionEvaluation
    
    
    