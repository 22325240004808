
    import { PopulatedSectionSubmission } from "@/types/SectionSubmission"
    import "./index.scss"
    import { getStatus } from "./helpers"
import { useEffect, useId, useState } from "react"
import useGetIds from "@/hooks/useGetIds"
import { getUsersTemplatesTable } from "@/api/sectionTemplate"
import { TemplateRowType } from "@/types/tableRows"

    type Props = {
        submissions:PopulatedSectionSubmission[]
    }

    const SectionTemplateTable = ()=>{

        const [tableData,setTableData] = useState<TemplateRowType[]>([])
        const [{userId}] = useGetIds()
        

        useEffect(()=>{
            
            
            if(!userId){
                return
            }
            const f = async ()=>{
                const res = await getUsersTemplatesTable({userId})
                if(!res.error){
                    setTableData(res?.tableData||[])
                }
            }
            f()

        },[userId])


        return(
            <section className="table-cont">
                <h2>Past Development Projects</h2>
            <table className="section-submission-table">
                <thead>
                    <th>_id</th>
                    <th>Template Name</th>
                    <th>Section Type</th>
                    <th></th>
                </thead>
                <>
                {tableData.map((tableRow:TemplateRowType,index:number)=>{
                    return <TemplateRow
                    key={index}
                    tableRow={tableRow}
                    ></TemplateRow>
                })}
                </>
            </table>
    </section>
        )
    }
    export default SectionTemplateTable
    
    type RowProps = {
        tableRow:TemplateRowType
    }

    function TemplateRow({tableRow}:RowProps){

        const {templateName,sectionType,_id} = tableRow

        return<tr>
            <td>{_id}</td>
            <td>{templateName}</td>
            <td>{sectionType}</td>
            <td><a href={`/section-template-brancher/${_id}`}>Make Variation</a></td>
        </tr>
    }
    
    