
    import EmptyHeader from "@/layout/headers/EmptyHeader"
import "./index.scss"
    import React, { useEffect, useState } from "react"
import useGetIds from "@/hooks/useGetIds"
import { getDevCenterData } from "@/api/devCenter"
import { DevCenterData } from "./types"
import SectionSubmissionsTable from "@/components/SectionSubmissionsTable"
import { PopulatedSectionSubmission } from "@/types/SectionSubmission"
import TemplateTable from "@/components/TemplateTable"
import SectionSubmissionEarnings from "@/components/SectionSubmissionEarnings"
import Experimental from "@/wrappers/Experimental"

    const DevCenter = ()=>{

        const [ids] = useGetIds()
        const [idTested,setIdTested] = useState(false)
        // DevCenterDataType needs to be filled out
        const [devCenterData,setDevCenterData] = useState<null|DevCenterData>(null)
        useEffect(()=>{
            setTimeout(()=>setIdTested(true),2000)
        },[])

        const userId = ids?.userId
        
        useEffect(()=>{
            if(!userId){
                return
            }
            const f = async ()=>{
                const res = await getDevCenterData({userId})
                if(!res.data){
                    return
                }
                setDevCenterData(res.data)
            }
            f() 
        },[userId])

        if(!ids.userId&&idTested){
            window.location.href = "/login"
        }

        if(!ids.userId){
            return<></>
        }

        const submissions:(PopulatedSectionSubmission[]|undefined) = devCenterData?.submissions

        return(
            <Experimental>
            <EmptyHeader additionalClass="filled-in help-page">
                <h1> {"{      Dev Center      }"}</h1>
            </EmptyHeader>
            <main className="dev-center-main">
                <div className="action-items">
                    <h2>Want Freelance work?</h2>
                <a href="/section-jobs">Find a Project on Our Jobs Board</a>
                </div>
                {submissions?<SectionSubmissionEarnings
                submissions={submissions}
                ></SectionSubmissionEarnings>:<div></div>}
                {!submissions?<></>:<SectionSubmissionsTable
                submissions={submissions}
                ></SectionSubmissionsTable>}
                <TemplateTable></TemplateTable>
            </main>
            </Experimental>
        )
    }
    export default DevCenter
    
    
    