
    import "./index.scss"
    import React, { useContext } from "react"
import { PageContext } from "@/pages/Standard/context"
import { PageContextType } from "@/types/Context"
import { SectionState, SectionType } from "@/types/Section"
import { SectionContext } from "@/components/PuxMod"
import AutoResizeTextArea from "@/generics/AutoResizeTextArea"

    type Props = {
        field:string
    }

    const H3 = ({field}:Props)=>{

        const sectionState:SectionState = useContext(SectionContext)
        const {fields,handleInputChange} = sectionState
        const value = fields[field]

        const {isEditing}:PageContextType = useContext(PageContext)


        if(isEditing){
            return<h3 className="h3">
                <AutoResizeTextArea
                name={field} onChange={handleInputChange} className="h3" value={value}
                
                ></AutoResizeTextArea>
                {/* <input name={field} onChange={handleInputChange} className="h3" value={value}></input> */}
                </h3>
        }

        return(
            <h3 className="h3">{value}</h3>
        )
    }
    export default H3
    
    
    