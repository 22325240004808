import { createDummyParent, toggleClass } from "@/utils/DOM"

type ChangeImageAlignmentProps = {
    html:string
    imageId:string
    alignment:string
}

export const changeImageAlignment = ({html,imageId,alignment}:ChangeImageAlignmentProps)=>{
    const dummyParent = createDummyParent({html})
    const imageContainer = dummyParent.querySelector(`.image-editor-cont[data-image-id="${imageId}"]`)
    if(!imageContainer){
        return
    }
    const classOptions = ["left","full","right"]
    toggleClass({newClass:alignment,classOptions,element:(imageContainer as HTMLElement)})
    const newHTML = dummyParent.innerHTML;
    return newHTML
}

type DeleteImageProps = {
    html:string
    imageId:string
}

export const deleteImage = ({html,imageId}:DeleteImageProps)=>{
    const dummyParent = createDummyParent({html})
    const imageContainer = dummyParent.querySelector(`.image-editor-cont[data-image-id="${imageId}"]`)
    if(!imageContainer){
        return
    }
    imageContainer.remove()
    const newHTML = dummyParent.innerHTML;
    return newHTML
}


type GenerateHTMLPropsForDBProps = {
    container:HTMLDivElement
}

export const generateHTMLForDB = ({container}:GenerateHTMLPropsForDBProps)=>{
    container.className = container.className.replace("live-content ","")
    container.removeAttribute("contenteditable")
    return container.outerHTML
}


export const getInitialValues = (string:string)=>{
    const dummyParent = createDummyParent({html:string})
    const container = dummyParent.querySelector("div")
    if(!container){
        return {html:string,font:undefined}
    }
    const html = container.innerHTML
    const foundClassName = container.className.split(" ").find((classItem)=>{
        return classItem.includes("font")
    })
    let font;
    if(foundClassName){
        font = foundClassName.split("__")[0]
    }
    return {html,font}
}