
    import "./index.scss"
    import React from "react"

    type Props = {
        handleClick:()=>void
        text?:string
        additionalClass?:string
    }

    const FullWidthAdd = ({handleClick,additionalClass,text}:Props)=>{
        return(
            <div className="full-width-add">
                <button onClick={handleClick}>+ {text||""}</button>
            </div>
        )
    }
    export default FullWidthAdd
    
    
    