// tryLogginInWithGoogle

import { APIFailure } from "@/types/API"
import { GoogleLoginResponse } from "@/types/GoogleLoginResponse"
import { fexPost } from "@/utils/fex"

type UserWithoutPassword = {
    _id:string
    email:string
    isEmailConfirmed:boolean
    name:string
    pages:string[]
    _v:number
    stripeId:string
}

export type LoginAPISuccessResponse = {
    message:"success",
    user:UserWithoutPassword,
    accessToken:string
}


export const loginWithGoogle = async (token:string):Promise<(LoginAPISuccessResponse | APIFailure)>=>{
    const data:(LoginAPISuccessResponse | APIFailure) = await fexPost("api/googleAuth/login",{token})
    return data
}

export const registerWithGoogle = async (token:string):Promise<(LoginAPISuccessResponse | APIFailure)>=>{
    const data:(LoginAPISuccessResponse | APIFailure) = await fexPost("api/googleAuth/register",{token})
    return data
}