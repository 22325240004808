
    import { FaCoins } from "react-icons/fa"
import "./index.scss"
    import React, { useContext } from "react"
import CountFloater from "@/generics/CountFloater"
import { Context, ContextValueType } from "@/context"
import { Link } from "react-router-dom"
import Experimental from "@/wrappers/Experimental"

    const HeaderTokens = ()=>{
        
        const {tokens}:ContextValueType = useContext(Context)
        
        return(
            <Experimental>

            <Link to="/purchase-tokens">
            <div className="header-token-cont tooltip" style={{position:"relative"}}>
                <CountFloater
                count={tokens}
                ></CountFloater>
            <FaCoins size={27}></FaCoins>
            </div>
                </Link>
                </Experimental>
        )
    }
    export default HeaderTokens
    
    
    