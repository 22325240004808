import { EditOrdinalsType } from "@/pages/Standard/context"
import { PageDetailsType } from "@/types/Page"
import { SectionType } from "@/types/Section"

export const getOrdinalPairs = (page:PageDetailsType)=>{
    const res = page.sections.map((s:SectionType)=>{
        return {sectionId:s._id,ordinal:s.ordinal}
    })
    return res
}

export const getEditOrdinal = ({sectionId,editOrdinals}:{sectionId:string,editOrdinals:EditOrdinalsType[]})=>{
    const obj = editOrdinals.find((e:EditOrdinalsType)=>e.sectionId===sectionId)
    if(obj?.ordinal===0){
        return 0
    }
    if(!obj?.ordinal){
        return 100
    }
    return obj.ordinal
}