import { CSSLineType } from "@/components/CSSEditor"
import { DynamicAnyObject } from "@/types/generic"
import { makeFullStyleObject } from "../theme"

type CreateCustomTemplateStyleObjectArgs = {
    styleString:string
    customCSSArray:CSSLineType[]
}

export const createCustomTemplateStyleObject = ({styleString,customCSSArray}:CreateCustomTemplateStyleObjectArgs)=>{
    const obj:DynamicAnyObject = {}
    customCSSArray.forEach((cssLine,index)=>{
        if(cssLine.editable&&cssLine.variable){
            obj[cssLine.variable] = cssLine.value
        }
    })
    const specifiedStyleObject = makeFullStyleObject(styleString)
    for (let key in specifiedStyleObject){
        obj[key] = specifiedStyleObject[key]
    }
    return obj
}