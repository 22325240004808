import { useStripe, useElements,Elements, CardElement, CardNumberElement, CardCvcElement, CardExpiryElement, CartElement, PaymentElement } from '@stripe/react-stripe-js';    
import "./index.scss"
import stripe,{loadStripe} from '@stripe/stripe-js';
    import React, { useContext } from "react"
import { Context, ContextValueType } from '@/context';
import { addPaymentMethodToUser } from '@/api/stripe';
import { PaymentMethod } from '@/types/Stripe';
import { FaStripe } from 'react-icons/fa';

    const liveStripeKey = "pk_live_51MK6pnKFhLOoQfiBWhXgKS8leRZkfIsOPswtjf3VQf3svfjkS7sOw2HoMNO0NsHB48SsTFX2AAOgnGtn3tVW9yNq00TGBE9zFs"
    const testStripeKey = 'pk_test_51MK6pnKFhLOoQfiBW8rOOSzn6oTjkIB2cqSc34yg1XP4PZVeGJyefY3jzL8Sd4KAJG9guoytz2NaOkRLVQT8SaeO005uXZccB0'
    let stripeKey;
    if(window.location.origin==="http://localhost:3000"){ 
        stripeKey=testStripeKey
    }else{
        stripeKey=liveStripeKey
    }
    const stripePromise = loadStripe(stripeKey);

    type Props = {
        setIsAdding:React.Dispatch<boolean>
        paymentMethods:PaymentMethod[],
        setPaymentMethods:React.Dispatch<PaymentMethod[]>,
        addCardCallback:()=>void
    }

    const AddCreditCard = ({setIsAdding,paymentMethods,setPaymentMethods,addCardCallback}:Props)=>{
        const options = {
            // passing the client secret obtained from the server
            // clientSecret: '{{CLIENT_SECRET}}',
        };
        
        return(
            <Elements stripe={stripePromise} options={options}>
                <AddPaymentMethodStripe paymentMethods={paymentMethods} setPaymentMethods={setPaymentMethods}  setIsAdding={setIsAdding}
                addCardCallback={addCardCallback}
                
                />
            </Elements>
        )
    }
    export default AddCreditCard
    
    type AddPMProps = {
        setIsAdding:React.Dispatch<boolean>,
        paymentMethods:PaymentMethod[],
        setPaymentMethods:React.Dispatch<PaymentMethod[]>,
        addCardCallback:()=>void
    }

    function AddPaymentMethodStripe({setIsAdding,paymentMethods,setPaymentMethods,addCardCallback}:AddPMProps){
        const {credentials}:ContextValueType = useContext(Context)
        const userId = credentials.user._id
        const stripe = useStripe()
        const elements = useElements();
        
        const handleAddCard = async ()=>{

            if(!stripe||!elements){
                return
            }

            const {error, paymentMethod} = await stripe.createPaymentMethod({
                type: 'card',
                card: elements.getElement(CardNumberElement)||{token:""},
            });  
            if(!paymentMethod){
                return
            }     
            const paymentMethodId = paymentMethod.id
            addPaymentMethodToUser({paymentMethodId,userId})
            setIsAdding(false)
            setPaymentMethods([...paymentMethods,paymentMethod])
            addCardCallback()
        }

        const options = {
            showIcon:true,
            style: {
                iconStyle: 'default',
              base: {
                fontSize: '20px',
                color: '#555555',
                '::placeholder': {
                  color: '#aab7c4',
                },
                backgroundColor:"#eeeeee",
                fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                fontSmoothing: 'antialiased',
                ':-webkit-autofill': {
                  color: '#fce883',
                },
                padding:"10px",
                display:"flex",
                flexDirection:"column",
                wordWrap:"break-word"
              },
              invalid: {
                color: '#9e2146',
              },
            },
          }

        return(<>
        {/* <CardElement options={options} className='pm-card'></CardElement> */}
        <div>
        
        
        <div className='card-elements-cont'>
            <div className='stripe-element-cont number'>
            <label>Number</label>
            <CardNumberElement options={options}></CardNumberElement>
            </div>
            <div className='stripe-element-cont expiration'>
            <label>Expiration</label>
            <CardExpiryElement options={options}></CardExpiryElement>
            </div>
            <div className='stripe-element-cont cvc'>
            <label>CVC</label>
            <CardCvcElement options={options}></CardCvcElement>
            </div>
            <div className='stripe-sign'>
                payments by<FaStripe size={70}></FaStripe>
                </div>
            <div className='btn-cont'>
        <button onClick={handleAddCard}>Add Card</button>
        <button className='danger' onClick={()=>setIsAdding(false)}>Cancel Add Card</button>

        </div>
            </div>
        </div>
        </>)
}


