
    import { DynamicObject } from "@/types/generic"
import "./index.scss"
    import React from "react"

    type Props = {
        children:JSX.Element|JSX.Element[]
        buttonHandler:(arg1:(DynamicObject|undefined))=>void
        payload?:DynamicObject
        top?:string
        bottom?:string
        left?:string
        right?:string
   }

    const LittleCircleButton = ({children,top,bottom,left,right,buttonHandler,payload}:Props)=>{

        const style = {
            top:top||"unset",
            bottom:bottom||"unset",
            left:left||"unset",
            right:right||"unset"
        }

        return(
            <button onClick={()=>buttonHandler(payload)} style={style} className="little-circle-button">
                {children}
            </button>
        )
    }
    export default LittleCircleButton
    
    
    