    import "./index.scss"
    import React, { useEffect, useState } from "react"
    import { useContext } from "react"
import { Context } from "@/context"
import { DynamicObjectWithNumber } from "@/types/generic"
import useSectionZIndex from "@/hooks/useSectionZIndex"

    type Props = {
        modalTitle?:string,
        children?:JSX.Element,
        additionalClass?:string,
        setIsModalOpen?:React.Dispatch<boolean>,
        style?:DynamicObjectWithNumber
        position?:"center"
    }

    const Modal = ({modalTitle,additionalClass,children,setIsModalOpen,style,position}:Props)=>{
        const sectionElement = useSectionZIndex();

        let setIsModalOpenResolved:    any;
        const [expanded,setExpanded] = useState<boolean>(false)
        const {setModalOpen}:{setModalOpen:React.Dispatch<React.SetStateAction<string|null>>} = useContext(Context)
        if(!setIsModalOpen){
            setIsModalOpenResolved = setModalOpen
        }else{
            setIsModalOpenResolved = setIsModalOpen
        }

        useEffect(()=>{
            setExpanded(true)
        },[])


        return(
            <div className={`modal-overlay ${additionalClass}-overlay `}>
            <div ref={sectionElement} style={style||{}} className={`modal ${additionalClass} ${expanded?"expanded":""} ${position||""}-pos`}>
                <button className="close-modal-button no-translate-animation" onClick={()=>setIsModalOpenResolved(null)}>×</button>
                <section className="modal-content">
                {modalTitle&&<h1>{modalTitle}</h1>}
                {children}
                </section>

            </div>
            </div>
        )
    }
    export default Modal
    
    
    