
    import { getUsersPages } from "@/api/user"
import "./index.scss"
    import React, { MouseEventHandler, useContext, useEffect, useState } from "react"
import { Context, ContextValueType } from "@/context"
import { PageDetailsType } from "@/types/Page"
import { deletePage } from "@/api/page"
import Confirmation from "@/generics/Confirmation"
import ConfirmationPopUp from "@/generics/ConfirmationPopUp"

    const InactivePageManagement = ()=>{
        
        const [inactivePages,setInactivePages] = useState<PageDetailsType[]>([])
        const {credentials}:ContextValueType = useContext(Context)
        const [confirmationPayload,setConfirmationPayload] = useState<null|string>(null)

        useEffect(()=>{
            const get = async ()=>{

                const pages = await getUsersPages({credentials})
                if(!Array.isArray(pages)){
                    return
                }
                const inactivePages = pages.filter(p=>!p.status.isOn)
                setInactivePages(inactivePages)
            }
            get()
        },[])

        const handleDelete:MouseEventHandler<HTMLButtonElement> = (e)=>{
            const target = e.target as HTMLElement;
            const {pageId} = target.dataset
            if(!pageId){
                return
            }
            setConfirmationPayload(pageId)
        }

        const handleConfirm = (pageId:string)=>{
            const newPageArray = inactivePages.filter(p=>p._id!==pageId)
            setInactivePages(newPageArray)
            deletePage(pageId) 
        }

        return(
            <div className="inactive-pages">
                 <h2>Inactive Pages</h2>

                {inactivePages.map((page:PageDetailsType,index:number)=>{
                    return<div className="page-line">
                        <span>
                            {page.pageName}
                            </span>
                    
                    <button onClick={handleDelete} data-page-id={page._id}>Delete</button>
                    
                    </div>
                })}
                {confirmationPayload&&<ConfirmationPopUp
                confirmationPayload={confirmationPayload}
                setConfirmationPayload={setConfirmationPayload}
                handleConfirm={handleConfirm}
                ><h3>Delete Page?</h3></ConfirmationPopUp>}
            </div>
        )
    }
    export default InactivePageManagement
    
    
    