

export default     {
    prompt:"Image Size",
    field:"--imageHeight",
    type:"style",
    default:"30vw",
    options:[
        {value:"10vw",text:"10"},
        {value:"20vw",text:"20"},
        {value:"30vw",text:"30"},
        {value:"40vw",text:"40"},
    ]
}