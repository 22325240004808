
    import "./index.css"
    import React from "react"

    type Props = {
        message?:string
    }

    const LoadingSpinner = ({message="Loading..."}:Props)=>{

        const letters = message.split("").map(char=>{
            if(char===" "){
                return "\u00A0"
            }
            return char
        })

        return(
            <div className="loading-overlay">
                <h1>
                {letters.map((l:string,i:number)=>{
                    return<span style={{animationDelay:(i*200)+"ms"}} key={i}>{l}</span>
                })}
                </h1>
                </div>
        )
    }
    export default LoadingSpinner
    
    
    