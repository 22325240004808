
    import "./index.scss"
    import React from "react"
import { SectionType } from "@/types/Section"
import PuxMod from "@/components/PuxMod"
import GenericSubsection from "@/components/GenericSubsection"
import EGenericSubsection from "@/subsections/EGenericSubsection"
import SubPuxMod from "@/components/SubPuxMod"
import { GenericSubsectionDocument } from "@/types/Documents"

    const ThreeItems = ({section}:{section:SectionType})=>{
        const items = section.genericSubsections
        return(
            <PuxMod section={section}>
                <Children items={items}></Children>
            </PuxMod>
        )
    }
    export default ThreeItems


    function Children({items}:{items:GenericSubsectionDocument[]}){

        return(<div className="content">
                {items.map((item,index:number)=>{
                    return<SubPuxMod key={index} subsection={item}>
                        <EGenericSubsection></EGenericSubsection>
                    </SubPuxMod>

                })}
            </div>)

    }
    
    
    