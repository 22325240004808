
    import "./index.scss"
    import React, { useContext } from "react"
import { PageContext } from "@/pages/Standard/context"
import { PageContextType } from "@/types/Context"
import { SectionState, SectionType } from "@/types/Section"
import { SectionContext } from "@/components/PuxMod"

    type Props = {
        field:string
    }

    const P = ({field}:Props)=>{

        const sectionState = useContext(SectionContext)
        const {fields,handleInputChange}:SectionState = sectionState
        const value = fields[field]

        const {isEditing}:PageContextType = useContext(PageContext)


        if(isEditing){
            return<p className="p">
                <textarea name={field} onChange={handleInputChange} className="p" value={value}></textarea>
                </p>
        }

        return(
            <p className="p">{value}</p>
        )
    }
    export default P
    
    
    