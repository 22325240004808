
import "./index.scss"
import React, { useContext, useState } from "react"
import { SectionContext } from "@/components/PuxMod"
import { PageContextType } from "@/types/Context"
import { PageContext } from "@/pages/Standard/context"
import Video from "@/generics/Video"
import InputAndLabelContainer from "@/generics/Containers/InputAndLabelContainer"
import { SectionState } from "@/types/Section"
import { loggo } from "@/utils/dev"
import Section from "@/generics/Section"
import { SubsectionContext } from "@/components/SubPuxMod"
import { SubsectionState } from "@/hooks/useSubsectionState"

    type Props = {
        field:string
        width?:number
        subsection?:boolean
    }

    const EVideo = ({field,width,subsection}:Props)=>{
        const {isEditing}:PageContextType = useContext(PageContext)

        const sectionState:SectionState = useContext(SectionContext)
        const subsectionState:any = useContext(SubsectionContext)
        let fields;
        let handleInputChange
        if(subsection){
            fields = subsectionState.fields
            handleInputChange = subsectionState.handleInputChange
        }else{
            fields = sectionState.fields
            handleInputChange = sectionState.handleInputChange
        }
        
        const videoURL = fields[field]||sectionState.fields[field]

        if(isEditing){
        return(
            <div className={field+"_class e-video"}><Video
            setHeight={true}            
            src={videoURL}></Video>
            <InputAndLabelContainer>
            <label>Youtube URL: </label>
            <input value={fields[field]} name={field} onChange={handleInputChange}></input>
            </InputAndLabelContainer>
            </div>
            )
        }
            return(
                <Video src={videoURL}></Video>
            )
    }

    export default EVideo
    
    
    