
    import useSkipFirstMutation from "@/hooks/useSkipFirstMutation"
import "./index.scss"
    import React, { useEffect, useState } from "react"
import { validateHex } from "./helpers"

    const Hex = ({color,setColor}:{color:string,setColor:(arg0:string)=>void})=>{

        const [interiorHex,setInteriorHex] = useState(color)

        const handleChange = (string:string)=>{
            if(string.length>6){
                return
            }
            const newString = "#"+string+color.slice(-2)
            setInteriorHex(newString)
        }
        
        useSkipFirstMutation(()=>{
            if(!validateHex(interiorHex)){
                return
            }
            setColor(interiorHex)
        },[interiorHex])

        return(
        <div className="hex-cont">
            #
            <input onChange={(e)=>handleChange(e.target.value)} value={interiorHex.slice(1,-2)}></input>
        </div>
        )
    }
    export default Hex
    
    
    