import { SocialsType } from "@/types/Socials"
import Copyright from "../Copyright"
import FooterLinks from "../FooterLinks"
import Logo from "../Logo"
import Section from "../Section"
import Socials from "../Socials"
import "./index.css"

    type FooterLinkList = {
        heading:string,
        links:{text:string,href:string}[]
    }

    type Props = {
        footerLinksLists?:FooterLinkList[],
        children?:JSX.Element|JSX.Element[]
        socials:SocialsType
    }
    const Footer = ({footerLinksLists,socials,children}:Props)=>{
        return(
            <div className="footer-style">
            <Section>
                <div className="footer-grid">
                <Logo text=""></Logo>
                <Socials socials={socials}></Socials>
                <Copyright year={2022} entity={"Joe Christianson"}></Copyright>
                {children}
                {footerLinksLists&&footerLinksLists?.map((list,key)=>{
                    return(
                        <div key={key} className={`footer-links footer-links-${key}`}>
                            <h3>{list.heading}</h3>
                            <ul>
                                {list.links&&list.links.map(((link,key)=>{
                                    return(
                                        <li key={key}>
                                            <a href={link.href}>{link.text}</a>
                                        </li>
                                    )
                                }))}
                            </ul>
                        </div>
                    )
                })}
                
                </div>
            </Section>
            </div>
        )
    }
    export default Footer
    
    
    