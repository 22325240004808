
    import { getSectionJobs } from "@/api/sectionRequest"
import "./index.scss"
    import React, { useEffect, useState } from "react"
import { SectionRequest } from "@/types/SectionRequest"
import Modal from "@/generics/Modal"
import useGetIds from "@/hooks/useGetIds"
import { getUserStatus } from "./helpers"
import LoadingSpinner from "@/generics/LoadingSpinner"
import { createSectionSubmission } from "@/api/sectionSubmission"
import Logo from "@/generics/Logo"
import BackButtonCont from "@/components/BackButtonCont"

    const SectionJobs = ()=>{

        const [jobs,setJobs] = useState<SectionRequest[]>([])
        const [current,setCurrent] = useState<null|number>(null)

        useEffect(()=>{
            const f = async ()=>{
                const res = await getSectionJobs()
                setJobs(res||[])
            }
            f()
        },[])

        return(<>
            <header className="section-jobs-header">
                <Logo text=""></Logo>
                <h3>Section Jobs</h3>
                <button className="back-btn" onClick={()=>window.location.href = "/dev-center"}>Back</button>
            </header>
            <main className="jobs-main">
            <section className="section-jobs-board">
            <div className="search-bar"><input placeholder="search"></input></div>
                {jobs.map((job,index)=>{
                    
                    return<article>
                        <aside>
                            ${job.budgetDollars}
                        </aside>
                        <section>

                        <h5>requested by: {job.userName||"anonymous"}</h5>
                        <h3>
                        {job.sectionRequestTitle}
                        </h3>
                        <a onClick={()=>setCurrent(index)}>Details</a>
                        </section>
                        </article>
                })}
            </section>
                </main>
                {
                    current!==null&&<JobDetails setCurrent={setCurrent} job={jobs[current]}></JobDetails>
                }
        </>
        )
    }
    export default SectionJobs
    
    type DetailsProps = {
        job:SectionRequest
        setCurrent:React.Dispatch<null|number>
    }

    function JobDetails({job,setCurrent}:DetailsProps){

        const [isModalOpen,setIsModalOpen] = useState(true)
        const [isLoading,setIsLoading] = useState(false)
        const [ids] = useGetIds()
        const {userId} = ids
        const developerCount = job?.developers?.length||0
        const submissionCount = job?.submissions?.length||0

        const userStatus = getUserStatus({userId,job})
        const handleCreate = async ()=>{
            if(!userId){
                return
            }
            setIsLoading(true)
            const res:any = await createSectionSubmission({requestId:job._id,userId})
            setIsLoading(false)
            const data = res.data;
            if(!data?.submissionId){
                return
            }
            const dest = `${window.location.origin}/freelance-dev/${data.submissionId}`
            window.location.replace(dest)
        }


        useEffect(()=>{
            if(!isModalOpen){
                setCurrent(null)
            }
        },[isModalOpen])

        if(isLoading){
            return<LoadingSpinner></LoadingSpinner>
        }



        return<Modal
        setIsModalOpen={setIsModalOpen}
        additionalClass="job-details-modal"
        >
            <article>
                <header>
                        <aside>
                            <h2>
                            ${job.budgetDollars}
                            </h2>
                        </aside>
                        <h5>requested by: {job.userName||"anonymous"}</h5>
                </header>
                        <section>

                        <h3>
                        {job.sectionRequestTitle}
                        </h3>
                        <p>Instructions: {job.sectionContent}</p>
                        <a className="view-current" target="_blank" href={`${window.location.href.replace("section-jobs",job.pageName)}`}>View Current Site</a>
                        <div>
                            {job.assets.map((asset,index)=>{
                                return<img src={asset}></img>
                            })}
                        </div>
                        </section>
                        <footer>
                        {(userStatus==="same")&&<div>
                                <p className="request-disclaimer">This is your request for your page: {job.pageName}</p>
                                {!submissionCount&&<p>There are no current submissions.</p>}
                                {submissionCount?<button onClick={()=>alert("not implemented yet.")}>View {submissionCount} submissions</button>:<></>}
                            </div>
                        }
                            {(userStatus==="open")&&<div>
                                <p className="request-disclaimer">This section request is viewable by any developer on Puxhub. Multiple developers may create sections for this job. It is the right of the client submitting this request to choose which to accept. They will only pay out to the developer with the chosen design.</p>
                                <button onClick={handleCreate}>Build section</button>
                            </div>
                            }
                        </footer>
                        </article>
        </Modal>
    }