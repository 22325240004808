
const layoutOptionsObject = {
    prompt:"Paragraph Font Size",
    field:"fontSize",
    child:".auto-textarea,.text-cont",
    type:"childClass",
    valueType:"string",
    options:[
        {value:"size-set-small",text:"Small"},
        {value:"size-set-medium",text:"Medium"},
        {value:"size-set-large",text:"Large"}
    ]
}

export default layoutOptionsObject