
    import "./index.scss"
    import React from "react"
import { SectionType } from "@/types/Section"
import PuxMod from "@/components/PuxMod"
import ETextArea from "@/components/EditableComponent/ETextArea"
import EButton from "@/components/EditableComponent/EButton"
import EVideo from "@/components/EditableComponent/EVideo"

    const VideoWithText = ({section}:{section:SectionType})=>{
        return(
            <PuxMod section={section} headless={true}>
            <div className="content">
            <div className="left">

            <ETextArea
            field="heading"
            ></ETextArea>
            <ETextArea
            field="text"
            ></ETextArea>
            {false&&<EButton
            field="buttonText"
            ></EButton>}

            </div>
            <div className="right">
                <EVideo field="url"></EVideo>
            </div>
            </div>
        </PuxMod>
        )
    }
    export default VideoWithText
    
    
    