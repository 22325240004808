    import "./index.css"
    import React from "react"

    type Props = {
        additionalClass?:string
        children:JSX.Element|JSX.Element[]
    }

    const Header = ({additionalClass,children}:Props)=>{
        return(
            <header className={`${additionalClass}`}>
                {children}
            </header>
        )
    }
    export default Header
    
    
    