
    import "./index.scss"
    import React from "react"
import { FaEdit } from "react-icons/fa"

    type Props = {
        setIsEditing:React.Dispatch<boolean>,
        position?:any
        size?:number
        tooltip?:string
    }

    const EditButton = ({setIsEditing,position,size,tooltip}:Props)=>{
        
        const style = {
            padding:`${(size||30)/2}px`,
            paddingTop:`${(size||30)/3}px`,
            paddingLeft:`${(size||30)/2*1.5}px`,
            position:position||"absolute"
        }
    
        
        return(
            <button style={style} onClick={()=>setIsEditing(true)} data-tooltip={tooltip||""} className={`edit-button-generic ${tooltip?"tooltip":""}`}>
                <FaEdit size={size||30}></FaEdit>
            </button>
        )
    }
    export default EditButton
    
    
    