
    import "./index.css"
    import React from "react"
import { LayoutProperty } from "@/types/LayoutProperty"
import ObjectProperties from "@/generics/ObjectProperties"
import { getStep } from "./helpers"

    type Props = {
        layout:LayoutProperty
        // we have to decide how to handle making sure it is a number here
        value:number
        handleChange:({field,value}:{field:string,value:(number|string)})=>void
        unit?:string
    }

    const LayoutSlider = ({layout,value,handleChange,unit}:Props)=>{

        const sliderHandler = (numberValue:number)=>{
            let value:(string|number) = numberValue
            if(unit){
                value = `${value}${unit}`
            }
            handleChange({value,field:layout.field})
        }

        return(
            <>
            <input onChange={(e)=>sliderHandler(parseFloat(e.target.value))} step={getStep()} value={value} min={layout?.range?.[0]||0} max={layout?.range?.[1]||100} type="range"></input>
            </>
        )
    }
    export default LayoutSlider
    
    
    