
    import "./index.scss"
    import React, { useContext, useEffect, useRef, useState } from "react"
import sectionTypes from "@/data/sectionTypes"
import { PageContext } from "@/pages/Standard/context"
import { PageContextType } from "@/types/Context"
import { t } from "@/utils/stringFormatting"
import { setToTop } from "@/utils/positioning"
import AddSectionView from "../AddSectionView"

    const AddSectionDropdown = ({buttonClass,isOpenOverride}:{buttonClass:string,isOpenOverride?:boolean})=>{
        const dropdownRef = useRef(null)
        const [isOpen,setIsOpen] = useState(isOpenOverride||false)
        const {addSection}:PageContextType=useContext(PageContext)
        const [value,setValue] = useState("")

        const handleClick = (sectionType:string)=>{
            setIsOpen(false)
            addSection(sectionType)
        }
        
        useEffect(()=>{

            const div = dropdownRef.current;
            setToTop({element:div})
        },[isOpen])

        return(
            <div className="add-section-cont">
            {!isOpen?<button className={buttonClass?buttonClass:""} onClick={()=>setIsOpen(true)}>Add Section +</button>:<AddSectionView
            sections={sectionTypes}
            close={()=>setIsOpen(false)}
            handleAdd={(sectionType:string)=>handleClick(sectionType)}
            ></AddSectionView>}
            </div>
        )
    }
    export default AddSectionDropdown
    
    
    