
    import "./index.scss"
    import React from "react"

    type Props = {
        currentPage:number
        pageNumbers:number[]
        setCurrentPage:React.Dispatch<number>
    }

    const PageNumberBar = ({currentPage,pageNumbers,setCurrentPage}:Props)=>{
        
        const activeNumbers = [pageNumbers[0],pageNumbers[pageNumbers.length-1]]
        const localRange = [currentPage-2,currentPage-1,currentPage,currentPage+1,currentPage+2]
        activeNumbers.push(...localRange)
        const gaps = {before:false,after:false}
        
        
        return(<footer  className="page-number-bar">
            {pageNumbers.map((number,index) => {
                if(!activeNumbers.includes(number)){
                    if(number<currentPage&&!gaps.before){
                        gaps.before = true
                        return<Gap key={number}></Gap>
                    }
                    if(number>currentPage&&!gaps.after){
                        gaps.after = true
                        return<Gap key={number}></Gap>
                    }

                    return<></>
                }


                return (
                    <button className={`page-number-button ${currentPage===number?"current":""}`} key={number} onClick={() => setCurrentPage(number)}>
                    {number}
                    </button>
                    );
                })}
        </footer>)
    }
    export default PageNumberBar
    
    
    function Gap (){
        return<div className="gap"></div>
    }