import { createContext, useState } from "react";

export const SectionDevelopmentContext:   any = createContext<(any|{})>({})



export function SectionDevelopmentContextProvider({children,submission}:{children:JSX.Element | JSX.Element[],submission:any}){
    const [devSubmission,setDevSubmission] = useState(submission)
    
    const markAsSubmitted = ()=>{
        const newDevSubmission = {...devSubmission}
        newDevSubmission.isDone = true;
        setDevSubmission({...newDevSubmission})
        window.location.replace("/submission-success")
    }


    return<SectionDevelopmentContext.Provider
    value = {{
        devSubmission,
        markAsSubmitted
    }}
    >
        {children}
    </SectionDevelopmentContext.Provider>

}