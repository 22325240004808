import { APIFailure } from "@/types/API"
import { DynamicObject } from "@/types/generic"
import { SectionType, UnpopulatedSectionType } from "@/types/Section"
import { fexDelete, fexGet, fexPost, fexPut } from "../utils/fex"

export type EditSectionFieldsResponse = (UnpopulatedSectionType | APIFailure)

export const editSectionFields = async ({sectionId,changedFields}:{sectionId:string,changedFields:DynamicObject}):Promise<EditSectionFieldsResponse>=>{
    const resp:EditSectionFieldsResponse = await fexPut(`api/section/fields/${sectionId}`,changedFields)
    return resp
}

export type ChangeIsOnResponse = (APIFailure | UnpopulatedSectionType)

export const changeIsOn = async ({sectionId,isOn}:{sectionId:string,isOn:boolean}):Promise<ChangeIsOnResponse>=>{
    const resp:ChangeIsOnResponse = await fexPut("api/section/isOn/"+sectionId,{isOn})
    return resp
}

export const changeInMenu = async ({sectionId,inMenu}:{sectionId:string,inMenu:boolean}):Promise<ChangeIsOnResponse>=>{
    const resp:ChangeIsOnResponse = await fexPut("api/section/in-menu/"+sectionId,{inMenu})
    return resp
}


export const addSectionCall = async ({pageId,template}:{pageId:string,template:string}):Promise<ChangeIsOnResponse>=>{
    const resp:ChangeIsOnResponse = await fexPost("api/section/"+pageId,{sectionName:template})
    return resp
}

export const removeSection = async ({sectionId,pageId}:{sectionId:string,pageId:string}):Promise<ChangeIsOnResponse>=>{
    const resp:ChangeIsOnResponse = await fexDelete("api/section/"+sectionId,{pageId})
    return resp
}

export const getSectionFields = async ({sectionId}:{sectionId:string}):Promise<ChangeIsOnResponse>=>{
    const resp:ChangeIsOnResponse = await fexGet("api/section/fields/"+sectionId)
    return resp
}

export const changeNavName = async ({sectionId,navName}:{sectionId:string,navName:string}):Promise<ChangeIsOnResponse>=>{
    const resp:ChangeIsOnResponse = await fexPut("api/section/nav-name/"+sectionId,{navName})
    return resp
}