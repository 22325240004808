
    import { FaCog } from "react-icons/fa"
import "./index.scss"
    import React, { useContext, useState } from "react"
import FullPageMiddleScroller from "../FullPageMiddleScroller"
import Toggle from "@/generics/Toggle"
import { PageContext } from "@/pages/Standard/context"
import { PageContextType } from "@/types/Context"

    const PageSettings = ()=>{

        const [isOpen,setIsOpen] = useState(false)
        const {paginated,setPaginated}:any = useContext(PageContext)
        if(!isOpen){
            return <button
                onClick={()=>setIsOpen(true)}
                data-tooltip="Page Settings."
                className="tooltip down blue"
            ><FaCog></FaCog></button>
        }

        return(
            <div style={{zIndex:30000}}>

            <FullPageMiddleScroller
            setIsOpen={setIsOpen}
            additionalClass="page-settings"
            >
                <div>
                    <span>Paginate</span>
                    <Toggle
                    action={()=>setPaginated(!paginated)}
                    initialValue={paginated}
                    ></Toggle>
                </div>
            </FullPageMiddleScroller>
                    </div>
        )
    }
    export default PageSettings
    
    
    