import { CSSField } from "@/types/css"
import { CSSLineType } from "../CSSEditor"
import findCSSValueInArray from "@/utils/findCSSValueInArray"
import { getScreenDimensions } from "@/utils/screen"

type Args = {
    element:any
    cssArray:CSSField[]
}

const getHorizontalPadding = ({element,cssArray}:Args)=>{
    const elementClass = element.class||element.classString?.replace("sub-section ","");
    // iterate through cssArray to check if there's a css rule with display:flex for this element
    let padding = findCSSValueInArray({classString:elementClass,property:'padding',cssArray})
    
    if(padding?.includes("rem")){
         const paddingNum = parseFloat(padding.replace("rem",""))*16
         return paddingNum*2
    }
    if(padding?.includes("em")){
        const paddingNum = parseFloat(padding.replace("em",""))*16
        return paddingNum*2
   }
    if(padding?.includes("vw")){
        const screenWidth = getScreenDimensions().width
        const perc = parseFloat(padding.replace("vw",""))
        return screenWidth*perc/100
    }

    return (padding?parseInt(padding?.replaceAll("px","")):0)*2
}

export default getHorizontalPadding