
    import "./index.css"
    import React, { FormEventHandler } from "react"
import Modal from "../Modal"


    type Props = {
        setIsModalOpen:React.Dispatch<boolean>
        handleSubmit:FormEventHandler<HTMLFormElement>
    }

    const ChooseFileModal = ({setIsModalOpen,handleSubmit}:Props)=>{
        return(
            <Modal
            modalTitle="Choose a File"
            setIsModalOpen={setIsModalOpen}
            additionalClass="file-chooser-modal"
            >
            <form onSubmit={handleSubmit}>

            <input type="file"></input>
            <button className="btn-primary">Submit Picture</button>
            </form>
            </Modal>
        )
    }
    export default ChooseFileModal
    
    
    