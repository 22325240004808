
import * as reactColor from 'react-color'
import "./index.scss"
    import React from "react"
import { ColorObjectType } from "@/types/Color"

    type Props = {
        handleChange:(arg0:string)=>void
        initialValue:string
    }

    const FieldSelectorCont = ({handleChange,initialValue}:Props)=>{
        const {ChromePicker}:  any = reactColor

        const pickerProps = {
            width:"250px",
            height:"200px",
            color:initialValue,
            onChange:(color:ColorObjectType)=>handleChange(color.hex)
            }

        return(
            <ChromePicker {...pickerProps} />
        )
    }
    export default FieldSelectorCont
    
    
    