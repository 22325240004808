
import "./index.scss"
import {useState} from "react"
import { addGenericSubsection, editGenericSubsection } from "@/api/genericSubsection"
import useSectionZIndex from "@/hooks/useSectionZIndex"
import useMainZIndex from "@/hooks/useMainZIndex"
import CustomTextEditor from "@/components/CustomTextEditor"
import FullPageMiddleScroller from "@/components/FullPageMiddleScroller"
import TextEditorHeader from "@/components/TextEditorHeader"
import InputAndLabelContainer from "../Containers/InputAndLabelContainer"
    
    type BlogEntryType = any

    type PostDetails = {
        heading:string
        subheading:string
        paragraph:string
    }

type Props = {
        postDetails?:PostDetails
        sectionId?:string
        subsectionId?:string
        setIsEditing:React.Dispatch<boolean>
        blogEntries:BlogEntryType[]
        setBlogEntries:React.Dispatch<BlogEntryType[]>
    }

    const BlogEditor = ({postDetails,sectionId="63e19de8c16c32aab8f01dcf",subsectionId,setIsEditing,blogEntries,setBlogEntries}:Props)=>{

        const emptyPost = {
            heading:"",
            subheading:"",
            paragraph:""
        }

        const [post,setPost] = useState(postDetails||emptyPost)

        const handleSubmit = async(paragraph:string)=>{
            let res
            post.paragraph = paragraph
            if(!subsectionId){
                res  = await addGenericSubsection({
                    sectionId,type:"post",fields:post
                })
                setBlogEntries([...blogEntries,res])
            }else{
                const variables = {
                    subsectionId,changedFields:post,type:"post"
                }
                res = await editGenericSubsection(variables)
                const newBlogEntries = [...blogEntries]
                let index;
                newBlogEntries.forEach((b,i:number)=>{
                    
                if(b._id===subsectionId){
                    index=i
                }
                })
                if(index===undefined){
                    return
                }
                newBlogEntries[index] = post

                setBlogEntries(newBlogEntries)
            }
            setIsEditing(false)

        }


        return(
            <FullPageMiddleScroller
            setIsOpen={setIsEditing}
            additionalClass="blog"
            >
            <TextEditorHeader>
            <InputAndLabelContainer>
            <label>Heading</label>
            <input  value={post.heading} onChange={(e)=>{setPost({...post,heading:e.target.value})}}></input>
            </InputAndLabelContainer>
            <InputAndLabelContainer>
            <label>Subheading</label>
            <input  value={post.subheading} onChange={(e)=>{setPost({...post,subheading:e.target.value})}}></input>
            </InputAndLabelContainer>
            </TextEditorHeader>
            <CustomTextEditor
            handleSubmit={handleSubmit}
            value={post.paragraph}
            setIsOpen={setIsEditing}
            
            ></CustomTextEditor>
            </FullPageMiddleScroller>
        )
    }
    export default BlogEditor
    
    
    