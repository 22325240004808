import backgroundOn from "../fields/backgroundOn";
import casing from "../fields/casing";
import eventDisplay from "../fields/eventDisplay";
import font from "../fields/font";
import headingFont from "../fields/headingFont";
import sectionBackground from "../fields/sectionBackground";
import textColor from "../fields/textColor";
import generics from "./generics";

export default [
    eventDisplay,
    ...generics,
    casing,
]