import { EventType } from "@testing-library/react"
import React, {createContext, useEffect, useState } from "react"

type ContextType={
    Provider:  any
    Consumer:  any
}

export const EventsContext:ContextType = createContext({})

export function EventsContextProvider({children}:{children:(JSX.Element|JSX.Element[])}){
    
    const [events,setEvents]:[EventType[],React.Dispatch<EventType[]>] = useState<EventType[]>([])

    return(
        <EventsContext.Provider value={{
        events,setEvents
}}>
            {children}
        </EventsContext.Provider>
    )
}