
    import LogarithmicSlider from "@/generics/LogarithmicSlider"
import "./index.css"
    import React, { useState } from "react"
import { ImageDetails } from "@/types/Images"
import Modal from "@/generics/Modal"


    type Props = {
        imageDetails:ImageDetails
        setImageDetails:(arg0:ImageDetails)=>void
        setIsOpen:React.Dispatch<boolean>
    }

    const ImageDetailsEditor = ({imageDetails,setImageDetails,setIsOpen}:Props)=>{


        const [height,setHeight] = useState(imageDetails.height)

        const handleSliderHeight = (heightNumber:number)=>{
            const height =`${heightNumber}vw`
            setHeight(height)
            setImageDetails({...imageDetails,height})
        }

        return (
        <Modal
        setIsModalOpen={setIsOpen}
        >
            <div>

            <h3>Image Details</h3>
        
        <LogarithmicSlider
        propertyName="size"
        unit="size"
        value={parseInt((height||"10").replace("vw",""))}
        setValue={handleSliderHeight}
        min={0}
        max={100}
        ></LogarithmicSlider>
        </div>
        </Modal>)
    }
    export default ImageDetailsEditor
    
    
    