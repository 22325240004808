import { reformatDate, reformatTime } from "./stringFormatting"

export const getTimeFromDate = (date:string)=>{
    const split = date.split("T")
    if(!split[1]){
        return ""
    }
    const time = split[1].substring(0,5)
    
    return time
}

export const getDateFromString = (date:string)=>{
    const split = date.split("T")
    
    if(!split[1]){
        return split[0]
    }
    return split[0]
}

export const parseDate = (date:string)=>{
    const res:{time?:string,date?:string,day?:string,fullDateString?:string} = {}
    const fullDateString = date
    res.time = reformatTime({dateString:fullDateString})
    res.date = reformatDate(fullDateString)
    const objectDate = new Date(fullDateString)
    res.day = objectDate.toString().substring(0,3)
    res.fullDateString = fullDateString
    return res
}

export const parseSeconds = (seconds:number)=>{
    let minutes = Math.floor(seconds / 60);
  let secs = Math.floor(seconds % 60);

  return minutes >= 60 ? `${minutes}:${secs < 10 ? '0' + secs : secs}` : `${minutes}:${secs < 10 ? '0' + secs : secs}`;
}

export type EventWithParsedDateType = {
    date:string,
    venue:string,
    city:string,
    link?:string,
    title?:string,
    time?:string,
    day?:string,
    fullDateString?:string
}