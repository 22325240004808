export const getElementHeight = (element:HTMLElement)=>{
    return element.offsetHeight;
}

export const getElementWidth = (element:HTMLElement)=>{
    return element.offsetWidth;
}

export const getChild = ({element,selector}:{element:HTMLElement,selector:string})=>{
    const child = (element.querySelector(selector) as HTMLElement)
    return child
}