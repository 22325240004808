
    import { getAssistance, removeAssistance } from "@/api/assistance"
import PageMessenger from "../PageMessenger"
import "./index.scss"
    import React from "react"

    type Props = {
        pageId:string
        assistanceOn:boolean
        setAssistanceOn:React.Dispatch<boolean>
    }

    const AssistanceModal = ({pageId,assistanceOn,setAssistanceOn}:Props)=>{
        
        
        const handleToggle = ()=>{
            if(!assistanceOn){
                getAssistance(pageId)
            }
            else{
                removeAssistance(pageId)
            }
            setAssistanceOn(!assistanceOn)
        }

        if(!assistanceOn){
            return(
                <main className="assistance-main">
                    <div>

                    <h2>Want a hand?</h2>
                    <p>
                        If you turn on assistance, we can make direct edits to your site per your instructions. 
                    </p>
                    <p>
                        Also, you will have a chat window for giving us instructions or asking questions.
                    </p>
                    </div>
                    <div className="btn-cont">
                    <button onClick={handleToggle}>Turn On Assistance  </button>
                    </div>
                </main>
            )
        }

        
        return(
            <main className="assistance-main-set">
                <PageMessenger
                pageId={pageId}
                ></PageMessenger>
                <button className="danger" onClick={handleToggle}>Turn {assistanceOn?"off":"on"} Assistance</button>
            </main>
        )
    }
    export default AssistanceModal
    
    
    