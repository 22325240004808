export const hexToRgb = (hex: string): number[] => {
    const r = parseInt(hex.substring(1, 3), 16);
    const g = parseInt(hex.substring(3, 5), 16);
    const b = parseInt(hex.substring(5, 7), 16);
    return [r, g, b];
  };

export  const rgbToHex = (rgb: number[]): string => {
    return `#${rgb.map((c) => Math.round(c).toString(16).padStart(2, '0')).join('')}`;
  };

const getBrightness = (rgb: number[]): number => {
    return Math.sqrt(0.299 * rgb[0] * rgb[0] + 0.587 * rgb[1] * rgb[1] + 0.114 * rgb[2] * rgb[2]);
  }

export const adjustColorBrightness = ({ color, factor = 2, threshold = 128 }: { color: string, factor?: number, threshold?: number }): string => {
    // Calculate the brightness of the color using the HSP color model
    // HSP = sqrt(0.299 * R^2 + 0.587 * G^2 + 0.114 * B^2)

    const rgb = hexToRgb(color);
    const brightness = getBrightness(rgb);
  
    if (brightness >= threshold) {
      // Color is light, so darken it
      return darkenColor({ color, factor });
    } else {
      // Color is dark, so brighten it
      return brightenColor({ color, factor });
    }
  };

export const brightenColor = ({color,factor=1.5}:{color:string,factor:number}):string=>{
      
        // Convert RGB value to hex color string
        const rgbToHex = (rgb: number[]): string => {
          return `#${rgb.map((c) => Math.round(c).toString(16).padStart(2, '0')).join('')}`;
        };
      
        // Brighten RGB value by multiplying by a factor
        const brightenRgb = (rgb: number[], factor: number): number[] => {
          return rgb.map((c) => Math.min(255, Math.round(c * factor)));
        };
      
        const rgb = hexToRgb(color);
        const brightenedRgb = brightenRgb(rgb, factor);
        const brightenedColor = rgbToHex(brightenedRgb);
      
        return brightenedColor;
}

export const darkenColor = ({ color, factor = 0.5 }: { color: string, factor?: number }): string => {
    // Convert hex color string to RGB value
  
    // Convert RGB value to hex color string

  
    // Darken RGB value by multiplying by a factor less than 1
    const darkenRgb = (rgb: number[], factor: number): number[] => {
      return rgb.map((c) => Math.round(c * factor));
    };
  
    const rgb = hexToRgb(color);
    const darkenedRgb = darkenRgb(rgb, factor);
    const darkenedColor = rgbToHex(darkenedRgb);
  
    return darkenedColor;
  };

  export const standardColors = [
    "#FFFFFF",
    "#000000",
    "#FF0000",
    "#00FF00",
    "#0000FF",
    "#FFFF00",
    "#FF00FF",
    "#00FFFF",
    "#C0C0C0",
    "#808080",
    "#800000",
    "#808000",
    "#008000",
    "#800080",
    "#008080",
    "#F0F8FF",
    "#FA8072",
    "#FFA07A",
    "#FFD700",
    "#FFC0CB",
    "#F5F5F5",
    "#F0F0F0",
    "#D3D3D3",
    "#A9A9A9",
    "#696969",
    "#F8F8FF",
    "#F5FFFA",
    "#DCDCDC",
    "#FAFAFA",
  ];

 export  function isColorDark(color:string) {
    // Convert color to RGB values
    const hex = color.replace("#", "");
    const r = parseInt(hex.substr(0, 2), 16);
    const g = parseInt(hex.substr(2, 2), 16);
    const b = parseInt(hex.substr(4, 2), 16);
  
    // Calculate relative luminance of the color
    const luminance = (0.2126 * r + 0.7152 * g + 0.0722 * b) / 255;
  
    // Check if the color is dark or light
    if (luminance < 0.5) {
      return true; // Dark color
    } else {
      return false; // Light color
    }
  }


  type Cell = {
    h:number
    s:number
    l:number
    row:number
    column:number
  }


  function hslToHex({h, s, l}:{h:number,l:number,s:number}): string {
    
    // Calculate chroma, hue prime, and intermediate variables
    const c = (1 - Math.abs(2 * l - 1)) * s;
    const hp = h / 60;
    const x = c * (1 - Math.abs(hp % 2 - 1));
    
    // Calculate RGB values and convert to HEX
    let r, g, b;
    if (hp >= 0 && hp < 1) {
      [r, g, b] = [c, x, 0];
    } else if (hp >= 1 && hp < 2) {
      [r, g, b] = [x, c, 0];
    } else if (hp >= 2 && hp < 3) {
      [r, g, b] = [0, c, x];
    } else if (hp >= 3 && hp < 4) {
      [r, g, b] = [0, x, c];
    } else if (hp >= 4 && hp < 5) {
      [r, g, b] = [x, 0, c];
    } else {
      [r, g, b] = [c, 0, x];
    }
    
    const m = l - c / 2;
    const rHex = Math.round((r + m) * 255).toString(16).padStart(2, '0');
    const gHex = Math.round((g + m) * 255).toString(16).padStart(2, '0');
    const bHex = Math.round((b + m) * 255).toString(16).padStart(2, '0');
    
    return `#${rHex}${gHex}${bHex}`;
  }
  

  export const getColorGrid = (width:number,height:number)=>{
    const count = width*height;
    const cells:Cell[] = []
    for (let i=0;i<count;i++){
      cells.push({h:0,s:1,l:0,row:Math.floor(i/width),column:i%width})
    }
    cells.forEach((c)=>{
      c.h = 360/width*c.column
    })
    const lightStep = 1/(height+1)
    cells.forEach((c)=>{
      c.l = 0+((c.row+1)*lightStep)
      if(c.row*2<height){
        c.s = .82
      }
    })
    const hexes = cells.map(c=>hslToHex({h:c.h,s:c.s,l:c.l}))
    const greyScaleHexes = getGrayscaleHexValues(width)
    hexes.push(...greyScaleHexes)

    return hexes
  }
  

  function getGrayscaleHexValues(n: number): string[] {
    const hexValues: string[] = [];
    const step = 255 / (n - 1); // Calculate step size between each grayscale value
    
    for (let i = 0; i < n; i++) {
      const value = Math.round(i * step); // Calculate grayscale value for this step
      const hexValue = value.toString(16).padStart(2, '0'); // Convert value to HEX format
      hexValues.push(`#${hexValue}${hexValue}${hexValue}`); // Add HEX value to array
    }
    
    return hexValues;
  }
  