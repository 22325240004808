
    import { SectionType } from "@/types/Section"
import "./index.scss"
    import React from "react"
import PuxMod from "@/components/PuxMod"
import Disqus from "@/components/Disqus"

    const Comments = ({section}:{section:SectionType})=>{
        return(
            <PuxMod section={section}>
                <Disqus></Disqus>
        </PuxMod>
        )
    }
    export default Comments
    
    
    