
    import "./index.scss"
    import { useContext } from "react"
import { SubsectionContext } from "@/components/SubPuxMod"
import ETextArea from "@/components/EditableComponent/ETextArea"
import EImage from "@/components/EditableComponent/EImage"

    const EGenericSubsection = ({imageless}:{imageless?:boolean})=>{

        return(
            <div className="subsection">
                <ETextArea subsection={true} field="heading"></ETextArea>
                {!imageless&&<EImage subsection={true} field="image" ></EImage>}
                <ETextArea subsection={true} field="paragraph"></ETextArea>
            </div>
        )
    }
    export default EGenericSubsection
    
    
    