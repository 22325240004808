export default {prompt:"Logo Position?",
field:"--header-name-fd",
type:"style",
default:"row-reverse",
options:[
    {value:"row-reverse",text:"Left"},
    {value:"row",text:"Right"},
    {value:"column",text:"Below"},
    {value:"column-reverse",text:"Above"}
]
}