
    import "./index.scss"
    import React, { ChangeEvent, CSSProperties, TextareaHTMLAttributes, useEffect, useRef, useState } from "react"

    const AutoResizeTextArea = (props:TextareaHTMLAttributes<HTMLTextAreaElement>)=>{
        const {onChange,value,name,className} = props
        const textAreaRef = useRef<HTMLTextAreaElement>(null);
        const [text, setText] = useState((value as string).replaceAll("<br>","\n"));
        const [textAreaHeight, setTextAreaHeight] = useState("auto");
        const [parentHeight, setParentHeight] = useState("auto");
        const [cursorPosition,setCursorPosition] = useState(text.length)

        const parentStyle: CSSProperties = {
            minHeight: parentHeight,
        };
        const textAreaStyle: CSSProperties = {
            height: textAreaHeight,
            ...props.style
        };

        useEffect(()=>{
            setRefsPosition(cursorPosition)
        },[cursorPosition])

        const setRefsPosition = (position: number) => {
            if (textAreaRef.current) {
              textAreaRef.current.setSelectionRange(position, position);
              textAreaRef.current.focus();
            }
        };

        const handleChange = (e:ChangeEvent<(HTMLTextAreaElement)>)=>{
            const cursorPosition = e.target.selectionStart;
            if(e.target.value.length<text.length){
                setTextAreaHeight("auto");
                
            }
            setText(e.target.value);
            setCursorPosition(cursorPosition)
            if (props.onChange) {
                props.onChange(e);
            }
                setParentHeight(`${textAreaRef.current!.scrollHeight}px`);
            if(!e||!onChange){
                return
            }
            e.target.value = e.target.value.split("\n").join("<br>")
            onChange(e)
        }

        useEffect(() => {
            setParentHeight(`${textAreaRef.current!.scrollHeight}px`);
            setTextAreaHeight(`${textAreaRef.current!.scrollHeight}px`);
        }, [text]);


        

        return (
            <div className={ " auto-textarea-cont"} style={parentStyle}>
                <textarea className={className+" auto-textarea p"}
                    name={name}
                    value={(text)}
                    onChange={handleChange}
                    ref={textAreaRef}
                    style={textAreaStyle}
                    rows={1}
                />
            </div>
        );
    }
    export default AutoResizeTextArea
    
    
    