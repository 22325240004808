
    import "./index.scss"
    import React, { ChangeEvent, useState } from "react"

    type Props = {
        value:string
        setValue:React.Dispatch<string>
        label?:string
    }

    const UrlEditBar = ({value,setValue,label}:Props)=>{
        
        const [isEditing,setIsEditing] = useState(false)
        
        const handleChange = (e:ChangeEvent<HTMLInputElement>)=>{
            setValue(e.target.value)
        }

        return(
            <>
            <label className="url-edit-bar-label">{label}</label>
            <div className="url-edit-bar">
            <input value={value} onChange={handleChange} disabled={!isEditing}></input>
            <button  onClick={()=>setIsEditing(!isEditing)}>{isEditing?"Save":"Edit"}</button>
            </div>
            </>
        )
    }
    export default UrlEditBar
    
    
    