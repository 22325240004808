
    import EmptyHeader from "@/layout/headers/EmptyHeader"
import "./index.scss"
    import React, { useEffect, useState } from "react"
    import { SectionRequest } from "@/types/SectionRequest"
import LoadingSpinner from "@/generics/LoadingSpinner"
import { getSectionRequestPrivate } from "@/api/sectionRequest"
import getSlug from "@/utils/getSlug"
import SectionSubmissionEvaluation from "./SectionSubmissionEvaluation"
import NumberBar from "@/generics/NumberBar"


    const MySectionRequest = ()=>{

        const requestId = getSlug().substring(1)
        const [request,setRequest] = useState<null|SectionRequest>(null)

        useEffect(()=>{
            const f = async ()=>{
                const res = await getSectionRequestPrivate({requestId})
                
                const request = res.data.sectionRequest
                setRequest(request)
            }
            f()
        },[])

        if(!request){
            return<LoadingSpinner></LoadingSpinner>
        }

        const submissions = request?.submissions

        return(
            <>
            <EmptyHeader
             additionalClass="filled-in help-page"
            >

                <h1>{"{ Request }"}</h1>
            </EmptyHeader>
            {submissions&&<SubmissionsList
            submissions={submissions}
            requestId={requestId}
            ></SubmissionsList>}
            </>
        )
    }
    export default MySectionRequest
    
    type SubmissionsListProps = {
        submissions:any  
        requestId:string
    }

    function SubmissionsList({submissions,requestId}:SubmissionsListProps){
        
        const [currentIndex,setCurrentIndex] = useState(0)

        const currentSubmission = submissions[currentIndex]

        return<main className="eval-main">
        <div className="section-eval-cont">
        <SectionSubmissionEvaluation
            submission={currentSubmission}
            requestId={requestId}
        ></SectionSubmissionEvaluation>
        </div>
            <NumberBar
            length={submissions.length}
            currentIndex={currentIndex}
            setCurrentIndex={setCurrentIndex}
            
            ></NumberBar>
            </main>

    }
    