
import Header from "@/generics/Header"
import "./index.scss"
import { useEffect, useState } from "react"
import { getSpotlightPages } from "@/api/spotlight"
import Standard from "../Standard"
import { PageDetailsType } from "@/types/Page"
import { APIFailure } from "@/types/API"
import StandardHeader from "@/layout/headers/StandardHeader"
import HomepageHeader from "@/layout/headers/EmptyHeader"
import dummy from "./dummyPages"
import SpotlightCard from "@/components/SpotlightCard"

interface Props {}

export type SpotlightItem = {
  pageName:string
  url:string
  image:string
}

function Index(props: Props) {
    const {} = props

    const [showcasedWebsites,setShowcasedWebsites] = useState<SpotlightItem[]>(dummy)

    return (
        <div className="spotlight-page">
        <HomepageHeader
        >

          
        </HomepageHeader>
        <main>
    <div className="spotlight-cont">
      <div>
        Spotlight
        <h4>check out these comedian websites made on PuxHub.</h4>
      </div>
      {showcasedWebsites.map((s)=>{
        return<SpotlightCard
        item={s}
        ></SpotlightCard>
      })}
      <div className="call-to-action">
        <h3>
        Want your own page?
        </h3>
        <a href="/register"><button>
          Register Here
          </button>
          </a>
      </div>
    </div>
        </main>
        </div>
    )
}

export default Index
