
    import "./index.scss"
    import React from "react"
import { FaEdit, FaTrash } from "react-icons/fa"

type Props = {
    size?:number
    handleEdit?:React.MouseEventHandler
    handleDelete?:React.MouseEventHandler
}

    const SideButtons = ({size,handleEdit,handleDelete}:Props)=>{

        return(
            <div className="side-buttons-cont">
                    {handleEdit&&<div className="svg-cont">
                        <FaEdit className="edit" onClick={handleEdit} size={size||25}></FaEdit>
                        </div>
                        }
                    {handleDelete&&<div className="svg-cont">
                        <FaTrash className="delete" onClick={handleDelete} size={size||25}></FaTrash>
                        </div>
                        }
            </div>
        )
    }
    export default SideButtons
    
    
    