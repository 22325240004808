    import "./index.css"
    import React, { useEffect, useRef, useState } from "react"
import { getContrastLabelStyle } from "@/utils/toggles"
import { DynamicObject } from "@/types/generic"

    type Props = {
        action:Function,
        initialValue?:boolean
        label?:string
        className?:string
    }

    const Toggle = ({action,initialValue,label,className}:Props)=>{

        const labelEl = useRef(null)
        const [toggleValue,setToggleValue] = useState(initialValue||false)
        const [style,setStyle] = useState<DynamicObject>({})
        const handleCheckbox = ()=>{
            setToggleValue(!toggleValue)
            action()
        }

        useEffect(()=>{
            if(!labelEl){
                return
            }
            const el = labelEl.current
            if(!el){
                return
            }
            const color = getContrastLabelStyle(el)
            setStyle({color})
        },[])

         

        return(
            <div className={className||""}>
                {label?<span style={style} ref={labelEl} className="label-span">
                    {label}
                </span>:<></>
            }
            <label className="switch">
                <input onChange={()=>{}} checked={toggleValue}  onClick={handleCheckbox} type="checkbox"></input>
                <span className="slider round"></span>
            </label>
            </div>
        )
    }
    export default Toggle
    
    
    