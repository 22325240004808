import { PopulatedSectionSubmission } from "@/types/SectionSubmission"


export const getEarnings = (submissions:PopulatedSectionSubmission[])=>{
    let earnings = 0;
    for (let submission of submissions){
        const selectedSubmission = submission.sectionRequest?.selectedSubmission
        const submissionId = submission._id
        if(selectedSubmission===submissionId){
            earnings = earnings+submission.sectionRequest.budgetDollars
        }
    }
    return earnings
}