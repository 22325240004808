
    import { SpotlightItem } from "@/pages/Spotlight"
import "./index.scss"
    import React from "react"

    type Props = {
        item:SpotlightItem
    }

    const SpotlightCard = ({item}:Props)=>{
        
        const {pageName,url,image}= item;

        const handleLink = ()=>{
            window.open(url,"_blank")
        }

        return(
            <div onClick={handleLink} className="spotlight-card">
                <img src={image}></img>
                <footer>{pageName}</footer>
            </div>
        )
    }
    export default SpotlightCard
    
    
    