export default {
prompt:"Text Align",
field:"textAlign",
type:"style",
default:"left",
options:[
    {value:"left",text:"Left"},
    {value:"center",text:"Center"},
    {value:"right",text:"Right"},
    {value:"justify",text:"Justify"}
]
}