import { APIFailure } from "@/types/API"
import { fexPost } from "@/utils/fex"

type ConfirmSuccess = {
    error:false,
    message:string
}

export type ConfirmRegistrationResponse = (ConfirmSuccess|APIFailure)

export const confirmRegistration= async (params:{email:string,confirmationString:string}):Promise<ConfirmRegistrationResponse> => {
    const resp:ConfirmRegistrationResponse = await fexPost("api/confirm-registration",params)
    return resp
}