import { NecessaryAny } from '@/types/procrast';
import { useState, useEffect } from 'react';

export default (initialValue:NecessaryAny, timeout:number)=> {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    if (!value) {
      return;
    }

    const timeoutId = setTimeout(() => {
      setValue(false);
    }, timeout);

    return () => clearTimeout(timeoutId);
  }, [value, timeout]);

  return [value, setValue];
}

// const [showMessage, setShowMessage] = useTimeoutState(false, 5000);
