
    import ButtonBar from "@/generics/ButtonBar"
import "./index.scss"
    import React, { useEffect, useState } from "react"
import ActiveColors from "./ActiveColors"
import StandardColors from "./StandardColors"
import * as reactColor from 'react-color'
import useGetIds from "@/hooks/useGetIds"
import { getCurrentColors } from "@/api/colors"
import { getColorGrid } from "@/utils/color"
import useSkipFirstMutation from "@/hooks/useSkipFirstMutation"
import OpacitySlider from "../ColorSelector/OpacitySlider"
import { getHexValue, getOpacityBaseTen, replaceOpacity } from "./helpers"
import FieldSelectorCont from "./FieldSelectorCont"
import Hex from "./Hex"


    type Props = {
        initialColor:string
        handleChange:(arg0:string)=>void
        handleBack?:()=>void
    }

    const NewColorSelector = ({initialColor,handleChange,handleBack}:Props)=>{

        const [value,setValue] = useState("active")
        const [activeColors,setActiveColors] = useState([])
        const [color,setColor] = useState(initialColor||"#000000ee")

        const buttons = [{
            value:"active",
            text:"Active Colors"
        },{
            value:"standard",
            text:"Color Grid"
        },{
            value:"custom",
            text:"Custom Color"
        }
    ]

        useSkipFirstMutation(()=>{
            handleChange(color)
        },[color])

    const handleInteriorChange = (newColor:string)=>{
        const withOpacity = newColor+color.slice(-2)
        setColor(withOpacity)
    }

    const handleOpacityChange = (value:number)=>{
        const hexValue = getHexValue(value)
        setColor(replaceOpacity({color,opacity:hexValue}))
    }

    const {ChromePicker}:  any = reactColor

        return(
            <div className="color-selector-cont">
                {handleBack&&<button className="back-btn" onClick={handleBack}>Back</button>}
                <ButtonBar
                    buttons={buttons}
                    handleSelect={(value:string)=>{setValue(value)}}
                ></ButtonBar>
            <div>
                {value==="active"&&<ActiveColors handleChange={handleInteriorChange}></ActiveColors>}
                {value==="standard"&&<StandardColors
                opacity={getOpacityBaseTen(color)/100}
                handleChange={handleInteriorChange}></StandardColors>}
                {value==="custom"&&<FieldSelectorCont
                            initialValue={initialColor}

                handleChange={handleInteriorChange}></FieldSelectorCont
                
                >}
            </div>
            <footer>

            <OpacitySlider
            initialValue={getOpacityBaseTen(initialColor)}
            handleOpacityChange={handleOpacityChange}
            ></OpacitySlider>
            
            <Hex
            color={color}
            setColor={setColor}
            ></Hex>
            </footer>
            </div>
        )
    }
    export default NewColorSelector
    
    
    