    import Form from "@/generics/Form"
import "./index.scss"
    import React, { useContext, useState } from "react"
import { requestSection } from "@/api/sectionRequest"
import HomepageHeader from "@/layout/headers/HomepageHeader"
import { Context, ContextValueType } from "@/context"

    const SectionRequest = ()=>{
        
        const {tokens,setTokens}:ContextValueType = useContext(Context)
        const pageId = window.location.search.substring(1)
        const [success,setSuccess] = useState(false)
        const [pageName,setPageName] = useState("")
        const fields:any = [{
            type:'normal',
            name:'sectionRequestTitle',
            required:true
        },
        {
            type:"number",
            name:"budgetDollars",
            options:{
                min:0,
                max:tokens||0
            }
        },
        {
            type:'textArea',
            name:'sectionContent',
            required:true
        },
        {
            type:'date',
            name:'deadline',
            required:true
        },
        {
            type:"assets",
            name:"images"
        }
    ]

    const handleSubmit = async (sub:any)=>{
        const params = {
            ...sub,pageId
        }
        const tokensSpent = parseInt(sub.budgetDollars)
        setTokens(tokens-tokensSpent)
        const res:any = await requestSection(params)
        if(res.pageName){
            setPageName(res.pageName)
        }
        setSuccess(true)
    }

    const handleReturn = ()=>{
        window.location.href = "/"+pageName
    }

        if(success){
            return(
                <main className="section-request-main">

            <div className="section-request-success">
                <h2>Section Request Submitted</h2>
                <p>This request now appears on a developer gig board <a href="/section-jobs">
                    here
                    </a>
                    </p>
                <footer>
                    <button onClick={()=>setSuccess(false)} className="primary">Request Another Section</button>
                    <button onClick={handleReturn} >Return to Editor</button>
                </footer>
            </div>
                </main>
            )
        }

        return(
<>
<HomepageHeader
opaque={true}
></HomepageHeader>
<main className="section-request-main">

            <Form
            fields={fields}
            submitName="Make Section Request"
            handleSubmit={handleSubmit}
            ></Form>
            
            </main>
            </>
        )
    }
    export default SectionRequest
    
    
    