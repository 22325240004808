import { fexPost } from "@/utils/fex"

type VenmoPayoutArguments = {
    userId:string
    amount:number
    venmoId:string
}

export const venmoPayout = async ({userId,amount,venmoId}:VenmoPayoutArguments)=>{
    const res = await fexPost("api/payout-request/venmo",{
        userId,amount,venmoId
    })
    return res
}


export const mailPayout = async ({userId,amount,venmoId}:VenmoPayoutArguments)=>{
    const res = await fexPost("api/payout-request/venmo",{
        userId,amount,venmoId
    })
    return res
}