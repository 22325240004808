import "./index.css"
import { confirmRegistration, ConfirmRegistrationResponse } from "@/api/confirmRegistration"
import Section from "@/generics/Section"
import { getParams } from "@/utils/getSlug"
import React, { useContext, useState } from "react"
import { getSlug } from "../Standard/helpers"
import { addDefaultFields } from "@/utils/objects"
import { DynamicObject } from "@/types/generic"
import { Context, ContextValueType } from "@/context"

    const ConfirmRegistration = ()=>{

        const [success,setSuccess] = useState(false)
        const {setCredentials}:ContextValueType = useContext(Context)

        const handleClick =async ()=>{
            const unCheckedParams:DynamicObject = getParams()
            // const checkedParams:{email:string,confirmationString:string} = {email:unCheckedParams?.email||"",confirmationString:unCheckedParams?.confirmationString||""}
            const checkedParams:{email:string,confirmationString:string} = (addDefaultFields({
                object:unCheckedParams,params:["email","confirmationString"]
            }) as {email:string,confirmationString:string})
            
            const resp:ConfirmRegistrationResponse = await confirmRegistration(checkedParams)
            if(resp.error){
                return
            }
            setCredentials(resp)
            window.location.replace("/")
        }

        return(
            <main className="confirm-page">
            {!success?<>
                <h1>Thank you for registering for PuxHub!</h1>
                <h3>Click below to finish your registration</h3>
                <button onClick={handleClick}>Confirm Email</button>
                </>
                :<h2>
                Success! Login once redirected.
                </h2>
                
                }
            </main>
        )
    }
    export default ConfirmRegistration
    
    
    