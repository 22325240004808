
    import { Children } from "@/types/JSX"
import "./index.scss"
    import React, { useEffect, useRef, useState } from "react"
import StretchDragger from "./StretchDragger"
import useSkipFirstMutation from "@/hooks/useSkipFirstMutation"
import { getLeftRightFromWidth } from "./helpers"

    type Props = {
        frameWidth?:string
        frameHeight?:string
        children:Children
        specifiedWidth?:number|null
        setPreviewWidth?:React.Dispatch<number|null>

    }

    const Stretcher = ({frameHeight,frameWidth,children,specifiedWidth,setPreviewWidth}:Props)=>{

        const frameRef = useRef(null)
        const stretcherRef = useRef<HTMLDivElement>(null)
        const defaultStretcherPosition = {
            top:"0px",
            bottom:"0px",
            left:"0px",
            right:"0px"
        }
        
        
        const [position,setPosition] = useState(defaultStretcherPosition)
        
        const style = {
            height:frameHeight||"100%",
            width:frameWidth||"100%"
        }
        
        useSkipFirstMutation(()=>{
            if(!specifiedWidth){
                return
            }
            const leftRight = getLeftRightFromWidth({frameRef,specifiedWidth})
            if(leftRight.error){
                return
            }
            setPosition({...position,...leftRight})
        },[specifiedWidth])




        const handleDrag = ({side,px}:{side:string,px:string})=>{
                const newPosition:any = {...position}
                newPosition[side] = `${px}px`.replaceAll("pxpx","px")
                setPosition(newPosition)
                if(setPreviewWidth){
                    setPreviewWidth(stretcherRef?.current?.getBoundingClientRect()?.width||0)
                }
            }

        return(
            <div ref={frameRef} style={style} className={`stretch-frame`}>
                <div ref={stretcherRef} style={{...position}} className="stretcher">
        
                <>
                <StretchDragger position={position} direction="up" handleDrag={handleDrag}></StretchDragger>
                <StretchDragger position={position} direction="down" handleDrag={handleDrag}></StretchDragger>
                <StretchDragger position={position} direction="left" handleDrag={handleDrag}></StretchDragger>
                <StretchDragger position={position} direction="right" handleDrag={handleDrag}></StretchDragger>
                </>
                    {children}
                </div>
            </div>    
        )
    }
    export default Stretcher
    
    
    