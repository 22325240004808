
    import "./index.scss"
    import React, { useState } from "react"
import HomepageHeader from "@/layout/headers/HomepageHeader"
import Form from "@/generics/Form"
import { validateSetNewPassword } from "@/utils/yup"
import FormErrors from "@/generics/FormErrors"
import { resetPassword } from "@/api/resetPassword"
import getSlug from "@/utils/getSlug"
import Success from "@/generics/Success"

    const ResetPassword = ()=>{
        const confirmationCode = getSlug().slice(1)
        const [errors,setErrors] = useState([])
        const [success,setSuccess] = useState(false)

        const fields = [
            {name:"email",type:"email"}
            ,{name:"password",type:"password"},
            {name:"confirmPassword",type:"password"}
        ]

        type FormValues = {
            email:string,
            password:string,
            confirmPassword:string,
            recaptchaToken:string
        }

        const handleSubmit = async (values:FormValues)=>{
            const checkRecaptcha = values?.recaptchaToken
            if(!checkRecaptcha){

                return
            }
            const validation = await validateSetNewPassword(values)
            if(validation.isError){

                const errors = validation.errors
                setErrors(errors)
                return
            }
            const resp = await resetPassword({...values,confirmationCode})
            if(resp.error){

                return
            }
            setSuccess(true)
        }

        const successCb = ()=>{
            window.location.href = "/login"
        }

        return(<>
                    <HomepageHeader additionalClass="black"></HomepageHeader>
            <main className="forgot-password">
            {!success&&<h2>Reset your password</h2>}
            {!success?
            
            <Form
            fields={fields}
            recaptcha={true}
            handleSubmit={handleSubmit}
            ></Form>:<Success 
            delayedCallback={successCb}
            setSuccess={setSuccess}
            message="Success! Password Reset."></Success>}
            
            <FormErrors formErrors={errors}></FormErrors>
            </main>
        </>
        )
    }
    export default ResetPassword
    
    
    