    import { useEffect, useState } from "react"
import "./index.scss"
import useGetIds from "@/hooks/useGetIds"
import { getMySectionRequests } from "@/api/sectionRequest"
import { SectionRequest } from "@/types/SectionRequest"
import MySectionRequestCard from "@/components/MySectionRequestCard"
import { filterRequests } from "./helpers"
import HomepageHeader from "@/layout/headers/EmptyHeader"
import RedirectButton from "@/generics/RedirectButton"

    const MySectionRequests = ()=>{

        const [ids] = useGetIds()
        const userId = ids.userId
        const [sectionRequests,setSectionRequests] = useState<SectionRequest[]>([])

        const filtered = filterRequests(sectionRequests)

        useEffect(()=>{
            if(!userId){
                return
            }
            const f = async ()=>{
                const res = await getMySectionRequests({userId})
                if(res.error||!res.data){
                    return
                }
                setSectionRequests(res.data.sectionRequests)
                
            
            }
            f()

        },[userId])

        const noSectionRequests = (sectionRequests?.length||0)===0

        return(
            <div className="full-page">
            <HomepageHeader additionalClass="filled-in my-section-requests-header">
                <h1>
                    {"{My Section Requests}"}
                </h1>
            </HomepageHeader>
            <main className="my-section-requests-main">
                <RedirectButton to="/">Back to Dash</RedirectButton>
            <div>
                {noSectionRequests&&<div>
                    You currently have no requests for developers to make sections.
                    </div>}
                {filtered.map((sectionRequest,index)=>{
                    return<MySectionRequestCard sectionRequest={sectionRequest}></MySectionRequestCard>
                })}
            </div>
                </main>
                </div>
            )
    }
    export default MySectionRequests