
    import "./index.scss"
    import React, { useEffect } from "react"
    import innerHTML from "./embed"

    const Instagram = ({handle}:{handle:string})=>{
        
        const replacedHTML = innerHTML.replaceAll("alisultancomedy",handle).replaceAll("Ali Sultan","")

        useEffect(() => {
            const pastScript = document.querySelector("#igscript")
            if(pastScript){
              document.body.removeChild(pastScript)
            }
            const script = document.createElement("script");
            script.id = "igscript"
            script.src = "https://www.instagram.com/embed.js";
            script.async = true;
            document.body.appendChild(script);
          }, []);

        return(
            <>
            <div className="instagram-cont" dangerouslySetInnerHTML={{__html:replacedHTML}}>

            </div>
            </>
        )
    }
    export default Instagram
    
    
    