export default {
    prompt:"Item Layout",
    field:"teamLayout",
    type:"childClass",
    child:".team-member",
    default:"layout-set-left",
    options:[
        {value:"layout-set-left",text:"Image Left"},
        {value:"layout-set-right",text:"Image Right"}
    ]
}