export default {
prompt:"Line Height",
field:"lineHeight",
type:"childStyle",
child:"p",
valueType:"string",
default:"1.5em",
property:"lineHeight",
options:[
    {value:"1em",text:"tight"},
    {value:"1.4em",text:"normal"},
    {value:"2em",text:"high"},
    {value:"2.5em",text:"extra high"}
]
}