import "./index.css"
    import React, { ChangeEventHandler, MouseEventHandler, useContext, useEffect, useState } from "react"
import { useDebounce } from "use-debounce"
import { deleteLink, editLink } from "../../api/link"
import DeleteCircle from "../../generics/DeleteCircle"
import { PageContext } from "../../pages/Standard/context"
import { Link as LinkType } from "../../types/Event"
import { SectionType } from "../../types/Section"
import { PageContextType } from "@/types/Context"
import { DynamicObject } from "@/types/generic"

    const Link = ({section,link}:{section:SectionType,link:LinkType})=>{

        const pageContext:PageContextType = useContext(PageContext)

        const {isEditing} = pageContext
        const {href,text} = link

        const fix = (href:string)=>{
            if(href.indexOf("//")>0){
                return href
            }else{
                return `https://${href}`
            }
        }

        if(isEditing){
            return <EditSection initialFields={link} sectionId={section._id} linkId={link?._id||""}></EditSection>
        }
        return(
            <a target="_blank" href={fix(href)}>{text}</a>
        )
    }
    export default Link
    
type EditSectionProps = {
    initialFields:DynamicObject,
    sectionId:string,
    linkId:string
}

    function EditSection({initialFields,sectionId,linkId}:EditSectionProps){
        const context = useContext(PageContext)
        const [additionalClass,setAdditionalClass] = useState("")
        const [fields,setFields] = useState(initialFields)
        const [delayedFields] = useDebounce(fields,1000)

        useEffect(()=>{
            if(fields===initialFields){
                return
            }
            let changedFields:DynamicObject = {}
            for(let fieldKey in delayedFields){
                if(delayedFields[fieldKey]!==initialFields[fieldKey]){
                    changedFields[fieldKey] = delayedFields[fieldKey]
                }
            }
            const data = editLink({linkId,changedFields})
        },[delayedFields])

        const handleChange:ChangeEventHandler<(HTMLInputElement|HTMLTextAreaElement)> = (e)=>{
            let {name,value} = e.target

            const newFields = {...fields}
            newFields[name] = value;
            setFields(newFields)
        }
        const {text,href,_id} = fields

        const handleDelete:MouseEventHandler<HTMLButtonElement> = (e)=>{
            setAdditionalClass("hide")
            deleteLink({sectionId,linkId})
        }

        return(
        <div className={`link-cont ${additionalClass}`}>
            <div className="input-cont">
            <div className="label-cont">

            <label>Link Text:</label>
            </div>
            <input name="text" onChange={handleChange} value={text} placeholder="link name"></input>
            </div>
            <div className="input-cont">
            <div className="label-cont">
            <label>https://</label>
            </div>
            <input name="href" onChange={handleChange} value={href} placeholder="link url"></input>
            </div>
            <DeleteCircle _id={_id.toString()} handleDelete={handleDelete}></DeleteCircle>
        </div>)
    }