import { useEffect, useRef } from 'react';



const useMainZIndex = (disable?:boolean,selector?:string) => {
  const sectionElement = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const closestSection:(HTMLElement|null) = (sectionElement.current as HTMLElement).closest(selector||'main.standard');
    if (closestSection && !disable) {
      closestSection.style.zIndex = '240';
      closestSection.style.position = 'fixed';
    }

    return () => {
      if (closestSection && !disable) {
        closestSection.style.zIndex = '0';
        closestSection.style.position = 'unset';
      }
    };
  }, []);

  return sectionElement;
};

export default useMainZIndex;
