import { getClassName, getInnerHTMLFromString } from "./DOM";
import { loggo } from "./dev";

const replaceSubsections = (html:string)=>{
    let result = html
    const regEx = /<subsections[\s\S]*?<\/subsections>/
    const countRegEx = /count=(\d+)/
    let exhausted = false;
    while(!exhausted){
        const match = result.match(regEx)
        const count = parseInt(match?.[0]?.match(countRegEx)?.[1]||"0")
        const className = getClassName({html:match?.[0]||""})
        
        const innerHTML = getInnerHTMLFromString({html:match?.[0]||""})
        
        const replacement  = `<div class="${className}">${innerHTML}</div>`
        let fullReplacement = ""
        for (let i = 0;i<count;i++){
            fullReplacement+=replacement
        }
        
        if(match){
            result = result.replace(match[0],fullReplacement)
        }
        exhausted = true 
    }
    return result
}

export default replaceSubsections