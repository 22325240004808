
    import "./index.scss"
    import React, { ChangeEvent, useState } from "react"
import InputCont from "../InputCont"

    type Props = {
        handleAdd:(arg0:string)=>void
        name:string
        label:string
        regEx?:RegExp
        formatMessage?:string
    }

    const AddInputCont = ({handleAdd,name,label,regEx,formatMessage}:Props)=>{

        const [value,setValue] = useState("")
        const [error,setError] = useState<(null|string)>(null)
        const disabled = value===""

        const handleChange = (e: ChangeEvent<HTMLInputElement>)=>{
            setValue(e.target.value)
            if(regEx){
                if(value.match(regEx)){
                    setError(null)
                }
            }
        }

        const handleClick = ()=>{
            if(disabled){
                return
            }
            if(regEx){
                if(!value.match(regEx)){
                    setError(formatMessage||"Bad Format.")
                    return
                }
            }
            handleAdd(value)
            setValue("")
        }

        return(
            <div className="add-input-cont">

            <InputCont
            value={value}
            handleChange={handleChange}
            name={name}
            label={label}
            tooltip="Paste in Video ID here and click Submit"

            />
            <button disabled={disabled} onClick={handleClick}>
                +
            </button>
            {!error?<></>:<div className="error-div">{error}</div>}
            </div>
        )
    }
    export default AddInputCont
    
    
    