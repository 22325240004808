
    import { Children } from "@/types/JSX"
import "./index.scss"
    import React from "react"

    type Props = {
        children:Children
        tooltip?:string
        handleClick:()=>void
    }

    const EditorButtonSmall = ({children,handleClick,tooltip}:Props)=>{
        return(
            <button data-tooltip={tooltip||""} onClick={handleClick} className="editor-button-small tooltip">
                {children}
            </button>
        )
    }
    export default EditorButtonSmall
    
    
    