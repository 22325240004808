import { ChangeEvent, useContext, useEffect, useRef, useState } from "react"
import "./index.scss"
import "@/style/layout_classes.scss"
import { APIFailure } from "@/types/API"

import pageTypes from "@/data/pageTypes"
import { t } from "@/utils/stringFormatting"
import { createPage } from "@/api/page"
import { Context, ContextValueType } from "@/context"
import ReCAPTCHA from "react-google-recaptcha"
import { PopulatedPage } from "@/types/Page"
import { fexGet } from "@/utils/fex"
import { useDebounce } from "use-debounce"
import FullPageLoader from "@/components/FullPageLoader"

    const FirstPage = ()=>{
        const [isCreating,setIsCreating] = useState(false)

        const {credentials,setCredentials}:ContextValueType = useContext(Context)
        const userId = credentials?.user?._id
        const [isPageNameOkay,setIsPageNameOkay] = useState(false)
        type Fields = {
            template:null|string
            pageName:null|string
        }

        const [fields,setFields] = useState<Fields>({template:null,pageName:null})
        const [debouncedFields] = useDebounce(fields,500)
        const [index,setIndex] = useState(0)
        const [errorMessage,setErrorMessage] = useState("")
        const recaptchaRef = useRef<any>()
        const [recaptchaToken,setRecaptchaToken] = useState<string>()
        const siteKey = process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY||""
        const updateRecaptchaToken = (token:string|null)=>{
            setRecaptchaToken(token as string)
            setIndex(3)
        }

        useEffect(()=>{
            setIsPageNameOkay(false)
        },[fields])

        useEffect(()=>{
            setIsPageNameOkay(false)
            if(!(fields.pageName?.length&&fields.pageName?.length>3)){
                return
            }

            const fetchIt = async ()=>{
                const url = "api/page/check-name/"+fields.pageName
                const check = await fexGet(url)
                if(check.isFree){
                    setIsPageNameOkay(true)
                    setErrorMessage("")
                }else{
                    setErrorMessage("page name taken")
                }
            }
            fetchIt()

        },[debouncedFields])


        if(index===0)
            return(
                <main className="first-page">
                <div  className="slide-left__effect inframe">

                <h1>Select a Template </h1>
                <h3>(what is the site for?)</h3>
                </div>
                <select onChange={(e)=>setFields({...fields,template:e.target.value})} value={fields.template||0}>
                <option selected value={0} disabled={true}>Choose From Dropdown</option>
                    {
                        pageTypes.map((type,key)=>{
                            return(
                                <option key={key} value={type}>{t(type)}</option>
                                )
                            })
                        }
                </select>
                {fields.template&&<button onClick={()=>setIndex(1)}>Next</button>}
                </main>
            )

        const handleInputChange = (e:ChangeEvent<HTMLInputElement>)=>{
            const regex = /^[a-zA-Z0-9]+$/;
            if(!regex.test(e.target.value)&&!(e.target.value==="")){
                return
            }


            setFields({...fields,pageName:e.target.value})
        }


        const handleMakeSite = async ()=>{
            const {template,pageName}= fields
            if(template===null||pageName===null||recaptchaToken===undefined){
                return
            }

            const variables = {
                userId,
                recaptchaToken,
                template,pageName
            }
            setIsCreating(true)
            const res = await createPage(variables)
            if("error" in res && res.error){
                setIsCreating(false)

                return
            }
            const success = res as PopulatedPage
            setCredentials({...credentials,user:{...credentials.user,pages:[success.pageName]}})
            // setIsCreating(false)
            window.location.replace(`/${success.pageName}`)
        }

        
        if(isCreating){
            return<FullPageLoader
            text="Creating Page."
            ></FullPageLoader>
        }

        if(index===1){

            return(
                <main className="first-page">
            <h1>Name Your Site</h1>
            <ul>
                <li>Can include only numbers and letters.</li>
                <li>Must be at least four characters.</li>
            </ul>
            <div className="input-cont">
                <span>www.puxhub.com/</span>
            <input value={fields.pageName||""} onChange={handleInputChange}/>
            </div>
            <div className="button-cont">
            {isPageNameOkay?<button onClick={()=>setIndex(2)}>Next</button>:<div>{errorMessage}</div>}
            </div>
            </main>
            )
        }

        if(index===2){
            return(
                <main className="first-page">
                    <h1>Hey, you robot, dude?</h1>
                <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={siteKey}
            onChange={updateRecaptchaToken}
            // size="invisible"
            ></ReCAPTCHA>
            </main>
            )
        }

    return(
        <main className="first-page">
            <h1>Ready to go?</h1>
            <h2>Click below to have your template generated.</h2>
        <button onClick={handleMakeSite}>Create Your Site</button>
        </main>
    )
        
    }
    export default FirstPage
    
    
    