
    import "./index.scss"
    import React from "react"

    type Props = {
        children:JSX.Element|JSX.Element[]
    }

    const Floater = ({children}:Props)=>{
        return(
            <div className="floater">
            {children}
            
            </div>
        )
    }
    export default Floater
    
    
    