export const getOpacityBaseTen = (colorString:string)=>{
    const opacityHex = colorString.slice(-2)
    const opacityDec = parseInt(opacityHex,16)
    const res =  Math.round(opacityDec/2.55)
    return res
}

export const getHexValue = (value:number)=> {
    const hex = Math.round(value / 100 * 255).toString(16).padStart(2, "0");
    return hex.toUpperCase();
  }

export const replaceOpacity = ({color,opacity}:{color:string,opacity:string})=>{
    return color.slice(0, -2) + opacity;
}