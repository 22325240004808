
import PuxMod, { SectionContext } from "@/components/PuxMod"
import { PageContext } from "@/pages/Standard/context"
import TeamMember from "@/subsections/TeamMember"
import { PageContextType } from "@/types/Context"
import { SectionState, SectionType } from "@/types/Section"
import { addToOrdinals } from "@/utils/subsectionOrdering"
import { useContext, useState } from "react"
import { addHost } from "./helpers"
import "./index.scss"
import { GenericSubsectionDocument } from "@/types/Documents"

    const Team = ({section}:{section:SectionType})=>{
        const [teamArray,setTeamArray] = useState(section.genericSubsections)
        const {isEditing}:PageContextType = useContext(PageContext)


        return<PuxMod section={section}>
            <>
            <div className="team-grid">
            {(teamArray || []).map((teamMember: any,index:number) => {
                return <TeamMember key={index} teamMember={teamMember}/>;
            })}
            </div>
            {isEditing?<Add
            teamArray={teamArray}
            setTeamArray={setTeamArray}
            section={section}
            ></Add>:<></>}
            </>

        </PuxMod>
    }
    export default Team

    type AddProps = {
        teamArray:GenericSubsectionDocument[]
        setTeamArray:React.Dispatch<GenericSubsectionDocument[]>
        section:SectionType
    }
    
    function Add({teamArray,setTeamArray,section}:AddProps){

        const {subsectionOrdinals,setSubsectionOrdinals}:SectionState = useContext(SectionContext)


        const handleAdd = async ()=>{
            const resp = await addHost({sectionId:section._id})
            addToOrdinals({subsectionOrdinals,setSubsectionOrdinals,_id:resp._id})
            const newArray = [...teamArray]
            newArray.push({...resp,ordinal:teamArray.length})
            setTeamArray(newArray)
        }

        return<button className="mb-3" onClick={handleAdd}>Add Host</button>
    }
    