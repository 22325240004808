import { CSSLineType } from "@/components/CSSEditor"
import { DynamicAnyObject } from "@/types/generic"
import { createCustomTemplateStyleObject } from "./custom-template-style-object"
import { makeChangesObject } from "../objects"
import renderCustomTemplateStyles from "./renderCustomTemplateStyles"

type Args = {
    customSectionStyleObject:DynamicAnyObject
    style:string
    customCSSArray:CSSLineType[]
    sectionElement:any
}


const handleCustomTemplateStyleChange = ({customSectionStyleObject,style,customCSSArray,sectionElement}:Args)=>{
    const newStyleObject = createCustomTemplateStyleObject({styleString:style,customCSSArray})
    const changedStylesObject = makeChangesObject({oldObject:customSectionStyleObject,newObject:newStyleObject})
    renderCustomTemplateStyles({stylesObject:changedStylesObject,customCSSArray,sectionElement})
}

export default handleCustomTemplateStyleChange