
    import "./index.scss"
    import React, { ChangeEvent, useContext, useEffect, useState } from "react"
import InstagramFeed from "@/generics/Instagram"
import useSectionState from "@/hooks/useSectionState"
import { SectionType } from "@/types/Section"
import { DynamicObject } from "@/types/generic"
import { makeFieldObject } from "@/utils/arrays"
import { useDebounce } from "use-debounce"
import { editSectionFields } from "@/api/section"
import Section from "@/generics/Section"
import SectionHeading from "@/generics/SectionHeading"
import EditSectionHeader from "@/components/EditSectionHeader"
import InputCont from "@/generics/InputCont"
import PuxMod from "@/components/PuxMod"
import { PageContextType } from "@/types/Context"
import { PageContext } from "@/pages/Standard/context"

    const Instagram = ({section}:{section:SectionType})=>{
        const [initialized,setInitialized] = useState(false)

        const {isEditing}:PageContextType = useContext(PageContext)
        const [fields,setFields] = useState<DynamicObject>(makeFieldObject(section.fields)) // **
        const [delayedFields]= useDebounce(fields,1000) // **
        const [feedOn,setFeedOn] = useState(true) 

        const handleChange = (e:ChangeEvent<HTMLInputElement>)=>{// **
            const {name,value}= e.target// **
            const newFields = {...fields}// **
            newFields[name] = value;// **
            setFields(newFields)// **
        }

        useEffect(()=>{
            setFeedOn(false)
            if(!initialized||!delayedFields){
                setInitialized(true)
                return
            }
            const resp = editSectionFields({sectionId:section._id,changedFields:delayedFields}).then((res)=>{
            })
        },[delayedFields])

        useEffect(()=>{
            if(!feedOn){
                setTimeout(()=>setFeedOn(true),500)
            }
        },[feedOn])

        return<PuxMod section={section}>
            <>
            {isEditing&&<InputCont
            label="Instagram Handle"
            name="handle"
            value={fields.handle}
            handleChange={handleChange}
            tooltip="Wait a few seconds after changing the IG Handle, then toggle to save to see the embed. It also may take a bit of time to load."
            ></InputCont>}
            {feedOn&&<InstagramFeed handle={fields?.handle||""}/>}
            </>
        </PuxMod>
    }
    export default Instagram
    
    
    