import "./index.scss"
import React, { ChangeEvent, useContext, useEffect, useState } from "react"
import { getLayoutOptions } from "@/utils/sectionEditorUtils/layout"
import { getFieldName, makeStyleString, validateCSSArray } from "./helpers"
import { changeSectionStyle } from "@/api/style"
import ColorSelector from "../ColorSelector"
import FontSelector from "../FontSelector"
import DraggableModal from "../DraggableModal"
import { isMobile } from "@/utils/screen"
import { DynamicObject, DynamicObjectWithNumber } from "@/types/generic"
import { LayoutProperty } from "@/types/LayoutProperty"
import LayoutSlider from "../LayoutSlider"
import { num } from "@/utils/typeConversion"
import { t } from "@/utils/stringFormatting"
import CSSEditor, { CSSLineType } from "../CSSEditor"
import NewColorSelector from "../NewColorSelector"
import Experimental from "@/wrappers/Experimental"
import { getParsedValue } from "../SectionTemplatePreview/helpers"
import useSkipFirstMutation from "@/hooks/useSkipFirstMutation"
import { useDebounce } from "use-debounce"
import { updateSectionCSS } from "@/api/cssField"
import { PageContextType } from "@/types/Context"
import { PageContext } from "@/pages/Standard/context"
import { SectionState } from "@/types/Section"
import { SectionContext } from "../PuxMod"

type Props = {
    style:string
    setStyle:React.Dispatch<string>
    sectionType:string
    sectionId:string
    setLayoutModalOpen:React.Dispatch<boolean>
    sectionName?:string
    positioning?:string
    buttonClass?:string
    isStatic?:boolean
    header?:string
    buttonName?:string
}

    const LayoutModal = ({style,setStyle,sectionType,sectionId,setLayoutModalOpen,sectionName,positioning,buttonClass,isStatic,header,buttonName}:Props)=>{
        const [isOpen,setIsOpen] = useState(false)
        const layoutOptions = getLayoutOptions(sectionType)
        const [layoutObject,setLayoutObject] = useState<DynamicObjectWithNumber>({})
        const [selectorOpen,setSelectorOpen] = useState<any>(null)
        const [top,setTop] = useState<(null|string)>(null)
        const [cssOpen,setCssOpen] = useState(false)
        const {currentCustomCSSArray,setCurrentCustomCSSArray}:SectionState = useContext(SectionContext)
        const [delayedArray] = useDebounce(currentCustomCSSArray,2000)
        useEffect(()=>{
            if(!Array.isArray(currentCustomCSSArray)){
                return
            }
            currentCustomCSSArray.forEach((style:any)=>{
                    const {selector,property,value} = style
                    if(selector!==""){
                        try{
                            const parsedValue = getParsedValue(value)
                            const section = document.getElementById(sectionId)
                            if(!section){
                                return
                            }
                            const matches = Array.from(section.querySelectorAll(selector))
                            for (let match of matches){
                                match.style[property] = parsedValue
                            }
                        }catch{
                        }
                    }
                })
            }
        ,[currentCustomCSSArray])

        useSkipFirstMutation(()=>{
            const f = async ()=>{
                const validatedArray = validateCSSArray({cssArray:delayedArray})
                const res = await updateSectionCSS({sectionId,cssArray:validatedArray})
            }
            f()
        },[delayedArray])

        useEffect(()=>{
            if(selectorOpen&&!header){
                setTop("60%")
            }else{
                if(!header){
                    setTop("50%")
                }
            }
        },[selectorOpen])

        useEffect(()=>{
            setLayoutModalOpen(isOpen)
        },[isOpen,setLayoutModalOpen])


        useEffect(()=>{
            const layoutObject:DynamicObjectWithNumber = {}
            layoutOptions.forEach((option:LayoutProperty)=>{
                if(option.field&&option.child&&option.type==="childStyle"){
                    layoutObject[`${option.field}`] = option.default||(option.valueType==="number"?0:"")
                }
                else if(option.field&&option.child){
                    layoutObject[`${option.field}/${option.child}`] = option.default||(option.valueType==="number"?0:"")
                }
                else if(option.field){
                    layoutObject[option.field] = option.default||""
                }
            })
            const layoutProps = style?.split(" ")||[]
            layoutProps.forEach((prop)=>{
                if(prop.includes("xx")){
                    const [key,value] = prop.split("xx")
                    layoutObject[key] = value
                }
                else if(prop.includes(":")){
                    const [key,value] = prop.split(":")

                    layoutObject[key] = value
                }
                else if(prop.includes("**")){
                    const [element,key,value] = prop.split("**")
                    layoutObject[`${element}//${key}`]=value
                }
            })
            setLayoutObject(layoutObject)
    },[style])


        const handleClick = (e:React.MouseEvent<(HTMLButtonElement|HTMLOptionElement)>)=>{
            const {value,field} = (e.target as HTMLButtonElement).dataset;
            if(!field||!value){
                return
            }
            const newLayoutObject = {...layoutObject}
            newLayoutObject[field] = value;
            setLayoutObject(newLayoutObject)
            const newStyleString = makeStyleString(newLayoutObject)
            setStyle(newStyleString)
            changeSectionStyle({sectionId,style:newStyleString})
        }

        const handleColorChange = ({color,field}:{color:string,field:string})=>{
            const newLayoutObject:DynamicObjectWithNumber = {...layoutObject}
            newLayoutObject[field] = color;
            setLayoutObject(newLayoutObject)
            const newStyleString = makeStyleString(newLayoutObject)
            setStyle(newStyleString)
            changeSectionStyle({sectionId,style:newStyleString})
        }

        const handleSelector = ({field,value}:{field:string,value:(string|number)})=>{
            const newLayoutObject = {...layoutObject}
            newLayoutObject[field] = value;
            setLayoutObject(newLayoutObject)
            const newStyleString = makeStyleString(newLayoutObject)
            setStyle(newStyleString)
            changeSectionStyle({sectionId,style:newStyleString})
        }

        const handleSelect = (e:ChangeEvent<HTMLSelectElement>)=>{
            const value = e.target.value
            const field = e.target.dataset.field
            const newLayoutObject = {...layoutObject}
            if(!field){
                return
            }
            newLayoutObject[field] = value;
            setLayoutObject(newLayoutObject)
            const newStyleString = makeStyleString(newLayoutObject)
            setStyle(newStyleString)
            changeSectionStyle({sectionId,style:newStyleString})
        }

        if(isMobile()){
            return<></>
        }

        if(!isOpen){
            return(
                <button  onClick={()=>{setIsOpen(true)}} className={`${buttonClass?buttonClass:""} open-layout-btn no-translate-animation`}>
                    {buttonName||"Style"}
                </button>
                )
        }

        const modalHeader = header||`${sectionName?sectionName:"Section"} style`

        return(<>
            <DraggableModal top={top} isStatic={isStatic}  header={modalHeader} additionalClass={`layout-modal ${selectorOpen}`} setIsModalOpen={setIsOpen}>
            <>
            {(!selectorOpen&&!cssOpen)&&<div>
                {
                    layoutOptions.map((layout:LayoutProperty,index:number)=>{

                        if(layout.condition&&!(layoutObject[layout.condition.field]===layout.condition.value)||layout.ignore){
                            return<></>
                        }
                        if(layout.fontSelector){
                            return(
                            <div key={index}>
                            {layout.prompt}
                            <div className="option-btn-div">
                            <FontSelector
                            value={layoutObject[layout.field].toString()}
                            handleSelector={handleSelector}
                            name={layout.field}
                            ></FontSelector>
                            </div>
                            </div>)
                        }
                        if(layout.colorPicker){
                            return<ColorSelector 
                            backgroundColor={layoutObject?.background.toString()} 
                            color={layoutObject?.color.toString()} 
                            reposition={true} 
                            value={(layoutObject[getFieldName(layout)].toString())}
                            setValue={(color:string)=>{handleColorChange({color,field:getFieldName(layout)})}}
                            prompt={`${t(layout.field)}`}
                            positioning={positioning}
                            setSelectorOpen={()=>setSelectorOpen(layout.field)}
                            layout={getFieldName(layout)}
                            inline={true}
                            ></ColorSelector>
                        }
                        if(layout.slider){
                            return<div>
                                {layout.prompt}
                                <LayoutSlider layout={layout}
                                handleChange={handleSelector}
                                value={num(layoutObject[layout.field])}
                                unit={layout?.unit?.toString()}
                                ></LayoutSlider>
                            </div>
                        }

                        return(
                            <div key={index}>
                            {layout.prompt}
                        <div className="option-btn-div">
                        <select  onChange={handleSelect} data-field ={(layout.type!=="child"?layout.field:`${layout?.child||""}//${layout.field}`)} value={(layout.type!=="child"?layoutObject[layout.field]:layoutObject[`${layout?.child||""}//${layout.field}`])}>
                        {layout.options.map((option,index:number)=>{
                            return(
                                <option onClick={handleClick} data-field={layout.field} value={option.value} data-type={layout.type}>{option.text}</option>
                                )
                            })}
                            </select>
                        </div>
                        </div>
                        )
                    })
                }
                {/* this will be implemented later */}
                <Experimental>
                <button onClick={()=>setCssOpen(true)}>CSS</button>
                </Experimental>

            </div>}

            {(selectorOpen&&!cssOpen)&&(
                        <NewColorSelector
                        initialColor={layoutObject[selectorOpen].toString()}
                        handleBack={()=>setSelectorOpen(null)}
                        handleChange={(color:string)=>{handleColorChange({color,field:selectorOpen})}}
                        ></NewColorSelector>                           
                        )}
            {!selectorOpen&&cssOpen&&<>
            <CSSEditor
            cssArray={currentCustomCSSArray}
            setCssArray={setCurrentCustomCSSArray}
            ></CSSEditor>
            </>}
            </>
            </DraggableModal>
                </>
        )
    }
    export default LayoutModal
    
    
    