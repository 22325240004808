
    import "./index.scss"
    import React, { MouseEventHandler, useEffect, useState } from "react"

    type Props= {
        children:JSX.Element[]
        handleChange?:(arg0:number)=>void
    }

    const Shuffler = ({children,handleChange}:Props)=>{

        const [currentIndex,setCurrentIndex] = useState(0)
        const current = children[currentIndex]
        const length = children.length

        useEffect(() => {
            const intervalId = setInterval(() => {
              setCurrentIndex((prevIndex) => (prevIndex + 1)%length);
            }, 10000);
        
            return () => clearInterval(intervalId);
          }, []);

        useEffect(()=>{
            if(handleChange){
                handleChange(currentIndex)
            }
        },[currentIndex])

        const handleClick:MouseEventHandler<HTMLDivElement> = (e)=>{
            const target = e.target
            if(!target){
                return
            }
            const buttonIndex = (e.target as HTMLElement).dataset.index
            setCurrentIndex(parseInt(buttonIndex||"0")||0)
        }


        return(
            <>
            <>
                {current}
            </>
            <div className="shuffle-circle-cont">
                {children.map((c,i)=>{
                    return <div data-index={i} onClick={handleClick} key={i} className={`${currentIndex===i?"selected":""} shuffle-circle`}></div>
                })}
            </div>
            </>
            )
    }
    export default Shuffler
    
    
    