import { getParams } from "@/utils/getSlug"
import { getTopBottom } from "@/utils/screen"
import {createContext, useEffect, useState} from "react"
import getCredentials from "../utils/jwt"
import useSkipFirstMutation from "@/hooks/useSkipFirstMutation"

export type ModalType = {
    name:string,
    state:(string | boolean)
}

type CredentialsType = any
export type ConfirmationType = {
    callback:Function,
    cancelCallback:Function,
    message:string
    loadingMessage?:string
}
type GoogleCredentialsType = any

type Loading = {
    isLoading:boolean,
    message?:string
}

export type ContextValueType = {
    modal:ModalType,
    setModal:React.Dispatch<ModalType>
    confirmation:(ConfirmationType|null),
    setConfirmation:React.Dispatch<(ConfirmationType|null)>,
    credentials:CredentialsType,
    setCredentials:React.Dispatch<CredentialsType>
    googleCredentials:GoogleCredentialsType
    setGoogleCredentials:React.Dispatch<GoogleCredentialsType>
    messages:string[],
    spinner:boolean,
    setSpinner:React.Dispatch<boolean>
    topBottom:{top:number,bottom:number},
    setTopBottom:React.Dispatch<{top:number,bottom:number}>
    loading:Loading
    setLoading:React.Dispatch<Loading>
    tokens:number
    setTokens:React.Dispatch<number>
    outstandingRequestCount:number
    setOutstandingRequestCount:React.Dispatch<number>
}

export const Context:   any = createContext<(ContextValueType|{})>({})


export function ContextProvider({children}:{children:JSX.Element | JSX.Element[]}){
    const [modal,setModal]=useState<ModalType>({name:"none",state:"none"})
    const [confirmation,setConfirmation] = useState<ConfirmationType|null>(null)
    const [credentials,setCredentials] = useState(null)
    const [googleCredentials,setGoogleCredentials] = useState(null)
    const [spinner,setSpinner] = useState(false)
    const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID
    const [topBottom,setTopBottom] = useState({top:0,bottom:0})
    const [loading,setLoading] = useState({isLoading:false})
    const [tokens,setTokens] = useState(null)
    const [outstandingRequestCount,setOutstandingRequestCount] = useState(0)

    useSkipFirstMutation(()=>{
        if(typeof credentials === "object"){
            const newCredentials = {...(credentials as any)}
            if(newCredentials?.user?.tokenAccount?.tokenCount){
                newCredentials.user.tokenAccount.tokenCount = tokens
                setCredentials(newCredentials)
            }
        }
    },[tokens])


    useEffect(()=>{
        const credentials = getCredentials()
        setCredentials(credentials)
        const tokenCount = credentials?.user?.tokenAccount?.tokenCount
        setOutstandingRequestCount(credentials?.outstandingRequestCount||0)
        setTokens(tokenCount||0)
        const params = getParams()
        if(params.login){
            setModal({name:"auth",state:"login"})
        }
        setTopBottom(getTopBottom())
        setTimeout(()=>setTopBottom(getTopBottom()),200)
    },[])

    useEffect(()=>{
        
        if(credentials){
            window.sessionStorage.setItem("the_one_cred",JSON.stringify(credentials))
        }
    },[credentials])


    return(
        <Context.Provider value={{
    modal,setModal,
    confirmation,setConfirmation,
    credentials,setCredentials,
    googleCredentials,setGoogleCredentials,
    spinner,setSpinner,topBottom,setTopBottom,
    loading,setLoading,tokens,setTokens,
    outstandingRequestCount,setOutstandingRequestCount
}}>
            {children}
        </Context.Provider>
    )
}
