import "./index.css"
import React,{useContext, useEffect, useState} from "react"
import { getUsersPages } from "../../api/user"
import { Context, ContextValueType } from "../../context"
import PageCard from "../PageCard"
import Section from "@/generics/Section"
import PageStatusPopup from "../PageStatusPopup"
import { PageDetailsType } from "@/types/Page"
import ShareModal from "@/generics/ShareModal"

    const MyPages = ({setIsCreating}:{setIsCreating:React.Dispatch<boolean>})=>{

        const {credentials,outstandingRequestCount}:ContextValueType = useContext(Context)
        const [isModalOpen,setIsModalOpen] = useState(false)
        const [cards,setCards]:[PageDetailsType[],React.Dispatch<PageDetailsType[]>] = useState<PageDetailsType[]>([])
        const [statusModalOpen,setStatusModalOpen] = useState<PageDetailsType|undefined>(undefined)
        const [isStatusModalOpen,setIsStatusModalOpen] = useState<boolean>(false)
        const [shareModal,setShareModal] = useState({pageName:""})
        const [subState,setSubState] = useState(false)

        useEffect(()=>{
            if(statusModalOpen){
                setIsStatusModalOpen(true)
            }
        },[statusModalOpen])


        useEffect(()=>{
            if(window.location.origin.includes("localhost")){
                console.log(credentials?.accessToken)
            }

            const get = async ()=>{
                const pages = await getUsersPages({credentials})
                if(!Array.isArray(pages)){
                    return
                }
                setShareModal({pageName:""})
                setCards(pages)
            }
            get()
        },[])

        useEffect(()=>{
            if(cards.length===0){
                return
            }
            if(window.location.search[0]==="?"){
                const openId = window.location.search.slice(1)
                const card = cards.find(c=>c._id===openId)

                if(card?.status?.isPaidFor){
                    return
                }
                setStatusModalOpen(card)
            }
        },[cards])

        const handleStatusButton = (card:PageDetailsType)=>{

            setStatusModalOpen(card)
        }
        
        return(
            <div>
                {(outstandingRequestCount||"")&&<div>
                    You have {outstandingRequestCount} outstanding section requests right now. <a href="/my-section-requests">Click Here to Review</a>
                    </div>}
                <Section sectionName="my-pages-banner">
                <h1>{credentials.user.name}'s Pages</h1>
                {cards.length>0?<p>Select a Page below to edit or click to add a new page.</p>:<h3>Click 'New Page +' button below to add your first page.</h3>}
                </Section>
                <Section sectionName="cards-section">


                {/* <button className="add-page-btn" onClick={()=>setIsModalOpen(true)}> */}
                <button className="add-page-btn" onClick={()=>setIsCreating(true)}>

                    New Page +

                </button>
                <div className="cards-cont">
                {cards.map((card:PageDetailsType,index:number)=>{
                    return(
                        <PageCard
                        key={index}
                        setShareModal={setShareModal}
                        setSubState={setSubState}
                        handleStatusButton={()=>handleStatusButton(card)}
                        cardsUseState={[cards,setCards]} card={card} setModal={setIsStatusModalOpen}></PageCard>
                        )
                    })}
                </div>
                </Section>
                {(isStatusModalOpen&&statusModalOpen)&&<PageStatusPopup subState={subState} page={statusModalOpen} setIsModalOpen={setIsStatusModalOpen}/>}
                {(shareModal&&(!(shareModal.pageName === "")))&&<ShareModal setModal={setShareModal} pageName={shareModal.pageName}/>}
            </div>
        )
    }
    export default MyPages
    
    
    