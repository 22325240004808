
    import { PageContext } from "@/pages/Standard/context"
import AssistanceModal from "../AssistanceModal"
import "./index.css"
    import React, { useContext, useState } from "react"
import { PageContextType } from "@/types/Context"
import Modal from "@/generics/Modal"

    const AssistanceButtonToggle = ()=>{

        const [isOpen,setIsOpen] = useState(false)
        const pageContextValues:PageContextType = useContext(PageContext)
        const [assistanceOn,setAssistanceOn] = useState(pageContextValues.page.assisted||false)
        const pageId = pageContextValues.pageId
        if(!isOpen){
            return<button onClick={()=>setIsOpen(true)}>Assistance</button>
        }

        return(
            <Modal
                setIsModalOpen={setIsOpen}
            >

            <AssistanceModal
            pageId={pageId}
            assistanceOn={assistanceOn}
            setAssistanceOn={setAssistanceOn}
            
            ></AssistanceModal>
            </Modal>
        )
    }
    export default AssistanceButtonToggle
    
    
    