
    import { PopulatedSectionSubmission } from "@/types/SectionSubmission"
import ConfirmAcceptSubmission from "./ConfirmAcceptSubmission"
import "./index.scss"
    import React, { useState } from "react"


    type Props = {
        submission:PopulatedSectionSubmission
    }

    const AcceptSubmission = ({submission}:Props)=>{
        
        
        const [isOpen,setIsOpen] = useState(false)

        if(!isOpen){
            return<button className="accept-submission-btn" onClick={()=>setIsOpen(true)}>Accept Submission Below</button>
        }
         
        return<ConfirmAcceptSubmission
        setIsOpen={setIsOpen}
        submission={submission}
        ></ConfirmAcceptSubmission>

    }
    export default AcceptSubmission
    
    
    