
    import "../CustomLayoutModal/index.scss"
    import React, { useContext, useEffect, useRef, useState } from "react"
import { Context } from "@/context"
import useSectionZIndex from "@/hooks/useSectionZIndex"

    type Props = {
        modalTitle?:string,
        children?:JSX.Element,
        additionalClass?:string,
        setIsModalOpen?:React.Dispatch<boolean>,
        header?:string
        isStatic?:boolean
        top?:(string|null)
        collapsed?:boolean
        setCollapsed?:React.Dispatch<boolean>
    
      }

    const DraggableModal = ({isStatic,additionalClass="",children,setIsModalOpen,header,top,collapsed,setCollapsed}:Props)=>{

      const zIndexHelper = useSectionZIndex();
        let setIsModalOpenResolved:    any;
        const {setModalOpen}:{setModalOpen:React.Dispatch<React.SetStateAction<string|null>>} = useContext(Context)
        if(!setIsModalOpen){
            setIsModalOpenResolved = setModalOpen
        }else{
            setIsModalOpenResolved = setIsModalOpen
        }
        
        const [modalStyles, setModalStyles] = useState({});
        const modalRef = useRef<HTMLDivElement>(null);
        const [grabbing,setGrabbing] = useState(false)
        const [posData,setPosData] = useState<any>()
        useEffect(()=>{
            const newStyles = {...modalStyles,top};
            if(top&&modalRef?.current?.style&&!isStatic){
              modalRef.current.style.top = "50%"
              setModalStyles(newStyles)
            }
        },[top])


        const handleMouseDown = (event: React.MouseEvent) => {
          if (isStatic){
            return
          }
          event.preventDefault()
          setGrabbing(true)
          const currentStyles = modalRef.current?.getBoundingClientRect();
          if(!currentStyles){
            return
          }
          const currentTop = currentStyles?.top
          const clientY = event.clientY
          const initialX = event.clientX - currentStyles?.left;
          const offsetY = event.clientY - currentTop
          const dimensions = {
            width:currentStyles.width,
            height:currentStyles.height
          }
          function handleMouseMove(event: MouseEvent) {
            const clientY = event.clientY

            const newStyles = {
              left: (event.clientX - initialX)+dimensions.width/2,
              top: clientY - offsetY+dimensions.height/2
            }
            setPosData({clientY,offsetY,height:dimensions.height,top:newStyles.top})
            setModalStyles(newStyles);
          }
      
          window.addEventListener("mousemove", handleMouseMove);
      
          function handleMouseUp() {
            window.removeEventListener("mousemove", handleMouseMove);
            window.removeEventListener("mouseup", handleMouseUp);
            setGrabbing(false)
          }
      
          window.addEventListener("mouseup", handleMouseUp);
        }
        return(
    <div
      ref={modalRef}
      className={`modal ${additionalClass}`}
      style={modalStyles}
    //   onMouseDown={handleMouseDown}
    >
        <div ref={zIndexHelper} className={`move-bar ${grabbing?"grabbing":""}`} onMouseDown={handleMouseDown}>
          {header&&<h3>{header}</h3>}
          {setCollapsed&&<>
            {!collapsed&&<span onClick={()=>setCollapsed(true)}>-</span>}
            {collapsed&&<span onClick={()=>setCollapsed(false)}>+</span>}
          </>}
        </div>
                <button className="close-modal-button no-translate-animation" onClick={()=>setIsModalOpenResolved(null)}>×</button>
                <section  className="modal-content">
                  <>
                {children}
                  </>
                </section>
            </div>
        )
    }
    export default DraggableModal
    
    
    