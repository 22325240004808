import { getTextWidth } from "@/components/FullFlexTextarea/helpers"
import { loggo } from "@/utils/dev"
import lineHeight from "@/utils/sectionEditorUtils/fields/lineHeight"

type HandleWorkBreaksArgs = {
    maxWidth:number
    text:string
    charactersPerLine:number
    style:CSSStyleDeclaration
}

const handleWordBreaks = ({maxWidth,text,style}:HandleWorkBreaksArgs)=>{
    const {fontFamily,fontSize,fontWeight,textTransform} = style

    let words = text.split(" ")
    words = words.map((word,index)=>{
        if(index===0){
            return word
        }
        return " "+word
    })
    const lines = []
    let currentLine = ""
    for (let word of words){
        const line = currentLine+word
        const testTextWidth  = getTextWidth(line,fontFamily,fontSize,fontWeight,textTransform).newWidth
        if(maxWidth<testTextWidth){
            // i'll have to handle very long strings too
            lines.push(currentLine)
            currentLine = word
        }
        else{
            currentLine+=word
        }
    }
    lines.push(currentLine)
    return lines.length
}




type GetTextWidthsArgs = {
    lines:string[]
    computedStyle:CSSStyleDeclaration
    padding:number
}

const getTextWidths = ({lines,computedStyle,padding}:GetTextWidthsArgs)=>{
    const {fontFamily,fontSize,fontWeight} = computedStyle
    const textTransform = computedStyle.textTransform
    const textWidths = lines.map((line)=>{
        return {line,width:getTextWidth(line,fontFamily,fontSize,fontWeight,textTransform,padding).newWidth}
    })   
    return textWidths
}

type GetNumberOfLinesArgs = {
    textWidths:{line:string,width:number}[]
    maxWidth?:number
    style:CSSStyleDeclaration
}

const getNumberOfLines = ({textWidths,maxWidth,style}:GetNumberOfLinesArgs)=>{
    const fontSizeNumber = parseInt(style?.fontSize?.replace("px",""))
    const getLineCount = ({textWidth,maxWidth}:{textWidth:{line:string,width:number},maxWidth?:number})=>{
        if(!maxWidth){
            return 1
        }
        const floatNaiveLineCount = (textWidth.width)/(maxWidth-fontSizeNumber/2)
        const charactersPerLine = textWidth.line.length/floatNaiveLineCount
         const lineCount = handleWordBreaks({maxWidth,text:textWidth.line,charactersPerLine,style})
        return lineCount
    }
    const totalLines = textWidths.reduce((acc:number,curr:{line:string,width:number})=>{
        const count = getLineCount({textWidth:curr,maxWidth})
        return count+acc
    },0)
    return totalLines
}




type CalcLineHeightArgs = {
    lineHeight:string
    fontSize:string
}


const calcLineHeight = ({lineHeight,fontSize}:CalcLineHeightArgs)=>{
    if(lineHeight==="normal"){
        return 1.4
    }

    if(lineHeight.includes("px")&&fontSize.includes("px")){
        const lineHeightNumber = parseFloat(lineHeight.replace("px",""))
        const fontSizeNumber = parseFloat(fontSize.replace("px",""))
        return lineHeightNumber/fontSizeNumber
    }
    return 1.4
}





const getTextStyleAttributes = (computedStyle:CSSStyleDeclaration)=>{
    const {fontSize,fontFamily,fontWeight,lineHeight} = computedStyle
    return {fontSize,fontFamily,fontWeight,lineHeight}
}


type GetDimensionsArgs = {
    element:HTMLTextAreaElement
    lines:string[]
    maxWidth?:number
    padding?:number|""
}




export const getDimensions = ({element,lines,maxWidth,padding}:GetDimensionsArgs)=>{
    const computedStyle = window.getComputedStyle(element)
    const textAttributes = getTextStyleAttributes(computedStyle)
    const lineHeight = computedStyle.lineHeight==="normal"?1.4:calcLineHeight({lineHeight:computedStyle.lineHeight,fontSize:computedStyle.fontSize})
    const textWidths = getTextWidths({lines,computedStyle,padding:(padding||0)})
    const numberOfLines = getNumberOfLines({textWidths,maxWidth,style:computedStyle})
    const widthNumbers = textWidths.map(t=>t.width)
    const width = Math.min(Math.max(...widthNumbers,30),(maxWidth||10000))
    const height = ((numberOfLines||1)*lineHeight)*(parseInt(computedStyle.fontSize))*1.05
    const dimensions = {
        width:(width)+(padding||0)+"px",
        height:height+(padding||0)+"px"
    }

    return dimensions
}