export default {prompt:"Text Animation?",
field:"effect",
type:"class",
default:"none",
options:[
    {value:"none",text:"None"},
    {value:"slide-left",text:"Slide In"},
    {value:"slide-up",text:"Slide Up"},
    {value:"grow",text:"Grow"}
]
}