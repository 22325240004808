
import "./index.scss"
import { ChangeEventHandler, useContext } from "react"
import { SectionContext } from "@/components/PuxMod"
import { PageContextType } from "@/types/Context"
import { PageContext } from "@/pages/Standard/context"
import Breaker from "@/generics/Breaker"
import { SubsectionContext } from "@/components/SubPuxMod"
import AutoResizeTextArea from "@/generics/AutoResizeTextArea"
import { SectionState } from "@/types/Section"
import { DynamicAnyObject, DynamicObject } from "@/types/generic"
import FullFlexAutoResizeTextArea from "@/generics/FullFlexAutoResizeTextArea"
import useSkipFirstMutation from "@/hooks/useSkipFirstMutation"
import MaxWidthAutoText from "@/generics/MaxWidthAutoText"

const EMaxWidthTextArea = ({field,subsection,classString,maxWidth,padding,passedStyle}:{field:string,maxWidth:number,subsection?:boolean,classString?:string,padding:number,passedStyle:DynamicObject})=>{
    
    const {isEditing}:PageContextType = useContext(PageContext)
    const subsectionContext:any = useContext(SubsectionContext)
    const sectionContext:SectionState = useContext(SectionContext)
    const {fields,handleInputChange}:{fields:DynamicObject,handleInputChange:ChangeEventHandler<HTMLTextAreaElement>} = subsection?subsectionContext:sectionContext
    let style:DynamicObject = {}
    if(field&&sectionContext?.childStyles?.[field]){
        style = field&&sectionContext?.childStyles?.[field]
    }
    style.maxWidth = maxWidth+"px"
    style.padding= padding.toString()

    if(isEditing){
        const textAreaStyle = {...style,...passedStyle}
        return(
            <div className={`${classString} ${field}+"_class e-textarea`}>
            <MaxWidthAutoText
            name={field}
            style={textAreaStyle}
            data-padding={padding} 
            value={(fields[field]||"").replaceAll("<br>","\n").replaceAll("$<$br$>$","\n")}
            onChange={handleInputChange}
            data-break={true}
            className={`transparent ${classString} `}
            id={subsectionContext.subsectionId||sectionContext.sectionId}
            ></MaxWidthAutoText></div>
            )
    }

    const className = field+"_class e-textarea "+(classString?classString:"")

    return(
        <div style={style} className={className}><Breaker text={(fields[field]||"")}></Breaker></div>
    )
}
export default EMaxWidthTextArea