export const navigateToPage = (pageName:string)=>{
    window.location.href = `/${pageName}`
}

export const toRegister = ()=>{
    window.location.href = `/register`
}

export const toLogin = ()=>{
    window.location.href = "/login"
}