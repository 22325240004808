import { editGenericSubsection } from "@/api/genericSubsection"
import { editSectionFields } from "@/api/section"
import { SectionContext } from "@/components/PuxMod"
import { SubsectionContext } from "@/components/SubPuxMod"
import ImageEditor from "@/generics/ImageEditorNew"
import { PageContext } from "@/pages/Standard/context"
import { PageContextType } from "@/types/Context"
import { DynamicObject } from "@/types/generic"
import { i } from "@/utils/images"
import route from "@/utils/route"
import { useContext, useEffect, useRef, useState } from "react"
import "./index.css"
import { GenericSubsectionDocument } from "@/types/Documents"
import { SectionState } from "@/types/Section"
import setImageDetailStyles from "./setImageDetailStyles"
import useSkipFirstMutation from "@/hooks/useSkipFirstMutation"


    const EImage = ({field,subsection,classString,handleClick}:{field:string,subsection?:boolean,classString?:string,handleClick?:any})=>{
        const {isEditing}:PageContextType = useContext(PageContext)
        const subsectionContext:GenericSubsectionDocument = useContext(SubsectionContext)
        const sectionContext:SectionState = useContext(SectionContext)
        const {fields} = subsection?subsectionContext:sectionContext

        const [imageDetails,setImageDetails] = useState(subsection?subsectionContext.imageDetails:sectionContext?.imageDetails)
        const imageRef = useRef(null)
        const imageElement = imageRef?.current

        useSkipFirstMutation(()=>{
            if(subsectionContext.setImageDetails){
                subsectionContext.setImageDetails(imageDetails)
            }
            
        },[imageDetails])

        const handleEditImage = async (url:string)=>{
            const changedFields:DynamicObject = {}
            changedFields[field] = url
            if(subsection){
                const data = await editGenericSubsection({subsectionId:subsectionContext.subsectionId,changedFields,type:"generic"})
            }else{
                const data = await editSectionFields({sectionId:sectionContext.sectionId,changedFields})
            }
        }
        const id = subsection?subsectionContext.subsectionId:sectionContext.sectionId
        const routeStr = route('api/upload')
        
        useEffect(()=>{
            if(imageElement){
                setImageDetailStyles({imageElement,imageDetails})
            }
        },[imageElement])
        if(isEditing){
            return<ImageEditor
                isSection={false}
                field={field}
                url={i(fields?.[field])||""}
                handleEdit={handleEditImage}
                sectionId={id}
                route={routeStr}
                imageDetails={imageDetails}
                setImageDetails={setImageDetails}
                className={classString}
            ></ImageEditor>
        }

        return(
            <img onClick={handleClick} ref={imageRef} src={i(fields[field]||"")} className={field+"_class"}></img>
        )
    }
    export default EImage
    
    
    