
    import "./index.scss"
    import React, { useContext } from "react"
import { fexPut } from "@/utils/fex"
import { PageContextType } from "@/types/Context"
import { PageContext } from "@/pages/Standard/context"
import { SectionType } from "@/types/Section"
import { getEditOrdinal } from "@/utils/pageManipulation"
import { FaArrowDown, FaArrowUp } from "react-icons/fa"

    type Props = {
        section:SectionType,
        first:boolean,
        last:boolean,
        handleEditScreen:Function
    }

    const ReorderArrows = ({section,first,last,handleEditScreen}:Props)=>{
        const {pageId}:PageContextType = useContext(PageContext)
        const {_id} = section

        const {editOrdinals,setEditOrdinals}:PageContextType = useContext(PageContext)
        const ordinal = getEditOrdinal({sectionId:section._id,editOrdinals})

        const handleUp = async ()=>{
            handleEditScreen("up")
            // const resp = await fexPut("api/section/ordinals/"+_id,{newOrdinal:ordinal-1,pageId})
        }
        const handleDown = ()=>{
            const url = `api/section/ordinals/${_id}`
            handleEditScreen("down")
            // fexPut(url,{newOrdinal:ordinal+1,pageId})
        }

        return(
            <div className="arrows-cont tooltip right" data-tooltip="Move Section">
                {!last&&<button onClick={handleDown}><FaArrowDown></FaArrowDown>
                </button>}
                {last&&<button disabled onClick={()=>{}}><FaArrowDown></FaArrowDown>
                </button>}
                {!first&&<button onClick={handleUp}><FaArrowUp></FaArrowUp>
                </button>}
                {first&&<button disabled onClick={()=>{}}><FaArrowUp></FaArrowUp>
                </button>}
            </div>
        )
    }
    export default ReorderArrows