
    import "./index.css"
    import React from "react"

    type Props = any

    const ThemePreview = ({styles,styleVariables,parsedStyles}:Props)=>{
        return(
                            <div style={{...styles,...styleVariables}} className={`preview-cont ${parsedStyles.class}`}>
                    <h1>Heading Preview</h1>
                    <p>This editor determines the appearance of the page as a whole. You can also customize every section. If there are additional theme options that you want available for the site, reach out to us at admin@puxhub.com.</p>
                     </div>
        )
    }
    export default ThemePreview
    
    
    