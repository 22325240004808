import { DynamicObject, DynamicObjectWithNumber } from "@/types/generic"
import { LayoutElementType } from "@/types/layout"

const styleKeys = ["--imageHeight","textAlign","--underlineColor","--header-name-fd","color","background","--overlayOpacity","textTransform","--headingSize","--font-size","--flex-direction","--verticalPadding","--linkSize","--textContBackground","--headingColor","lineHeight","--icon-color","--color"]

export const makeStyleString = (object:DynamicObjectWithNumber)=>{
    let str = ""
    for(let key in object){
        str+=`${key}:${object[key]} `
    }
    return str
}

export const getFieldName = (layout:LayoutElementType)=>{
    if(!layout.child||layout.property){
      return layout.field
    }

    return `${layout.field}/${layout.child}`
}


function startsWithNumbers(input: string): boolean {
    const pattern = /^\d+/;
    const match = input.match(pattern);
    return match !== null;
  }

function parseValueAndUnit(input: string): { value: number; unit: string } | null {
    const pattern = /^(\d+(?:\.\d+)?)([a-zA-Z]+)$/;
    const match = input.match(pattern);
  
    if (match !== null) {
      const value = parseFloat(match[1]);
      const unit = match[2];
      return { value, unit };
    }
  
    return null;
}

export type LayoutInputObject = {
    isNumber:boolean,
    hasNumber:boolean,
    value:number|string
    unit?:string
} 

function stringIsValidNumber(str: string): boolean {
    return !isNaN(parseFloat(str)) && isFinite(Number(str));
  }

export const parseCSSValue = (cssValue:(string|number)):LayoutInputObject=>{
    const isNumber = (typeof cssValue === "number")||stringIsValidNumber(cssValue)
    if(isNumber){
        return {
            isNumber:true,
            hasNumber:true,
            value:cssValue
        }
    }
    if(startsWithNumbers(cssValue+"")){
        const parsedValueAndUnit = parseValueAndUnit(cssValue+"")
        const value = parsedValueAndUnit?.value
        const unit = parsedValueAndUnit?.unit
        if(value&&unit){
            return {
                isNumber:false,
                hasNumber:true,
                value:value,
                unit:unit
            }
        }
    }
    return {
        isNumber:false,
        hasNumber:false,
        value:cssValue
    }
}


export const convertColorToString = (color:(string|number))=>{

     let colorString = color?.toString()||"#0000000"
        if(colorString.length<9){
            colorString = colorString.substring(0,7)+"ff"
        }
     return colorString
}