import React, { useEffect } from "react";
import "./index.scss"

const TikTokEmbed = ({videoId}: {videoId: string}) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.tiktok.com/embed.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
      <div className="tiktok-embed-wrapper">
      <iframe
          src={`https://www.tiktok.com/embed/${videoId}`}
          allow="encrypted-media;"
          scrolling="no"
          ></iframe>
    </div>
  );
};

export default TikTokEmbed;