
    import EditBox from "@/components/EditBox";
import "./index.css"
    import { useContext, useState } from "react"
import EditButton from "@/generics/EditButton";
import { DynamicObject } from "@/types/generic";
import { PageContext } from "@/pages/Standard/context";
import { PageContextType } from "@/types/Context";

    type ObjectFields = {
        href:string
        linkText:string
    }

    type Props = {
        initialFields:ObjectFields
        handleFieldSave:(arg0:DynamicObject)=>void
    }

    const ELink = ({handleFieldSave,initialFields}:Props)=>{

        const [fields,setFields] = useState(initialFields)
        const {href,linkText} = fields
        const {isEditing}:PageContextType = useContext(PageContext);
        const [isEditBoxOpen,setIsEditBoxOpen] = useState(false)

        if(!isEditing){
            return(<div className="e-link-cont">
            <a href={href}>{linkText}</a>
            </div>)
        }

        const handleSave = (fields:ObjectFields)=>{
            setFields(fields)
            setIsEditBoxOpen(false)
            handleFieldSave(fields)
        }

        if(isEditBoxOpen){
            return(
                <footer className="e-link-cont">
                <EditBox
                    initialValueObject={{href,linkText}}
                    handleSave={(fields)=>handleSave(fields as ObjectFields)}
                    ></EditBox>
                    </footer>
            )
        }

        return(
            <footer className="e-link-cont">
            <a href={href}>{linkText}</a>
            <EditButton 
            size={15}
            position="unset"
            setIsEditing={setIsEditBoxOpen}></EditButton>
            </footer>
        )

    }
    export default ELink
    
    
    