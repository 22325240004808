import "./index.scss"
import React,{useContext,useEffect,useRef,useState} from "react"
import { PageContextType } from "@/types/Context"
import { PageContext } from "@/pages/Standard/context"
import DeleteCircle from "@/generics/DeleteCircle"
import GenericSubsection from "@/components/GenericSubsection"
import { deleteGenericSubsection } from "@/api/genericSubsection"
import ExpanderButton from "@/generics/buttons/ExpanderButton"
import { GenericSubsectionDocument } from "@/types/Documents"

const Review = ({review}:{review:GenericSubsectionDocument})=>{
    
    const [isDeleted,setIsDeleted] = useState(false)
    const [expander,setExpander] = useState(false)
    const [isExpanded,setIsExpanded] = useState(false)
    const {isEditing}:PageContextType = useContext(PageContext)
    const reviewRef = useRef(null)

    const handleDelete = async  (e:React.MouseEvent<HTMLButtonElement>)=>{
        const _id = (e.target as any)?.dataset.id
        const resp = await deleteGenericSubsection({subsectionId:_id})
        setIsDeleted(true)
    }

    useEffect(()=>{
        if(!reviewRef||isEditing){
            return
        }
        const el = (reviewRef.current as unknown as HTMLElement).querySelector(".hidden-overflow");
        if(!el){
            return
        }
        const child = el.querySelector(".cont")
        const parentHeight = el.clientHeight||0
        const childHeight = child?.clientHeight||0
        if(parentHeight<childHeight){
            setExpander(true)
        }
    },[])

    if(isDeleted){
        return<></>
    }



    return(
    <div ref={reviewRef} className={`review-cont ${isExpanded?"expand":""} ${expander&&!isExpanded?"fade":""}`}>
    <div className="hidden-overflow">
        <GenericSubsection
        element={review}
        type="review"
        className={`review ${isEditing?"editing":""}`}
        includedFields={["heading","subheading"]}
        >
    </GenericSubsection>
    {(expander&&!isEditing)&&<ExpanderButton isExpanded={isExpanded}
    setIsExpanded={setIsExpanded}
    ></ExpanderButton>}
    </div>
    {isEditing&&<DeleteCircle
        _id={review._id}
        handleDelete={handleDelete}
        tooltip="Remove Review"
    ></DeleteCircle>}
    </div>)
}

export default Review

   