
    import { DynamicObject } from "@/types/generic"
import "./index.scss"
    import React, { DragEventHandler, useRef } from "react"

    type Position = {
        top:string
        bottom:string
        left:string
        right:string
    }

    type Props = {
        direction:string
        handleDrag:({side,px}:{side:string,px:string})=>void
        position:Position
    }

    const StretchDragger = ({direction,handleDrag,position}:Props)=>{
        const ref = useRef(null)

        const constantPosition:DynamicObject = {
            
        }

        if(direction==="up"){
            constantPosition.left = "50%"
            constantPosition.top = "0px"
            constantPosition.transform =`translate(-50%,-50%)`
        }
        if(direction==="down"){
            constantPosition.left = "50%"
            constantPosition.bottom = "0px"
            constantPosition.transform =`translate(-50%,50%)`

        }

        if(direction==="left"){
            constantPosition.top = "50%"
            constantPosition.left = `0px`
            constantPosition.transform =`translate(-50%,-50%)`

        }
        if(direction==="right"){
            constantPosition.top = "50%"
            constantPosition.right = `0px`
            constantPosition.transform =`translate(50%,50%)`

        }

        const handleDragWithin:DragEventHandler<HTMLDivElement> = (e)=>{
            if(!ref?.current){
                return
            }
            // const {clientX,clientY} = e
            const rect = (ref as any).current.parentNode.parentNode.getBoundingClientRect();
            const frameLeft = rect.x;
            const frameTop = rect.y
            const frameRight = rect.x+rect.width;            
            const frameBottom = rect.y+rect.height;
            if(direction==="right"){
                const right = Math.max(-e.clientX+frameRight,0)
                if(!right||e.clientX===0){
                    return
                }
                handleDrag({side:direction,px:`${right}`})
            }
            if(direction==="left"){
                const left = Math.max(e.clientX-frameLeft,0)
                if(!left||e.clientX===0){
                    return
                }
                handleDrag({side:direction,px:`${left}`})
            }
            if(direction==="up"){
                const top = Math.max(e.clientY-frameTop,0)
                if(!top||e.clientY===0){
                    return
                }
                handleDrag({side:"top",px:`${top}`})
            }
            if(direction==="down"){
                const bottom = Math.max(-e.clientY+frameBottom,0)
                if(!bottom||e.clientY===0){
                    return
                }
                handleDrag({side:"bottom",px:`${bottom}`})
            }
        }
        
        return(
            <div ref={ref} onDrag={handleDragWithin} draggable style={{...constantPosition}} className={`stretch-dragger ${direction}`}>

            </div>
        )
    }
    export default StretchDragger
    
    
    