
    import "./index.scss"
    import React, { ChangeEvent, ChangeEventHandler, CSSProperties, FormEvent, FormEventHandler, HTMLAttributes, useContext, useEffect, useRef, useState } from "react"
import { calculateHeightBasedOnRow } from "@/sections/NewHero/helpers";
import { getElementHeight } from "@/utils/elementProperties";
import { countLines } from "./helpers";
import { editSectionFields } from "@/api/section";
import Section from "../Section";
import { SectionContext } from "@/components/PuxMod";
import { SectionRef, SectionState } from "@/types/Section";
import { Procrast } from "@/types/procrast";

    interface TextareaHTMLAttributes<T> extends HTMLAttributes<T> {
        resizeTrigger?: number;
        value:string
        name:string
        className:string
        onChange:Procrast
        sectionRef:SectionRef
        proportion:number
        setFontScale:React.Dispatch<number>
        fontScale:number
        headingRows:number
        subheadingRows:number
        setHeadingRows:React.Dispatch<number>
        setSubheadingRows:React.Dispatch<number>
        
        // ...
      }

    const AutoResizeTextArea = (props:TextareaHTMLAttributes<HTMLTextAreaElement>)=>{

        
        
        const {onChange,value,name,className,resizeTrigger,sectionRef,proportion,setFontScale,fontScale,headingRows,subheadingRows,setHeadingRows,setSubheadingRows} = props
        const {sectionId}:SectionState  = useContext(SectionContext)
        const textAreaRef = useRef<HTMLTextAreaElement>(null);
        const [text, setText] = useState((value as string).replaceAll("<br>","\n"));
        const [textAreaHeight, setTextAreaHeight] = useState("auto");
        const [parentHeight, setParentHeight] = useState("auto");
        const [scrollHeight,setScrollHeight] = useState(0)
        const [textWithBR,setTextWithBR] = useState("")
        const [cursorPosition,setCursorPosition] = useState(text.length)
        const size = name==="heading"?2:1
        const [updateSize,setUpdateSize] = useState(0)
        const [rows,setRows] = useState(text.split("\n").length)
        const [elementsIntialized,setElementsInitialized] = useState(0)
            
        useEffect(()=>{
            const basePixels = size/100*window.innerWidth
            setParentHeight(`${basePixels*fontScale*rows}px`);
            setTextAreaHeight(`${basePixels*fontScale*(rows+.2)}px`);
        },[fontScale])

        useEffect(()=>{
            if(elementsIntialized<2){
                setElementsInitialized(elementsIntialized+1)
                return
            }
            if(name==="heading"&&sectionRef?.current){
                const unscaledHeight = calculateHeightBasedOnRow({scale:1,headingRows,subRows:subheadingRows})
                const targetHeight = getElementHeight(sectionRef.current)*proportion
                const newScale = targetHeight/unscaledHeight
                setFontScale(newScale)

                const basePixels = size/100*window.innerWidth
                setParentHeight(`${basePixels*newScale*rows}px`);
                setTextAreaHeight(`${basePixels*newScale*(rows+.2)}px`);
                setUpdateSize(updateSize+1)
                editSectionFields({sectionId,changedFields:{fontScale:newScale.toString()}})
            }

        },[headingRows,subheadingRows])

        const parentStyle: CSSProperties = {
            minHeight: parentHeight,
        };
        const textAreaStyle: CSSProperties = {
            height: textAreaHeight,
            ...props.style
        };

        useEffect(()=>{
            setRefsPosition(cursorPosition)
        },[text])

        useEffect(()=>{
            if(!updateSize){
            return
            }

        },[updateSize])

        useEffect(()=>{
            if(resizeTrigger&&sectionRef?.current){
                const unscaledHeight = calculateHeightBasedOnRow({scale:1,headingRows,subRows:subheadingRows})
                const targetHeight = getElementHeight(sectionRef.current)*proportion
                const newScale = targetHeight/unscaledHeight
                setFontScale(newScale)

                const basePixels = size/100*window.innerWidth
                setParentHeight(`${basePixels*newScale*rows}px`);
                setTextAreaHeight(`${basePixels*newScale*rows}px`);
                setUpdateSize(updateSize+1)

            }
        },[resizeTrigger])

        useEffect(()=>{
            if(textAreaRef){
                switch(name){
                    case "heading":
                        setHeadingRows(rows)
                        break;
                    case "subheading":
                        setSubheadingRows(rows)
                }
            }
        },[textAreaRef])

        const setRefsPosition = (position: number) => {
            if (textAreaRef.current) {
              textAreaRef.current.setSelectionRange(position, position);
              textAreaRef.current.focus();
            }
        };

        const handleChange = (e:ChangeEvent<(HTMLTextAreaElement)>)=>{
            const cursorPosition = e.target.selectionStart;
            setCursorPosition(cursorPosition)
            if(e.target.value.length<text.length){
                    setTextAreaHeight("auto");

            }
            setText(e.target.value);
            if (props.onChange) {
                props.onChange(e);
            }
            const newScrollHeight = textAreaRef.current!.scrollHeight
                setParentHeight(`${textAreaRef.current!.scrollHeight}px`);
                setTextWithBR(e.target.value.split("\n").join("<br>"))
                setScrollHeight(newScrollHeight)
            if(!e||!onChange){
                return
            }
            e.target.value = e.target.value.split("\n").join("<br>")
            onChange(e)

            const currentRows = (e.target.value.split("<br>").length)
            if(currentRows!==rows){
                if(name==="heading"){
                    setHeadingRows(currentRows)
                }else{
                    setSubheadingRows(currentRows)
                }
                setRows(currentRows)
            }
        }

        useEffect(() => {
            const basePixels = size/100*window.innerWidth
            setParentHeight(`${basePixels*fontScale*rows}px`);
            setTextAreaHeight(`${basePixels*fontScale*rows}px`);
        }, [text]);

        return (
            <div className={ " auto-textarea-cont over-wide"} style={parentStyle}>
                <textarea className={className+" auto-textarea p"}
                    name={name}
                    value={(text)}
                    onChange={handleChange}
                    ref={textAreaRef}
                    style={textAreaStyle}
                    rows={1}
                />
            </div>
        );
    }
    export default AutoResizeTextArea
    
    
    