import { fexGet, fexPost } from "@/utils/fex"


export const addArticle = async ({title,body}:{title:string,body:string})=>{
    const res = await fexPost("api/article",{title,text:body})
    return res
}

export const getArticle = async ({slug}:{slug:string})=>{
    const res = await fexGet(`api/article/${slug}`)
    return res
}

export const getAllArticles = async ()=>{
    const res = await fexGet(`api/article/`)
    return res
}