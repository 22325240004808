    import "./index.scss"
    import React, { useState } from "react"

    type ButtonProps = {
        value:string
        text:string
    }

    type Props = {
        buttons:ButtonProps[]
        handleSelect:(arg0:string)=>void
    }

    const ButtonBar = ({buttons,handleSelect}:Props)=>{

        const [selected,setSelected] = useState<string>(buttons[0].value)

        const handleClick = (e:any)=>{
            const {value} = e.target.dataset;
            setSelected(value)
            handleSelect(value)
        }

        return(
            <div className="button-bar">
                {buttons.map((button:ButtonProps,index:number)=>{
                    return <button className={button.value===selected?"selected":""} 
                    onClick={handleClick}
                    data-value={button.value}>{button.text}</button>
                })}
            </div>
        )
    }
    export default ButtonBar
    
    
    