import { APIFailure } from "@/types/API"
import { CSSField } from "@/types/css"
import { CustomElementType } from "@/types/sectionTemplate"
import { fexGet, fexPost } from "@/utils/fex"


type GetSectionElementsResponse = (CustomElementType | APIFailure)




export const getSectionElements = async (templateId:string)=>{
    try{
        const res:GetSectionElementsResponse = await fexGet("api/section-template/"+templateId)
        return res
    }catch(err){
        return {error:true}
    }
}

export const getSingleTemplate = async ({templateId}:{templateId:string })=>{
    try{
        const res = await fexGet(`api/section-template/${templateId}`)
        return res
    }catch(err){
        return {error:true}
    }
}

export const getAllTemplates = async ()=>{
    try{
        const res:GetSectionElementsResponse = await fexGet("api/section-template")
        return res
    }catch(err){
        return {error:true}
    }
}

type SubmitNewTemplateArgs = {
    name:string
    cssArray:CSSField[]
    html:string
    type:string
    pageId?:string
}

export const getUsersTemplatesTable = ({userId}:{userId:string})=>{
    const res = fexGet(`api/section-template/table-data/${userId}`)
    return res
}


export const submitNewTemplate = async ({name,cssArray,html,type,pageId}:SubmitNewTemplateArgs)=>{
    const res = await fexPost(`api/section-template`,{name,cssArray,html,type,pageId}) 
    return res
} 

type AddSectionFromTemplateArgs = {
    sectionTemplateId:string
    pageId:string
}

export const addSectionFromTemplate = async ({sectionTemplateId,pageId}:AddSectionFromTemplateArgs)=>{
    const res = await fexPost(`api/section-template/add-to-page`,{
        sectionTemplateId,pageId
    })
    return res

}