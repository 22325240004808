import "./index.scss"
import { login, register } from "../../api/user"
import { Context, ContextValueType } from "../../context"
import React, { useContext, useState } from "react"
import { validateRegistration } from "../../utils/yup"
import Form from "../../generics/Form"
import GoogleOAuth from "../GoogleOAuth"
import { RegistrationCredentials } from "@/types/credentials"
import LoadingSpinner from "@/generics/LoadingSpinner"
import { isMobile } from "@/utils/screen"
import GIS from "../GIS"

    const Register = ()=>{
        type ErrorsType = {field:string,statement:string}[]
        const [errors,setErrors]:[ErrorsType,React.Dispatch<ErrorsType>] = useState<ErrorsType>([])        
        const [showConfirmation,setShowConfirmation] = useState(false)
        const {setCredentials,setSpinner,spinner}:ContextValueType = useContext(Context)
        const [email,setEmail] = useState("")

        const handleSubmit =async  (formValues:RegistrationCredentials)=>{

            const recaptchaToken = formValues.recaptchaToken
            
            const {confirmPassword,email,name,password} = formValues
            const credentials = {confirmPassword,email,name,password}

            const validation = await validateRegistration(credentials)
            setSpinner(true)
            if(validation.isError){
                setSpinner(false)
                setErrors(validation.errors.map((error:string)=>{
                    return {field:error.split(" ")[0],statement:error}
                }))
                return
            }        
            setEmail(credentials.email)   
            const data = await register({credentials,recaptchaToken})
            if( data.error&&data.errorType){
                setErrors([data.errorType])
            }
            else if(data.error && "message" in data){
                setErrors([{field:"",statement:data.message}])
                setSpinner(false)

                return
            }
            if(!data.error){
                setShowConfirmation(true)
            }
            await login({credentials,setCredentials})
            setSpinner(false)

        }
        
        return(
            <div className="login">
                <div className="authentication-services">
                    <GIS></GIS>
                <p>By registering, you consent to our <a href="user-agreement">user agreement.</a></p>
                </div>
            <Form
            fields={[
                // {name:"userName"},
                {name:"name"},    
                {name:"email",type:"email"},
                {name:"password",type:"password",options:{placeholder:"Minimum 8 characters"}},
                {name:"confirmPassword",type:"password"}
                
            ]}
            errors={errors}
            inline={isMobile()}
            submitName="Sign up"
            buttonClass="secondary"
            handleSubmit={handleSubmit}
            recaptcha={true}
            ></Form>
            {showConfirmation&&<div className="confirmation-overlay"><div className="confirmation-message">
                    <h2>Success!</h2>
                    <p>
                        A confirmation email has been sent to <strong>
                            {email}.
                            </strong>
                        </p>
                        <p>
                            Check your inbox. (It may be hidden in spam or another folder.)
                            </p> 
                            <p>If this the the incorrect email,  
                                <a href="/register">
                                 {" reload this page"}
                                </a>
                                , and reregister.</p>
                </div>
                </div>
                }
                {spinner&&<LoadingSpinner></LoadingSpinner>}
            </div>
        )
    }
    export default Register
    
    
    