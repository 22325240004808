import "./index.scss"
import React, { ChangeEvent, useContext, useEffect, useState } from "react"
import { getLayoutOptions } from "@/utils/sectionEditorUtils/layout"
import { convertColorToString, getFieldName, makeStyleString, parseCSSValue } from "./helpers"
import { changeSectionStyle } from "@/api/style"
import ColorSelector from "../ColorSelector"
import FontSelector from "../FontSelector"
import DraggableModal from "../DraggableModal"
import { isMobile } from "@/utils/screen"
import { DynamicAnyObject, DynamicObject, DynamicObjectWithNumber } from "@/types/generic"
import { LayoutProperty } from "@/types/LayoutProperty"
import LayoutSlider from "../LayoutSlider"
import { num } from "@/utils/typeConversion"
import { t } from "@/utils/stringFormatting"
import CSSEditor from "../CSSEditor"
import NewColorSelector from "../NewColorSelector"
import { SectionState } from "@/types/Section"
import { SectionContext } from "../PuxMod"
import { getTemplateStylingObject } from "@/sections/Custom/stylingUtils"
import { CSSField } from "@/types/css"
import getCustomLayoutOptions from "./getCustomLayoutOptions"
import NumberInputRow from "./NumberInputRow"
import useSkipFirstMutation from "@/hooks/useSkipFirstMutation"

type Props = {
    style:string
    setStyle:React.Dispatch<string>
    sectionType:string
    sectionId:string
    setLayoutModalOpen:React.Dispatch<boolean>
    sectionName?:string
    positioning?:string
    buttonClass?:string
    isStatic?:boolean
    header?:string
    buttonName?:string
}

    const CustomLayoutModal = ({style,setStyle,sectionType,sectionId,setLayoutModalOpen,sectionName,positioning,buttonClass,isStatic,header,buttonName}:Props)=>{
        const {currentCustomCSSArray,setCurrentCustomCSSArray}:SectionState = useContext(SectionContext)
        const editableFields = currentCustomCSSArray.filter((f:CSSField)=>f.editable)
        const [isOpen,setIsOpen] = useState(false)
        const layoutOptions = getCustomLayoutOptions({cssArray:editableFields})
        const [layoutObject,setLayoutObject] = useState<DynamicObjectWithNumber>({})
        const [selectorOpen,setSelectorOpen] = useState<any>(null)
        const [top,setTop] = useState<(null|string)>(null)
        const [cssOpen,setCssOpen] = useState(false)

        useEffect(()=>{
            if(selectorOpen&&!header){
                setTop("60%")
            }else{
                if(!header){
                    setTop("50%")
                }
            }
        },[selectorOpen])

        useEffect(()=>{
            setLayoutModalOpen(isOpen)
        },[isOpen,setLayoutModalOpen])

        useEffect(()=>{
            const layoutObject:DynamicAnyObject = {}
            layoutOptions.forEach((layoutOption)=>{
                if(layoutOption.property){
                    layoutObject[layoutOption.property] = layoutOption.default
                }

            })
            const layoutProps = style?.split(" ")||[]
            layoutProps.forEach((prop)=>{
                if(prop.includes("xx")){
                    const [key,value] = prop.split("xx")
                    layoutObject[key] = value
                }
                else if(prop.includes(":")){
                    const [key,value] = prop.split(":")

                    layoutObject[key] = value
                }
                else if(prop.includes("**")){
                    const [element,key,value] = prop.split("**")
                    layoutObject[`${element}//${key}`]=value
                }
            })
            setLayoutObject(layoutObject)
    },[style])

        const handleClick = (e:React.MouseEvent<(HTMLButtonElement|HTMLOptionElement)>)=>{
            const {value,field} = (e.target as HTMLButtonElement).dataset;
            if(!field||!value){
                return
            }
            const newLayoutObject = {...layoutObject}
            newLayoutObject[field] = value;
            setLayoutObject(newLayoutObject)
            const newStyleString = makeStyleString(newLayoutObject)
            setStyle(newStyleString)
            changeSectionStyle({sectionId,style:newStyleString})
        }

        const handleColorChange = ({color,field}:{color:string,field:string})=>{
            try{

                const newLayoutObject:DynamicObjectWithNumber = {...layoutObject}
                newLayoutObject[field] = color;
                setLayoutObject(newLayoutObject)
                const newStyleString = makeStyleString(newLayoutObject)
                setStyle(newStyleString)
                changeSectionStyle({sectionId,style:newStyleString})
            }catch(err:any){
                console.log(err.message)
            }
        }

        const handleSelector = ({field,value}:{field:string,value:(string|number)})=>{
            const newLayoutObject = {...layoutObject}
            newLayoutObject[field] = value;
            setLayoutObject(newLayoutObject)
            const newStyleString = makeStyleString(newLayoutObject)
            setStyle(newStyleString)
            changeSectionStyle({sectionId,style:newStyleString})
        }

        const handleSelect = (e:ChangeEvent<HTMLSelectElement>)=>{
            const value = e.target.value
            const field = e.target.dataset.field
            const newLayoutObject = {...layoutObject}
            if(!field){
                return
            }
            newLayoutObject[field] = value;
            setLayoutObject(newLayoutObject)
            const newStyleString = makeStyleString(newLayoutObject)
            setStyle(newStyleString)
            changeSectionStyle({sectionId,style:newStyleString})
        }

        const handleInputChange = ({value,field}:{value:any,field:string})=>{
            const newLayoutObject = {...layoutObject}
            newLayoutObject[field] = value;
            const newStyleString = makeStyleString(newLayoutObject)
            setStyle(newStyleString)
            changeSectionStyle({sectionId,style:newStyleString})
        }

        if(isMobile()){
            return<></>
        }

        if(!isOpen){
            return(
                <button  onClick={()=>{setIsOpen(true)}} className={`${buttonClass?buttonClass:""} open-layout-btn no-translate-animation`}>
                    {buttonName||"Style"}
                </button>
                )
        }



        const modalHeader = header||`${sectionName?sectionName:"Section"} style`
        console.log({layoutOptions})
        return(<>
            <DraggableModal top={top} isStatic={isStatic}  header={modalHeader} additionalClass={`layout-modal custom-layout-modal ${selectorOpen}`} setIsModalOpen={setIsOpen}>
            <>
            {(!selectorOpen&&!cssOpen)&&<div className="properties-list">
                {
                    layoutOptions.map((layout:LayoutProperty,index:number)=>{

                        if(layout.condition&&!(layoutObject[layout.condition.field]===layout.condition.value)||layout.ignore){
                            return<></>
                        }
                        if(layout.fontSelector){
                            const font = layoutObject?.[layout?.field]?.toString()||layout.default
                            if(!font){
                                return<></>
                            }
                            
                            return(
                            <div key={index}>
                            {layout.prompt}
                            <div className="option-btn-div">
                            <FontSelector
                            value={font}
                            handleSelector={handleSelector}
                            name={layout.field}
                            ></FontSelector>
                            </div>
                            </div>)
                        }
                        if(layout.colorPicker){
                            return<ColorSelector 
                            backgroundColor={convertColorToString(layoutObject[layout.field])} 
                            color={"#000000ff"} 
                            reposition={true} 
                            value={(layoutObject?.[getFieldName(layout)]?.toString())||""}
                            setValue={(color:string)=>{handleColorChange({color,field:getFieldName(layout)})}}
                            prompt={`${t(layout.field)}`}
                            positioning={positioning}
                            setSelectorOpen={()=>setSelectorOpen(layout.field)}
                            layout={getFieldName(layout)}
                            inline={true}
                            ></ColorSelector>
                        }
                        if(layout.slider){
                            return<div>
                                {layout.prompt}
                                <LayoutSlider layout={layout}
                                handleChange={handleSelector}
                                value={num(layoutObject[layout.field])}
                                
                                ></LayoutSlider>
                            </div>
                        }
                        const cssValue = layoutObject[getFieldName(layout)]||layout.default||""
                        const inputObject = parseCSSValue(cssValue)
                        if(inputObject.hasNumber){
                            return<NumberInputRow
                            field={layout.field}
                            label={layout.field}
                            inputObject={inputObject}
                            handleInputChange={handleInputChange}
                            ></NumberInputRow>
                        }
                        return(
                            <div key={index}>
                            {layout.prompt}
                        <div className="option-btn-div">
                        <select  onChange={handleSelect} data-field ={(layout.type!=="child"?layout.field:`${layout?.child||""}//${layout.field}`)} value={(layout.type!=="child"?layoutObject[layout.field]:layoutObject[`${layout?.child||""}//${layout.field}`])}>
                        {layout.options.map((option,index:number)=>{
                            return(
                                <option onClick={handleClick} data-field={layout.field} value={option.value} data-type={layout.type}>{option.text}</option>
                                )
                            })}
                            </select>
                        </div>
                        </div>
                        )
                    })
                }
                {/* this will be implemented later */}
                {/* <button onClick={()=>setCssOpen(true)}>CSS</button> */}
            </div>}

            {(selectorOpen&&!cssOpen)&&(
                        <NewColorSelector
                        initialColor={convertColorToString(layoutObject?.[selectorOpen])}
                        handleBack={()=>setSelectorOpen(null)}
                        handleChange={(color:string)=>{handleColorChange({color,field:selectorOpen})}}
                        ></NewColorSelector>                           
                        )}
    
            </>
            </DraggableModal>
                </>
        )
    }
    export default CustomLayoutModal
    
    
    