import { APIFailure } from "@/types/API"
import { LinkDocument } from "@/types/Documents"
import { DynamicObject } from "@/types/generic"
import { SectionType } from "@/types/Section"
import { fexDelete, fexPost, fexPut } from "../utils/fex"


export type LinkResponse = {
    data:LinkDocument,
    error:boolean
}

export const editLink = async ({linkId,changedFields}:{linkId:string,changedFields:DynamicObject}):Promise<(APIFailure | LinkResponse |Error)>=>{
    try{
        const res:(APIFailure | LinkResponse) = await fexPut(`api/link/${linkId}`,changedFields)
        return res
    }catch(err){
        let message = "Unknown Error"
        if(err instanceof Error){
            message = err.message
        }
        return {error:true,message}
    }
}

export const addLink = async ({sectionId}:{sectionId:string}):Promise<(APIFailure | LinkResponse)>=>{
    try{
        const res:(APIFailure | LinkResponse) = await fexPost(`api/link/${sectionId}`,{})
        return res
    }catch(err){
        let message = "Unknown Error"
        if(err instanceof Error){
            message = err.message
        }
        return {error:true,message}
    }
}

export type DeleteLinkSuccess = {
    error:false,
    data:SectionType
}

export const deleteLink = async ({sectionId,linkId}:{sectionId:string,linkId:string}):Promise<(APIFailure | DeleteLinkSuccess)>=>{
    try{
        const res:(APIFailure | DeleteLinkSuccess) = await fexDelete(`api/link/${sectionId}`,{linkId})
        return res
    }catch(err){
        let message = "Unknown Error"
        if(err instanceof Error){
            message = err.message
        }
        return {error:true,message}
    }
}