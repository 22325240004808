
    import { isColorDark } from "@/utils/color"
import "./index.scss"
    import React, { useState } from "react"
import { DynamicObjectWithNumber } from "@/types/generic"

    type Props = {
        colors:string[]
        columns?:number
        size?:number
        handleChange:(arg0:string)=>void
        opacity?:number
    }

    const ColorGrid = ({colors,columns,size,handleChange,opacity}:Props)=>{

        const [selected,setSelected] = useState<string|null>(null)
        
        const handleClick = (color:string)=>{
            setSelected(color)
            handleChange(color)
        }
        
        const {parentStyle,childStyle} = getStyle({columns,size})
        
        if(opacity!==undefined){
            parentStyle.opacity = opacity
        }

        return(
            <div style={parentStyle} className="color-grid">
                {colors.map((color,index)=>{
                    return<div 
                    onClick={()=>handleClick(color)} className={`color-cell ${color===selected?"selected":""} ${isColorDark(color)&&"dark"}`} style={{backgroundColor:color,...childStyle}}></div>
                })}
            </div>
        )
    }
    export default ColorGrid
    
    
    
    export const getStyle = ({columns,size}:{columns?:number,size?:number})=>{
        const parentStyle:DynamicObjectWithNumber = {}
        if(columns){
            parentStyle.gridTemplateColumns = `repeat(${columns},1fr)`
        }
        const childStyle:DynamicObjectWithNumber = {}
        if(size){
            childStyle.width = `${size}px`
            childStyle.height = `${size}px`

        }
        return {parentStyle,childStyle}
    }