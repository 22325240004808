
    import "./index.css"
    import React, { useContext,createContext } from "react"
import { PageContextType } from "@/types/Context"
import { PageContext } from "@/pages/Standard/context"
import EditSectionHeader from "../EditSectionHeader"
import { SectionState, SectionType } from "@/types/Section"
import Section from "@/generics/Section"
import useSectionState from "@/hooks/useSectionState"
import SectionHeading from "@/generics/SectionHeading"
import { getSectionHeading } from "./helpers"
import { hyphenateCamelcase } from "@/utils/stringFormatting"
import { getEditOrdinal } from "@/utils/pageManipulation"
import SectionDevManager from "../SectionDevManager"
import { CSSField } from "@/types/css"

    type Props = {
        section:SectionType
        children:(JSX.Element|JSX.Element[]|undefined)
        headless?:boolean
        setSectionRef?:React.Dispatch<React.SetStateAction<null>>
    }

    const PuxModWithinContext = ({section,children,headless,setSectionRef}:Props)=>{
        const {isEditing,editOrdinals}:PageContextType = useContext(PageContext)
        const sectionState:SectionState = useContext(SectionContext)
        const ordinal = getEditOrdinal({sectionId:section._id,editOrdinals})
        const {fields,handleInputChange,isOn,setIsOn,style,setStyle,layoutModalOpen,setLayoutModalOpen,inNav,cssFields}:SectionState = sectionState
        
        const isDeveloping = window.location.href.includes("freelance-dev")

        if(!isOn&&!isEditing){
            return<></>
        }

        const heading = getSectionHeading(fields,section)

        const option=window.location.search.substring(1)
        return(
        <Section setSectionRef={setSectionRef} additionalClass={""} ordinal={ordinal} id={section._id} styleString={style} sectionName={hyphenateCamelcase(section.sectionForm)}
        cssFields={cssFields||[]}
        >
            {!isEditing?<></>:<EditSectionHeader
            section={section}
            isOn={isOn}
            setIsOn={setIsOn}
            style={style}
            setStyle={setStyle}
            layoutModalOpen={layoutModalOpen}
            setLayoutModalOpen={setLayoutModalOpen}
            sectionName={heading}
            inNav={inNav}
            />}
            <>
            {(headless||!isOn)?<></>:<SectionHeading handleChange={handleInputChange} text={heading}/>}
            {(!isOn||!children)?<></>:children}

            {isDeveloping&&<SectionDevManager></SectionDevManager>}
            </>
        </Section>
        )
    }
    
    export const SectionContext = createContext({})

    type PuxModProps = {
        children:JSX.Element|JSX.Element[]
        section:SectionType,
        headless?:boolean,
        setSectionRef?:React.Dispatch<React.SetStateAction<null>>
        customCSSArray?:CSSField[]
    }

    const PuxMod = ({children,section,headless,setSectionRef,customCSSArray}:PuxModProps)=>{

        const sectionState = useSectionState({section,customCSSArray,options:{enableAPI:true}})
        
    return<>

        <SectionContext.Provider value={sectionState}>
        <PuxModWithinContext setSectionRef={setSectionRef} section={section} headless={headless}>
            {children}
        </PuxModWithinContext>
        </SectionContext.Provider>

    </>
        
        
        
    }
    
    export default PuxMod
    