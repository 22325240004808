
    import CloseX from "@/generics/CloseX"
import FormOptionEditor from "./FormOptionEditor"
import "./index.scss"
    import React, { ChangeEvent, useContext, useEffect, useState } from "react"
import FullPageMiddleScroller from "../FullPageMiddleScroller"
import useSectionZIndex from "@/hooks/useSectionZIndex"
import EditFieldBox from "./EditFieldBox"
import SubPuxMod from "../SubPuxMod"
import { SectionContext } from "../PuxMod"
import { SectionState } from "@/types/Section"
import { GenericSubsectionDocument } from "@/types/Documents"
import EditButton from "@/generics/EditButton"
import { PageContextType } from "@/types/Context"
import { PageContext } from "@/pages/Standard/context"

    export type FieldObject = {
        label:string
        required?:boolean
        inputType:string
        options:string[]
        _id:string
    }

    export type FormObject = {
        name:string
        description:string
        fields:FieldObject[]   
    }

    type Props = {
        formObject:FormObject
        handleHeaderChange:(e:ChangeEvent)=>void
        handleFormChange:React.Dispatch<FormObject>
        setModalOpen:React.Dispatch<boolean>
    }

    const FormEditor = ({setModalOpen}:Props)=>{

        const {isEditing}:PageContextType = useContext(PageContext)
        const {handleInputChange,fields,subsections,handleAddSubsection}:SectionState = useContext(SectionContext)
        const {name,description} = fields
        const inputFields = subsections
        const [isEditingForm,setIsEditingForm] = useState(false)

        // const handleAdd = ()

        const handleAddButton = ()=>{
            const fields={
                label:"label",
                type:"text"
            }

            handleAddSubsection({fields})
        }

        return(
            <main className="form-editor">
            <div className="fields">
                {inputFields.map((field:GenericSubsectionDocument,index:number)=>{
                    return<>
                    <SubPuxMod subsection={field}>
                    <EditFieldBox
                    isEditing={isEditingForm}
                    ></EditFieldBox>
                    </SubPuxMod>
                        </>
                })}
            </div>
            <div className="buttons-cont">

            <button onClick={handleAddButton}>Add Field</button>
            <button onClick={()=>window.location.replace(`${window.location.href}?edit`)}>Save Form</button>
            </div>
            </main>
        )
    }
    export default FormEditor
    
    
    