
    import Toggle from "@/generics/Toggle"
import { FieldObject } from ".."
import FormOptionEditor from "../FormOptionEditor"
import "./index.scss"
    import React, { ChangeEvent, useContext, useState } from "react"
import { FaTrash } from "react-icons/fa"
import { SubsectionContext } from "@/components/SubPuxMod"
import { Input } from "@/sections/CustomForm"
import { deleteGenericSubsection, putSubsectionStringArray } from "@/api/genericSubsection"
import { removeNthElement } from "@/utils/arrays"
import useSkipFirstMutation from "@/hooks/useSkipFirstMutation"


type Props = {
    isEditing:boolean
}

    const EditFieldBox = ({isEditing}:Props)=>{
        
        const [isDeleted,setIsDeleted] = useState(false)
        const subsectionState:any = useContext(SubsectionContext)
        const {handleInputChange,stringArray,setStringArray} = subsectionState
        const options = stringArray
        const fieldObj = subsectionState.fields
        const handleAddOption:any = (_id:string)=>{
            setStringArray([...stringArray,""])
        }
        const handleDeleteOption:any = ({optionIndex}:{optionIndex:number})=>{
            const newArray = removeNthElement(stringArray,optionIndex)
            setStringArray([...newArray])
        }
        const handleChangeOption:any=({optionIndex,value}:{optionIndex:number,value:string})=>{
            const newArray = [...stringArray]
            newArray[optionIndex] = value;
            setStringArray(newArray)
        }

        const {_id} = subsectionState.subsectionId
        const [required,setRequired] = useState(fieldObj.required==="false"?false:true)

        useSkipFirstMutation(()=>{
            handleInputChange({target:{
                value:required,
                name:"required",
                dataset:{}
            }})
        },[required])

        const handleFieldDelete = ()=>{
            deleteGenericSubsection({subsectionId:subsectionState.subsectionId})
            setIsDeleted(true)
        }

        if(isDeleted){return<></>}

        return(
            <div className="field-box">
                        <div className="prompt-cont">
                        <input placeholder="Enter Prompt Here" name="label" value={fieldObj.label} id={_id} onChange={handleInputChange}></input>
                        </div> 
                        <div className="dropdown-cont">
                        <select value={fieldObj.inputType} onChange={handleInputChange} id={_id}
                        name="inputType"
                        >
                            <option>Text</option>
                            <option value="number">Number</option>
                            <option value="date">Date</option>
                            <option value="time">Time</option>
                            <option value="email">Email</option>
                            <option value="dropdown">Dropdown</option>
                            <option value="multiple-choice">Multiple Choice</option>
                        </select>
                        </div>
                        {(["multiple-choice","dropdown"].includes(fieldObj.inputType||""))&&<FormOptionEditor 
                        options={options}
                        handleAdd={()=>handleAddOption(_id)}
                        handleChange={({optionIndex,value})=>handleChangeOption({optionIndex,value,fieldId:_id})}
                        handleDelete={({optionIndex}:{optionIndex:number})=>handleDeleteOption({optionIndex,fieldId:_id})}
                        ></FormOptionEditor>}
                    <footer>
                        <hr></hr>
                        <div className="delete-cont">
                            <div onClick={handleFieldDelete}>
                            <FaTrash ></FaTrash>
                            </div>
                        <div>
                        <div className="toggle-cont">

                        <label>Required</label>
                        <Toggle
                        initialValue={required}
                        action={()=>setRequired(!required)}                    
                        ></Toggle>
                        </div>
                        </div>
                        </div>
                    </footer>
                    </div>
        )
    }
    export default EditFieldBox
    
    
    