
    import SectionTemplatePreview from "@/components/SectionTemplatePreview"
import "./index.scss"
    import React, { useEffect, useState } from "react"
import { defaultHTML } from "./defaults"
import CSSEditor from "@/components/CSSEditor"
import useRedirectAnonymous from "@/hooks/useRedirectAnonymous"
import Modal from "@/generics/Modal"
import { submitNewTemplate } from "@/api/sectionTemplate"
import { filterCSSArray, transformHTMLForBackend, getDisplayHTMLAndChildren, wrapHTML, handleCopiedHTML } from "./helpers"
import FullPageSuccess from "@/components/FullPageSuccess"
import validateTemplate from "./validate"
import { CSSField } from "@/types/css"
import DraggableModal from "@/components/DraggableModal"
import { FaDesktop, FaMobile } from "react-icons/fa"
import saveProgress from "./saveProgress"
import getSavedProgress from "./getSavedProgress"
import sectionTypeList from "./sectionTypeList"
import addVariablesToHTML from "./addVariablesToHTML"
import SectionBuilderElementsEditor from "@/components/SectionBuilderElementsEditor"
import SectionTemplateBuilderSubmission from "@/components/SectionTemplateBuilderSubmission"
import getPageId from "./getPageId"
import getParams from "./getPageId"


    type Props = {
        copiedHTML?:string
        copiedStyles?:CSSField[]
    }

    const SectionTemplateBuilder = ({copiedHTML,copiedStyles}:Props)=>{
        const {savedHTML,savedCSSArray} = getSavedProgress()
        const {pageId,isDev,submissionId} = getParams(window.location.href)
        const [html,setHTML] = useState(handleCopiedHTML({html:copiedHTML})||savedHTML)
        const hasPage = pageId&&pageId.length>8
        const [collapsed,setCollapsed] = useState(false)
        const [specifiedWidth,setSpecifiedWidth]= useState<null|number>(null)
        const isMobile = specifiedWidth&&(specifiedWidth<700)

        // does this keep?
        useRedirectAnonymous()

        const styles = copiedStyles||savedCSSArray||[{
            selector:".container",property:"background",value:"#333333"},
            {
                selector:".container",property:"color",value:"white"},
                {selector:".name",property:"fontSize",value:"3vw"}
    ]

    const [isModalOpen,setIsModalOpen] = useState(false)
    const [cssArray,setCssArray] = useState(styles)

    const handleSetFullWidth = ()=>{
        const frame = document.querySelector(".stretch-frame")
        const width = frame?.getBoundingClientRect().width
        if(width){
            setSpecifiedWidth(width)
        }
    }

    const handleValidation = ()=>{
        const validation = validateTemplate({cssArray})
        if(validation.error){
            alert(validation?.message)
            return
        }
        setIsModalOpen(true)
    }

        const {displayHTML,children} = getDisplayHTMLAndChildren(html)

        return(
            <div className="section-template-builder">
            <main className="template-builder-main">
            {isModalOpen?<></>:<DraggableModal
            header="html & css"
            additionalClass="html-css-modal"
            // collapsed={collapsed}
            // setCollapsed={setCollapsed}
>
            <>
            {collapsed||<div className="view-toggle">
                {!isMobile?<FaMobile onClick={()=>setSpecifiedWidth(375)}></FaMobile>:
                <FaDesktop onClick={handleSetFullWidth}></FaDesktop>}
            </div>}


            {collapsed?<button onClick={()=>setCollapsed(false)}  className="expand-btn">Expand</button>:<aside>
            <SectionBuilderElementsEditor
            html={html}
            setHTML={setHTML}
            ></SectionBuilderElementsEditor>
            <CSSEditor
            cssArray={cssArray}
            setCssArray={setCssArray}
            ></CSSEditor>
            </aside>}
            </>
            </DraggableModal>}
            <SectionTemplatePreview
            html={displayHTML}
            styles={cssArray}
            specifiedWidth={specifiedWidth}
            children={children}
            frameHeight={(document.body.getBoundingClientRect().height-70)+"px"}
            ></SectionTemplatePreview>
            </main>
            <footer>
            <button onClick={()=>saveProgress({html,cssArray})} className="primary">Save Progress</button>
            {hasPage&&<button onClick={handleValidation}>Add Section to Page</button>}
            {!hasPage&&<button onClick={handleValidation}>Submit Template</button>}
            </footer>
            {isModalOpen?<SectionTemplateBuilderSubmission
            html={html}
            hasPage={hasPage||false}
            setIsModalOpen={setIsModalOpen}
            cssArray={cssArray}
            pageId={pageId||""}
            isDev={isDev}
            submissionId={submissionId}
            ></SectionTemplateBuilderSubmission>:<></>}
            </div>
        )
    }
    export default SectionTemplateBuilder
    
    
    