    import "./index.scss"
    import React, { useEffect, useState } from "react"

    type Props = {
        count:number
    }

    const CountFloater = ({count}:Props)=>{

        return(
            <div className="count-floater">
                <span>
                    {count}
                    </span>
                    </div>
        )
    }
    export default CountFloater
    
    
    