import { checkIfLocal } from "./environment"
import route from "./route"
import Compressor from "compressorjs"

export const i = (url:string)=>{
    try{
        
        if(!url){
            return ""
        }
        const res = (url.indexOf("docUpload:")===0||url.indexOf("/")===-1)?`${route("api/upload")}/${url.replace("docUpload:","")}`:url
        if(!checkIfLocal()&&res.includes("localhost")){
            return res.replace("http://localhost:3005",window.location.origin)
        }   
        return res
    }catch(err){
        
    }
}

export const compressImage = (file:File)=>{
    return new Promise((resolve,reject)=>{
        new Compressor(file,{
            quality: 0.5,
            maxWidth:640,
            maxHeight:640,
            success(file){
                return resolve({
                    success:true,
                    file:file
                })
            },
            error(err){
                return resolve({
                    success:false,
                    message:err.message
                })
            }
        })
    })
}