import { DynamicObject } from "@/types/generic"

type StringFilterObjectsArgs = {
    objectArray:DynamicObject[]
    searchString:string
    properties:string[]
}

export const stringFilterObjects = ({objectArray,searchString,properties}:StringFilterObjectsArgs)=>{
    const simplifiedSearchString = searchString.replaceAll(" ","").toLowerCase()
    const resArray = objectArray.filter((object:DynamicObject,index:number)=>{
        for (let key in object){
            if(!properties.includes(key)){
                continue
            }
            const simplifiedValue = object?.[key]?.replaceAll(" ","")?.toLowerCase()
            if(simplifiedValue.includes(simplifiedSearchString)){
                return true
            }
        }
        return false
    })
    return resArray
}