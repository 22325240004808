
    import "./index.css"
    import React, { useState } from "react"
import Section from "@/generics/Section"
import { Link } from "react-router-dom"
import Shuffler from "@/generics/Shuffler"

    const Samples = ()=>{

        const [linkIndex,setLinkIndex] = useState(0)

        const links = [
            "/comedyPage","podcastPage"
        ]

        const handleIndexChange = (num:number)=>setLinkIndex(num)

        return(
            <Section sectionName="samples">
                <div className="grid">
                    <div>
                        <h2>See What's Possible in just a few clicks and keystrokes.</h2>
                    <Link to={`${links[linkIndex]}?sample`}>
                    <button className="inspiration-btn">Get Inspiration</button>
                    </Link>
                    
                    </div>
                    <div className="sample-center">

                    <h2></h2>
                <Shuffler
                handleChange={handleIndexChange}
                >
                    <>
                    <Link to="/comedyPage?sample">
                        <div className="sample-img-cont">
                        <img src="/images/stock/comedian/bio.jpg"></img>
                        </div>
                    </Link>
                    <h3>Comedian Template</h3>
                    </>
                    <>
                    <Link to="/podcastPage?sample">
                        <div className="sample-img-cont">
                        <img src="/images/stock/podcast/hero-square.jpg"></img>
                        </div>
                    </Link>
                    <h3>Podcast Template</h3>
                    </>
                </Shuffler>
                    </div>

                </div>
            </Section>
        )
    }
    export default Samples
    
    
    