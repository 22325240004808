import { fexDelete, fexPost, fexPut } from "@/utils/fex"


export const addToStringArray = async ({sectionId,addedString}:{sectionId:string,addedString:string})=>{
    const res = await fexPost(`api/section/stringArray/${sectionId}`,{string:addedString})
}

export const removeFromStringArray = async ({sectionId,removedString}:{sectionId:string,removedString:string})=>{
    const res = await fexDelete(`api/section/stringArray/${sectionId}`,{string:removedString})
}

export const replaceStringArray = async ({sectionId,newStringArray}:{sectionId:string,newStringArray:string[]})=>{
    const res = await fexPut(`api/section/stringArray/replace/${sectionId}`,{newStringArray})
}