import { Field } from "@/types/Section"
import { DynamicObject } from "@/types/generic"

type Args = {
    html:string
fields:Field[]
}


const addValuesToVariables = ({html,fields}:Args)=>{
    let modifiedHtml = html;

    for (const field of fields) {
        const pattern = `{{${field.key}}}`;
        const replacement = `{{${field.key}:${field.value}}}`;
        modifiedHtml = modifiedHtml.replace(pattern, replacement);
    }

    return modifiedHtml;
}

export default addValuesToVariables