import { SectionContext } from "@/components/PuxMod"
import { Context, ContextValueType } from "@/context"
import { PageContext } from "@/pages/Standard/context"
import { PageContextType } from "@/types/Context"
import { SectionState } from "@/types/Section"
import { toLogin } from "@/utils/navigation"
import { useContext, useEffect, useState } from "react"

type IdsType = {
    userId:null|string
    pageId:null|string
    sectionId:null|string
}

export default ()=>{

    const mainContext:ContextValueType = useContext(Context)
    const userId = mainContext?.credentials?.user?._id
    const [waited,setWaited]= useState(false)

    useEffect(()=>{
        setTimeout(()=>{
            setWaited(true)
        },1000)
    },[])

    useEffect(()=>{
        if(!userId&&waited){
            toLogin()

        }
    },[waited])




    return null
}