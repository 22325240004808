import { ImageDetails } from "@/types/Images"
import { fexPut } from "@/utils/fex"


type EditProps = {
    subsectionId:string
    imageDetails:ImageDetails
}

export const editSubsectionImageDetails = async ({subsectionId,imageDetails}:EditProps)=>{
    const res = await fexPut(`api/subsection-image/${subsectionId}`,{...imageDetails})
}