import "./index.css"
import { useContext, useEffect, useState } from "react"
import Form from "../../generics/Form"
import { PageContext } from "@/pages/Standard/context"
import { PageContextType } from "@/types/Context"
import { ContactFormSubmission, sendMessage } from "@/api/contact"
import { SectionType } from "@/types/Section"
import LayoutModal from "@/components/LayoutModal"
import Notification from "@/generics/Notification"
import PuxMod from "@/components/PuxMod"

   const ContactSection = ({section}:{section:SectionType})=>{
    
    const {pageId,isEditing}:PageContextType = useContext(PageContext)

    const [notification,setNotification] = useState<false|string>(false)

    const postMessage = async (formSubmission:ContactFormSubmission)=>{
        const res = await sendMessage({pageId,formSubmission})
        if(!res.error){
            setNotification("Message Sent")
        }else{
            setNotification("Message Failed")
        } 
    }


    return(
        <PuxMod
        section={section}
        >
            <Form
                    handleSubmit={postMessage}
                    inline={true}
                    buttonClass="btn-primary mb-3"
                    fields={[{name:"Name",type:"text"},{name:"Email",type:"email"},{name:"Message",type:"textArea"}]}
                    recaptcha={false}
                    confirmationPopup="Message Sent!"
                    disabled={isEditing}
            ></Form>
            {!notification?<></>:<Notification
                notificationMessage={notification}
                setNotificationOpen={setNotification}
                timer={2000}
            />   
             }
        </PuxMod>
    )
    }
    export default ContactSection
    
    
    