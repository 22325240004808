import { MouseEventHandler } from "react"
import "./index.css"

    type Props = {
        onClick:MouseEventHandler,
        children:JSX.Element|JSX.Element[]
        additionalClass?:string
    }

    const Card = ({onClick,children,additionalClass}:Props)=>{
        return(
            <div className={`card ${additionalClass}`} onClick={onClick||(()=>{})}>
                {children}
            </div>
        )
    }
    export default Card
    
    
    