
    import DeleteCircle from "@/generics/DeleteCircle"
import "./index.scss"
    import React from "react"
import Experimental from "@/wrappers/Experimental"
import EditorButtonSmall from "@/generics/EditorButtonSmall"
import { FaCog, FaImage, FaTools } from "react-icons/fa"

    type Props = {
        optional?:boolean
        modifiedImageURL:string
        handleDelete:()=>void
        replace?:boolean
        imageDetails:any
        detailsEditorOpen:boolean
        handleDetailsButton:()=>void
    }

    const EditButtonsCont = ({optional,modifiedImageURL,handleDelete,replace,imageDetails,detailsEditorOpen,handleDetailsButton}:Props)=>{
        return(
            <div className="edit-buttons-cont">
                {imageDetails&&!detailsEditorOpen&&<Experimental>
                    <EditorButtonSmall handleClick={handleDetailsButton}
                    tooltip="Edit Image Details"
                    ><>
                <FaCog></FaCog>
                </>
                </EditorButtonSmall>
                    </Experimental>
                    }
            {(!optional||modifiedImageURL!=="")&&<EditorButtonSmall
                                tooltip="Replace Image"
            handleClick={handleDelete}
            ><>
            <FaImage></FaImage>
            </></EditorButtonSmall>}

            {/* {(!optional||modifiedImageURL!=="")&&<DeleteCircle
            _id={"delete"}
            handleDelete={handleDelete}
            tooltip="Replace Image"
            replace={replace}
            ></DeleteCircle>} */}
                </div>
        )
    }
    export default EditButtonsCont
    
    
    