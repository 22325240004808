import { CSSField } from "@/types/css"
import { handleHandlebars } from "./handleHandlebars"
import handleCustomElements from "./replaceCustomElements"
import replaceSubsections from "@/utils/replaceSubsections"
import addVariablesToHTML from "./addVariablesToHTML"





export const wrapHTML = (html:string)=>{
    return `<div class="container">${html}</div>`
}

export const getDisplayHTMLAndChildren = (html:string)=>{
    const replacedSubsections = replaceSubsections(html)
    const {newHTML,children} = handleCustomElements({html:replacedSubsections})
    const withoutHandlebars = handleHandlebars(newHTML)
    const wrappedHTML = wrapHTML(withoutHandlebars)
    const result = wrappedHTML.replaceAll("/n","")
    return {displayHTML:result,children}
}

export const transformHTMLForBackend = (html:string)=>{
    if(!html.includes("{{")){
        html = addVariablesToHTML({html})
    }
    const replaceBR = html.replaceAll("<br>","$<$br$>$").replaceAll("'","\'")
    const result = wrapHTML(replaceBR)
    return result
}

export const filterCSSArray = (cssArray:CSSField[])=>{
    const res = cssArray.filter((cssLine:CSSField)=>{
        if(cssLine.selector==="."||cssLine.selector===""){
            return false
        }
        if(cssLine.property===""||cssLine.value===""){
            return false
        }

        return true
    })
    return res
}

export const handleCopiedHTML = ({html}:{html:(string|undefined)})=>{
    if(!html){
        return false
    }
    if(!html.includes("<div class='container'>")){
        return html
    }
    html = html.replaceAll("<div class='container'>","")
    html = html.replaceAll("$<$br$>$","<br>")
    return html.substring(0,(html.length-6)) 
}