import "./index.scss"
import React, { useState, useEffect,useContext } from "react";

import Audio from "@/components/Audio";
import Episode from "@/components/Episode";
import Section from "@/generics/Section";
import PageNumberBar from "@/generics/PageNumberBar";
import SectionHeading from "@/generics/SectionHeading";
import UrlEditBar from "@/generics/UrlEditBar";
import { PageContextType } from "@/types/Context";
import { PageContext } from "@/pages/Standard/context";
import { getEditOrdinal } from "@/utils/pageManipulation";
import EditSectionHeader from "@/components/EditSectionHeader";
import { SectionType } from "@/types/Section";
import { fexCorsGet, fexGet } from "@/utils/fex";
import PuxMod from "@/components/PuxMod";


export type Episode = {
    title:string
    description:string
    link:string
    file:string
    pubDate:string
    author:string
    image:string
}

type Props = {
  rssFeed:string
  handleAPI:Function
  section:SectionType
}

const PodcastEpisodes = ({rssFeed,handleAPI,section}:Props)=>{

  const [isOn,setIsOn] = useState(section.isOn)
  const [style,setStyle] = useState(section.style)
  const [layoutModalOpen,setLayoutModalOpen] = useState(false)
  const {editOrdinals,setEditOrdinals}:PageContextType = useContext(PageContext)
  const ordinal = getEditOrdinal({sectionId:section._id,editOrdinals})
  const {isEditing}:PageContextType = useContext(PageContext)


  const [currentRssFeed,setCurrentRssFeed] = useState(rssFeed)

  const handleSet = (feedURL:string)=>{
      setCurrentRssFeed(feedURL)
      handleAPI(feedURL)
  }

  if(!isOn&&!isEditing){
    return<></>
  }

  return<PuxMod section={section}>
      <>
  {isEditing?<UrlEditBar
  value={currentRssFeed}
  setValue={handleSet}
  label="Rss feed url"
  
  />:<></>}
  <EpisodeList rssFeed={currentRssFeed}></EpisodeList>
  </>
  </PuxMod>

}



const EpisodeList = ({ rssFeed }:{rssFeed:string}) => {
  const [episodes, setEpisodes]:any = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [episodesPerPage] = useState(5);
  const [src,setSrc] = useState<null|string>(null)
const [currentEpisode,setCurrentEpisode] = useState<any>(null)
const [image,setImage] = useState("")
const [toggleMessage,setToggleMessage] = useState("Newest to Oldest")
  useEffect(() => {
    // Fetch the episodes from the RSS feed
    // const testFeed = "https://www.spreaker.com/show/5788059/episodes/feed"
    const fetchRss = async ()=>{
      // const rssFeed = testFeed
      const resp = await fexCorsGet(rssFeed)
      if(!resp){
        return
      }
      let text =resp;
      if(!resp.body){
          setImage(image||"")
          
          const episodeData = resp.items.map((item:any)=>{
            if (!item) return {
              title:"",
              link:"",
              description:""
          }
          const obj = {
            title: item.title,
            link: item.link,
            description: item.description||item.content,
            file: item.enclosure.url,
            pubDate: item.pubDate,
            author: item.author,
            image:item.itunes.image||""
          };
          return obj
        
      })
      setEpisodes(episodeData);
      return
      }

      text = await resp.text()
      const data = new window.DOMParser().parseFromString(text,"text/xml")
      const imageUrl = data?.querySelector("image")?.querySelector("url")?.textContent;
        setImage(imageUrl||"")
        const items = data.querySelectorAll("item");
        const episodeData = Array.from(items).map((item):any => {
        if (!item) return {
            title:"",
            link:"",
            description:""
        }
          return {
            title: item.querySelector("title")?.textContent,
            link: item.querySelector("link")?.textContent,
            description: item.querySelector("description")?.innerHTML.replaceAll("]]>",""),
            file: item.querySelector("enclosure")?.getAttribute("url"),
            pubDate: item.querySelector("pubDate")?.textContent,
            author: item.querySelector("author")?.textContent,
            image:imageUrl||""
          };
        });
        setEpisodes(episodeData);
    }
    fetchRss()
    }, [rssFeed]);
    
  // Calculate the index of the first and last episode on the current page
  const indexOfLastEpisode = currentPage * episodesPerPage;
  const indexOfFirstEpisode = indexOfLastEpisode - episodesPerPage;
  const currentEpisodes = episodes.slice(indexOfFirstEpisode, indexOfLastEpisode);

  const handlePlay = (episode:Episode)=>{
    setCurrentEpisode(episode)
  }


  // Render the episodes for the current page
  const renderEpisodes = currentEpisodes.map((episode:Episode, index:number) => {
    return (
    <Episode
    image={episode.image}
    episode={episode}
    handlePlay={handlePlay}
    key={index}
    ></Episode>
    );
  });

  // Render the pagination buttons
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(episodes.length / episodesPerPage); i++) {
    pageNumbers.push(i);
  }

  const handleToggle = ()=>{
    setEpisodes([...episodes].reverse())
    if(toggleMessage==="Newest to Oldest"){
      setToggleMessage("Oldest to Newest")
      return
    }
    setToggleMessage("Newest to Oldest")
  }

  return (
    <>
      <button className="toggle-btn" onClick={handleToggle}>{toggleMessage} (switch)</button>
        {currentEpisode&&<Audio
         episode={currentEpisode}
         setIsOpen={setCurrentEpisode}
         ></Audio>}
        <div className="episode-cont">

      {renderEpisodes}
        </div>
      <div>
        <PageNumberBar
        currentPage={currentPage}
        pageNumbers={pageNumbers}
        setCurrentPage={setCurrentPage}
        ></PageNumberBar>
      </div>
        </>
    
  );
};

export default PodcastEpisodes;
