
    import "./index.css"
    import React, { MouseEventHandler } from "react"

    type Props = {
        onClick:MouseEventHandler
        text:string
    }

    const EPKButton = ({onClick,text}:Props)=>{
        return(
            <button onClick={onClick}>{text}</button>
        )
    }
    export default EPKButton
    
    
    