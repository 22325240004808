import backgroundOn from "../fields/backgroundOn";
import font from "../fields/font";
import sectionBackground from "../fields/sectionBackground";
import textColor from "../fields/textColor";
import tiktoklayout from "../fields/tiktoklayout";
import generics from "./generics";


export default [
    ...generics,
    tiktoklayout
]