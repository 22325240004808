const contrast = (color:string):string=>{
    const rgb = color.match(/\d+/g);
    if(!rgb){
        return "rgb(0,0,0)"
    }
    const r = parseInt(rgb[0]);
    const g = parseInt(rgb[1]);
    const b = parseInt(rgb[2]);
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    return luminance > 0.5 ? "rgb(5,5,5)" : "rgb(250,250,250)";
}

export const getContrastLabelStyle = (el:(HTMLElement|null|undefined)):string=>{
    if(!el){
        return ""
    }
    const style = el.style
    const {backgroundColor,background} = style;
    if((backgroundColor+background)===""){
        return getContrastLabelStyle(el?.parentElement)
    }else{
        return contrast(backgroundColor)
    }
}