import React, { ChangeEventHandler, useContext, useEffect, useState } from "react"
import { PageContextType } from "@/types/Context"
import { PageContext } from "@/pages/Standard/context"
import { useDebounce } from "use-debounce"
import { editGenericSubsection } from "@/api/genericSubsection"
import ImageEditor from "@/generics/ImageEditorNew"
import { Context, ContextValueType } from "@/context"
import route from "@/utils/route"
import { i } from "@/utils/images"
import AutoResizeTextArea from "@/generics/AutoResizeTextArea"
import { isInputIncluded } from "./helpers"

    export type ElementType = {
        _id?:string,
        heading?:string,
        subheading?:string,
        image?:string,
        paragraph?:string,
        quote?:string,
        name?:string,
        title?:string
    }

    type Props = {
        element:ElementType,
        type?:string,
        className?:string
        children?:JSX.Element|JSX.Element[]
        autoResizeText?:boolean
        includedFields?:string[]
    }

    const GenericSubsection = ({element,type,className,children,autoResizeText,includedFields}:Props)=>{
        const {isEditing}:PageContextType = useContext(PageContext)

        if(isEditing){
            return<EditComponent
            children={children}
            className={className}
            element={element} type={type}
            autoResizeText={autoResizeText}
            includedFields={includedFields}
            ></EditComponent>
        }

        const ordinal = 1

        const style = {
            gridRow:ordinal
        }

        return(
                <div style={style} className={ "cont "+className}>
                    {element.heading&&<h1 className="h1">{element.heading}</h1>}
                    {element.subheading&&<h3 className="h3">{element.subheading}</h3>}
                    {element.image&&<img className="img" src={i(element.image)}></img>}
                    {element.quote&&<h3 className="quote">{element.quote}</h3>}
                    {element.name&&(
                    <div>
                    <span className="name">{element.name}</span>
                    {element.title&&<span className="title">{element.title}</span>}
                    </div>
                    )}
                    {element.paragraph&&<p className="p" dangerouslySetInnerHTML={{__html:element.paragraph}}></p>}
                    {children}
                </div>
        )
    }
    export default GenericSubsection
    
    type EditProps = {
        element:ElementType,
        type?:string
        className?:string
        children?:JSX.Element|JSX.Element[]
        autoResizeText?:boolean
        includedFields?:string[]
    }
    
    function EditComponent({element,type,className,children,autoResizeText,includedFields}:EditProps){

        const [fields,setFields] = useState(element)
        const [delayedFields] = useDebounce(fields,1000)
        const {credentials}:ContextValueType = useContext(Context) 

        const handleChange:ChangeEventHandler<(HTMLInputElement | HTMLTextAreaElement)> = (e)=>{
            let {name,value} = e.target
            if(name==="paragraph"){
                value = (value as string).replaceAll("\n","<br>")
            }
            const newFields:ElementType = {...fields}
            newFields[name as keyof ElementType] = value;
            setFields(newFields)
        }

        useEffect(()=>{
            if(fields===element){
                return
            }

            const changedFields:ElementType = {}
            for(let fieldKey in delayedFields){
                if(delayedFields[fieldKey as keyof ElementType]!==element[fieldKey as keyof ElementType]){
                    changedFields[fieldKey as keyof ElementType] = delayedFields[fieldKey as keyof ElementType]
                }
            }

            if(!element._id){
                return
            }

            const data = editGenericSubsection({subsectionId:element._id,changedFields,type:""})
        },[delayedFields])


        const handleEditImage = (url:string)=>{
            if(!element._id || !(typeof type === "string")){
                return
            }
            const data = editGenericSubsection({subsectionId:element._id,changedFields:{image:url},type})
        }
        const routeStr = route('api/upload')

        return(
            <div  className={"cont "+className} >
            {fields.heading!==undefined&&<input onChange={handleChange} name="heading" className="h1" value={fields.heading}></input>}
            {isInputIncluded({fields,fieldName:"subheading",includedFields})&&<input onChange={handleChange} name="subheading" className="h3" value={fields.subheading}></input>}
            {fields.image!==undefined&&<ImageEditor
                        url={i(fields?.image)||""}
                        handleEdit={handleEditImage}
                        credentials={credentials}
                        sectionId={element?._id||""}
                        field="image"
                        route={routeStr}
                        className={"img"}
                        replace={true}
            ></ImageEditor>}
            {fields.paragraph!==undefined&&!autoResizeText&&<textarea placeholder="enter paragraph here" onChange={handleChange} name="paragraph" className="p" value={(fields.paragraph.replaceAll("<br>","\n"))||""}></textarea>}

            {fields.paragraph!==undefined&&autoResizeText&&<AutoResizeTextArea
            onChange={handleChange}
            value={fields.paragraph}
            name="paragraph"
            className="p"
            ></AutoResizeTextArea>}
            {fields.quote&&<textarea name="quote"  onChange={handleChange} className="quote" value={fields.quote}></textarea>}

            {fields.name&&(
                    <div className="name-and-title">
                    <input name="name" className="name" onChange={handleChange} value={fields.name}></input>
                    {fields.title&&<input name="title" onChange={handleChange} className="title" value={fields.title}></input>}
                    </div>
                    )}
                {children}
            </div>
        )
    }