
    import "./index.css"
    import React, { useState } from "react"
import { testPromoCode } from "@/api/promo"
import { boolean } from "yup"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

export type PromoType = {
    isActive:boolean,
    code?:string,
    freeMonths?:number
}

type Props = {
    promo:PromoType,
    setPromo:React.Dispatch<PromoType>
}

    const PromoCodeBox = ({promo,setPromo}:Props)=>{
        const [code,setCode] = useState("")
        const [message,setMessage] = useState<{isMessage:boolean,message:string}>({isMessage:false,message:""})
        const [success,setSuccess] = useState(false)

        const handleChange = (e:React.ChangeEvent<HTMLInputElement>)=>{
            setCode(e.target.value)
        }

        const handleSubmit = async ()=>{
            if(code===""){
                return
            }
            const data = await testPromoCode({code})
            if(data.error){
                setMessage({isMessage:true,message:data.message})
            }
            else{
                setSuccess(true)
                setMessage({isMessage:true,message:`Promo Code Applied: ${data.months} Months Free `})
                setPromo(data)
            }
        }

        return(
            <div className="promo-input-cont">
                <input disabled={success?true:false} className={`${success&&"promo-success"}`} placeholder="Enter Promo Code" value={code} onChange={handleChange}></input><button
                disabled={success?true:false} className={`${success&&"promo-success"}`}
                onClick={handleSubmit}>{success?<FontAwesomeIcon icon={faCheck} />:"Apply"}</button>
                {message.isMessage&&<div className="promo-message">{message.message}</div>}
            </div>
        )
    }
    export default PromoCodeBox
    
    
    