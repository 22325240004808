
    import { PageContext } from "@/pages/Standard/context"
import "./index.scss"
    import React, { useContext, useEffect, useState } from "react"
import { NavSectionType, PageContextType } from "@/types/Context"
import getFooter from "./getFooter"
import { FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa"
import { getElementFieldsWithPage } from "@/utils/layoutElements"
import { DynamicAnyObject, DynamicObject } from "@/types/generic"
import getSectionsArrayFroNavBar from "@/components/DynamicNavBar/getSectionsArrayForNavBar"
import Socials from "@/generics/Socials"
import FullFlexTextarea from "@/components/FullFlexTextarea"
import { changeLayoutElementFields } from "@/api/layoutElement"


    const PageFooter = ()=>{

        const {page,isEditing,navSections,editOrdinals}:PageContextType = useContext(PageContext)
        const [fields,setFields] = useState<DynamicObject>({})
        const [footerId,setFooterId] = useState("0")        
        const [onSections,setOnSections] = useState<any>([])

        useEffect(()=>{
            if(!page){
                return
            }
            const fields = getElementFieldsWithPage({page,elementName:"footer"})
            setFields(fields)

            const footerId = page.layoutElements.find((l)=>l?.elementName==="footer")?._id
            if(!footerId){
                return
            }
            setFooterId(footerId)
        },[page])

        useEffect(()=>{
            const onSections = getSectionsArrayFroNavBar({sections:navSections,ordinals:editOrdinals})
            if(onSections){
                setOnSections(onSections)
            }
        },[editOrdinals])
    

        const footer = (page?.layoutElements)?getFooter({page}):false

        if(!footer?.isOn&&!isEditing){
            return<></>
        }

        const email = fields.email||"bob@aol.com"
        const copyrightText = fields.copyrightText || "©Copyright 2023 Fake ABC Corp LTD. All Rights Reserved"

        const sections = onSections.map((section:NavSectionType)=>section.navName||section.heading||section.sectionName||section.sectionForm)

        const size = 30

        const handleChange = ({value,field}:{value:string,field:string})=>{
            const fields:DynamicAnyObject = {}
            fields[field] = value
            changeLayoutElementFields({elementId:footerId,fields})
        }

        return(
            <footer className="page-footer">
                <div className="left">
{isEditing?<FullFlexTextarea
                    additionalClass="email h4"
                    initialText={email}
                    handleChange={(value)=>handleChange({value,field:"email"})}
                    ></FullFlexTextarea>:
                    <h4 className="email">{email}</h4>}
                    {isEditing?<FullFlexTextarea
                    additionalClass="p"
                    initialText={copyrightText}
                    handleChange={(value)=>handleChange({value,field:"copyrightText"})}
                    ></FullFlexTextarea>:
                    <p>{copyrightText}</p>}
                    
                </div>
                <div className="center">
                    <ul>
                        {sections.map((section:string,index:number)=>{
                            return<li key={index}>{section}</li>
                        })}
                    </ul>
                </div>
                <div className="right">
                    <h4>Socials</h4>
                    <div className="socials">
                    <Socials
                    layoutId={footerId}
                    socials={fields}
                    isEditing={isEditing}
                    ></Socials>
                    {/* <FaFacebook size={size} className=""></FaFacebook>
                    <FaInstagram size={size} className=""></FaInstagram>
                    <FaLinkedin size={size} className=""></FaLinkedin> */}
                    </div>
                </div>

            </footer>
        )
    }
    export default PageFooter
    
    
    