const getSavedProgress = ()=>{
    const string = localStorage.getItem("sectionTemplate")
    if(!string){
        return {
            savedHTML:null,
            savedCSSArray:null
        }
    }
    const obj = JSON.parse(string)
    return obj
}

export default getSavedProgress