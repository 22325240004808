import { CSSField } from "@/types/css"

type Args = {
    styleString:string
    parent:HTMLElement
    cssArray:CSSField[]
}

function getPropertyValue(styleString: string, property: string): string | undefined {
  const properties = styleString.split(' ');

  for (let prop of properties) {
    const [key, value] = prop.split(':');
    if (key === property) {
      return value;
    }
  }
  return undefined;
}


const getValue = ({styleString,cssObj}:{styleString:string,cssObj:CSSField})=>{
    if(!cssObj?.variable){
      return cssObj.value
    }
    const setValue = getPropertyValue(styleString,cssObj.variable)
    return setValue||cssObj.value
}

const addCustomTemplateStyles = ({parent,styleString,cssArray}:Args)=>{
  // this is not the culprit either
    for (let cssObj of cssArray){
    // we'll make a function to get the correct value
        const value = getValue({styleString,cssObj})
        const matches:any = Array.from(parent.querySelectorAll(cssObj.selector))
        for (let match of matches){
            match.style[cssObj.property] = value

            if(cssObj.property==="padding"&&cssObj.value==="30px"){
            }
        }
    }
}


export default addCustomTemplateStyles