import { editSectionFields } from "@/api/section"
import MobileEditor from "@/components/MobileEditor"
import { getHorizontalAlignment, getVerticalAlignment } from "@/components/MobileEditor/helpers"
import { SectionContext } from "@/components/PuxMod"
import {  getSetScale } from "@/sections/NewHero/helpers"
import { DynamicObject, DynamicObjectWithNumber } from "@/types/generic"
import { SectionRef, SectionState, SectionType } from "@/types/Section"
import getSlug, { getPageName } from "@/utils/getSlug"
import { i } from "@/utils/images"
import route from "@/utils/route"
import { isMobile } from "@/utils/screen"
import { makeFullStyleObject } from "@/utils/theme"
import { ChangeEvent, useContext, useEffect, useState } from "react"
import AutoResizeTextAreaHero from "../AutoResizeTextAreaHero"
import ImageEditor from "../ImageEditorNew"
import "./index.scss"
import { PageContextType } from "@/types/Context"
import { PageContext } from "@/pages/Standard/context"
import { Procrast } from "@/types/procrast"

    type Props = {
        fields:Procrast
        setFields:React.Dispatch<Procrast>
        isEditing:boolean
        section:SectionType
        handleContainerFieldChange:(fields: DynamicObject) => void
        sectionRef:(null|SectionRef)
    }

    const EditableTextOnPicture = ({fields,setFields,isEditing,section,handleContainerFieldChange,sectionRef}:Props)=>{
        let {image,heading,subheading,mobileImage,position} = fields
        const {style}:SectionState = useContext(SectionContext)
        const [proportion,setProportion] = useState(getSetScale(section.style)||.5)
        const [fontScale,setFontScale] = useState(fields.fontScale||1)
        const [resize,setResize] = useState(0)
        const [headingRows,setHeadingRows] = useState(0)
        const [subheadingRows,setSubheadingRows] = useState(0)
        const {setImageLoaded}:PageContextType = useContext(PageContext)
        const [usePlaceholder,setUsePlaceholder] = useState(false)

        useEffect(()=>{
            const currentFontScale = fields?.fontScale
            if(!currentFontScale){
                resetFontScale()
            }else{
                setFontScale((typeof currentFontScale)==="number"?currentFontScale:parseFloat(currentFontScale.toString()))
            }
        },[sectionRef])

        useEffect(()=>{
            editSectionFields({sectionId:section._id,changedFields:{fontScale:fontScale.toString()}})
        },[fontScale])

        useEffect(()=>{
            const newProportion = getSetScale(style)
            if(proportion!==newProportion){
                resetFontScale()
                setResize(resize+1)
            }
        },[style])

        if(mobileImage&&(window.innerWidth < 700)){
            image=mobileImage
        }
        const modifiedImageURL = i(image.toString())
        let imageField = "image"

        useEffect(()=>{
            setProportion(getSetScale(style))
        },[style])

        const handleChange = (e:ChangeEvent<(HTMLInputElement|HTMLTextAreaElement)>)=>{
            const {name,value} = e.target
            const newFields = {...fields}
            newFields[name] = value.replaceAll("\n","<br>");
            setFields(newFields)
            handleContainerFieldChange(newFields)
        }

        const handleImageEdit = (img:string)=>{
            const newFields = {...fields}
            newFields[imageField] = img
            setFields(newFields)
            handleContainerFieldChange(newFields)
        }


        const scaleBasedOnView = isMobile()?(fields.mobileScale||fontScale):fontScale

        const fontSizes = {
            h1:(2*scaleBasedOnView)+"vw",
            h2:(1*scaleBasedOnView)+"vw"
        }
        const marginBottoms = {
            h1:subheading.length>0?(1*fontScale)+"vw":0,
            h2:(.5*fontScale)+"vw"
        }

        const resetFontScale = async ()=>{
            if(!sectionRef){
                return
            }
        }       

        const imageStyle = {
            "objectPosition":(`${position}%`||"50%"),
        }

        const styleObject = makeFullStyleObject(style)
        let mobileAddOns:DynamicObjectWithNumber = {}
        if(isMobile()){
            mobileAddOns.justifyContent = getVerticalAlignment(styleObject.mobileText||styleObject.text)
            mobileAddOns.textAlign = getHorizontalAlignment(styleObject.mobileTextHor||styleObject.textHor)
            
        }

        const placeholderImage = "images/hero-bg.png"

        const handleImageLoaded = ()=>{
            setImageLoaded(true)
        }

        const handleError = ()=>{
            setImageLoaded(true)
            setUsePlaceholder(true)
        }


        if(!isEditing){
            return<>
                <div className="cover">
                <div className="image-cont">
                <div className="overlay"></div>
                <img onError={handleError} onLoad={handleImageLoaded} style={imageStyle} className={`full-width ${getPageName()} ${isMobile()&&!mobileImage?"mobile":""}`} src={usePlaceholder?placeholderImage:modifiedImageURL}></img>
                </div>
                </div>
                {!isMobile()&&<div className="text-cont">
                    {/* <div className="floating-notice">{fontScale}</div> */}
                    <div style={{fontSize:fontSizes.h1,marginBottom:marginBottoms.h1,...mobileAddOns}} className="h1" dangerouslySetInnerHTML={{__html:heading}}></div>
                    {subheading.length>0&&<div style={{fontSize:fontSizes.h2,marginBottom:marginBottoms.h2,...mobileAddOns}} className="h2" dangerouslySetInnerHTML={{__html:subheading}}></div>}
                </div>}
                {isMobile()&&<div style={{height:"100%",display:"flex",flexDirection:"column",...mobileAddOns}} className="text-cont mobile">
                    {/* <div className="floating-notice">{fontScale}</div> */}
                    <div style={{fontSize:fontSizes.h1,marginBottom:marginBottoms.h1,...mobileAddOns}} className="h1" dangerouslySetInnerHTML={{__html:heading}}></div>
                    {subheading.length>0&&<div style={{fontSize:fontSizes.h2,marginBottom:marginBottoms.h2,...mobileAddOns}} className="h2" dangerouslySetInnerHTML={{__html:subheading}}></div>}
                </div>}
            </>
        }
        
        return(
            <><div className="cover">
        <div className="image-cont">
        <ImageEditor field={imageField} route={route('api/upload')} handleEdit={handleImageEdit} url={image} sectionId={section._id} isSection={true}></ImageEditor>

        </div>
        </div>
        <MobileEditor></MobileEditor>
        <div data-tooltip="click on text to edit" className="text-cont">
            <AutoResizeTextAreaHero
            proportion={proportion}
            resizeTrigger={resize} style={{fontSize:fontSizes.h1,marginBottom:marginBottoms.h1}}   onChange={handleChange} className="h1 tooltip" name="heading" value={heading.replaceAll("<br>","\n")
        }
        fontScale={fontScale}
        setFontScale={setFontScale}
        sectionRef={sectionRef}
        headingRows={headingRows}
        setHeadingRows={setHeadingRows}
        subheadingRows={subheadingRows}
        setSubheadingRows={setSubheadingRows}    
            ></AutoResizeTextAreaHero>
            <AutoResizeTextAreaHero
            proportion={proportion}
            resizeTrigger={resize} style={{fontSize:fontSizes.h2,marginBottom:marginBottoms.h2}}   onChange={handleChange} className="h2 tooltip" name="subheading" value={subheading.replaceAll("<br>","\n")
        }
        fontScale={fontScale}
        setFontScale={setFontScale}
        sectionRef={sectionRef}
        headingRows={headingRows}
        setHeadingRows={setHeadingRows}
        subheadingRows={subheadingRows}
        setSubheadingRows={setSubheadingRows}    
            ></AutoResizeTextAreaHero>
        </div></>
        )
    }
    export default EditableTextOnPicture
    
    
    