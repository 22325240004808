
import { changeLayoutElementFields } from "@/api/layoutElement"
import { fixOrdinals } from "@/api/ordinals"
import { addSectionCall } from "@/api/section"
import { changePageStyle } from "@/api/style"
import { PageDetailsType } from "@/types/Page"
import { getElementId, getElementStyle } from "@/utils/layoutElements"
import React, {createContext, useEffect, useReducer, useState } from "react"
import { checkEditOrdinals, checkIsFullHeight, getBodyWidth, navReducerAction } from "./helpers"
import { calculatedValuesReducer, initialCalculatedState } from "./valuesReducer"
import { SectionType } from "@/types/Section"
import { useDebounce } from "use-debounce"
import { fexPut } from "@/utils/fex"
import useSkipFirstMutation from "@/hooks/useSkipFirstMutation"
import { paginate, unpaginate } from "@/api/page"
import { SubPage, createSubPages, findCurrentSubPage } from "@/utils/pagination"
import {isEditingURL } from "@/utils/url"
type ContextType={
    Provider:  any
    Consumer:  any
}

export type EditOrdinalsType = {
    ordinal:number,
    sectionId:string
}



export type EditSubOrdinalsType = {
    ordinal:number,
    _id:string
}



export const PageContext:ContextType = createContext({})

export function PageContextProvider({children}:{children:(JSX.Element|JSX.Element[])}){
    
    const [submission,setSubmission] = useState(null)
    const [isEditing,setIsEditing] = useState(isEditingURL())
    const [theme,setTheme] = useState("grayscale")
    const [pageId,setPageId] = useState("null")
    const initialEditOrdinals:EditOrdinalsType[] = []
    const [editOrdinals,setEditOrdinals]:[EditOrdinalsType[],React.Dispatch<EditOrdinalsType[]>] = useState(initialEditOrdinals)
    const [isPaidFor,setIsPaidFor] = useState(false)
    const [style,setStyle] = useState("")
    const [page,setPage] = useState<(null|PageDetailsType)>(null)
    const [noPage,setNoPage] = useState<boolean>(false)
    const [additionalFields,setAdditionalFields] = useState<{key:string,value:string}[]>([])
    const [sectionEditing,setSectionEditing] = useState("")
    const [navStyle,setNavStyle] = useState("")
    const [navSections,setNavSections] = useState([])
    const [calculatedValuesState,calculatedValuesDispatch] = useReducer(calculatedValuesReducer,initialCalculatedState)
    const [imageLoaded,setImageLoaded] = useState(false)
    const [delayedEditOrdinals] = useDebounce(editOrdinals,1000)
    const [sectionRequests,setSectionRequests] = useState<any>([])
    const [paginated,setPaginated] = useState(false)
    const [subpages,setSubpages] = useState<SubPage[]>([])
    const [currentSubPage,setCurrentSubPage] = useState<null|SubPage>(null)
    const [bodyWidth,setBodyWidth] = useState<any>(null)
    const [isFullHeight,setIsFullHeight] = useState(false)

    useEffect(()=>{
        const bodyWidth = getBodyWidth()
        setBodyWidth(bodyWidth)
        const isFullHeight = checkIsFullHeight()
        setIsFullHeight(isFullHeight)
        window.addEventListener('resize', function(event) {
            const bodyWidth = getBodyWidth()
            setBodyWidth(bodyWidth)
            const isFullHeight = checkIsFullHeight()
            setIsFullHeight(isFullHeight)
        });
    },[])

    useEffect(()=>{
        if(paginated){
            const sections = page?.sections
            if(!sections){
                return
            }
            const subPages = createSubPages({ordinals:editOrdinals,sections})
            setSubpages(subPages)
            setCurrentSubPage(subPages[0])
        }
    },[paginated])

    useEffect(()=>{
        setPaginated(page?.paginated||false)
    },[page])

    useSkipFirstMutation(()=>{
        const f = async ()=>{

            if(paginated){
                const res = await paginate({pageId})
            }
            else{
                unpaginate({pageId})
            }
        }
        f()
    },[paginated])



    useEffect(()=>{
        if(!page){
            return
        }
        setSectionRequests(page?.sectionRequests||[])
    },[page])

    useEffect(()=>{
        fexPut("api/section/ordinals/"+pageId,{ordinals:delayedEditOrdinals})
    },[delayedEditOrdinals])

    useEffect(()=>{
        if(!page){
            return
        }
        setAdditionalFields(page.additionalFields)
        const shouldChange = !(style===""||style===page?.style)
        if(!shouldChange){
            return
        }
        changePageStyle({pageName:page.pageName,style})
        
    }
        ,[style])

    useEffect(()=>{
        const check = checkEditOrdinals({editOrdinals})
        if(!check){
            fixOrdinals({pageId})
        }
    },[editOrdinals])

        useEffect(()=>{
            if(!page){
                return
            }
            const shouldChange = !(navStyle===""||getElementStyle({page,elementName:"header"})===navStyle)
            // we also need to handle when there isn't an element
            navReducerAction({navStyle,calculatedValuesDispatch})
            if(!shouldChange){
                return
            }
            const elementId = getElementId({page,elementName:"header"})
            changeLayoutElementFields({elementId,fields:{style:navStyle}})
        }
        ,[navStyle])
        

    const deleteSection = (sectionId:string)=>{
        if(!page){
            return
        }
        const newPageSections = [...page.sections].filter(s=>s._id!==sectionId).sort((a,b)=>{
            return (a.ordinal-b.ordinal)
        })
        
        for (let i = 0;i<newPageSections.length;i++){
            newPageSections[i].ordinal = i;
        }
        const newPage = {...page}
        newPage.sections = newPageSections
        setPage(newPage)
        const newEditOrdinals = editOrdinals.filter(o=>o.sectionId!==sectionId).sort((a,b)=>{
            return a.ordinal-b.ordinal
        }).map((o,index)=>{
            return {sectionId:o.sectionId,ordinal:index}
        })
        setEditOrdinals(newEditOrdinals)
    }

    const addSection = async (sectionType:string)=>{
        if(!page){
            return
        }
        const newSection = await addSectionCall({pageId:page._id,template:sectionType})
        if("error" in newSection){
            return
        }
        const newPageSections = [...page.sections,(newSection as unknown as SectionType)]
        const newPage = {...page}
        
        newPage.sections = newPageSections
        setPage(newPage)
        if("error" in newSection){
            return
        }
        const ordinalObject = {sectionId:newSection._id,ordinal:newSection.ordinal}
        setEditOrdinals([...editOrdinals,ordinalObject])
    }

    useEffect(()=>{
        const previewSectionId = (new URLSearchParams(window.location.search)).get("previewSection")
        if (previewSectionId){
            setImageLoaded(true)
        }
    },[])

    const handlePaginatedNav = (sectionId:string)=>{
        if(sectionId==="home"){
            setCurrentSubPage(subpages[0])
            return
        }
        const currentSubPage = findCurrentSubPage({sectionId,subpages})
        if(currentSubPage){
            setCurrentSubPage(currentSubPage)
        }
    }

    return(
        <PageContext.Provider value={{
            page,setPage,
        isEditing,setIsEditing,
        theme,setTheme,
        pageId,setPageId,
        editOrdinals,setEditOrdinals,
        isPaidFor,setIsPaidFor,
        style,setStyle,noPage,setNoPage,
        additionalFields,setAdditionalFields,
        sectionEditing,setSectionEditing,deleteSection,addSection,navStyle,setNavStyle,navSections,setNavSections,calculatedValuesState,calculatedValuesDispatch,
        imageLoaded,setImageLoaded,
        submission,setSubmission,
        sectionRequests,setSectionRequests,
        paginated,setPaginated,
        currentSubPage,
        handlePaginatedNav,
        bodyWidth,
        isFullHeight
}}>
            {children}
        </PageContext.Provider>
    )
}