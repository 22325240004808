
    import "./index.scss"
    import React from "react"
import { ButtonsObject, LabelAndValue } from ".."
import { DynamicObjectWithNumber } from "@/types/generic"
import { Field } from "@/types/Section"

    type Props = {
        buttonsObject:ButtonsObject
        stateObject:DynamicObjectWithNumber
        handleClick:(arg1:Field)=>void
    }

    const OptionButtons = ({buttonsObject,stateObject,handleClick}:Props)=>{
        
        const buttonsArray = buttonsObject.options
        
        const value = stateObject[buttonsObject.key]

        const style = {
            gridTemplateColumns:`repeat(${buttonsArray.length},1fr)`
        }

        return(
            <div style={style} className="buttons-row">
                {buttonsArray.map((option:LabelAndValue,index:number)=>{
                    return<button data-selected={value===option.value} data-value={option.value}
                    onClick={(e)=>{handleClick({key:buttonsObject.key,value:option.value})}}
                    
                    >
                        {option.label}
                    </button>
                })}
            </div>
            )
    }
    export default OptionButtons
    
    
    