type Args = {
    parentMaxWidth?:number
    parentHorizontalPadding:number
    specifiedWidth?:number|null
    specifiedMaxWidth?:number|null
}

const getMaxWidth = ({parentMaxWidth,parentHorizontalPadding,specifiedWidth,specifiedMaxWidth}:Args)=>{
    const defParentWidth = parentMaxWidth||3000
    const defMaxWidth = specifiedMaxWidth||3000
    const defWidth = specifiedWidth||3000
    return Math.min((defParentWidth-parentHorizontalPadding),defMaxWidth,defWidth)
}

export default getMaxWidth