import Masonry from '@/generics/MasonryComponent';
    import "./index.scss"
    import React, { useContext, useState } from "react"
import { SectionState, SectionType } from "@/types/Section"
import PuxMod, { SectionContext } from "@/components/PuxMod"
import Bar from '@/generics/Bar';
import ImageGridAdder from './ImageGridAdder';

    const ImageGrid = ({section}:{section:SectionType})=>{
        return(
            <PuxMod section={section} headless={true}>
                {/* map all the images, let's start with just an array. */}

            <Consumer></Consumer>
            </PuxMod>
        )
    }

    function Consumer(){

        const sectionC:SectionState = useContext(SectionContext)
        const {setStringArray,isEditing} = sectionC
        const [value,setValue] = useState("")
        const stringArray:string[] = sectionC.stringArray

        const handleSubmit = ()=>{
            if(value.length<5){
                return
            }
            setStringArray([...stringArray,value])
            setValue("")
        }

        const handleAdd = (newUrl:string)=>{
            setStringArray([...stringArray,newUrl])
        }

        const reorder = ()=>{
            const newStringArray = [...stringArray]
            newStringArray[0] = stringArray[1]
            newStringArray[1] = stringArray[0]
            setStringArray(newStringArray)
        }

        return<div style={{width:"100%"}}>
            <Masonry/>
            {isEditing&&<Bar>
                <ImageGridAdder handleAdd={handleAdd}></ImageGridAdder>
                {/* <input value={value} onChange={(e)=>setValue(e.target.value)} placeholder="add image url"></input><button onClick={handleSubmit}>Add Image</button> */}
                <button onClick={reorder}>reorder</button>
                </Bar>
            }
        </div>
    }

    export default ImageGrid
    
    
    