
    import "./index.scss"
    import React, { useContext, useEffect, useState } from 'react';
    import { DiscussionEmbed } from 'disqus-react';
import { PageContext } from "@/pages/Standard/context";
import { PageContextType } from "@/types/Context";
    

    const shortname = "puxhub-com"
    const identifier = "oinewoigfwe939j1"
    const url = 'https://www.puxhub.com/test-page1'
    const title = "test-page"


    const Disqus = ()=>{

        const pageValues:PageContextType = useContext(PageContext)
        const pageName = pageValues?.pageId||"testPage"
        const identifier = pageName
        const url = `https://www.puxhub.com/${identifier}`
        const title = identifier


        const disqusConfig = {
            shortname: shortname,
            config: { identifier: identifier, url: url, title: title, 
            },
            width:"90vw"
          };

        
          return (
            <div className="disqus-cont">
              <DiscussionEmbed {...disqusConfig} />
            </div>
          );
    }
    export default Disqus
    
    
    