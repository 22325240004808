import { changeIsOn, editSectionFields } from "@/api/section"
import LayoutModal from "@/components/LayoutModal"
import PuxMod from "@/components/PuxMod"
import ReorderArrows from "@/components/widgets/ReorderArrows"
import ToggleOn from "@/components/widgets/ToggleOn"
import Section from "@/generics/Section"
import SectionHeading from "@/generics/SectionHeading"
import Socials from "@/generics/Socials"
import { PageContext } from "@/pages/Standard/context"
import { PageContextType } from "@/types/Context"
import { DynamicObject } from "@/types/generic"
import { Field, SectionType } from "@/types/Section"
import { convertArrayIntoObject, firstLastCheck, reorderSections } from "@/utils/arrays"
import { getEditOrdinal } from "@/utils/pageManipulation"
import { t } from "@/utils/stringFormatting"
import React, { ChangeEventHandler, useContext, useEffect, useState } from "react"
import { useDebounce } from "use-debounce"
import "./index.scss"

type Props = {
    section:SectionType
}

const SocialsSection = ({section}:Props)=>{
    const {isEditing}:PageContextType = useContext(PageContext)
    const initialFields = section.fields
    const convert = (fields:Field[])=>{
        const fieldsObject:DynamicObject = {}
        for (let field of fields){
            fieldsObject[field.key] = field.value
        }
        return fieldsObject
    }

    const [socials,setSocials] = useState(convert(initialFields))
    const socialsArray = []
    for (let key in socials){
        socialsArray.push({key,href:socials[key]})
    }


    const handleChange:ChangeEventHandler<HTMLInputElement> = (e)=>{
        const {value,id:key} = e.target
        const newSocials = {...socials}
        newSocials[key] = value;
        setSocials(newSocials)
    }


    return<PuxMod section={section}>
            <Socials sectionId={section._id} isEditing={isEditing} socials={socials}></Socials>
    </PuxMod>
}
export default SocialsSection
