
    import getSlug from "@/utils/getSlug"
import "./index.scss"
    import React, { useEffect, useState } from "react"
import { getSectionSubmission } from "@/api/sectionSubmission"
import { SectionRequest } from "@/types/SectionRequest"
import Standard from "../Standard"
import { SectionDevelopmentContextProvider } from "@/context/SectionDeveloperContext"

    const FreelanceDev = ()=>{

        const [devSubmission,setDevSubmission] = useState<any>(null)
        const submissionId = getSlug().substring(1)
        useEffect(()=>{
            const f = async ()=>{
                const res = await getSectionSubmission({submissionId})
                if (!res?.data?.submission){
                    return
                }
                setDevSubmission(res.data.submission)
            }
            f()
        },[])

        const pageName = devSubmission?.page?.pageName;

        if(!devSubmission){
            return<></>
        }

        return(<SectionDevelopmentContextProvider
        submission={devSubmission}
        >

            <main className="freelance-section-dev-main">
                {devSubmission?.sectionRequest&&<DetailsPanel
                request={devSubmission.sectionRequest}
                ></DetailsPanel>}
                {pageName&&<div className="editor">
                    <Standard host={pageName}></Standard>
                    </div>}
            </main>
                </SectionDevelopmentContextProvider>
        )
    }
    export default FreelanceDev
    
    
type PanelProps = {
    request:SectionRequest
}    

function DetailsPanel({request}:PanelProps){

    const {
        assets,budgetDollars,userName,pageName,sectionContent,developers
    } = request

    const numberOfDevelopers = developers?.length||0
    const isPlural = numberOfDevelopers>1

    return<aside>
        <header>

        <h2>{request.sectionRequestTitle}</h2>
        <h4>{developers?.length} developer{isPlural&&"s"} vying for ${budgetDollars}</h4>
        Section Request for Page: 
        <h3><a target="_blank" href={`/${request.pageName}`}>
            {request.pageName}
            </a>
            </h3>
        </header>
        <div className="description">{sectionContent}</div>
        <div className="assets-box">
            {assets.map((asset,key)=>{
                return<img src={asset}></img>
            })}
        </div>
    </aside>
}