export default     {
    prompt:"Button Background?",
    field:"buttonBackground",
    type:"childStyle",
    child:".e-button",
    valueType:"string",
    colorPicker:true,
    default:"inherit",
    property:"background",
    condition:{field:"buttonStyle",value:"button-set-solid"},
}