
    import "./index.scss"
    import React, { useEffect, useState } from "react"
import Header from "@/generics/Header"
import HomepageHeader from "@/layout/headers/HomepageHeader"
import BlogEntry from "@/generics/BlogEntry"
import { getAllArticles } from "@/api/article"
import ArticleDetails from "@/generics/ArticleDetails"
import { ArticleType } from "@/types/Article"

    const ArticlesList = ()=>{
        
        const [articles,setArticles] = useState<ArticleType[]>([])
        
        useEffect(()=>{
            const fetch = async ()=>{
                const res = await getAllArticles()
                setArticles(res)
            }
            fetch()
        },[])
        

        return(
            <>
            <HomepageHeader additionalClass="articles-header"/>

            <main className="articles-list-main">
            <h1>Articles</h1>
                {articles.map((article:ArticleType)=><ArticleDetails
                    title={article.title}
                    
                />)}
            </main>
            </>
        )
    }
    export default ArticlesList
    
    
    