import backgroundOn from "../fields/backgroundOn";
import buttonBackgroundColor from "../fields/buttonBackgroundColor";
import buttonBorderColor from "../fields/buttonBorderColor";
import buttonBorderRadius from "../fields/buttonBorderRadius";
import buttonFont from "../fields/buttonFont";
import buttonHover from "../fields/buttonHover";
import buttonStyle from "../fields/buttonStyle";
import buttonTextColor from "../fields/buttonTextColor";
import contentDirection from "../fields/contentDirection";
import font from "../fields/font";
import overlayOpacity from "../fields/overlayOpacity";
import sectionBackground from "../fields/sectionBackground";
import textColor from "../fields/textColor";
import generics from "./generics";

export default [
    ...generics,
    contentDirection,
    buttonFont,
    buttonStyle,
    buttonTextColor,
    buttonBackgroundColor,
    buttonBorderRadius,
    buttonBorderColor,
    buttonHover
]