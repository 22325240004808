
    import "./index.scss"
    import React, { useEffect, useState } from "react"
import BigQuote from "@/generics/BigQuote"
import Section from "@/generics/Section"
import { Link } from "react-router-dom"
import Expander from "@/generics/Expander"
import faqs, { FaqType } from "./faqs"
import Samples from "../Samples"

    const AppMid = ()=>{
        const [headerAnimation,setHeaderAnimation] = useState("")

        useEffect(()=>{
            const addAnimation = ()=>{
                setHeaderAnimation("visible")
            }
            setTimeout(addAnimation,1000)
        },[])

        return(
            <>
            <Section sectionName="how-to">
                <div className="grid">
                <div>
                <h1>Get a site live in five steps</h1>
                <p>It's stupidly easy.</p>
                </div>
                <div className="list-cont">
                    <ol>
                        <li>Register.</li>
                        <li>Add a page by choosing a page name and template.</li>
                        <li>Edit the boilerplate with your text and images</li>
                        <li>Once you are happy with the design, subscribe to a $5/month plan to make the site live.</li>
                        <li>Share the site to your target audience.</li>
                    </ol>
                    <Link to="/register">
                    <button className="mid-cta-btn">Get Started</button>
                    </Link>
                </div>
                </div>
            </Section>
            <Samples></Samples>
            <Section sectionName="faq">
                <div className="grid bg-none">
                <aside className="faqs-aside">

                {faqs.map((f:FaqType,i:number)=>{
                    return<Expander
                    key={i}
                    header={f.q}
                    body={f.a}
                    ></Expander>
                })}
                </aside>
                    <div className="faq-title">
                    <h1>FAQS</h1>
                    </div>
                </div>
            </Section>
            </>
        )
    }
    export default AppMid
    
    
    