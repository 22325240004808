
    import "./index.scss"
    import React from "react"
import HomepageHeader from "@/layout/headers/HomepageHeader"

    const Agreement = ()=>{
        return(<>
            <HomepageHeader additionalClass="agreement"></HomepageHeader>
            <main className="user-agreement">
            <h1>USER AGREEMENT</h1>
<p>Welcome to PuxHub! By using our service, you are agreeing to the following terms and conditions:</p>
<ol>
  <li>You are solely responsible for the content that you publish on your website. This includes, but is not limited to, text, images, videos, and any other materials.</li>
  <li>You agree to not use our website builder for any illegal or fraudulent purposes.</li>
  <li>You grant us a worldwide, non-exclusive, royalty-free license to use any content that you publish on your website for the purpose of promoting our website builder.</li>
  <li>We reserve the right to remove any content that we determine to be inappropriate or in violation of these terms and conditions.</li>
  <li>We are not responsible for any errors or malfunctions that may occur with our website builder.</li>
  <li>We reserve the right to modify or discontinue our website builder at any time without notice.</li>
  <li>We will not be liable for any damages resulting from the use or inability to use our website builder.</li>
</ol>

<p>By using our website builder, you agree to be bound by these terms and conditions. If you do not agree to these terms and conditions, please do not use our website builder.</p>

<p>Thank you for using PuxHub!</p>
        </main>
        </>
        )
    }


export default Agreement
    
    
    