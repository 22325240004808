
    import Expander from "@/generics/Expander"
import { HelpTopic } from "../getHelpTopics"
import "./index.scss"
    import React from "react"

    type Props = {
        helpTopic:HelpTopic
    }

    const HelpTopicListing = ({helpTopic}:Props)=>{
        
        const {title,subtitle,html,_id} = helpTopic
        return(
            <article id={_id} className="help-topic-listing">
                <header>

                <h2>{title}</h2>
                <h4>{subtitle}</h4>
                </header>
                <div className="content" dangerouslySetInnerHTML={{__html:html}}></div>
            </article>
        )
    }
    export default HelpTopicListing
    
    
    