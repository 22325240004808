
    import { FaArrowLeft } from "react-icons/fa"
import "./index.scss"
    import React from "react"

    type Props = {
        close:()=>void
    }

    const BackButtonCont = ({close}:Props)=>{
        return(
            <div onClick={close} className="back-button-cont">
            <button><FaArrowLeft></FaArrowLeft> Return to Editor</button>
        </div>
        )
    }
    export default BackButtonCont
    
    
    