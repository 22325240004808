
import "./index.scss"
import React, { ChangeEvent, useState } from "react"

type Props = {
    propertyName:string
    unit:string
    value:number
    min:number,
    max:number
    setValue:React.Dispatch<number>
    label?:string
  }


const LogarithmicSlider = ({
    propertyName,
    unit,
    min,
    max,
    label,
    value,
    setValue
    

}:Props)=>{

    const handleChange = (event:ChangeEvent<HTMLInputElement>) => {
        const value = Math.exp(parseFloat(event.target.value))
      setValue(Math.round(value));
    };
  
    return (
      <div className="slider-cont">
        {label&&<label>{label}</label>}
        <input
          className="custom-slider"
          type="range"
          min={min}
          max={Math.log(max)}
          value={Math.log(value)}
          onChange={handleChange}
          step={.001}
        />
        {!label&&<div>{propertyName}: {value} {unit}</div>}
      </div>
    );
}
export default LogarithmicSlider