export function getTextWidth(text:string, font:string, fontSize:string,fontWeight:string,textTransform?:string,padding?:number) {
  const div = document.createElement("div")
  div.style.setProperty("font-family",font)
  div.style.setProperty("font-size",fontSize)
  div.style.setProperty("font-weight",fontWeight)
  if(padding){ 
    div.style.setProperty("padding",`${padding}px`)
  }
  if(textTransform){
    div.style.setProperty("text-transform",textTransform)
  }
  div.style.setProperty("display","inline")
  div.innerHTML=text
  document.body.appendChild(div)
  const width = div.getBoundingClientRect().width*1.05+(padding||0)
  const height = div.getBoundingClientRect().height*1.05+(padding||0)
  div.remove()
  return {newWidth:width,lineHeight:height};
  }

export  function findLongestString(strings:string[]) {
    if (strings.length === 0) {
      return null; // Return null if the array is empty
    }
  
    var longestString = strings[0]; // Assume the first string is the longest
  
    for (var i = 1; i < strings.length; i++) {
      if (getLength(strings[i]) > getLength(longestString)) {
        longestString = strings[i]; // Update longestString if a longer string is found
      }
    }
    return longestString;
  }

export const getFontProperties = ({refStyle}:any)=>{
}

const getLength = (string:string)=>{
  let count = 0;

  for (let i = 0; i < string.length; i++) {
    if (string[i].toLocaleLowerCase() === 'i') {
      count += 0.4;
    } else {
      count += 1;
    }
  }
  return count;
}