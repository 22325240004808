
    import "./index.css"
    import React from "react"

    const HTML = ({html}:{html:string})=>{
        return(
            <div dangerouslySetInnerHTML={{__html:html}}></div>
        )
    }
    export default HTML
    
    
    