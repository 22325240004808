
    import { getMessages, markAsRead } from "@/api/messages"
import "./index.scss"
    import React, { useEffect, useRef, useState } from "react"
import MessageBox from "../MessageBox"
import Message from "../Message"
import useGetIds from "@/hooks/useGetIds"

    type Props = {
        pageId:string
    }

    export type MessageType = {
        text:string
        _id:string
        name:string
    }

    const PageMessenger = ({pageId}:Props)=>{

        const messagesRef = useRef<HTMLDivElement>(null);
        const [ids] = useGetIds()
        const [messages,setMessages] = useState<MessageType[]>([])
        const [conversationId,setConversationId] = useState(null)
        const fetchNewMessages = async ()=>{
                
            const resp = await getMessages({pageId})
            const newMessages = resp.messages
            if(messages.length!==newMessages.length){
                setMessages(newMessages)
            }
        }

        useEffect(()=>{
            const f = async ()=>{
                
                const resp = await getMessages({pageId})
                const {messages,conversation} = resp
                setMessages(messages||[])
                setConversationId(conversation)
            }
            f()
        },[pageId])

        useEffect(() => {
            if (messagesRef.current) {
              // Scroll to the bottom of the messages div
              messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
            }
          }, [messagesRef,messages]);

          useEffect(() => {
            // Fetch new messages every 5 seconds
            const interval = setInterval(fetchNewMessages, 5000);
        
            // Cleanup the interval on component unmount
            return () => clearInterval(interval);
          }, []);

          useEffect(()=>{
            const userId = ids.userId;
            if(!userId||!conversationId){
                return
            }
            markAsRead({userId,conversationId})
          },[conversationId])

        return(
            <>
            <div ref={messagesRef} className="past-messages">
                {messages.map(m=>{
                    return<Message message={m}></Message>
                })}
            </div>
                <MessageBox
                pageId={pageId}
                setMessages={setMessages}
                messages={messages}
                ></MessageBox>
                </>

        )
    }
    export default PageMessenger
    
    
    