
    import { Context, ContextValueType } from "@/context"
import "./index.scss"
    import React, { ContextType, FormEvent, KeyboardEventHandler, useContext, useState } from "react"
import { PageContextType } from "@/types/Context"
import { sendMessage } from "@/api/messages"
import { MessageType } from "../PageMessenger"

    type Props = {
        pageId:string
        setMessages:React.Dispatch<MessageType[]>
        messages:MessageType[]
    }

    const MessageBox = ({pageId,messages,setMessages}:Props)=>{
        
        const [value,setValue] = useState("")

        const context:ContextValueType = useContext(Context)
        const {name,_id} = context.credentials.user
        const handleSubmit = (e:FormEvent)=>{
            setValue("")
            e.preventDefault()
            const params = {pageId,text:value,name,senderId:_id}
            sendMessage(params)
            setMessages([...messages,{text:value,name,_id:"null"}])
        }

        const handleKey:KeyboardEventHandler = (e)=>{
            if(e.key==="Enter"){
                e.preventDefault()
                setValue("")
                const params = {pageId,text:value,name,senderId:_id}
                sendMessage(params)
                setMessages([...messages,{text:value,name,_id:"null"}])
            }
        }

        return(
            <form className="message-box" onSubmit={handleSubmit}>
                <textarea onKeyDown={handleKey} value={value} onChange={(e)=>setValue(e.target.value)}></textarea>
                <button>Send</button>
            </form>
        )
    }
    export default MessageBox
    
    
    