
    import "./index.css"
    import React from "react"
import { DynamicObject } from "@/types/generic"

    const ParagraphDivider = ({text,style}:{text:string,style?:DynamicObject})=>{

        const paragraphs = text.replaceAll("\n","<br>").split("<br>")

        return(
            <>
            {paragraphs.map((para,i)=>{
                return<p style={style||{}} key={i}>{para}</p>
            })}
            </>
        )
    }
    export default ParagraphDivider
    
    
    