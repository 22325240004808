export default     {
    prompt:"Content Direction",
    field:"contentDirection",
    type:"childStyle",
    child:".content",
    default:"row",
    valueType:"string",
    property:"flex-direction",
    options:[
        {value:"row",text:"Left"},
        {value:"row-reverse",text:"Right"},
        {value:"column",text:"Up"},
        {value:"column-reverse",text:"Down"}
    ]
}