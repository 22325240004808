import "./index.css"
import React, {useContext, useState} from "react"
import Hero from "@/generics/Hero"
import MyPages from "../../components/MyPages"
import { Context, ContextValueType, ModalType } from "../../context"
import Section from "../../generics/Section"
import AppFooter from "../../layout/footers/AppFooter"
import HomepageHeader from "../../layout/headers/HomepageHeader"
import Confirmation from "@/generics/Confirmation"
import AppMid from "@/components/AppMid"
import { isMobile } from "@/utils/screen"
import FirstPage from "../FirstPage"

    const Home = ()=>{
        const context:ContextValueType = useContext(Context)
        const {credentials,confirmation} = context
        const [isCreating,setIsCreating] = useState(false)

        if(credentials&&(credentials.user.pages.length===0||isCreating)){
            return <FirstPage/>
        }



        return(<>
            <HomepageHeader additionalClass={`${credentials?'my-pages-header':''}`}></HomepageHeader>
            {credentials?<Section sectionName="my-pages"><MyPages setIsCreating={setIsCreating} ></MyPages>
                </Section>
                :<Splash></Splash>}
            {!confirmation?<></>:<Confirmation
            ></Confirmation>}
        </>
        )
    }
    export default Home
    
    function Splash(){

        let image = "images/hero-bg-marble.png"
        if(isMobile()){
            image="images/hero-bg-marble-mobile.png"
        }

        return <main className="splash">
        <Hero
        image={image}
        alt="blue marble"
        heading="Fast and Easy"
        paragraph="Build a custom webpage in less than 10 minutes."
        ctaText="Start Here."
        handleHeroCTA={()=>window.location.href="/register"}
        textAnimation="slide-left"
        ></Hero>
        <AppMid></AppMid>
        <AppFooter></AppFooter>
    </main>
    }