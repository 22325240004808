
    import RedirectButton from "@/generics/RedirectButton"
import "./index.scss"
    import React from "react"
import Logo from "@/generics/Logo"
import Experimental from "@/wrappers/Experimental"

    const DevelopersSplash = ()=>{
        return(
            <Experimental>
            <header className="developers-splash-header">
                <RedirectButton to="/register">JOIN PUXHUB</RedirectButton>
            </header>
            <main className="developers-splash">
                <section className="left">
                    <Logo text=""></Logo>
                </section>
                <section className="right">
                <h2>
                    Are you a developer?<br></br> A UI/UX expert?<br></br> Are you looking to get into freelance web development?
                </h2>
                </section>
                <section className="full-width passive-income">
                    <h2>Why freelance on Puxhub?</h2>
                    <p>It's fast and easy. No need to build out a profile, add services, delineate prices, market yourself, etc. etc.. You just go to the job board, pick a job and start working.</p>
                    <p> The work is perfect for someone in between gigs or looking for design experience immediately out of a bootcamp.</p>
                </section>
                <section className="full-width work-for-you">
                    <div className="overlay"></div>
                    <div className="right">

                    <h3>Get to work quickly</h3>
                    <ol>
                        <li><a href="/register">
                            Register for an account.
                            </a>
                            </li>
                        <li>Visit our project board.</li>
                        <li>Choose a project</li>
                        <li>Build the project</li>
                        <li>Be selected by the client</li>
                        <li className="underline">Get paid</li>
                    </ol>
                    </div>
                    <div className="left">
                    <h1>
                        Puxhub has work for you!
                    </h1>
                    </div>
                </section>
                <section className="full-width passive-income">
                    <h2>Want passive income?</h2>
                    <p>Create a killer template. It can either be for a section, a page or a whole site. You will receive monthly income for each site that uses the template.</p>
                </section>
            </main>
                <footer className="developers-splash-footer">
                <RedirectButton to="/register">Register Now</RedirectButton>
                </footer>
            </Experimental>
        )
    }
    export default DevelopersSplash
    
    
    