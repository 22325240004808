import { fexDelete, fexPost, fexPut } from "../utils/fex"

export const createEvent = async ({sectionId}:{sectionId:string})=>{
    try{
        const res = await fexPost(`api/event/${sectionId}`,{})
        return res
    }catch(err){
        return err
    }
}

export const deleteEvent = async ({sectionId,eventId}:{sectionId:string,eventId:string})=>{
    try{
        const res = await fexDelete(`api/event/${sectionId}`,{eventId})
        return res
    }catch(err){
        return err
    }
}

export const editEvent = async ({eventId,changedFields}:{eventId:string,changedFields:{[key: string]: string}})=>{
    try{
        const res = await fexPut(`api/event/${eventId}`,changedFields)
        return res
    }catch(err){
        return err
    }
}