import { fexDelete, fexPost } from "@/utils/fex"


export const addDomain = async  ({pageId,domainName}:{pageId:string,domainName:string})=>{
    const resp = await fexPost("api/dns-records/"+pageId,{domainName})
    return resp
}

export const removeDomain = async  ({pageId,domainName}:{pageId:string,domainName:string})=>{
    const resp = await fexDelete("api/dns-records/"+pageId,{domainName})
    return resp
}