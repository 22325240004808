import { CSSLineType } from "@/components/CSSEditor"
import { fexPost, fexPut } from "@/utils/fex"

export const addCSSField = async (sectionId:string)=>{
    const res = await fexPost("api/css/"+sectionId,{selector:"replace",property:"these",value:"items"})
    return res
}

type UpdateSectionCSSArgs = {
    sectionId:string
    cssArray:CSSLineType[]
}

export const updateSectionCSS = async ({sectionId,cssArray}:UpdateSectionCSSArgs)=>{
    const res = await fexPut("api/css/"+sectionId,{cssArray})
    return res
}