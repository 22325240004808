
    import { Link } from "react-router-dom"
import "./index.scss"
    import React from "react"
import { FaQuestionCircle } from "react-icons/fa"

    const HelpLink = ({topicId}:{topicId:string})=>{
        return(
            <div className="help-link-cont">

`               <Link target="_blank" to={`/help-page#${topicId}`}>
                    <FaQuestionCircle size={15}></FaQuestionCircle>
                </Link>
            </div>
        )
    }
    export default HelpLink
    
    
    