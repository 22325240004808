
    import { Conversation } from "@/types/Conversation"
import "./index.scss"
    import React from "react"

    type Props = {
        conversations:Conversation[]
        currentConversation:Conversation|null
        setCurrentConversation:React.Dispatch<Conversation|null>
        hidden:boolean
        setMobileView:React.Dispatch<string>
    }

    const MessageList = ({conversations,currentConversation,setCurrentConversation,hidden,setMobileView}:Props)=>{



        return(
            <aside className={hidden?"hide":""}>
                {
                    conversations.map((c,i)=>{
                        return<div
                        onClick={()=>setMobileView("conversation")}
                        >
                            <Preview
                        currentConversation={currentConversation}
                        setCurrentConversation={setCurrentConversation}
                        
                        key={i} conversation={c}></Preview>
                        </div>
                    })
                    
                }
            </aside>
        )
    }
    export default MessageList
    

    type PreviewProps = {
        conversation:Conversation
        currentConversation:Conversation|null
        setCurrentConversation:React.Dispatch<Conversation|null>
    }
    
    function Preview({conversation,currentConversation,setCurrentConversation}:PreviewProps){

        const lastMessage = conversation.messages[conversation.messages.length-1]

        const handleClick = ()=>{
            setCurrentConversation(conversation)
        }

        return<article onClick={handleClick} className={`conversation-preview ${currentConversation===conversation&&"current"}` }>

            <h4>
                Page: {conversation.pageName}
            </h4>
            {lastMessage.text}
        </article>
    }