

import { fexPut } from "@/utils/fex"
import "./index.scss"
import React, { useEffect, useState } from "react"
import { PageDetailsType } from "@/types/Page"
import { filterNames } from "./filterNames"

    const TestPagesLinks = ()=>{

        const [pages,setPages] = useState<PageDetailsType[]>([])

        useEffect(()=>{
            const f = async ()=>{
                const pages = await fexPut("api/page/",{isOn:true,isPaidFor:true})
                setPages(filterNames(pages))
            }
            f()
        })


        return(
            <table className="test-links-table">
                {pages.map((page:PageDetailsType,index:number)=>{
                    return <ChildLine page={page}></ChildLine>
                })}
            </table>
        )
    }
    export default TestPagesLinks
    

    type Props = {
        page:PageDetailsType
    }

    const ChildLine = ({page}:Props)=>{

        const {pageName} = page


        return<tr>
            <td>
            {pageName}
            </td>
            <td>
                <a target="_blank" href={`http://localhost:3000/${pageName}`}>
                    local
                </a>

            </td>
            <td>
                <a target="_blank" href={`https://puxhubtest.herokuapp.com/${pageName}`}>
                    remote-test
                </a>
                
            </td>
            <td>
                <a target="_blank" href={`http://www.puxhub.com/${pageName}`}>
                    live
                </a>
                
            </td>
        </tr>
    }