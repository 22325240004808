
    import "./index.scss"
    import React, { useContext, useEffect, useState } from "react"
import Modal from "@/generics/Modal"
import { SectionContext } from "../PuxMod"
import { NavSectionType, PageContextType } from "@/types/Context"
import { PageContext } from "@/pages/Standard/context"
import { SectionState } from "@/types/Section"

    const EditClickAction = ({text}:{text:string})=>{

        const [isOpen,setIsOpen] = useState(false)
        const sectionState:SectionState = useContext(SectionContext)
        const {fields,handleInputChange} = sectionState
        const pageContextValues:PageContextType = useContext(PageContext)
        const actionTypeOptions = [
            "link",
            "scrollToSection"
        ]

        const onSections = pageContextValues.navSections.filter((s:NavSectionType)=>(s.isOn&&s.inMenu))

        const sectionScrollOptions = onSections.map(s=>{
            return{_id:s.sectionId,name:(s.heading||s.sectionName||s.sectionForm)}
        })

        if(!isOpen){
            return<div onClick={()=>setIsOpen(true)} className="edit-click-action-open">{text}</div>
        }

        return(
            <Modal additionalClass="edit-click-action-modal">
                <>
                <select onChange={handleInputChange} name="actionType" value={fields.actionType||""}>
                    {actionTypeOptions.map((opt,index)=>{
                        return<option value={opt} key={index}>{opt}</option>
                    })}
                </select>
                {fields.actionType==="link"&&<div className="input-cont">
                    <label>
                        link url:
                    </label>
                    <input onChange={handleInputChange} name="linkUrl" value={fields.linkUrl||""}>
                    </input>
                </div>}
                {
                    fields.actionType==="scrollToSection"&&<div className="input-cont">
                                        <select onChange={handleInputChange} name="sectionScroll" value={fields.sectionScroll||""}>
                    {sectionScrollOptions.map((opt,index)=>{
                        return<option value={opt._id} key={index}>{opt.name}</option>
                    })}
                </select>
                    </div>
                }
                <button className="primary" onClick={()=>{setIsOpen(false)}}>Save</button>
                </>

            </Modal>
        )
    }
    export default EditClickAction
    
    
    