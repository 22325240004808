
    import useGetIds from "@/hooks/useGetIds";
import ColorGrid from "../ColorGrid";
import "./index.css"
    import React, { useEffect, useState } from "react"
import { getCurrentColors } from "@/api/colors";


type Props = {
    handleChange:(arg0:string)=>void
}


    const ActiveColors = ({handleChange}:Props)=>{

        const [{userId,pageId,sectionId}] = useGetIds()
        const [activeColors,setActiveColors] = useState<string[]>([])

        useEffect(()=>{
            const f = async ()=>{
                if(!pageId){
                    return
                }
                try{

                    const resp = await getCurrentColors(pageId)
                    if(resp.error){
                        throw new Error("bad call")
                    }
                    
                    setActiveColors(resp.colors)
                }catch(err){
                    
                    setActiveColors([])
                }
            }
            f()
        },[])


        return(
            <ColorGrid columns={5} colors={activeColors}
            handleChange={handleChange}
            
            ></ColorGrid>
        )
    }
    export default ActiveColors
    
    
    