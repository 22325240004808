
    import { DynamicAnyObject } from "@/types/generic"
import "./index.scss"
    import React, { FormEventHandler, useContext, useState } from "react"
import { venmoPayout } from "@/api/payoutRequest"
import { Context, ContextValueType } from "@/context"


    type Props = {
        tokenCount:number
    }

    const PayoutForm = ({tokenCount}:Props)=>{

        const context:ContextValueType = useContext(Context)
        const userId = context?.credentials?.user?._id
        const {tokens,setTokens} = context
        const [paymentMethod,setPaymentMethod] = useState("venmo")

        const includeAddress = paymentMethod==="mailed"

        const handleSubmit:FormEventHandler = async (e)=>{
            e.preventDefault()
            const formData = new FormData(e.target as HTMLFormElement);
            const dataObject:DynamicAnyObject = {}
            formData.forEach((value, key) => {
                dataObject[key] = value
            });
            const {amount,venmoId} = dataObject
            if(paymentMethod==="venmo"){
                const res = await venmoPayout({amount,userId,venmoId})
                if(!res.error){
                    setTokens(tokens-amount)
                }
            }
        }


        return(
            <div className="payout-form-cont">
                <form onSubmit={handleSubmit}>
                <div>
                <label>Payment Method</label>
                <select value={paymentMethod} onChange={(e)=>setPaymentMethod(e.target.value)}>
                    <option value={"venmo"}>Venmo</option>
                    <option value="mailed">Mailed Check</option>
                </select>
                </div>
                    <div>
                        <label>Payment Amount</label>
                        <input defaultValue={tokenCount} required={true}  name="amount" type="number"></input>
                    </div>
                    {paymentMethod==="venmo"&&<div>
                        <label>Venmo Username</label>
                        <input required={true} name="venmoId"></input>
                    </div>}
                    {includeAddress&&<>
                        <div>
                        <label>Address</label>
                        <input required={true}  name="address"></input>
                    </div>
                    <div>
                        <label>City</label>
                        <input required={true}  name="city"></input>
                    </div>
                    <div>
                        <label>State</label>
                        <input required={true}  name="state"></input>
                    </div>
                    <div>
                        <label>ZIP</label>
                        <input required={true}  name="zip" type="number"></input>
                    </div>
                    </>}
                    <button className="primary">Cash Out</button>
                </form>
            </div>
        )
    }
    export default PayoutForm
    
    
    