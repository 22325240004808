export default     {
    prompt:"Vertical Space?",
    field:"--verticalPadding",
    type:"style",
    default:"10px",
    options:[
        {value:"0px",text:"0"},        
        {value:"2px",text:"2"},        
        {value:"4px",text:"4"},
        {value:"6px",text:"6"},
        {value:"8px",text:"8"},
        {value:"10px",text:"10"},
        {value:"12px",text:"12"},
        {value:"14px",text:"14"},
        {value:"16px",text:"16"},
        {value:"18px",text:"18"},
        {value:"20px",text:"20"},
        {value:"24px",text:"24"},
        {value:"28px",text:"28"},
        {value:"12px",text:"12"},
        {value:"16px",text:"16"},
        {value:"20px",text:"20"},
        {value:"24px",text:"24"},
        {value:"28px",text:"28"}
    ]
}