
    import "./index.css"
    import React from "react"
import HomepageHeader from "@/layout/headers/HomepageHeader"
import Section from "@/generics/Section"
import InactivePageManagement from "@/components/InactivePageManagement"

    const UserSettings = ()=>{
        return(
            <>
            <HomepageHeader additionalClass="filled-in my-pages-header"></HomepageHeader>
            <main className="settings-main">
                <Section sectionName="settings">
                <h1>Settings</h1>
                    <InactivePageManagement></InactivePageManagement>
                </Section>
                

            </main>
            </>
        )
    }
    export default UserSettings
    
    
    