
    import "./index.scss"
    import React, { useState } from "react"

    const PageNotOn = ({pageName}:{pageName:string})=>{

        const [previewCode,setPreviewCode] = useState("")

        return(
            <main className="page-not-on">
            <div className="content">

            <div>Page is not on</div>
            <div>
                Enter Preview Code Here:
                <input value={previewCode} onChange={(e)=>setPreviewCode(e.target.value)}></input>
                <button onClick={()=>{window.location.replace(`${window.location.origin}/${pageName}?previewCode=${previewCode}`)}}>Submit</button>
            </div>
            </div>
            </main>
        )
    }
    export default PageNotOn
    
    
    