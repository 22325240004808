
    import "./index.scss"
    import React from "react"
import TikTokEmbed from "@/generics/TikTok"
import { SectionType } from "@/types/Section"
import useSectionState from "@/hooks/useSectionState"
import AddInputCont from "@/generics/AddInputCont"
import DeleteCircle from "@/generics/DeleteCircle"
import DeleteableCont from "@/generics/DeleteableCont"
import Section from "@/generics/Section"
import SectionHeading from "@/generics/SectionHeading"
import EditSectionHeader from "@/components/EditSectionHeader"
import PuxMod from "@/components/PuxMod"
import { parseURLToId } from "./helpers"

    const TikTok = ({section}:{section:SectionType})=>{

        const {stringArray,handleStringArrayAdd,handleStringArrayDelete,isEditing} = useSectionState({section,options:{enableAPI:true}})

        const covertString = (string:string)=>{
            const convertedString = parseURLToId(string)

            handleStringArrayAdd(convertedString)
        }


        return(
            <PuxMod section={section}>

            <>
            <div className="tiktok-grid">
                {stringArray.map((vid,index)=>{
                    return(
                        <DeleteableCont
                        _id={vid}
                        handleDelete={handleStringArrayDelete}
                        >
                            <TikTokEmbed videoId={vid}/>
                        </DeleteableCont>)

}
) 
}       
            </div>
            {!isEditing?<></>:<AddInputCont
            handleAdd={covertString}
            name="videoId"
            label="Add Tiktok Video's URL or Video ID Below"
            />}
            </>
            </PuxMod>

        )
    }
    export default TikTok
    
    
    