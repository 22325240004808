
    import { t } from "@/utils/stringFormatting"
import "./index.scss"
    import React from "react"

    type Props = {
        selected:string
        handleAdd:(arg:string)=>void
    }


    const AddSectionConfirm = ({selected,handleAdd}:Props)=>{
        return(
            <div className="add-section-confirm-cont">
                <button onClick={()=>handleAdd(selected)}>

                <h2>Add {t(selected)} Section</h2>
                </button>
            </div>
        )
    }
    export default AddSectionConfirm
    
    
    