import "./index.scss"
import Card from "@/generics/Card"
import { Link } from "react-router-dom"
import { PageDetailsType } from "@/types/Page"
import DeleteCircle from "@/generics/DeleteCircle"
import { deletePage } from "@/api/page"
import { Context, ContextValueType, ModalType } from "@/context"
import { MouseEventHandler, useContext, useState } from "react"
import Toggle from "@/generics/Toggle"
import { handleTurnOn } from "./helpers"
import { returnStatus } from "@/utils/pageStatus"
import { t } from "@/utils/stringFormatting"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShare } from '@fortawesome/free-solid-svg-icons';
import route from "@/utils/route"
import { placeholderImage } from "@/utils/placeholderValues"
import Modal from "@/generics/Modal"
import AssistanceModal from "../AssistanceModal"

type Props = {
    card:PageDetailsType
    cardsUseState:[PageDetailsType[],React.Dispatch<PageDetailsType[]>],
    setModal:React.Dispatch<any>,
    setShareModal:React.Dispatch<PageDetailsType>
    handleStatusButton:MouseEventHandler<HTMLButtonElement>
    setSubState:React.Dispatch<boolean>
}

const PageCard = ({card,cardsUseState,setModal,handleStatusButton,setShareModal,setSubState}:Props)=>{
    const appAssistanceStatus = process.env.APP_ASSISTANCE_STATUS
    const modifiedImageURL = (card?.thumbnail?.indexOf("docUpload:")===0?`${route('api/upload')}/${card?.thumbnail.replace("docUpload:","")}`:card?.thumbnail)||""
    const [modalOpen,setModalOpen] = useState(false)
    const [assistanceOn,setAssistanceOn] = useState(card.assisted)
    const handleSubscribe = (e:React.MouseEvent<HTMLButtonElement>)=>{
        setSubState(false)
        handleStatusButton(e)
    }

    const handleUnSubscribe = (e:React.MouseEvent<HTMLButtonElement>)=>{
        setSubState(true)
        handleStatusButton(e)
    }
    
    if(modalOpen){
        return<Modal
        setIsModalOpen={setModalOpen}
        additionalClass="assistance-modal"
        >
        <AssistanceModal
        pageId={card._id}
        setAssistanceOn={setAssistanceOn}
        assistanceOn={assistanceOn||false}
        ></AssistanceModal>
    </Modal>
    }

    return (
        <Card
        additionalClass={assistanceOn?"assisted":""}
        
        onClick={()=>{}}>
                        <Link to={`/${card.pageName}`}>
            <h1 className="website-name">{card.pageName}</h1>
                        </Link>
            <img className="card-image" src={modifiedImageURL||placeholderImage}></img>
            <div className="flex-apart">
            <span><strong>
                ({card.template})
            </strong>
            </span>
            <Link to={`/${card.pageName}`}>
                <button>Edit Page</button>
            </Link>
            </div>
        <div className="status-cont flex-apart">
            <span data-tooltip="Subscribe to make live" title="A subscription must be purchased for a page to make it live to the public." className={card.status.isPaidFor?"":"tooltip red"} style={{color:card.status.isPaidFor?'var(--green-text)':'var(--red-text)'}}>
            Status: {t(returnStatus(card.status))}
            </span>
            {card.status.isPaidFor?<button className="" onClick={handleUnSubscribe}>Settings</button>:<button className="primary" onClick={handleSubscribe}>Subscribe</button>}
        </div>
        <div onClick={()=>setShareModal(card)} className="share-icon-cont"><span>
            Share
            </span>
            <FontAwesomeIcon icon={faShare} />
            </div>
        {appAssistanceStatus==="on"?<a className="get-assistance-anchor"
        onClick={()=>setModalOpen(true)}
        >Get Assistance</a>:<></>}
        </Card>
    )
}

export default PageCard


