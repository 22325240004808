import { SectionType } from "@/types/Section";
import { DynamicObject } from "@/types/generic";
import { log } from "console";

export const getSectionStyle = (section:SectionType)=>{
    const testStyle = getTestStyle()
    if(!testStyle){
        return section.style
    }
    const addedString = testStyle.replaceAll("cc",":#").replaceAll("yy",":").replaceAll("zz"," ")
    const newStyleString = removeDoubles(section.style+addedString)
    return (newStyleString)
}


export const getTestStyle = ()=>{
    const params = new URLSearchParams(window.location.search)
    return params.get("testStyle")
}

export const removeDoubles = (styleString:string)=>{
    let newStyleString = ""
    const styleAndClassArray = styleString.split(" ")
    const classObject:DynamicObject = {}
    styleAndClassArray.forEach(elem=>{
        if(elem.includes(":")){
            newStyleString+=` ${elem}`
        }
        if(elem.includes("xx")){
            const [key,value] = elem.split("xx")
            classObject[key] = value;
        }
    })
    for (let key in classObject){
        const classString = `${key}xx${classObject[key]}`
        newStyleString+=` ${classString}`
    }
    return newStyleString
}


