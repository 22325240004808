
    import "./index.scss"
    import React, { useState, useEffect } from "react";

    type Tweet = {
        text:string
        id:string
    }

    const TwitterFeed = ({userName}:{userName:string}) => {
      useEffect(() => {
        const pastScript = document.querySelector("#twitscript")
        if(pastScript){
          document.body.removeChild(pastScript)
        }
        const script = document.createElement("script");
        script.id = "twitscript"
        script.src = "https://platform.twitter.com/widgets.js";
        script.async = true;
        document.body.appendChild(script);
      }, [userName]);
    
      return (
        <div className="twitter-timeline-cont">
          <a className="twitter-timeline" data-width="800" data-height="800"  data-theme="light" data-link-color="#1DA1F2" href={"https://twitter.com/"+userName}>Tweets by {userName}</a>
        </div>
      );
    };
    
    export default TwitterFeed;
    