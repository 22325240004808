import { uuid } from "./uuid";

type CountProps = {
    html:string
    selector:string
  }

export const countNumberOfElements = ({html,selector}:CountProps)=>{
    const tempEl = document.createElement("div");
    tempEl.innerHTML = html;

  // Find all elements of the specified type
    const elements = tempEl.querySelectorAll(selector);
    const count = elements.length
    tempEl.remove()
    return count
}

function removeAllDescendants(element:HTMLElement) {
    while (element.firstChild) {
    if(element.firstChild instanceof HTMLElement){

        removeAllDescendants(element.firstChild);
    }
      element.removeChild(element.firstChild);
    }
}

export const cleanUp = (element:HTMLElement)=>{
    removeAllDescendants(element)
    element.remove()
}

type ParentHasClassProps = {
    element:HTMLElement
    className:string
}

export function parentHasClass({element, className}:ParentHasClassProps) {
    // Get the parent element
    const parentElement = element.parentNode;
  
    // Check if the parent element exists and has the specified class
    if (parentElement && parentElement instanceof HTMLElement && parentElement.classList.contains(className)) {
      return true;
    } else {
      return false;
    }
  }

type WrapElementProps = {
    element:HTMLElement
    containerClassName:string
}

export  function wrapElement({element,containerClassName}:WrapElementProps) {
    // Get the parent element
    const parentElement = element.parentNode;
    if(!(parentElement instanceof HTMLElement)){
        return
    }
  
    // Create a new container element
    const containerElement = document.createElement("div");
    containerElement.classList.add(containerClassName)
    // Insert the new container element before the original element
    parentElement.insertBefore(containerElement, element);
  
    // Move the original element into the new container element
    containerElement.appendChild(element);
  
    // Return the new container element
    return containerElement;
  }

  

  type AddImageProps = {
    html:string
    imageSrc:string
    currentDiv:null|HTMLDivElement
    getDropTarget:any
  }


  export const robustSelect = ({selectorString,element}:{selectorString:string,element:HTMLElement})=>{
      let matches:HTMLElement[] = []
      const selectors = selectorString.split(",")
      for (let selector of selectors){
        const subMatches = element.querySelectorAll(selector) as NodeListOf<HTMLElement>
        matches = [...matches,...Array.from(subMatches)]
      }
      return matches
  }

  export const addImageToElement = ({html,imageSrc,currentDiv,getDropTarget}:AddImageProps)=>{
    const container = document.querySelector("div.live-content") as HTMLElement;
    if(!container){
        return
    }

    const paragraphs = robustSelect({selectorString:"div,p,span",element:container})
    const id = uuid()
    const lastparagraph =  currentDiv||(paragraphs.length>0?paragraphs[paragraphs.length-1]:container)
        
    lastparagraph.innerHTML = `
    ${lastparagraph.innerHTML}
    <div draggable="true" data-image-id=${id} class="image-editor-cont">
    <img draggable="false" id=${id} src="${imageSrc}"></img>
    <button class="delete-circle" data-image-id=${id} >x</button>
    <div class="image-buttons-cont">
    <button class="image-align-btn" data-image-id=${id} data-class-add="left">Left</button>
    <button class="image-align-btn" data-image-id=${id} data-class-add="full">Full</button>
    <button class="image-align-btn" data-image-id=${id} data-class-add="right">Right</button>
    </div>
    </div>
    `

    const imageCont = lastparagraph.querySelector(".image-editor-cont")

    const handleDragEnd = (e:any)=>{
      const dropTarget = getDropTarget()
      return
      addImageToElement({html,imageSrc,currentDiv:dropTarget,getDropTarget})
    }
    imageCont?.addEventListener("dragend",handleDragEnd)

    return container.innerHTML
  }


  export const replaceImageWithWrapper = ()=>{

  }

  export const createDummyParent = ({html}:{html:string})=>{
      const dummyParent = document.createElement("div")
      dummyParent.innerHTML = html;
      return dummyParent
  }

  export const toggleClass = ({newClass,classOptions,element}:{newClass:string,classOptions:string[],element:HTMLElement})=>{
      const classList:string[] = Array.from(element.classList);
      const newClassList = classList.filter((classItem)=>{
          return (!classOptions.includes(classItem))
      })
      newClassList.push(newClass)
      element.className = newClassList.join(" ")
  }

export const createElementFromString = (string:string,datasetIndex:string)=>{
  const parser = new DOMParser();
  const doc = parser.parseFromString(string, 'text/html');
  const myElement = doc.querySelector(`[data-index="${datasetIndex}"]`);
  return myElement
}

export const getInnerHTMLFromString = ({html}:{html:string})=>{
  const parent = createDummyParent({html:html.replaceAll("$<$br$>$","<br>")})
  const subsectionsCont = parent.querySelector("subsections")
  return subsectionsCont?.innerHTML||""  
}

export const getInnerHTML = ({html,selector}:{html:string,selector:string})=>{
  const parent = createDummyParent({html:html.replaceAll("$<$br$>$","<br>")})
  const subsectionsCont = parent.querySelector(selector)
  return subsectionsCont?.innerHTML.replaceAll("\n","")||""  
}

export const getChildrenAsHTML = ({html,selector}:{html:string,selector:string})=>{
  const parent = createDummyParent({html:html.replaceAll("$<$br$>$","<br>")})
  const element = parent.querySelector(selector)
  return Array.from(element?.children||[]).map(c=>c.outerHTML)
}


export const getClassName = ({html}:{html:string})=>{
  const parent = createDummyParent({html})
  const element = parent.querySelector("subsections")
  return element?.className||""  
}

export const getElementObjectsFromString = ({html,selector}:{html:string,selector:string})=>{
  const parent = createDummyParent({html})
  const matchingElements = Array.from(parent.querySelectorAll(selector))
  const elementObjects = matchingElements.map((element)=>{
  const className = element.className
  const elementString = element.outerHTML
  const innerHTML = element.innerHTML
    return{
      element,className,elementString,innerHTML
    }
  })
  return elementObjects
}