import backgroundOn from "../fields/backgroundOn";
import casing from "../fields/casing";
import font from "../fields/font";
import headingFont from "../fields/headingFont";
import headingSize from "../fields/headingSize";
import headingSizeNumber from "../fields/headingSizeNumber";
import imageScale from "../fields/imageHeight";
import linkHover from "../fields/linkHover";
import linkSize from "../fields/linkSize";
import logoPosition from "../fields/logoPosition";
import maxWidth from "../fields/maxWidth";
import navAlignment from "../fields/navAlignment";
import sectionBackground from "../fields/sectionBackground";
import textColor from "../fields/textColor";
import underlineColor from "../fields/underlineColor";
import verticalPadding from "../fields/verticalPadding";


export default [
    logoPosition,
    headingSizeNumber,
    navAlignment,
    textColor,
    backgroundOn,
    sectionBackground,
    font,
    headingFont,
    casing,
    verticalPadding,
    linkSize,
    linkHover,
    underlineColor,
    imageScale,
    maxWidth
]