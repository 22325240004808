export default     {
    prompt:"Link Size?",
    field:"--linkSize",
    type:"style",
    default:"16px",
    options:[
        {value:"inherit",text:"same"},
        {value:"12px",text:"12"},
        {value:"16px",text:"16"},
        {value:"20px",text:"20"},
        {value:"24px",text:"24"},
        {value:"28px",text:"28"},
        {value:"32px",text:"32"}
    ]
}