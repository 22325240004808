const sectionTypeList = [
    "Agreement",
    "Blog",
    "Bio",
    "Button",
    "Calendar",
    "Carousel",
    "Comments",
    "Contact Form",
    "Custom Form",
    "Embed",
    "Podcast Episodes",
    "Events",
    "Hero",
    "ImageAndText",
    "Image Grid",
    "Instagram",
    "Linked Image Grid",
    "Links",
    "Mailing List",
    "Other",
    "Reviews",
    "Socials",
    "Sponsors",
    "Subscribe",
    "Team",
    "Text",
    "Three Items",
    "Two Items",
    "TikTok",
    "Twitter",
    "Video",
    "Videos",
]

export default sectionTypeList