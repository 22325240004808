import "@/types/declarations/global.d.ts"

    import "./index.scss"
    import React,{useContext, useEffect} from "react"
import { Context, ContextValueType } from "@/context"
import { LoginAPISuccessResponse, loginWithGoogle, registerWithGoogle } from "@/api/googleAuth"
import { APIFailure } from "@/types/API"

    const client_id = process.env.REACT_APP_GOOGLE_NEW_CLIENT_ID
    const GIS = ()=>{

        const {googleCredentials,setGoogleCredentials,credentials,setCredentials,setModal}:ContextValueType = useContext(Context)



        const handleCallBackResponse = async (response:{credential:string})=>{
            const token = response.credential
            const data:(LoginAPISuccessResponse | APIFailure) = await loginWithGoogle(token)
            if("error" in data && data.error){
                if(data.message==="no user"){

                    const registerData = await registerWithGoogle(token)
                    if(!("error" in registerData)){
                        setCredentials(registerData)
                        window.location.href = "/"
                        setModal({name:"none",state:"none"})
                    }
                    
                }
                // setErrors([data.error])
                return
            }else{
                setCredentials(data)
                window.location.href = "/"
            }
        }

        useEffect(()=>{
            // global google
            window.google.accounts.id.initialize({
                client_id,
                callback:handleCallBackResponse
            })
            window.google.accounts.id.renderButton(
                document.querySelector("#sign-in-div"),
                {theme:"outline",size:"medium"}
            )
        },[])

        return(
            <>
            <div id="sign-in-div">
            </div>
            </>
        )
    }
    export default GIS
    
    
    