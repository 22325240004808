import { DynamicObject, DynamicObjectWithNumber } from "@/types/generic"
import { LayoutElementType } from "@/types/layout"
import { CSSLineType } from "../CSSEditor"

const styleKeys = ["--imageHeight","textAlign","--underlineColor","--header-name-fd","color","background","--overlayOpacity","textTransform","--headingSize","--font-size","--flex-direction","--verticalPadding","--linkSize","--textContBackground","--headingColor","lineHeight","--icon-color","--color","imageHeight","--maxWidth"]

export const makeStyleString = (object:DynamicObjectWithNumber)=>{
    let str = ""
    for(let key in object){
      if(key.includes("//")){
        str+=`${key.replace("//","**")}**${object[key]}`
      }
      else if(!styleKeys.includes(key)){
        str+=`${key}xx${object[key]} `
      }
      else{
        str+=`${key}:${object[key]} `
      }

    }
    return str
}

export const getFieldName = (layout:LayoutElementType)=>{
    if(!layout.child||layout.property){
      return layout.field
    }

    return `${layout.field}/${layout.child}`
}

export const validateCSSArray = ({cssArray}:{cssArray:CSSLineType[]})=>{
    const res = cssArray.filter((field)=>{
      const {selector,property,value} = field
      if(selector===""||property===""||value===""){
        return false
      }
      return true
    })
    return res
}