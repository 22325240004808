import { SectionContext } from '@/components/PuxMod';
import { PageContext } from '@/pages/Standard/context';
import { PageContextType } from '@/types/Context';
import { removeNthElement } from '@/utils/arrays';
import { isMobile } from '@/utils/screen';
import React, { useState, useEffect, useContext, useRef } from 'react';
import DeleteCircle from '../DeleteCircle';
import { SectionState } from '@/types/Section';
import "./index.scss"
import { addMasonryWidth } from './helpers';
import ArrowButton from '../buttons/ArrowButton';

export interface Image {
  url: string;
  width: number;
  height: number;
  ratio:number;
  newWidth?:number
}


const Masonry = ()=> {
const {isEditing}:PageContextType = useContext(PageContext)
const sectionC:SectionState = useContext(SectionContext)
const {stringArray,setStringArray}:SectionState = sectionC
const images = stringArray
const [loadedImages, setLoadedImages] = useState<Image[]>([]);
const gridRef = useRef<HTMLDivElement>(null);

useEffect(() => {
  const loadImage = (url: string) =>
    new Promise<Image>((resolve) => {
      const img = new Image();
      img.onload = () => {
        resolve({ url, width: img.width, height: img.height,ratio:(img.width/img.height) });
      };
      img.src = url;
    });

  Promise.all(images.map(loadImage)).then((images) => {
    setNewWidths(images)

  });
}, [images]);

const setNewWidths = (images:Image[])=>{
  const imagesWithNewWidths = addMasonryWidth(images)
  setLoadedImages(imagesWithNewWidths);
}

useEffect(() => {

  const handleResize = ()=>{
    setNewWidths(loadedImages)
  }

  window.addEventListener('resize', handleResize);

  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, []);

const handleDelete = (index:number)=>{
    const newArray = [...stringArray]
    removeNthElement(newArray,index)
    setStringArray(newArray)
}


const handleClickLeft = (currentIndex:number)=>{
  const newStringArray = [...stringArray]
  newStringArray[currentIndex-1] = stringArray[currentIndex]
  newStringArray[currentIndex] = stringArray[currentIndex-1]
  setStringArray(newStringArray)
}


const handleClickRight = (currentIndex:number)=>{
  const newStringArray = [...stringArray]
  newStringArray[currentIndex+1] = stringArray[currentIndex]
  newStringArray[currentIndex] = stringArray[currentIndex+1]
  setStringArray(newStringArray)
}

if(isMobile()){
  return<div className='image-flow'>
      {images.map((image:any)=><img className='mobile-image' src={image} ></img>)}
  </div>
}

return (
  <div
    ref={gridRef} className='image-flow'
  >
    {loadedImages.map((image,index) => (
      (
      <div
      key={index}
        style={{
          width:`${image.newWidth}px`,
          height:`${(image.newWidth||1)/image.ratio}px`
        }}
      >
      <img
        key={image.url}
        src={image.url}
        alt=""
        
      />
    {isEditing&&<>
      {index!==0&&<ArrowButton handleClick={()=>handleClickLeft(index)} direction="left"></ArrowButton>}
      {index!==(loadedImages.length-1)&&<ArrowButton handleClick={()=>handleClickRight(index)} direction="right"></ArrowButton>}
    </>}
      <div className='pseudo-padding'></div>
{isEditing&&      <DeleteCircle
      within={true}
      _id={index.toString()}
      handleDelete={()=>handleDelete(index)}
      ></DeleteCircle>}
      </div>
      )
    ))}
  </div>
);
}

export default Masonry