const allTemplates = [
    "comedian","podcast","blank","discjockey"
]

const enabledTemplates = [
    "comedian","podcast","blank"
]

const getAppropriateTemplates = ()=>{
    if(window.location.origin.includes("localhost")){
        return allTemplates
    }
    return enabledTemplates
}

const currentTypes = getAppropriateTemplates()

export default currentTypes

