
    import { findLongestString, getTextWidth } from "@/components/FullFlexTextarea/helpers";
import "./index.scss"
    import React, { ChangeEvent, CSSProperties, TextareaHTMLAttributes, useContext, useEffect, useRef, useState } from "react"
import { SubsectionContext } from "@/components/SubPuxMod";
import useSkipFirstMutation from "@/hooks/useSkipFirstMutation";
import { getDimensions } from "./helpers";
import maxWidth from "@/utils/sectionEditorUtils/fields/maxWidth";
import { loggo } from "@/utils/dev";
import { SectionContext } from "@/components/PuxMod";
import { SectionState } from "@/types/Section";
import { PageContextType } from "@/types/Context";
import { PageContext } from "@/pages/Standard/context";

    type Dimensions= {
        width:string
        height:string
    }

    const MaxWidthAutoText = (props:TextareaHTMLAttributes<HTMLTextAreaElement>)=>{

        const {bodyWidth}:PageContextType = useContext(PageContext)
        const sectionState:SectionState = useContext(SectionContext)
        const sectionStyleString = sectionState.style
        const cssCompletelyLoaded = sectionState.cssUpToDate
        const [dimensions,setDimensions] = useState<Dimensions>({width:"30px",height:"40px"})
        const {onChange,value,name,className,id} = props
        const textAreaRef = useRef<HTMLTextAreaElement>(null);
        const [text, setText] = useState((value as string).replaceAll("<br>","\n"));
        const [cursorPosition,setCursorPosition] = useState(text.length)
        const lines = text.split("\n")
        const isDisplayBlock = props.style?.display==="block"
        const padding = (props as any)["data-padding"]
        const style = {...props.style,
            width:dimensions.width,
            height:dimensions.height
        }
        const classString = props.className



        const maxWidth = props?.style?.maxWidth?.toString()||"3000px"
        useSkipFirstMutation(()=>{
            setText((value as string).replaceAll("<br>","\n"))
        },[id])

        useEffect(()=>{
            setRefsPosition(cursorPosition)
        },[cursorPosition])

        const setRefsPosition = (position: number) => {
            if (textAreaRef.current) {
              textAreaRef.current.setSelectionRange(position, position);
              textAreaRef.current.focus();
            }
        };

        const handleChange = (e:ChangeEvent<(HTMLTextAreaElement)>)=>{
            const cursorPosition = e.target.selectionStart;
            setText(e.target.value);
            setCursorPosition(cursorPosition)
            if (props.onChange) {
                props.onChange(e);
            }
            if(!e||!onChange){
                return
            }
            e.target.value = e.target.value.split("\n").join("<br>")
            onChange(e)
        }
        useEffect(()=>{
            if(!textAreaRef.current){
                return
            }

            const maxWidthNumber = parseInt(maxWidth.replace("px",""))

            const dimensionsArgs = {element:textAreaRef.current,lines,maxWidth:maxWidthNumber,padding}
            const calcedDimensions = getDimensions(dimensionsArgs)
            let width = (parseFloat(calcedDimensions.width.replace("px",""))+(padding))+"px"
            let height = (parseFloat(calcedDimensions.height.replace("px",""))+(padding))+"px"

            if(!calcedDimensions){
                return
            }
            setDimensions({width,height})
        },[text,sectionStyleString,cssCompletelyLoaded,bodyWidth])

        return (
                <textarea className={className+" full-flex-auto max-auto-textarea p"}
                    name={name}
                    value={(text)}
                    onChange={handleChange}
                    ref={textAreaRef}
                    style={style}
                    rows={1}
                />
        );
    }
    export default MaxWidthAutoText
    
    
    