
    import { useDebounce } from "use-debounce"
import { LayoutInputObject } from "../helpers"
import "./index.css"
    import React, { useEffect, useState } from "react"


    type Props = {
        field:string
        label:string
        inputObject:LayoutInputObject
        handleInputChange:({field,value}:{field:string,value:any})=>void
    }


    

    const NumberInputRow = ({field,label,inputObject,handleInputChange}:Props)=>{
        const {value,unit} = inputObject
        const [inputValue,setInputValue] = useState(value)
        const [delayedValue] = useDebounce(inputValue,500)
        
        useEffect(()=>{
            if(delayedValue===""){
                return
            }
            const value = unit?((delayedValue||0)+unit):delayedValue
            if((typeof delayedValue!=="number")&&(typeof delayedValue!=="string")){
                return
            }
            handleInputChange({field,value})
        },[delayedValue])

        return(
            <div className="input-row">
                <label>{label}</label>
            <div className="number-and-unit">
                <input type="number" onChange={(e)=>setInputValue(e.target.value)} value={inputValue}></input>
                {unit&&<span>{unit}</span>}
            </div>
            </div>
        )
    }
    export default NumberInputRow
    
    
    