
    import "./index.css"
    import React, { useEffect, useState } from "react"

    type Props = {
        string:string
        msSpeed?:number
        doneTypingHandler?:()=>void
    }

    const Typer = ({string,msSpeed,doneTypingHandler}:Props)=>{

        const [visibleLetters,setVisibleLetters] = useState("")
        const length = visibleLetters.length

        useEffect(()=>{
            if(visibleLetters===string){
                setTimeout(()=>{
                    if(doneTypingHandler){
                        doneTypingHandler()
                    }
                },((msSpeed||100)*3))
                return
            }
            setTimeout(()=>{
                const current = string.substring(0,(length+1))
                setVisibleLetters(current)
            },(msSpeed||100))
        },[visibleLetters])

        return(
<>
{visibleLetters}
</>
        )
    }
    export default Typer
    
    
    