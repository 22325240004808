export default {prompt:"",
field:"textHor",
type:"class",
default:"center",
condition:{field:"stack",value:"overlay"},
options:[
    {value:"left",text:"Left"},
    {value:"center",text:"Center"},
    {value:"right",text:"Right"}
]
}