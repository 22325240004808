
    import "./index.css"
    import { createContext } from "react"
import useSubsectionState from "@/hooks/useSubsectionState"
import { GenericSubsectionDocument } from "@/types/Documents"
import { Children } from "../TextEditorHeader/JSX"

    type SubPuxModWithinContextProps = {
        subsection:GenericSubsectionDocument
        children:Children
    }

    const SubPuxModWithinContext = ({subsection,children}:SubPuxModWithinContextProps)=>{
        return <>{children}</>
    }

    export const SubsectionContext = createContext({})


    const SubPuxMod = ({subsection,children}:SubPuxModWithinContextProps)=>{
        const sectionState = useSubsectionState({subsection,options:{enableAPI:true}})
        
    return(
        <SubsectionContext.Provider value={sectionState}>
        <SubPuxModWithinContext subsection={subsection}>
            {children}
        </SubPuxModWithinContext>
        </SubsectionContext.Provider>)

    }
    export default SubPuxMod
    
    
    