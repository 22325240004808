
    import { Children } from "@/types/JSX"
import getSlug from "@/utils/getSlug"

    type Props = {
        children:Children
    }

    const Experimental = ({children}:Props)=>{

        const experimentalFeatures = process.env.EXPERIMENTAL
        const query = window.location.search.substring(1)
        let origin = window.location.origin
        const isExperimental = query==="experimental"||(origin.includes("localhost")&&experimentalFeatures==="on")

        if(!isExperimental){
            return<></>
        }

        return(
            <>
            {children}
            </>
        )
    }
    export default Experimental
    
    
    