import { ConfirmationType, Context, ContextValueType } from "@/context"
import { useContext } from "react"
import Modal from "../Modal"
import "./index.css"

type Props = {
    callback:Function,
    cancelCallback:Function,
    message:string
}

const Confirmation = ({additionalClass}:{additionalClass?:string})=>{
    const {confirmation,setLoading}:ContextValueType = useContext(Context)
    
    
    if(!confirmation){
        return <></>
    }
    const {cancelCallback,message,callback,loadingMessage}:ConfirmationType = confirmation
    const handleCancel = ()=>{
        cancelCallback()
    }
    
    const handleConfirm = async ()=>{

        setLoading({isLoading:true,message:loadingMessage})
        await callback()
        setLoading({isLoading:false})
    }
    return (
        <Modal additionalClass={`confirmation-modal ${additionalClass}`}>
        <div className={`confirmation ${additionalClass}`}>
            {message}
            <div className="btn-cont">
                <button className="primary" onClick={handleConfirm}>Confirm</button>
                <button className="danger" onClick={handleCancel}>Cancel</button>
            </div>
        </div>
        </Modal>
    )
}

export default Confirmation