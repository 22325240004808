export default     {
    prompt:"Button Hover?",
    field:"buttonHover",
    type:"childClass",
    child:".e-button",
    default:"hover-set-none",
    options:[
        {value:"hover-set-none",text:"None"},
        {value:"hover-set-sandwich",text:"Sandwich"},
        {value:"hover-set-slide",text:"Slide"},
        {value:"hover-set-bounce",text:"Bounce"}
    ]
}