
    import "./index.scss"
    import React from "react"
import Modal from "../Modal"
import Socials from "../Socials"
import { SocialsType } from "@/types/Socials"

type Props = {
    pageName:string,
    setModal:React.Dispatch<any>
}

    const ShareModal = ({pageName,setModal}:Props)=>{

        const socials:SocialsType = {facebook:`https://www.facebook.com/sharer/sharer.php?u=https://puxhub.com/${pageName}`,
        twitter:`https://twitter.com/share?url=https://puxhub.com/${pageName}`
    }
        const handleCopy = ()=>{
            navigator.clipboard.writeText(`https://puxhub.com/${pageName}`)
        }

        return(
            <Modal setIsModalOpen={setModal} additionalClass="share-modal">
                <>
            <h1>Share</h1>
            <hr></hr>
            <h5>via</h5>
            <Socials size={35} socials={socials}></Socials>
            <h5>Or copy link:</h5>
            <div className="copy-cont">
            <input value={`https://puxhub.com/${pageName}`}></input><button
            onClick={handleCopy}
            >Copy</button>
            </div>
                </>
            </Modal>
        )
    }
    export default ShareModal
    
    
    