
export type HelpTopic = {
    title:string
    subtitle:string
    html:string
    _id:string

}
const helpTopics: HelpTopic[] = [
  {
    _id: "topic_1",
    title: "Adding Your Custom Domain",
    subtitle: "Instructions for PuxHub settings and Domain Name Service",
    html: "<p>If you have just subscribed for your site, its url is www.puxhub.com/{your-site-name}. It's likely you want your own custom domain for the sake of marketing and professionalism and luckily PuxHub makes that possible. </p>   <p>The first step is purchasing a domain. Puxhub is not a domain name service, so you will have to make that purchase through another site (e.g. GoDaddy, Google Domains, etc). We recommend using a more reputable, modern-looking DNS, because they typically are more intuitive to use and reliable.</p> <p>Once you have purchased the domain. You will need to add a DNS record with the following for fields: </p><ul><li>Host Name: &nbsp; *</li><li>Type: CNAME</li><li>TTL: Any value works (recommend 600)</li><li>Data: www.puxhub.com</li></ul><br><p>The final step is completed on your Puxhub dashboard (the page that appears when you first log in). Find the card for your website and click the settings button. Paste the domain (for example 'www.mysite.com') into the appropriate input and click add domain. </p><p>After this, you have to wait (and by that I mean, go about the rest of your day). It takes a while for the internet to propagate your new domain record. If after a few hours, it still does not work (even after trying it on a different device), reach out to me at joechristiansonwebdev@gmail.com, and we can set up a zoom or phone call to handle it. As always, tech support is completely free.</p>"
  },
  // {
  //   _id: "topic_1",
  //   title: "Adding Your Custom Domain",
  //   subtitle: "Instructions for PuxHub settings and Domain Name Service",
  //   html: "<p>If you have just subscribed for your site, its url is www.puxhub.com/{your-site-name}. It's likely you want your own custom domain for the sake of marketing and professionalism and luckily PuxHub makes that possible. </p>   <p>The first step is purchasing a domain. Puxhub is not a domain name service, so you will have to make that purchase through another site (e.g. GoDaddy, Google Domains, etc). We recommend using a more reputable, modern-looking DNS, because they typically are more intuitive to use and reliable.</p> <p>Once you have purchased the domain. You will need to add a DNS record with the following for fields: </p><ul><li>Host Name: &nbsp; *</li><li>Type: CNAME</li><li>TTL: Any value works (recommend 600)</li><li>Data: www.puxhub.com</li></ul><br><p>The final step is completed on your Puxhub dashboard (the page that appears when you first log in). Find the card for your website and click the settings button. Paste the domain (for example 'www.mysite.com') into the appropriate input and click add domain. </p><p>After this, you have to wait (and by that I mean, go about the rest of your day). It takes a while for the internet to propagate your new domain record. If after a few hours, it still does not work (even after trying it on a different device), reach out to me at joechristiansonwebdev@gmail.com, and we can set up a zoom or phone call to handle it. As always, tech support is completely free.</p>"
  // },
  // {
  //   _id: "topic_3",
  //   title: "Adding Your Custom Domain",
  //   subtitle: "Instructions for PuxHub settings and Domain Name Service",
  //   html: "<p>If you have just subscribed for your site, its url is www.puxhub.com/{your-site-name}. It's likely you want your own custom domain for the sake of marketing and professionalism and luckily PuxHub makes that possible. </p>   <p>The first step is purchasing a domain. Puxhub is not a domain name service, so you will have to make that purchase through another site (e.g. GoDaddy, Google Domains, etc). We recommend using a more reputable, modern-looking DNS, because they typically are more intuitive to use and reliable.</p> <p>Once you have purchased the domain. You will need to add a DNS record with the following for fields: </p><ul><li>Host Name: &nbsp; *</li><li>Type: CNAME</li><li>TTL: Any value works (recommend 600)</li><li>Data: www.puxhub.com</li></ul><br><p>The final step is completed on your Puxhub dashboard (the page that appears when you first log in). Find the card for your website and click the settings button. Paste the domain (for example 'www.mysite.com') into the appropriate input and click add domain. </p><p>After this, you have to wait (and by that I mean, go about the rest of your day). It takes a while for the internet to propagate your new domain record. If after a few hours, it still does not work (even after trying it on a different device), reach out to me at joechristiansonwebdev@gmail.com, and we can set up a zoom or phone call to handle it. As always, tech support is completely free.</p>"
  // }
]


const getHelpTopics = ()=>{
    return helpTopics
}


export default getHelpTopics