import { SectionRequest } from "@/types/SectionRequest"

export const getUserStatus = ({userId,job}:{userId:string|null,job:SectionRequest})=>{
    
    if(!userId){
       return "not logged in"
    }
    if(userId===job.userId){
        return "same"
    }
    if(Array.isArray(job?.developers)&&job.developers.includes(userId)){
        return "active"
    }
    return "open"
}