
    import { makeNumberArray } from "./helpers"
import "./index.scss"
    import React from "react"

    type Props = {
        length:number
        currentIndex:number
        setCurrentIndex:React.Dispatch<number>
    }

    const NumberBar = ({length,currentIndex,setCurrentIndex}:Props)=>{

        const numberArray = makeNumberArray(length)

        const handleClick = (index:number)=>{
            setCurrentIndex(index)
        }

        return(
            <div className="number-bar">
                {numberArray.map((n,i)=>{
                    if(i===currentIndex){
                        return <div className="current-index">{i+1}</div>
                    }
                    return<button onClick={()=>handleClick(i)}>{i+1}</button>
                })}
            </div>
        )
    }
    export default NumberBar
    
    
    