const getTimeFromDate = (date:string)=>{
    const split = date.split("T")
    if(!split[1]){
        return ""
    }
    const time = split[1].substring(0,5)
    
    return time
}

export const getDateFromString = (date:string)=>{
    const split = date.split("T")
    
    if(!split[1]){
        return split[0]
    }
    return split[0]
}

export default getTimeFromDate