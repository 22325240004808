export default ()=>{
    const urlParams = new URLSearchParams(window.location.search);
    const previewCode = urlParams.get("previewCode")
    if (previewCode===getCode()){
        return true
    }return false
}

const getCode = ()=>{

    return "34"

    const date = new Date()
    const year = date.getFullYear().toString().slice(-2);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const dateNumber = parseInt(year+month+day);
    const code = dateNumber%99
    return code.toString()
}

