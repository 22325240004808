
    import "./index.css"
    import React from "react"
import Modal from "@/generics/Modal"
import { handleAddPictureForm } from "../handlers"
import { HandleAddPictureArgs } from "../handlers"
    type Props = {
        isSection:boolean|undefined
        sectionId:string
        setIsDropOpen:React.Dispatch<boolean>
        credentials:Credential
        field:string
        route:string
        setIsModalOpen:React.Dispatch<boolean>
        setImageUrl:React.Dispatch<string>
        handleEdit:(arg0:string)=>void
        isLayoutElement:boolean|undefined
        handleAddPictureForm:(e:React.FormEvent<HTMLFormElement>,arg1:HandleAddPictureArgs)=>void
    }

    const ChooseAnImageFile = ({isSection,sectionId,setIsDropOpen,credentials,field,route,setIsModalOpen,setImageUrl,handleEdit,isLayoutElement}:Props)=>{

        return(
                <Modal
                modalTitle="Choose a File"
                setIsModalOpen={setIsModalOpen}
                additionalClass="file-chooser-modal"
                >
                <form onSubmit={(e)=>handleAddPictureForm(e,{isSection,sectionId,setIsDropOpen,credentials,field,route,setIsModalOpen,setImageUrl,handleEdit,isLayoutElement})}>
                <input type="file"></input>
                <button className="btn-primary">Submit Picture</button>
                </form>
                </Modal>
        )
    }
    export default ChooseAnImageFile
    
    
    