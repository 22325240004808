
    import "./index.scss"
    import React,{useContext,useState,ChangeEvent,useEffect} from "react"
import Section from "@/generics/Section"
import SectionHeading from "@/generics/SectionHeading"
import { PageContextType } from "@/types/Context"
import { PageContext } from "@/pages/Standard/context"
import { useDebounce } from "use-debounce"
import { DynamicObject } from "@/types/generic"
import { editSectionFields } from "@/api/section"
import { makeFieldObject } from "@/utils/arrays"
import EditSectionHeader from "@/components/EditSectionHeader"
import { getEditOrdinal } from "@/utils/pageManipulation"
import { SectionState, SectionType } from "@/types/Section"
import PuxMod, { SectionContext } from "@/components/PuxMod"
export type SubscribeFields = DynamicObject


    const Subscribe = ({section}:{section:SectionType})=>{

    return<PuxMod
    section={section}
    
    ><Content></Content></PuxMod>

    return<></>

    }
    
    function Content(){
        const {fields,handleInputChange}:SectionState = useContext(SectionContext)
        const {isEditing}:PageContextType = useContext(PageContext)

        const services= [
            {
                name:"apple",
                logo:"/images/icons/podcast/apple.svg",
                href:fields.apple
            },
            {
                name:"google",
                logo:"/images/icons/podcast/google.svg",
                href:fields.google
            },
            {
                name:"spotify",
                logo:"/images/icons/podcast/spotify.svg",
                href:fields.spotify
            },
            {
                name:"stitcher",
                logo:"/images/icons/podcast/stitcher.svg",
                href:fields.stitcher
            },
            {
                name:"amazonPod",
                logo:"/images/icons/podcast/amazon.png",
                href:fields.amazonPod
            },
            {
                name:"iHeartRadio",
                logo:"/images/icons/podcast/iHeartRadio.svg",
                href:fields.iHeartRadio
            }
        ]

        const short = services.filter((s)=>{
            return (s.href&&s.href!=="")
        }).length<3

        if(!isEditing){
            return(
                <div className={`services-cont ${short&&"short"}`}>
                {services.filter((s)=>{
                    return (s.href&&s.href!=="")
                }).map((s,i:number)=>{
                    return(<div>
                        <a target="_blank" href={s.href}>
                        <img src={s.logo}/>
                        </a>
                        </div>
                        )
                })}
            </div>
            )
        }

        return(
            <>
            <div>Enter the specific url for your podcast for each of these services</div>
            <div className="services-cont editing">
            {services.map((s,i:number)=>{
                return(<div key={i} className="input-cont">
                    <img src={s.logo}/>
                    <input name={s.name} onChange={handleInputChange} value={s.href}></input>
                    </div>
                    )
                })}
            </div>
                </>
                )            
    }


    export default Subscribe