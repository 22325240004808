import { DynamicObject, DynamicObjectWithNumber } from "@/types/generic"
import "./index.scss"
import { parseStyles } from "@/utils/theme"
import { KeyboardEvent, MouseEventHandler, useContext, useEffect, useRef, useState } from "react"
import { Context, ContextValueType } from "@/context"
import { ChildStyle, addChildClasses, addChildStylesProps, getChildStylesAndClasses } from "./helpers"
import { PageContextType } from "@/types/Context"
import { PageContext } from "@/pages/Standard/context"
import { SectionState } from "@/types/Section"
import { SectionContext } from "@/components/PuxMod"
import { cssToObjects } from "@/utils/css"
import addCustomTemplateStyles from "./addCustomTemplateStyles"
import { CSSField } from "@/types/css"
import { getParsedValue } from "@/components/SectionTemplatePreview/helpers"

type Props = {
    alternating?:boolean,
    sectionName?:string,
    styleString?:string
    children?:(JSX.Element|JSX.Element[]),
    ordinal?:number,
    additionalClass?:string,
    id?:string
    setSectionRef?:React.Dispatch<any>
    cssFields?:any[]
}

    const Section = ({alternating,sectionName,children,ordinal,styleString,id,additionalClass,setSectionRef,cssFields}:Props)=>{
        const {credentials}:ContextValueType = useContext(Context)
        const {isEditing,pageId,isFullHeight}:PageContextType = useContext(PageContext)
       const sectionElement = useRef<(HTMLElement|null)>(null)
        const {topBottom}:ContextValueType = useContext(Context)
        const [inframe,setInframe] = useState(false)
        const {customLoaded,isCustomTemplate,currentCustomCSSArray}:SectionState = useContext(SectionContext)


        useEffect(()=>{
            const sectionTop = sectionElement?.current?.offsetTop
            const {top,bottom} = topBottom
            if(!sectionTop){
                return
            }
            if((top<sectionTop)&&(sectionTop<bottom)){
                setInframe(true)
                return
            }
            setInframe(false)
        },[topBottom])

        useEffect(()=>{
            if(setSectionRef){
                setSectionRef(sectionElement)
            }
        },[])
        useEffect(()=>{
            const parent = sectionElement.current
            if(!parent){
                return
            }
            if(parent&&styleString&&sectionName&&!isCustomTemplate){
                const res = getChildStylesAndClasses({styleString,sectionType:sectionName})
                const customSectionCSSStyles:any = []
                const childStyles = [...customSectionCSSStyles,...res.childStyles]
                childStyles.forEach(({selector,property,value}:ChildStyle)=>{
                    addChildStylesProps({selector,parent,value,property})
                })
                res.childClasses.forEach(({selector,value}:{selector:string,value:string})=>{
                    addChildClasses({selector,parent,value})
                })
            }
            if(parent&&styleString&&isCustomTemplate){
                setTimeout(()=>{
                    addCustomTemplateStyles({parent,styleString,cssArray:currentCustomCSSArray.filter((c:CSSField)=>c.editable)})
                },100)
                }
            if(cssFields){
                cssFields.forEach((style:any)=>{
                    const {selector,property,value} = style
                    if(selector!==""){
                        try{
                            const parsedValue = getParsedValue(value)
                            const matches = Array.from(parent.querySelectorAll(selector))
                            for (let match of matches){
                                match.style[property] = parsedValue
                            }
                        }catch{
                        }
                    }
                })
            }
        },[sectionElement,styleString,isEditing,customLoaded])

        let style:DynamicObjectWithNumber = {gridRow:0}
        if(ordinal){
            style.gridRow = ordinal+1
        }
        if(ordinal===0){
            style.gridRow = ordinal+1
        }
        let customClass = ""
        if(styleString){
            const addedStyles = parseStyles(styleString)
            style = isCustomTemplate?{}:{...style,...parseStyles(styleString).styles}
            customClass = addedStyles.class
        }

        const previewSectionId = (new URLSearchParams(window.location.search)).get("previewSection")


        if(previewSectionId&&previewSectionId!==id){
            return <></>
        }

        const handleControlClick:MouseEventHandler = (event) => {
            if (event.ctrlKey && event.button === 0) {
                console.log({accessToken:credentials.accessToken,userId:credentials.user._id,pageId,sectionId:id})
            }
          };

        return(
            <section onClick={handleControlClick} id={id||""} ref={sectionElement} style={style} className={`${additionalClass} ${ordinal===0&&"first"} section ${sectionName||""} ${alternating?"alternating":""} ${customClass} ${inframe?"inframe ":""} ${isFullHeight?"full-height":"not-full-height"}`}>
                {children}
            </section>
        )
    }
    export default Section
    
    
    