import "./index.css"
    import React,{useContext, useEffect, useState} from "react"
import { Context, ContextValueType } from "../../../context"
import LogoutButton from "../../../components/LogoutButton"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { Children } from "@/types/JSX"

    const HomepageHeader = ({additionalClass,children}:{additionalClass?:string,children:Children})=>{
        const context:ContextValueType = useContext(Context)
        const {setModal,credentials} = context
        const [scrolled,setScrolled] = useState(false)
        
        const inSettings = window.location.href.includes("user-settings")


        const handleScroll = ()=>{
            const top = !(window.scrollY>0)
            if(!scrolled&&!top){
                setScrolled(true)
            }
            else if(top){
                setScrolled(false)
            }

        }

        useEffect(()=>{
            window.addEventListener("scroll",handleScroll)
        },[])

        return(
            <header className={`main-header ${scrolled?"scrolled":""} ${additionalClass||""}`}>
                <div className="header-left">
                <a href="/">
                    <img className="header-logo" src="/images/logo.png"></img>
                </a>
                </div>
                {children}
            </header>
        )
    }
    export default HomepageHeader
    
    type Props = any

    function HeaderRight({credentials}:Props){
        const inSettings = window.location.href.includes("user-settings")

        if(inSettings){
            return<div className="header-right">
                <Link to="/">
                <button className="smaller">Back to Dashboard</button>
                </Link>
            </div>
        }

        if(credentials){
            return<div className="header-right">
            <Link to="/user-settings">
            <FontAwesomeIcon icon={faCog} size="2x"></FontAwesomeIcon>
            </Link>
            <LogoutButton></LogoutButton>
        </div>
        }


        return                    <div className="header-right">
        <Link to="/login">
        <button className="login-btn">Login</button>
        </Link>
        <Link to="/register">
        <button className="">Register</button>
        </Link>
        </div>
    }