
    import "./index.scss"

    type Props = {
        children:JSX.Element|JSX.Element[]
    }

    const InputAndLabelContainer = ({children}:Props)=>{

        return(
            <div className="input-and-label-container">
                {children}
            </div>
            
        )
    }
    export default InputAndLabelContainer
    
    
    