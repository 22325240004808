import { fexPost } from "@/utils/fex"

type AddCustomSectionArgs = {
    sectionId:string
    className:string
}

export const addCustomSection = async ({sectionId,className}:AddCustomSectionArgs)=>{
    const res = await fexPost(`api/custom-subsection/${sectionId}`,{className})
    return res
}