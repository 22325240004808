
    import getExplanation from "@/utils/explanation"
import DraggableModal from "../DraggableModal"
import "./index.scss"
    import React, { useState } from "react"

    type Props = {
        topic:string
    }

    const Explanation = ({topic}:Props)=>{
        
        const [isOpen,setIsOpen] = useState(false)
        
        const explanation = getExplanation(topic)

        if(!explanation){
            return<></>
        }

        if (!isOpen||!explanation){
            return <button
            onClick={()=>setIsOpen(true)}
            className="explanation-btn">?</button>
        }

        return(
            <DraggableModal additionalClass="layout-modal" setIsModalOpen={setIsOpen}
            header="Instructions"
            >
                <div className="explanation-text">
               {explanation}
                </div>
            </DraggableModal>
        )
    }
    export default Explanation
    
    
    