import themes from "../../../pages/Standard/themes.json"
import "./index.scss"
    import Header from "@/generics/Header"
import React,{ChangeEvent, useContext} from "react"
import Toggle from "../../../generics/Toggle"
import { PageContext } from "../../../pages/Standard/context"
import { Link } from "react-router-dom"
import { changeTheme } from "@/api/theme"
import { PageContextType } from "@/types/Context"
import DynamicNavBar from "@/components/DynamicNavBar"
import OwnersNav from "@/components/OwnersNav"

    const StandardHeader = ({ownPage}:{ownPage:boolean})=>{
        const {isEditing,setIsEditing,theme,setTheme,isPaidFor,pageId,style,setStyle}:PageContextType = useContext(PageContext)
        const isDeveloping = window.location.href.includes("freelance-dev/")
        const handleToggle = ()=>{
            if(isEditing){
                window.location.reload()
            }
            setIsEditing(!isEditing)
        }
        if(!ownPage){
            return(<DynamicNavBar publicPage={true}/>)
        }

        return (
            <Header additionalClass={`standard ${ownPage?"private":"public"}`}>
                {(isDeveloping||ownPage)?<OwnersNav/>:<></>}
                <DynamicNavBar/>
            </Header>
        )

    }
    export default StandardHeader
    