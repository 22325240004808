import "./index.scss"
import Event from "../../components/Event"
import { EventType } from "../../types/Event"
import { useContext, useEffect, useState } from "react"
import { makeFieldObject } from "../../utils/arrays"
import BigMiddleButton from "../../generics/BigMiddleButton"
import { createEvent } from "../../api/event"
import { PageContext } from "@/pages/Standard/context"
import { PageContextType } from "@/types/Context"
import { EventsContext, EventsContextProvider } from "./context"
import { SectionType } from "@/types/Section"
import { DynamicObject } from "@/types/generic"
import { orderEvents } from "./handlers"
import PuxMod from "@/components/PuxMod"


    const Events = ({section}:{section:SectionType})=>{

        const [fields,setFields] = useState<DynamicObject|null>(null)

        useEffect(()=>{
            if(section.fields){
                setFields(makeFieldObject(section.fields))
            }
        },[section])

        return(
            <EventsContextProvider>
            <EventsContextConsumer section={section} fields={fields} setFields={setFields}></EventsContextConsumer>
            </EventsContextProvider>
        )
    }

    type ConsumerType = {
        fields:(DynamicObject|null),
        setFields:React.Dispatch<(DynamicObject)|null>,
        section:SectionType
    }

    const EventsContextConsumer = ({fields,setFields,section}:ConsumerType)=>{
        const {isEditing}:PageContextType = useContext(PageContext)
        const {events,setEvents}:{events:EventType[],setEvents:React.Dispatch<EventType[]>}=useContext(EventsContext)

        useEffect(()=>{
            setEvents(section.events)
        },[])

        const handleAddEvent = async ()=>{
            const resp = await createEvent({sectionId:section._id})
            const newEvent = resp.data;

            setEvents([...events,newEvent])
        }
        if(!fields){
            return<></>
        }

        orderEvents(events)

        return (<PuxMod section={section}>
                {isEditing?<></>:<div className="events-cont">
              {events.map((event,key)=>{
                  return(
                      <Event sectionId={section._id} key={key} event={event}></Event>
                      )
                  })}
              </div>}
              {!isEditing?<></>:<EditComponent section={section}/>}
        </PuxMod>)

    }


    export default Events

    type EditSectionProps = {
        section:SectionType
    }

    function EditComponent({section}:EditSectionProps){

        const {events,setEvents}:{events:EventType[],setEvents:React.Dispatch<EventType[]>}=useContext(EventsContext)



    const handleAddEvent = async ()=>{
        const resp = await createEvent({sectionId:section._id})
        const newEvent = resp.data;

        setEvents([...events,newEvent])
    }


        return(
            <>
            <div className="events-cont">
              {events.map((event,key)=>{
                  return(
                      <Event sectionId={section._id} key={key} event={event}></Event>
                      )
                  })}
              </div>
              <BigMiddleButton buttonClass="primary" text="Add Event" handleClick={handleAddEvent}/>
            </>)

    }