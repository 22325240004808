
    import { PopulatedSectionSubmission } from "@/types/SectionSubmission"
    import "./index.scss"
    import { getStatus } from "./helpers"

    type Props = {
        submissions:PopulatedSectionSubmission[]
    }

    const SectionSubmissionsTable = ({submissions}:Props)=>{
        return(
            <section className="table-cont">
                <h2>Section Projects</h2>
            <table className="section-submission-table">
                <thead>
                    <th>Budget</th>
                    <th>Request</th>
                    <th>Deadline</th>
                    <th>Status</th>
                    <th>Other Developers</th>
                    <th>Other Submissions</th>
                </thead>
                <>
                {submissions.map((submission,index)=>{
                    return <SubmissionRow
                    key={index}
                    submission={submission}
                    ></SubmissionRow>
                })}
                </>
            </table>
    </section>
        )
    }
    export default SectionSubmissionsTable
    
    type RowProps = {
        submission:PopulatedSectionSubmission
    }

    function SubmissionRow({submission}:RowProps){
        
        const {sectionRequest,_id} = submission
        const {sectionRequestTitle,deadline,budgetDollars,developers,submissions} = sectionRequest
        const status = getStatus(submission)
        const developerCount = developers?.length||1
        const submissionCount = submissions?.length||1

        return<tr>
            <td>${budgetDollars}</td>
            <td><a href={`/freelance-dev/${_id}`}>
                {sectionRequestTitle}
                </a>
                </td>
            <td>{deadline}</td>
            <td>{status}</td>
            <td>{developerCount-1}</td>
            <td>{submissionCount-1}</td>
        </tr>
    }
    
    