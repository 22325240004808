import { fexDelete, fexPost } from "@/utils/fex"


export const getAssistance = async (pageId:string)=>{
    const res = await fexPost(`api/assistance/${pageId}`,{})
    return res
}


export const removeAssistance = async (pageId:string)=>{
    const res = await fexDelete(`api/assistance/${pageId}`,{})
    return res
}