
    import getSectionThumbnails from "@/data/getSectionThumbnails"
import "./index.scss"
    import React from "react"

    type Props = {
        sectionName:string|null
    }

    const SectionThumbnail = ({sectionName}:Props)=>{
        
        let image:null|string = getSectionThumbnails(sectionName||"default")
        if(!sectionName){
            return<div className="section-thumbnail-cont ">
                <h3>Hover your mouse over a section name to see a preview.</h3>
            </div>
        }
        
        
        return(
            <div className="section-thumbnail-cont">
            <img src={image||""}></img>
            </div>
        )
    }
    export default SectionThumbnail
    
    
    