
    import { SectionState } from "@/types/Section"
import "./index.css"
    import React, { ChangeEvent, useContext, useState } from "react"
import { SectionContext } from "../PuxMod"
import { addCSSField } from "@/api/cssField"
import AddCSSText from "../AddCSSText"


    type Props = {
        cssArray:CSSLineType[]
        setCssArray:React.Dispatch<CSSLineType[]>
    }

    const CSSEditor = ({cssArray,setCssArray}:Props)=>{

        const [isModalOpen,setIsModalOpen] = useState(false)
        const handleAddField = ()=>{
            const obj = {
                selector:"",
                property:"",
                value:""
            }
            setCssArray([...cssArray,obj])
        }

        const handleChange = ({index,key,value}:HandleChangeProps)=>{
            const newArray:any = [...cssArray]
            if(key==="selector"){
                const selector = value.includes(".")?value:`.${value}`    
                newArray[index][key] = selector;

            }else{
                newArray[index][key] = value;
            }
            setCssArray(newArray)
        }

        return(
            <div className="css-editor-cont">
                {isModalOpen&&<AddCSSText
                setCssArray={setCssArray}
                setIsModalOpen={setIsModalOpen}
                ></AddCSSText>}
                {!isModalOpen&&
                <>
                <div className="table-wrapper">
                    <button className="wide-narrow-btn" onClick={()=>setIsModalOpen(true)}>Paste New CSS</button>
                <table>
                <thead>
                    <th>Class</th>
                    <th>Property</th>
                    <th>Value</th>
                </thead>
                <tbody>

                {
                    cssArray.map((obj:CSSLineType,index:number)=>{
                        return <CSSLine
                        cssLine={obj}
                        index={index}
                        handleChange={handleChange}  
                        ></CSSLine>
                    })
                }
                </tbody>
                </table>
                <div style={{margin:"10px",paddingBottom:"20px"}} className="add-btn-cont">

                <button className="wide-narrow-btn" onClick={handleAddField}>Add CSS Line</button>
                </div>
                </div>
                </>
                }
            </div>
        )
    }
    export default CSSEditor
    
    export type CSSLineType = {
        selector:string
        property:string
        value:string
        editable?:boolean
        variable?:string
    }

    type HandleChangeProps = {
        index:number
        key:string
        value:string
    }


    type LineProps = {
        cssLine:CSSLineType
        index:number
        handleChange:(arg0:HandleChangeProps)=>void
    }

function CSSLine({cssLine,index,handleChange}:LineProps){


    const {selector,property,value} = cssLine

    const changeHandler = (e:ChangeEvent<HTMLInputElement>)=>{
        handleChange({index,key:e.target.name,value:e.target.value})
    }


    return(
        <>
        {<tr className="css-input-line">
            <td>

            <input className="selector-input" onChange={changeHandler} name="selector" value={selector}></input>
            </td>
            <td>

            <input className="property-input" onChange={changeHandler} name="property" value={property}></input>
            </td>
            <td>
            <input onChange={changeHandler} name="value" value={value}></input>
            </td>

        </tr>}
        </>


    )
}