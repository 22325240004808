import "./index.scss"
import { Episode } from '@/sections/PodcastEpisodes';
import React, { useEffect, useState } from 'react';
import { FaPause, FaPlay } from "react-icons/fa";
import { parseSeconds } from "@/utils/timeStrings";

type Props = {
    episode:Episode
    setIsOpen:React.Dispatch<any>
}

const Audio = ({ episode,setIsOpen }:Props) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioRef, setAudioRef] = useState<HTMLAudioElement|null>(null);
  const [duration, setDuration] = useState(0);
    const [currentTime,setCurrentTime] = useState(0)

  useEffect(()=>{
    if(!audioRef){
        return
    }
    setIsPlaying(true);
    audioRef.play();
  },[audioRef,episode])


  useEffect(() => {
    const audio = audioRef;
    if(!audio){
        return
    }
    audio.addEventListener('loadedmetadata', () => {
      setDuration(audio.duration);
    });
    audio.addEventListener('timeupdate', () => {
      setCurrentTime(audio.currentTime);
    });
  }, [audioRef]);


  const handlePlay = () => {
    if(!audioRef){
        return
    }
    setIsPlaying(true);
    audioRef.play();
  };

  const handlePause = () => {
    if(!audioRef){
        return
    }
    setIsPlaying(false);
    audioRef.pause();
  };

  const handleProgressClick = (event:React.MouseEvent<HTMLProgressElement>) => {
    const progress = event.target as HTMLElement;
    const x = event.pageX - progress.offsetLeft;
    const percent = x / progress.offsetWidth;
    if(!audioRef){
        return
    }
    audioRef.currentTime = percent * duration;
  };
  

  return (
    <section className='audio-player-bar'>
        <div className="close-button" onClick={()=>setIsOpen(null)}>×</div>
        <img src={episode.image}></img>
        <main>

        <h3>{episode.title}</h3>
      <audio ref={setAudioRef} src={episode.file} />
      <button onClick={isPlaying ? handlePause : handlePlay}>
        {isPlaying ? <FaPause className="pause" size={16}></FaPause> : <FaPlay size={16}></FaPlay>}
      </button>
      <progress
          value={currentTime}
          max={duration}
          onClick={handleProgressClick}
        />
        <div>
            {parseSeconds(currentTime)} / {parseSeconds(duration)}
        </div>
        </main>
    </section>
  );
};

export default Audio;