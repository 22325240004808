
    import "./index.scss"
    import React, { ChangeEvent, useEffect, useState } from "react"
import { useDebounce } from "use-debounce"
import { fexPost, fexPut } from "@/utils/fex"
import AutoResizeInput from "@/generics/AutoResizeInput"
import { scrollToTop } from "@/utils/scrolling"
import FullFlexTextarea from "../FullFlexTextarea"
import Breaker from "@/generics/Breaker"

    type Props = {
        isEditing:boolean
        initialValue:string
        _id:string
    }

    const HeaderName = ({isEditing,initialValue,_id}:Props)=>{

        const [text,setText] = useState(initialValue||"a")
        const [delayedText] = useDebounce(text,1000)
        const handleChange = (value:string)=>{  
            
            setText(value)
        }

        useEffect(()=>{
            setText(initialValue)
        },[initialValue])

        useEffect(()=>{
            const fetch = async ()=>{
                const res = await fexPut(`api/layout-element/${_id}`,{text})
            }
            fetch()
        },[delayedText])



        return(
            <span onClick={scrollToTop} className="header-name-cont">
                {isEditing?<FullFlexTextarea
                initialText={text}
                handleChange={handleChange}
            ></FullFlexTextarea>:<span><Breaker
            text={text}
            ></Breaker></span>}
                
                {/* {isEditing?<AutoResizeInput
                onChange={handleChange}
                style={{width:"100px"}}
                value={text}></AutoResizeInput>:<span>{text}</span>} */}
            </span>
        )
    }
    export default HeaderName
    
    
    