import "./index.scss"
    import React, { MouseEventHandler, useEffect, useRef, useState } from "react"
import { FaExchangeAlt, FaImages, FaTrash } from "react-icons/fa"
import checkIfParentFullWidth from "./checkIfParentFullWidth"

    type Props = {
        _id:string,
        handleDelete:MouseEventHandler<HTMLButtonElement>
        tooltip?:string
        within?:boolean
        buttonPosition?:string
        replace?:boolean
    }

    const DeleteCircle = ({_id,handleDelete,tooltip,within,buttonPosition,replace}:Props)=>{

        const [additionalClass,setAdditionalClass] = useState("not-full")
        const ref = useRef(null)


        useEffect(()=>{
            if(ref.current!==null){
                const fullWidth = checkIfParentFullWidth({buttonElement:ref.current})
                if(fullWidth){
                    setAdditionalClass(additionalClass.replaceAll("not-full","within"))
                }
            }

        },[ref.current])


        return(
            <button ref={ref} data-tooltip={tooltip||""} data-id={_id} data-testid="delete-circle" onClick={handleDelete} className={`delete-circle no-translate-animation ${tooltip&&"tooltip"} ${buttonPosition||""} ${within&&"within"} ${additionalClass}`}>{replace?<FaImages></FaImages>:<FaTrash></FaTrash>}</button>
        )
    }
    export default DeleteCircle
    
    
    