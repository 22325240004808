
    import useMainZIndex from "@/hooks/useMainZIndex"
import "./index.scss"
import { PopulatedSectionSubmission } from "@/types/SectionSubmission"
import Standard from "@/pages/Standard"
import getSlug from "@/utils/getSlug"
import { acceptSubmission } from "@/api/sectionSubmission"

    type Props = {
        setIsOpen:React.Dispatch<boolean>
        submission:PopulatedSectionSubmission
    }

    const ConfirmAcceptSubmission = ({setIsOpen,submission}:Props)=>{
        const ref = useMainZIndex(undefined,".submissions-list")
        const requestId = getSlug().substring(1)
        const previewRef = useMainZIndex(undefined,".section-eval-cont")

        const pageName = `${submission.userId}-${requestId}`

        const handleAcceptButton = async ()=>{
            const res = await acceptSubmission({submissionId:submission._id})
            if(!res.error){
                const {pageName} = res.data
                window.location.replace(`/${pageName}`)
            }
        }


        return(
            <main ref={ref} className="confirm-accept-submission-main">
                <h2>Preview:</h2>
                <div ref={previewRef} className="section-submission-preview">
            <Standard
                override={pageName}
                submission={submission}
            ></Standard>
                </div>
                <div className="verbage">
                    <p>
                        By clicking "Accept", you will add this section to your page. The section request you made will be closed and you will receive no further submissions. The budgeted tokens will be allocated to the developer of this section. You should be able to do further customization in your page editor if you wish to make changes. 
                    </p>
                    <p>Click "Cancel" if you are not happy with this submission and want to choose a different submission.</p>

                </div>
                <footer>
                    <button onClick={()=>setIsOpen(false)} className="danger">Cancel</button>
                    <button onClick={handleAcceptButton}>Accept</button>
                </footer>
            </main>
        )
    }
    export default ConfirmAcceptSubmission
    
    
    