import { fexPost } from "@/utils/fex"
import getJWT from "@/utils/getJWT"
import route from "@/utils/route"



const uploadFileAndGetKey = async (files:any)=>{
    let formData = new FormData()
    const file = files[0]
    formData.append('image', file)
    const bearer = getJWT()
    const url = route("api/upload/simple")

    const resp = await fetch(url, {
      method: 'POST',
      headers:{
        'Authorization':`Bearer ${bearer}`
    },
      body: formData
    });
    const data= await resp.json()
    return data.key
}











export default uploadFileAndGetKey