import { fexPost } from "@/utils/fex"

type Arguments = {
    email:string,
    password:string,
    confirmPassword:string,
    recaptchaToken:string,
    confirmationCode:string
}


export const resetPassword = async ({email,password,confirmPassword,recaptchaToken,confirmationCode}:Arguments)=>{
    try{
        const resp = await fexPost("api/reset-password/"+confirmationCode,{email,password,confirmPassword,recaptchaToken})
        return resp
    }catch(err){
        return err
    }
}

export const requestNewPasswordLink = async ({email,recaptchaToken}:{email:string,recaptchaToken:string})=>{
    try{
        const resp = await fexPost("api/request-reset-email/",{email,recaptchaToken})

        return resp
    }catch(err){

        return err
    }
}