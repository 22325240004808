
    import "./index.scss"
    import React, { ChangeEvent, useEffect, useRef, useState } from "react"
import FontSelector from "../FontSelector"
import { FaImage } from "react-icons/fa"
import ChooseFileModal from "@/generics/ChooseFileModal"
import getJWT from "@/utils/getJWT"
import route from "@/utils/route"
import { addImageToElement } from "@/utils/DOM"
import { changeImageAlignment, deleteImage, generateHTMLForDB, getInitialValues } from "./helpers"

    type Props = {
        value:string
        setIsOpen:React.Dispatch<boolean>
        handleSubmit:(arg0:string)=>void

    }

    const CustomTextEditor = ({value,setIsOpen,handleSubmit}:Props)=>{
        
        const editorRef = useRef(null)

        const {font,html} = getInitialValues(value)

        const [fontValue,setFontValue] = useState(font||"arial")
        const [editorText,setEditorText] = useState(html)
        const [imageUploaderOpen,setImageUploaderOpen] = useState(false)
        const [align,setAlign] = useState(0)
        const [currentDiv,setCurrentDiv] = useState<any>(null)
        const dragDispatch = useState(null)

        const handleFontSelector = ({value}:{value:string})=>{
            setFontValue(value)
        }

        const handleImageSubmit = async (e:React.FormEvent<HTMLFormElement>)=>{
            e.preventDefault()
            let formData = new FormData()
            const target = (e.target as HTMLFormElement)
            const file = (target[0] as any).files[0]
            formData.append('image', file)
            const bearer = getJWT()
            const url = route("api/upload/simple")
            const resp = await fetch(url, {
              method: 'POST',
              headers:{
                'Authorization':`Bearer ${bearer}`
            },
              body: formData
            });
            const data = await resp.json()
            const imageURL = route(`api/upload/${data.key}`)
            const newHTML = addImageToElement({html:value,imageSrc:imageURL,currentDiv,getDropTarget})
            if(!newHTML){
                return
            }
            setImageUploaderOpen(false)
        }

        const handleImageAlign = (e:React.MouseEvent<HTMLButtonElement>)=>{
            const target = e.target as HTMLElement;
            const dataset = target.dataset;
            const imageId = dataset.imageId;
            const alignment = dataset.classAdd;
            if(!imageId||!alignment){
                return
            }
            const editorHTML = (editorRef.current as any).innerHTML;
            const variables = {html:editorHTML,imageId,alignment}
            const newHTML = changeImageAlignment(variables)            
            setAlign(align+1)
            if(typeof newHTML === "string"){
                setEditorText(newHTML)
            }
        }

        const handleDeleteImage = (e:React.MouseEvent<HTMLButtonElement>,)=>{
            const target = e.target as HTMLElement;

            const dataset = target.dataset;
            const imageId = dataset.imageId;
            if(!imageId){
                return
            }
            const editorHTML = (editorRef.current as any).innerHTML;
            const variables = {html:editorHTML,imageId}
            const newHTML = deleteImage(variables)            
            setAlign(align+1)
            if(typeof newHTML === "string"){
                setEditorText(newHTML)
            }
        }

        useEffect(()=>{
            if(imageUploaderOpen){
                return
            }
            const buttons = document.querySelectorAll("button.image-align-btn") as NodeListOf<HTMLButtonElement>
            buttons.forEach((button:HTMLButtonElement)=>{
                if(!button.onclick){
                    button.addEventListener("click",(handleImageAlign as any))
                }
            })
            const deleteCircles = document.querySelectorAll("button.delete-circle") as NodeListOf<HTMLButtonElement>
            deleteCircles.forEach((button:HTMLButtonElement)=>{
                if(!button.onclick){
                    button.addEventListener("click",(handleDeleteImage as any))
                }
            })
        },[imageUploaderOpen,align])

        const setIsEditing =(isOn:boolean)=>{}

        const handleSubmitAndClose = ()=>{
            const container = editorRef.current
            if(!container){
                return
            }
            const htmlToSubmit = generateHTMLForDB({container})
            handleSubmit(htmlToSubmit)
            setIsOpen(false)
        }

        const handleCursorClick = ()=> {
            var selection = window.getSelection();
            var currentDiv = null;
            if(!selection){
                return
            }
            if (selection.rangeCount > 0) {
              var range = selection.getRangeAt(0);
              currentDiv = range.commonAncestorContainer;
              
              // If the currentDiv is a text node, get its parent element
              if (currentDiv.nodeType === Node.TEXT_NODE) {
                currentDiv = currentDiv.parentNode;
              }
            }
          setCurrentDiv(currentDiv)
            // Log the currentDiv for demonstration
          }
         
          const [dragTarget,setDragTarget] = dragDispatch
        const handleDragOver = (e:any)=>{
            dragDispatch[1](e.target)
        }

        const getDropTarget = ()=>{
            return    dragTarget
        }

        return(
            <div className="custom-text-editor">
                <div className="row">
                <div className="editor">
                    <div className="toolbar">
                        <FontSelector value={fontValue} handleSelector={handleFontSelector}></FontSelector>
                        <span>
                            <button onClick={()=>{setImageUploaderOpen(true)}}><FaImage></FaImage></button>
                            <button onClick={()=>addImageToElement({html:value,imageSrc:"https://m.media-amazon.com/images/I/61uxXJukIcL._AC_UL400_.jpg",currentDiv,getDropTarget})}></button>
                        </span>
                    </div>
                        <div
                        ref={editorRef} 
                        className={`live-content ${fontValue}__font`} contentEditable={true} dangerouslySetInnerHTML={{__html:editorText}}
                        onMouseUp={handleCursorClick}
                        onDragOver={handleDragOver}
                        >
                        </div>
                        <button onClick={handleSubmitAndClose}>Submit</button>
                        <button onClick={()=>setIsEditing(false)} className="danger">Cancel</button>
                </div>
                {imageUploaderOpen&&<ChooseFileModal
                setIsModalOpen={setImageUploaderOpen}
                handleSubmit={handleImageSubmit}
                
                ></ChooseFileModal>}
            </div>
        </div>
        )
    }
    export default CustomTextEditor
    
    
    