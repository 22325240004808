function isTextNode(node: Node): boolean {
    const isTextNode = node.nodeType === Node.TEXT_NODE;
    if(isTextNode){
        const string = node.textContent
        if(!string||string?.replaceAll(" ","").replaceAll("\n","").length<1){
            return false
        }
    }
    return isTextNode
  }

  function isImageNode(node: Node): boolean {
    return node.nodeName === 'IMG';
  }



const replaceValuesWithVariables:any = (parent:(HTMLElement),iterator:number)=>{
    const isText = isTextNode(parent)
    const isImage = isImageNode(parent)
    const nodeArray = !(isText)?Array.from(parent.childNodes):[]
    if(isImage){
        (parent as HTMLImageElement).src = `{{${iterator}:${(parent as HTMLImageElement).src}}}`
    }
    if(nodeArray.length>0){
        if(typeof parent !== "string"){
            parent.innerHTML =  nodeArray.map((n,i)=>{
                return replaceValuesWithVariables(n,(iterator.toString()+i))
            }).join("")
        }

    }
    let parentString = !(isText)?parent.outerHTML:parent.nodeValue
    if(isText){
        parentString = `{{${iterator}:${parentString}}}`
    }

    return parentString

}


const addVariablesToHTML = ({html}:{html:string})=>{
    const parent = document.createElement("parent")
    parent.innerHTML = html;
    const res = replaceValuesWithVariables(parent,0).replaceAll("<parent>","").replaceAll("</parent>","")
    return res
}


export default addVariablesToHTML