
    import { findLongestString, getTextWidth } from "@/components/FullFlexTextarea/helpers";
import "./index.scss"
    import React, { ChangeEvent, CSSProperties, TextareaHTMLAttributes, useEffect, useRef, useState } from "react"
import { SubsectionContext } from "@/components/SubPuxMod";
import useSkipFirstMutation from "@/hooks/useSkipFirstMutation";

    type Dimensions= {
        width:string
        height:string
    }

    const FullFlexAutoResizeTextArea = (props:TextareaHTMLAttributes<HTMLTextAreaElement>)=>{
       

        const [dimensions,setDimensions] = useState<Dimensions>({width:"30px",height:"40px"})
        const {onChange,value,name,className,id} = props
        const textAreaRef = useRef<HTMLTextAreaElement>(null);
        const [text, setText] = useState((value as string).replaceAll("<br>","\n"));
        const [parentHeight, setParentHeight] = useState("auto");
        const [cursorPosition,setCursorPosition] = useState(text.length)
        const lines = text.split("\n")
        const longestLine = findLongestString(lines)
        const style = {
            width:dimensions.width,
            height:dimensions.height
        }

        useSkipFirstMutation(()=>{
            setText((value as string).replaceAll("<br>","\n"))
        },[id])

        useEffect(()=>{
            setRefsPosition(cursorPosition)
        },[cursorPosition])

        const setRefsPosition = (position: number) => {
            if (textAreaRef.current) {
              textAreaRef.current.setSelectionRange(position, position);
              textAreaRef.current.focus();
            }
        };

        const handleChange = (e:ChangeEvent<(HTMLTextAreaElement)>)=>{
            const cursorPosition = e.target.selectionStart;
            setText(e.target.value);
            setCursorPosition(cursorPosition)
            if (props.onChange) {
                props.onChange(e);
            }
                setParentHeight(`${textAreaRef.current!.scrollHeight}px`);
            if(!e||!onChange){
                return
            }
            e.target.value = e.target.value.split("\n").join("<br>")
            onChange(e)
        }

        useEffect(()=>{
            if(!textAreaRef.current){
                return
            }
            const style = window.getComputedStyle(textAreaRef.current)
           const {newWidth} = getTextWidth(longestLine||"",style.fontFamily,style.fontSize,style.fontWeight)
           const lineHeight = style.lineHeight==="normal"?1.4:parseFloat(style.lineHeight)
           const newDimensions = {...dimensions}
           newDimensions.width = `${Math.max(newWidth+25,20)}px`
           newDimensions.height = `${(parseInt(style.fontSize.replace("px",""))*(lines.length||1))*lineHeight}px`
           setDimensions(newDimensions)
        },[text])

        useEffect(() => {
            setParentHeight(`${textAreaRef.current!.scrollHeight}px`);
        }, [text]);

        return (
                <textarea className={className+" full-flex-auto auto-textarea p"}
                    name={name}
                    value={(text)}
                    onChange={handleChange}
                    ref={textAreaRef}
                    style={style}
                    rows={1}
                />
        );
    }
    export default FullFlexAutoResizeTextArea
    
    
    