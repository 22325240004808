

    import { SectionState, SectionType } from "@/types/Section"
import "./index.scss"
    import React, { MouseEventHandler, useContext, useEffect, useRef } from "react"
import PuxMod, { SectionContext } from "@/components/PuxMod"
import renderTemplate from "./renderTemplate"
import { PageContextType } from "@/types/Context"
import { PageContext } from "@/pages/Standard/context"
import { CSSField } from "@/types/css"
import CustomElementResolver from "@/components/CustomElementResolver"
import { getTemplateStylingObject } from "./stylingUtils"
import { loggo } from "@/utils/dev"
import handleContainerStyles from "./handleContainerStyles"
import { Context, ContextValueType } from "@/context"
import addCustomTemplateStyles from "@/generics/Section/addCustomTemplateStyles"

    const Custom = ({section}:{section:SectionType})=>{

        const {isEditing}:PageContextType = useContext(PageContext)

        if(!section.template){
            return<></>
        }
        
        const html = section.template.html
        const fields = section.fields
        const renderedHTML = renderTemplate({html,fields,subsections:section.genericSubsections})
        const cssArray = section.template.css
        if(!isEditing){
            return<Static
            templateId={section.template._id}
            sectionId={section._id}
            html={renderedHTML} cssArray={cssArray}
            style={section.style}
            isOn={section.isOn}
            ></Static>
        }

        return(
            <PuxMod section={section} headless={true} customCSSArray={cssArray}>            
                <Editing
                section={section}
                cssArray={cssArray}
                ></Editing>
            </PuxMod>
        )
    }
    export default Custom
    
    
    type StaticProps = {
        html:string
        sectionId:string
        cssArray:CSSField[]
        templateId:string
        style:string
        isOn:boolean
    }

    const Static = ({html,cssArray,sectionId,templateId,style,isOn}:StaticProps)=>{
        const {credentials}:ContextValueType = useContext(Context)
        const {pageId}:PageContextType = useContext(PageContext)
        const sectionRef = useRef(null)
        const innerRef = useRef(null)
        useEffect(()=>{
            if(!sectionRef?.current){
                return
            }
            addCustomTemplateStyles({parent:sectionRef.current,styleString:style||"",cssArray})

        },[sectionRef.current,innerRef.current])

        const handleControlClick:MouseEventHandler = (event) => {
            if (event.ctrlKey && event.button === 0) {
                console.log({accessToken:credentials.accessToken,userId:credentials.user._id,pageId,sectionId})
            }
          };

          if(!isOn){
            return<></>
          }

        return<section onClick={handleControlClick} id={sectionId} ref={sectionRef} data-template={templateId} className="section custom">
            <div ref={innerRef} style={{width:"100%"}}  dangerouslySetInnerHTML={{__html:html}}></div>
        </section>

    }


    type EditingProps = {
        section:SectionType
        cssArray:CSSField[]
    }

    const Editing = ({section,cssArray}:EditingProps)=>{
    const sectionRef = useRef(null)
    const {setSectionElement,setCSSUpToDate,style}:SectionState = useContext(SectionContext)
    // this is not the culprit, where else are we setting styles?
    const setCSS = ()=>{
        cssArray.forEach((c)=>{
            const {selector,property,value} = c
            const els = Array.from((sectionRef.current as any).querySelectorAll(c.selector))
            try{
                const withMatch = Array.from((sectionRef.current as any).querySelectorAll(`${c.selector}_class`))
                els.push(...withMatch)
                if(c.selector[0]!=="."){
                    const tagClassMatches = Array.from((sectionRef.current as any).querySelectorAll(`.${c.selector}`))
                    els.push(...tagClassMatches)
                }
            }catch(err){
                console.log({err})
            }
            els.forEach((el)=>{
                (el as any).style[c.property] = c.value
            })
            const tag = (els as any)?.[0]?.localName
            const section = sectionRef.current
            handleContainerStyles({tag,selector,property,value,section})
        })
        if(!sectionRef.current){
            return
        }
        addCustomTemplateStyles({parent:sectionRef.current,styleString:style||"",cssArray})

    }

    useEffect(()=>{
        if(!sectionRef?.current){
            return
        }
        setCSS()
        setSectionElement(sectionRef.current)
    },[sectionRef.current])
       
    // have the above be a function
    
    if(!section.template){
            return<></>
        }

        return<div style={{width:"100%"}} ref={sectionRef}>
            <CustomElementResolver
            parentHorizontalPadding={0}
            element={section.template.rootElement}
            cssArray={cssArray}
            setCSS={setCSS}
            parentMaxWidth={document.body.getBoundingClientRect().width}
        ></CustomElementResolver>
        </div>
    }


