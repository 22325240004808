export default     {
    prompt:"Overlay Opacity",
    field:"overlayOpacity",
    type:"childStyle",
    child:".overlay",
    default:0.2,
    valueType:"number",
    slider:true,
    range:[0,1],
    property:"opacity"
}