
    import "./index.scss"
    import React, { useContext, useState } from "react"
import Toggle from "@/generics/Toggle"
import { SectionContext } from "../PuxMod"
import { changeInMenu } from "@/api/section"
import { changeNavSectionField } from "@/utils/navBar"
import { PageContext } from "@/pages/Standard/context"
import { PageContextType } from "@/types/Context"
import { SectionState } from "@/types/Section"

    const MenuToggle = ()=>{
        const {navSections,setNavSections}:PageContextType = useContext(PageContext)
        const {inMenu,setInMenu,sectionId}:SectionState = useContext(SectionContext)
        const action = ()=>{
            changeInMenu({sectionId,inMenu:!inMenu})
            setInMenu(!inMenu)
            changeNavSectionField({navSections,setNavSections,sectionId,field:"inMenu",value:!inMenu})
        }

        const label = inMenu?"in menu":"not in menu"

        return(
            <Toggle
            action={action}
            initialValue={inMenu}
            className="menu-toggle"
            label={label}
            ></Toggle>
        )
    }
    export default MenuToggle
    
    
    