export const getVerticalAlignment = (value:string)=>{
    switch (value){
        case "top":
            return "flex-start";
        case "bottom":
            return "flex-end"
        default:
            return "center"
        
    }
}

type AlignmentValue = "start" | "end" | "left" | "right" | "center" | "justify" | "match-parent"

export const getHorizontalAlignment = (value:string):AlignmentValue=>{
    switch (value){
        case "left":
            return "left";
        case "right":
            return "right"
        default:
            return "center"
    }
}
