import React, { ChangeEvent } from "react"
import { utilityFonts } from "@/utils/fonts"

    type HandleSelector = (arg0:{field:string,value:string})=>void

    const FontSelector = ({name="font",value,customFonts,handleSelector}:{name?:string,customFonts?:string[],value:string,handleSelector:HandleSelector})=>{

        const fonts = customFonts || utilityFonts

        const handleChange = (e:ChangeEvent<HTMLSelectElement>)=>{
            const {name,value} = e.target;
            handleSelector({field:name,value})
        }

        return(
            <select value={value} onChange={handleChange} name={name||"font"}>
                {(fonts.sort((a:string,b:string)=>{
                    if(a>b){
                        return 1
                    }
                    return -1
                })).map((font,index)=>{

                    return<option className={`${font}__font`} key={index} value={font}>{font}</option>
                })}
            </select>
        )
    }
    export default FontSelector
    
    
    