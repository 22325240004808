export default [
    {q:"Is PuxHub right for me?",a:"If you're looking for a simple, efficient and hassle-free solution for building your website, the answer is yes! PuxHub's unique approach to web building, using a very straightforward interface, makes it easy for anyone to create a professional-looking website without any coding skills. Whether you're a small business owner, a freelancer, or an individual, PuxHub is the perfect choice for building your online presence."},
    {q:"Is it difficult to edit a site?",a:"No, and that is the point. PuxHub's editor is a 'What you see is what you get.' If you can work a very basic word processor, handling content edits will be a breeze. Style edits are even simpler. There is no css involved, nor frustrating dragging and dropping. Instead all styling is handled by basic toggles and buttons. Moreover, mobile layout is handled for you, so there is no need to fret about a responsive design."}
,
{q:"What is the deal with subscriptions?",a:"For a site to be live, you need to pay a monthly subscription of $5.00. You can edit the site to your liking before deciding to paying for its publication. You can cancel this subscription at any time. It is billed through Stripe, so no sensitive card details are retained by PuxHub."}
,    {q:"What if you do not have what I want/need?",a:"Week by week, additional templates will be added to PuxHub. If your use does not have a template, reach out to us at admin@puxhub.com, and we will begin constructing one and notify you when it is ready. Moreover, if you have an appropriate template, but are missing a certain kind of functionality, reach out to us and we will let you know if we can do it and when we plan on integrating it."}
]

export type FaqType = {
    q:string
    a:string
}