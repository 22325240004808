import "./index.scss"
import React,{useContext,useEffect,useState} from "react"
import { PageContextType } from "@/types/Context"
import { PageContext } from "@/pages/Standard/context"
import DeleteCircle from "@/generics/DeleteCircle"
import GenericSubsection from "@/components/GenericSubsection"
import { deleteGenericSubsection } from "@/api/genericSubsection"
import Socials from "@/generics/Socials"
import { getSocials } from "@/utils/socials"
import { getOrdinal, moveDown, moveUp, removeFromOrdinals } from "@/utils/subsectionOrdering"
import { SectionContext } from "@/components/PuxMod"
import { GenericSubsectionDocument } from "@/types/Documents"
import { SectionState } from "@/types/Section"




const TeamMember = ({teamMember}:{teamMember:GenericSubsectionDocument})=>{
    
    const [isDeleted,setIsDeleted] = useState(false)

    const {isEditing}:PageContextType = useContext(PageContext)
    const {subsectionOrdinals,setSubsectionOrdinals,update,setUpdate}:SectionState= useContext(SectionContext)
    let ordinal = getOrdinal({_id:teamMember._id,ordinals:subsectionOrdinals})
    const [style,setStyle] = useState({
        gridRow:(ordinal+1)
    })

    const handleDelete = async  (e:React.MouseEvent<HTMLButtonElement>)=>{

        const resp = await deleteGenericSubsection({subsectionId:teamMember._id})
        setIsDeleted(true)
        removeFromOrdinals({subsectionOrdinals,setSubsectionOrdinals,_id:teamMember._id})
        setUpdate(!update)
    }
    useEffect(()=>{
        const ordinal = getOrdinal({_id:teamMember._id,ordinals:subsectionOrdinals})
        setStyle({
            gridRow:(ordinal+1)
        })
    },[update])

    if(isDeleted){
        return<></>
    }

    const socials =  getSocials(teamMember)

    
    const first = ordinal==0
    const last = (ordinal+1)===subsectionOrdinals.length
    
    const handleUp = ()=>{
        moveUp({subsectionOrdinals,setSubsectionOrdinals,_id:teamMember._id})
        ordinal = getOrdinal({_id:teamMember._id,ordinals:subsectionOrdinals})
        setUpdate(!update)
    }
    const handleDown = ()=>{
        moveDown({subsectionOrdinals,setSubsectionOrdinals,_id:teamMember._id})
        ordinal = getOrdinal({_id:teamMember._id,ordinals:subsectionOrdinals})
        setUpdate(!update)
    }

    return(
    <div style={style} className="team-member-cont">
    {isEditing&&<>
    {!first&&<button onClick={handleUp}>↑</button>}
    {!last&&<button onClick={handleDown}>↓</button>}
    </>
    }
    <GenericSubsection
        element={teamMember}
        type="teamMember"
        className="team-member"
        autoResizeText={true}
    >
        <Socials
        socials={socials}
        size={40}
        isEditing={isEditing}
        subsectionId={teamMember._id}
        ></Socials>

    </GenericSubsection>
    {isEditing&&<DeleteCircle
        _id={teamMember._id}
        handleDelete={handleDelete}
        tooltip="Remove Host"
        
   ></DeleteCircle>}
    </div>)
}

export default TeamMember

   