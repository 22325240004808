import { CSSField } from "@/types/css"

type Args = {
    cssArray:CSSField[]
}

export type TemplateValidation = {
    error:boolean
    message?:string
}

const validateTemplate = ({cssArray}:Args):TemplateValidation=>{
    cssArray.forEach((field)=>{
        if(!field.selector.includes("."))
        return {error:true,message:"all selectors must be classes"}
    })
    return {error:false}
}

export default validateTemplate