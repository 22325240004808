
    import Breaker from "@/generics/Breaker"
import { beautifyHtml } from "./helpers"
import "./index.scss"
    import React, { useState } from "react"
import LittleCircleButton from "@/generics/buttons/LittleCircleButton"
import { FaEdit, FaSave } from "react-icons/fa"
import FullFlexTextarea from "../FullFlexTextarea"

    type Props = {
        html:string
        setHTML:React.Dispatch<string>
    }

    const SectionBuilderElementsEditor = ({html,setHTML}:Props)=>{

        const [isEditing,setIsEditing] = useState(false)

        const beautifiedHTML = beautifyHtml(html)


        if(!isEditing){
            return(
            <div className="elements-editor">
                <LittleCircleButton
                buttonHandler={()=>setIsEditing(true)}
                >
                    <FaEdit></FaEdit>                    
                </LittleCircleButton>
                <div className="html-text">
                    <Breaker
                    text={beautifiedHTML}
                    tabs={true}
                    maxWidth={400}
                    ></Breaker>
            </div>
                    </div>
                )
        }

        return(
            <div className="elements-editor">
                <LittleCircleButton
                buttonHandler={()=>setIsEditing(false)}
                >
                    <FaSave></FaSave>                  
                </LittleCircleButton>
            <div className="html-edit-cont">

            {"<div class='container'>"}
            <textarea value={html} onChange={(e)=>setHTML(e.target.value)}></textarea>
            {"</div>"}
            </div>
            </div>
            )
    }
    export default SectionBuilderElementsEditor
    
    
    