import backgroundOn from "../fields/backgroundOn";
import headingFont from "../fields/headingFont";
import font from "../fields/font";
import sectionBackground from "../fields/sectionBackground";
import headingWeight from "../fields/headingWeight";
import headingSize from "../fields/sectionHeadingSize";
import textColor from "../fields/textColor";
import headingColor from "../fields/headingColor";
import textAlign from "../fields/textAlign";
import lineHeight from "../fields/lineHeight";

const fields = [
    textColor,
    backgroundOn,
    sectionBackground,
    font,
    headingFont,
    headingWeight,
    headingSize,
    headingColor,
    textAlign,
    lineHeight
]
export default fields