
    import { FaImage } from "react-icons/fa"
import "./index.scss"
    import React, { useState } from "react"
import ImageDropModal from "@/generics/ImageDropModal"

    type Props = {
        handleAdd:(arg:string)=>void
    }

    const ImageGridAdder = ({handleAdd}:Props)=>{
        const [isOpen,setIsOpen] = useState(false)

        const handleSubmit = (newUrl:string)=>{
            handleAdd(newUrl)
            setIsOpen(false)
        }

        if(!isOpen){
            return<button onClick={()=>setIsOpen(true)} data-tooltip="Add Image" className="image-add-button tooltip"><FaImage size={20}></FaImage>                
                </button>
        }

        return(
            <ImageDropModal setIsDropOpen={setIsOpen} handleSubmit={handleSubmit}></ImageDropModal>
        )
    }
    export default ImageGridAdder
    
    
    