export default {prompt:"Event Display?",
field:"eventDisplay",
type:"class",
default:"normal",
options:[
    {value:"normal",text:"Normal"},
    {value:"oneLine",text:"Single Line"},
    {value:"wideWithButton",text:"Wide with Button"},
    {value:"dateOnSide",text:"Date on Side"}
]
}