
    import "./index.scss"
    import React, { useState } from "react"
import HomepageHeader from "@/layout/headers/HomepageHeader"
import Form from "@/generics/Form"
import { validateEmail, validateSetNewPassword } from "@/utils/yup"
import FormErrors from "@/generics/FormErrors"
import { requestNewPasswordLink, resetPassword } from "@/api/resetPassword"
import getSlug from "@/utils/getSlug"

    const ForgotPassword = ()=>{
        const confirmationCode = getSlug().slice(1)
        const [errors,setErrors] = useState<any>([])
        const [isComplete,setIsComplete] = useState(false)

        const fields = [
            {name:"email",type:"email"}
        ]

        type FormValues = {
            email:string,
            password:string,
            confirmPassword:string,
            recaptchaToken:string
        }

        const handleSubmit = async (values:FormValues)=>{
            const checkRecaptcha = values?.recaptchaToken
            if(!checkRecaptcha){

                return
            }
            const validation = await validateEmail(values)
            if(validation.isError){

                const errors = validation.errors
                setErrors(errors)
                return
            }
            const resp = await requestNewPasswordLink({...values})
            if(resp&&"message" in resp){
                setErrors([resp.message])
            }else{
                setIsComplete(true)
            }
        }

        return(<>
                    <HomepageHeader additionalClass="black"></HomepageHeader>
            {isComplete&&<main className="success">
                <h2>Success</h2>
                <p>Look for the link for reseting your password in your email.</p>
                </main>}
            {!isComplete&&<main className="forgot-password">
            <h2>Request a Reset Password Link</h2>
            <Form
            fields={fields}
            recaptcha={true}
            handleSubmit={handleSubmit}
            ></Form>
            <FormErrors formErrors={errors}></FormErrors>
            </main>}
        </>
        )
    }
    export default ForgotPassword
    
    
    