import backgroundOn from "../fields/backgroundOn";
import font from "../fields/font";
import headingFont from "../fields/headingFont";
import heroClip from "../fields/heroClip";
import horizontalTextContAlign from "../fields/horizontalTextContAlign";
import imageOverlay from "../fields/imageOverlay";
import isTextWithinPicture from "../fields/isTextWithinPicture";
import overlayOpacity from "../fields/overlayOpacity";
import sectionBackground from "../fields/sectionBackground";
import textAlignmentWithinPicture from "../fields/textAlignmentWithinPicture";
import textAnimation from "../fields/textAnimation";
import textColor from "../fields/textColor";
import textContBackground from "../fields/textContBackground";
import textScale from "../fields/textScale";

export default [
    textScale,
    textColor,
    backgroundOn,
    sectionBackground,
    font,
    headingFont,
    textAlignmentWithinPicture,
    horizontalTextContAlign,
    textAnimation,
    heroClip,
    textContBackground,
    isTextWithinPicture,
    overlayOpacity
]