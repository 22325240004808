
    import "./index.scss"
    import React, { useContext, useState } from "react"
import PaymentMethods from "../PaymentMethods"
import { Context, ContextValueType } from "@/context"
import { subscribe, SubscribeVariables } from "@/api/stripe"
import Card from "@/generics/Card"
import { PageDetailsType } from "@/types/Page"
import { PaymentMethodType } from "@stripe/stripe-js"
import { PromoType } from "../PromoCodeBox"

type Props = {
    page:PageDetailsType,
    promo:PromoType
}

    const SubscriptionBox = ({page,promo}:Props)=>{
        const [paymentMethod,setPaymentMethod] = useState(false)
        return(
            <div id="subscription-box">

            <PaymentMethods
            setSubscriptionPaymentMethod={setPaymentMethod}
            ></PaymentMethods>
            {paymentMethod?<ChooseSubscription promo={promo} page={page} paymentMethod={paymentMethod}></ChooseSubscription>:<></>}
            </div>
        )
    }
    export default SubscriptionBox
    
    type ChooseSubscriptionProps = {
        paymentMethod:   any,
        page:PageDetailsType,
        promo:PromoType
    }
    
    function ChooseSubscription({paymentMethod,page,promo}:ChooseSubscriptionProps){

       const {setConfirmation}:ContextValueType = useContext(Context)
       const [isConfirmationOn,setIsConfirmationOn] = useState(false)
        const {credentials}:ContextValueType = useContext(Context)
        const userId = credentials.user._id
        const [errorMessage,setErrorMessage] = useState<null|string>(null)
        const [isLoading,setIsLoading] = useState(false)
        const confirmSubscription = async ()=>{
            setIsLoading(true)
            const variables:SubscribeVariables = {userId,pageId:page._id,pm_id:paymentMethod.id}
            if(promo){
                variables.promoCode = promo.code
            }
            const res = await subscribe(variables)
            if(res.error){
                setConfirmation(null)
                setErrorMessage("Card not working. Choose/Add a different payment method.")
                return
            }
            window.location.reload()
        }

        const handleClick = ()=>{
            setIsConfirmationOn(true)
        }

        const handleSub = ()=>{
            confirmSubscription()
        }

        const handleCancel = ()=>{
            setIsConfirmationOn(false)
        }



        return (
            <div className="choose-subscription">
                {!isConfirmationOn&&<><h3>Click Subscribe below for a $5/month basic plan.
                to be charged on your {paymentMethod.card.brand.toUpperCase()} (xxxx-{paymentMethod.card.last4}) </h3>
                
                <button onClick={handleClick} className="secondary">Subscribe</button></>}
                {isConfirmationOn&&!isLoading&&
                <div>
                <h3>Confirm your subscription to make page live:</h3>
                <div className="button-cont">
                <button onClick={handleSub} className="secondary">Confirm</button>
                <button onClick={handleCancel} className="danger">Cancel</button></div></div>
                }
                {isLoading&&<h3>Setting up Payment With Stripe</h3>}
                {errorMessage?<div className="error-message">{errorMessage}</div>:<></>}
            </div>
        )
    }