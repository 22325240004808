import { Field } from "@/types/Section"
import { makeFieldObject } from "./arrays"
import { DynamicAnyObject, DynamicObject } from "@/types/generic"
import { getChildrenAsHTML } from "./DOM"

type ElementSpec = {
    elementTag:string
    regex:RegExp
    template:string
    replacers:{match:string,replacement:string}[]
    innerHTMLTransform:(arg:string)=>string
}

const youtube:ElementSpec = {
    elementTag:"youtube",
    regex: /<youtube[\s\S]*?<\/youtube>/,
    // template: `<iframe class="video" src="{{innerHTML}}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="" style="height: 380px; width: 700px;"></iframe>`,
    // template: `<iframe class="video" src="{{innerHTML}}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="" style="height: 380px; width: 700px;"></iframe>`,
    template: `<iframe class="video" src="{{innerHTML}}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>`,
    replacers:[],
    innerHTMLTransform:(srcString:string)=>{
        let embedURL = srcString.replaceAll(" ","")
        if(embedURL.includes('watch?v=')){
            const urlSplit = srcString.split('watch?v=')
            embedURL = urlSplit[0]+"embed/"+urlSplit[1]
        }
        if(embedURL.includes('youtu.be')){
            embedURL = embedURL.replace("youtu.be","youtube.com/embed")
        }
        if(embedURL.includes("/shorts/")){
            const urlSplit = srcString.split('shorts')
            embedURL = urlSplit[0]+"embed/"+urlSplit[1]
        }
        const endParams = "?modestbranding=1;controls=0;showinfo=0;rel=0;fs=1"
        embedURL+=endParams
        return embedURL
    }
}

const carousel:ElementSpec = {
    elementTag:"carousel",
    regex: /<carousel[\s\S]*?<\/carousel>/,
    template: `<div id="carousel-mount">
    </div>`,
    replacers:[],
    innerHTMLTransform(arg:string){
        return arg}
}

interface Resolver {
    customElements:ElementSpec[]
    fields:DynamicObject
    children: DynamicAnyObject

}

class Resolver {
    constructor({fields}:{fields?:Field[]}){
        this.customElements = [carousel,youtube];
        this.fields = makeFieldObject((fields||[]))
        this.children={}
    }
    replaceSingleElement({html,elementTag}:{html:string,elementTag:string}){
        let revisedHTML = html
        const spec = this.customElements.find(c=>c.elementTag===elementTag)
        if(!spec){
            return
        }
        let searchExhausted = false;

        while (!searchExhausted){
            const match = revisedHTML.match(spec?.regex)?.[0]
            if(!match){
                searchExhausted = true
                continue
            }
            const innerHTML = (match as unknown as string).match(/(?<=>)(.*?)(?=<)/)?.[0]
            const field = innerHTML?.split("::")?.[0]
            const innerHTMLValue = this.fields?.[field||""]||innerHTML?.split("::")?.[1]||innerHTML||""
            const finalInnerHTMLValue = spec.innerHTMLTransform(innerHTMLValue)
            const replacement = spec.template.replace(`{{innerHTML}}`,finalInnerHTMLValue||"")
            revisedHTML = revisedHTML.replace(spec?.regex,replacement)
            const children = getChildrenAsHTML({html,selector:elementTag})
            if(children){
                this.children[elementTag] = children   
            }
        }
        return revisedHTML
    }
    replaceCustomElements({html}:{html:string}){
        let revisedHTML = html;
        this.customElements.forEach((customElement:ElementSpec)=>{
            revisedHTML = this.replaceSingleElement({html:revisedHTML,elementTag:customElement.elementTag})||revisedHTML
        })

        return revisedHTML
    }
}



const handleCustomElements = ({html,fields}:{html:string,fields?:Field[]})=>{
    const customElementResolver = new Resolver({fields})
    const newHTML = customElementResolver.replaceCustomElements({html})
    return {newHTML,children:customElementResolver.children}
}

export default handleCustomElements