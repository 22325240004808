const dummy = [
    {
        pageName:"www.comradetripp.com",
        url:"https://www.comradetripp.com",
        image:"https://www.comradetripp.com/api/upload/ff83bca0-68fa-460b-b207-4480ec8e7ae1"
    },
    {
        pageName:"www.theharamguys.com",
        url:"https://www.theharamguys.com",
        image:"https://www.theharamguys.com/api/upload/29da7b04-4f19-4a95-b7c1-a26de1ca4630"
    },
    {
        pageName:"www.kevinlschwartz.com",
        url:"https://www.kevinlschwartz.com",
        image:"https://www.kevinlschwartz.com/api/upload/a937a745-08d2-4f6a-902f-bb738f207619"
    }
]




export default dummy
