import getSrcAttribute from "./getScriptStringSrc"


export const loadAsyncScript = (asyncScript:string,parentId:string,index:number)=>{
    const dataId = `${parentId}-${index}`
    const existingScript = document.querySelector(`[data-id='${dataId}']`)
    if(existingScript){
        return
    }
    const newScript = document.createElement("script")
    const srcAttr = getSrcAttribute(asyncScript)
    newScript.dataset.id = dataId
    if (!srcAttr){
        return
    }
    newScript.src = srcAttr
    document.querySelector("#root")?.appendChild(newScript)
    // 
}


export const loadScript = (script:string,parentId:string,index:number)=>{
    const dataId = `${parentId}-${index}`
    const existingScript = document.querySelector(`[data-id='${dataId}']`)
    if(existingScript){
        return
    }
    const div = document.createElement("div")
    div.innerHTML = script
    const newScript:any = div.querySelector("script")
    newScript.dataset.id = dataId
    newScript.defer = true;
    div.removeChild(newScript)
    document.querySelector("#root")?.appendChild(newScript)

}

