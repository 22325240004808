    import "./index.scss"
    import React, { MouseEventHandler, useContext } from "react"
import { PageContextType } from "@/types/Context"
import { PageContext } from "@/pages/Standard/context"
import DeleteCircle from "../DeleteCircle"

    type Props = {
        children:JSX.Element
        _id:string
        handleDelete:MouseEventHandler<HTMLButtonElement>
        buttonPosition?:string
    }

    const DeleteableCont = ({children,_id,handleDelete,buttonPosition}:Props)=>{
        const {isEditing}:PageContextType = useContext(PageContext)
        return(
            <div className={`deleteable-cont`}>
                {!isEditing?<></>:<DeleteCircle
                _id={_id}
                handleDelete={handleDelete} 
                buttonPosition={buttonPosition}
                />}
                {children}
            </div>
        )
    }
    export default DeleteableCont
    
    
    