const getParams = (url:string)=>{
    const params = new URLSearchParams(new URL(url).search);
    const pageId = params.get("pageId")
    const submissionId = params.get("submissionId")
    const isDev = submissionId?true:false
    return {
        pageId,submissionId,isDev
    }

}

export default getParams