
    import React,{MouseEvent, MouseEventHandler} from "react"
import { FaSave } from "react-icons/fa"

    type Props = {
        handleSave:MouseEventHandler
    }

    const SaveButton = ({handleSave}:Props)=>{
        return(
            <button onClick={handleSave} className="btn-primary"><FaSave></FaSave></button>
        )
    }
    export default SaveButton
    
    
    