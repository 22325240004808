
    import { Children } from "@/types/JSX"
import "./index.scss"
    import React from "react"

    type Props = {
        confirmationPayload:any,
        setConfirmationPayload:React.Dispatch<any>,
        handleConfirm:(e:any)=>void
        children:Children
    }

    const ConfirmationPopUp = ({children,handleConfirm,setConfirmationPayload,confirmationPayload}:Props)=>{
        
        const handleConfirmButton = ()=>{
            handleConfirm(confirmationPayload);
            setConfirmationPayload(null)
        }

        const handleCancelButton = ()=>{
            setConfirmationPayload(null)
        }
        
        return(
            <div className="overlay confirmation-overlay">
            <div className="modal">
                {children}
                <div className="button-cont">
                <button className="danger" onClick={handleConfirmButton}>Confirm</button>
                <button className="" onClick={handleCancelButton}>Cancel</button>
                </div>
            </div>
            </div>
        )
    }
    export default ConfirmationPopUp
    
    
    