import { DynamicObject } from "@/types/generic";

export const changeDate = (oldDate:string,newDate:Date)=>{
    const oldTime = oldDate.split("T")[1]
    let formattedDate = newDate.toISOString().slice(0, 10);
    const res = formattedDate+"T"+oldTime

    return res
}

export const changeTime = (oldDateString:string,newTime:string)=>{
    const oldDate = oldDateString.split("T")[0]
    const newDate = `${oldDate}T${newTime}:00.000Z`
    const res = newDate
    return res
}

export const convertToMiltaryTime = (time:string):string=>{
    let [hours, minutes] = time.split(":");
    if (hours === "12") {
      hours = "00";
    }
    if((parseInt(hours)<10)&&(hours.toString().length<2)){
        hours = "0"+hours
    }
    if(!minutes){
        minutes = "00"
    }
    if (time.includes("PM")) {
      hours = (parseInt(hours, 10) + 12).toString();
    }
    if (time.includes("AM")) {
        hours = hours.replace(" AM","");
      }
    const res= `${hours}:${minutes}`.split(" ")[0];
    return res
}

export const makeString = ({obj}:{obj:(string|DynamicObject)}):string=>{
    if(typeof obj === "string"){
        return obj
    }
    return obj.toString()
}

export const setDateValue = (date:string)=>{
    try{
        const res = new Date(date)
        const timestamp = res.getTime()
        if(isNaN(timestamp)){
            throw new Error()
        }
        return res
    }catch(err){
        return new Date()
    }


}

// const lastString = stringArray[stringArray.length-1]