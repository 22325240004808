
import { useState } from "react"
import "./index.scss"

import MaxWidthAutoText from "@/generics/MaxWidthAutoText"

const TestPage = ()=>{

    const [value,setValue] = useState("")
    
    return<></>
}
export default TestPage
    
    
    