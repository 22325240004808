import { GenericSubsectionDocument } from "@/types/Documents"

export const convertFieldsArrayForForm = (inputs:any[])=>{
    const res =  inputs.map((i)=>{
        const obj = {type:i.type,name:i.label,options:i.options,required:i.required==="false"?false:true,selectOptions:i.options}
        return obj

    })
    return res
}

export const removeUnrelatedFields = (subsection:GenericSubsectionDocument)=>{
    const {heading,image,paragraph} = subsection
    return {heading,image,paragraph}
}