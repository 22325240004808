const layoutObject = {prompt:"Layout?",
field:"layout",
type:"class",
default:"basic",
options:[
    {value:"basic",text:"Basic"},
    {value:"border-side",text:"Border Box"},
]
}


export default layoutObject