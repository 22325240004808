
    import MiddleStack from "@/generics/MiddleStack"
import "./index.scss"
    import React from "react"
import RedirectButton from "@/generics/RedirectButton"

    const SectionSubmitSuccess = ()=>{
        return(

            <MiddleStack className="section-submit-success">
                <h1>Congratulations, your section has been submitted.</h1>
                <p>You will be notified if/when the client chooses your submission. If they do you will be credited with tokens which you can cash in.</p>
                <div className="buttons">
                    <RedirectButton to="/">Return to Dash</RedirectButton>
                    <RedirectButton to="/section-jobs">Return to Jobs Board</RedirectButton>
                </div>
            </MiddleStack>
            )
    }
    export default SectionSubmitSuccess
    
    
    