import { addGenericSubsection } from "@/api/genericSubsection";

export const addHost = async ({sectionId}:{sectionId:string})=>{
    const teamMember = {
        heading: "John Smith",
        image: "/images/comedian.jpg",
        paragraph: "John Smith is a dynamic and charismatic host who has quickly become a leading voice in the podcasting world. With a background in journalism, he brings a unique perspective to the conversations he has on his show, covering the latest trends and hot topics in a variety of industries."
      };
    const resp = await addGenericSubsection({sectionId,fields:teamMember,type:"teamMember"})
    return resp
}