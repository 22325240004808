
    import parseCSS from "@/pages/SectionTemplateBuilder/transformCSSToArray"
import "./index.scss"
    import React, { useEffect, useState } from "react"
import { CSSLineType } from "../CSSEditor"

    type Props = {
        setCssArray:React.Dispatch<CSSLineType[]>
        setIsModalOpen:React.Dispatch<boolean>
    }

    const AddCSSText = ({setCssArray,setIsModalOpen}:Props)=>{
        
        const [value,setValue] = useState("")
        
        useEffect(()=>{
            const array = parseCSS(value)
            setCssArray(array)
        },[value])

        return(
            <div className="add-css-text-cont">
            <textarea onChange={(e)=>setValue(e.target.value)} value={value}></textarea>
            <button onClick={()=>setIsModalOpen(false)}>Save CSS</button>
            </div>
        )
    }
    export default AddCSSText
    
    
    