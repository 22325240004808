import backgroundOn from "../fields/backgroundOn";
import episodeBackground from "../fields/episodeBackground";
import episodeColor from "../fields/episodeColor";
import font from "../fields/font";
import headingFont from "../fields/headingFont";
import sectionBackground from "../fields/sectionBackground";
import textColor from "../fields/textColor";
import generics from "./generics";


export default [
    ...generics,
    episodeBackground,
    episodeColor
]