const checkIfParentFullWidth = ({buttonElement}:{buttonElement:HTMLButtonElement})=>{
    const parent = buttonElement?.parentNode
    if(parent){
        const width = parent.querySelector("img")?.getBoundingClientRect()?.width
        const windowWidth = document.querySelector("body")?.getBoundingClientRect()?.width
        if(windowWidth&&width&&(windowWidth-width)<50){
            return true
        }
    }
    return false
}

export default checkIfParentFullWidth