import {addGenericSubsection} from "@/api/genericSubsection"
import PuxMod, { SectionContext } from "@/components/PuxMod"
import { PageContext } from "@/pages/Standard/context"
import { PageContextType } from "@/types/Context"
import { SectionState, SectionType } from "@/types/Section"
import { addToOrdinals } from "@/utils/subsectionOrdering"
import { useContext, useState } from "react"
import "./index.scss"
import { GenericSubsectionDocument } from "@/types/Documents"
import LinkedImageSubsection from "@/subsections/LinkedImageSubsection"
import ImageGridAdder from "../ImageGrid/ImageGridAdder"

    const LinkedImageGrid = ({section}:{section:SectionType})=>{
        const [linkedImageArray,setLinkedImageArray] = useState(section.genericSubsections)
        const {isEditing}:PageContextType = useContext(PageContext)

        return<PuxMod section={section}>
            <>
            <div className="linked-image-grid">
            {(linkedImageArray || []).map((linkedImageDoc: GenericSubsectionDocument,index:number) => {
                return <LinkedImageSubsection key={index} linkedImageDoc={linkedImageDoc}/>;
            })}
            </div>
            {isEditing?<Add
            linkedImageArray={linkedImageArray}
            setLinkedImageArray={setLinkedImageArray}
            section={section}
            ></Add>:<></>}
            </>

        </PuxMod>
    }
    export default LinkedImageGrid

    type AddProps = {
        linkedImageArray:GenericSubsectionDocument[]
        setLinkedImageArray:React.Dispatch<GenericSubsectionDocument[]>
        section:SectionType
    }
    
    function Add({linkedImageArray,setLinkedImageArray,section}:AddProps){

        const {subsectionOrdinals,setSubsectionOrdinals}:SectionState = useContext(SectionContext)


        const handleAdd = async (url:string)=>{
            const resp = await addGenericSubsection({sectionId:section._id,fields:{imageURL:url,linkURL:"google.com",linkText:"Google"},type:"imageWithLink"})
            addToOrdinals({subsectionOrdinals,setSubsectionOrdinals,_id:resp._id})
            const newArray = [...linkedImageArray]
            newArray.push({...resp,ordinal:linkedImageArray.length})
            setLinkedImageArray(newArray)
        }

        return<ImageGridAdder handleAdd={handleAdd}></ImageGridAdder>
    }
    