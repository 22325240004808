
    import { GenericSubsectionDocument } from "@/types/Documents"
import CustomElementResolver from "../CustomElementResolver"
import SubPuxMod, { SubsectionContext } from "../SubPuxMod"
import "./index.scss"
    import React, { useContext } from "react"
import { CustomElementType } from "@/types/sectionTemplate"
import { CSSField } from "@/types/css"
import { CustomElementObj } from "@/types/customElements"
import checkIfFlex from "../CustomElementResolver/checkIfFlex"
import DeleteCircle from "@/generics/DeleteCircle"
import { SectionContext } from "../PuxMod"
import { SectionState } from "@/types/Section"

    type Props = {
         subsection:GenericSubsectionDocument
        cssArray:CSSField[]
        children:CustomElementType[]
        className:string    
    }


    const CustomSubsectionResolver = ({subsection,cssArray,className,children}:Props)=>{
        
        const rootElement = {
            classString:`sub-section ${className}`,
            tag:"div",
            children:children,
            editable:false,
            attributes:[],
            id:"",

        }
        
        return(
            <SubPuxMod
            subsection={subsection}
            >
                <WithinContext
                rootElement={rootElement}
                cssArray={cssArray}
                ></WithinContext>
            </SubPuxMod>
        )
    }


    type WithinProps = {
        rootElement:CustomElementType
        cssArray:CSSField[]
    }

    const WithinContext = ({rootElement,cssArray}:WithinProps)=>{

        const subFlex = checkIfFlex({element:rootElement,cssArray})
        const {subsection,isDeleted}:any = useContext(SubsectionContext)

        if(isDeleted){
            return<></>
        }

        return(

        <>
        <CustomElementResolver
        cssArray={cssArray}
        element={rootElement}
        subsection={subsection}
        subFlex={subFlex}
        parentHorizontalPadding={0}
        ></CustomElementResolver>
        </>
        )

    }

    export default CustomSubsectionResolver
    
    
    