import { SectionContext } from "@/components/PuxMod"
import { Context, ContextValueType } from "@/context"
import { PageContext } from "@/pages/Standard/context"
import { PageContextType } from "@/types/Context"
import { SectionState } from "@/types/Section"
import { useContext } from "react"

type IdsType = {
    userId:null|string
    pageId:null|string
    sectionId:null|string
}

export default ()=>{

    const mainContext:ContextValueType = useContext(Context)
    let isAdmin;
    if(mainContext&&mainContext?.credentials?.user?._id){
        isAdmin = mainContext.credentials.isAdmin
    }
    return isAdmin
}