
    import "./index.scss"
    import React from "react"

    type Props = {
        filled?:boolean
        handleClick?:()=>void
    }

    const Dot = ({filled,handleClick}:Props)=>{
        return(
            <div onClick={(handleClick||(()=>{}))} className="dot">
                {!filled?<></>:<div className="filler"></div>}
            </div>
        )
    }
    export default Dot
    
    
    