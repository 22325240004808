
    import "./index.css"
    import React from "react"
import { slugify } from "@/utils/stringFormatting"

    const ArticleDetails = ({title}:{title:string})=>{
        return(
            <div>
                <a href={`/_article/${slugify(title)}`}>{title}</a>
            </div>
        )
    }
    export default ArticleDetails
    
    
    