import uploadAndSetUrl from "./setFileUrl";

const handleDrop = async (e:any,handleSubmit:(arg0:string)=>void)=>{
    e.preventDefault()
    const dt = e.dataTransfer;
    const files = dt.files;
    uploadAndSetUrl(files,handleSubmit)

}

export default handleDrop