import { Procrast } from "@/types/procrast"
import { getLayoutOptions } from "@/utils/sectionEditorUtils/layout"
import { makeFullStyleObject } from "@/utils/theme"

type AddChildStylesProps = {
    selector:string,
    parent:HTMLElement,
    value:(string|number)
    property:any
}

export const addChildStylesProps = ({selector,parent,value,property}:AddChildStylesProps)=>{
    if(selector===".main-cont"){
    }
    const children:HTMLElement[] = Array.from(parent?.querySelectorAll(selector)||[])
    if(!selector.includes(".")){

        children.push(...Array.from(parent?.querySelectorAll(`.${selector}-tag`)||[]) as HTMLElement[])
    }
    if(selector==="i"){
    }
    children?.forEach((child:Procrast)=>{
        if(property==="--icon-color"){
        }
        child.style[property] = value
    })   
}

type AddChildClassProps = {
    selector:string
    parent:HTMLElement
    value:string
}

export const addChildClasses = ({selector,parent,value}:AddChildClassProps)=>{
    if(typeof value !=="string" ||value===""){
        return
    }
    const children:HTMLElement[] = Array.from(parent?.querySelectorAll(selector)||[])
    const split = value.split("-")
    const property = `${split[0]}-${split[1]}`
    children?.forEach((child:Procrast)=>{
            const matchingClasses = (Array.from(child?.classList||[]) as string[]).filter((className:string)=>{
                return className.includes(property)
            })           
            matchingClasses.forEach((className)=>{
                child.classList.remove(className)
            })
           child.classList.add(value)
    })   
}


type GetChildStylesAndClassesProps = {
    styleString:string
    sectionType:string
}

export type ChildStyle = {
    selector:string
    property:(keyof CSSStyleDeclaration)
    value:string|number
}

export type ChildClass = {
    selector:string
    value:string
}

export type FullChildSpec = {
    childStyles:ChildStyle[]
    childClasses:ChildClass[]
}

export const getChildStylesAndClasses = ({styleString,sectionType}:GetChildStylesAndClassesProps)=>{
    const styleObject = makeFullStyleObject(styleString)
    const layoutObjectArray = getLayoutOptions(sectionType.replaceAll("-",""))
    const childStyles = layoutObjectArray.filter((layoutObject)=>{
        return (layoutObject.type==="childStyle")
    })
    const childClasses = layoutObjectArray.filter((layoutObject)=>{
        return (layoutObject.type==="childClass")
    })
    const res:FullChildSpec = {childStyles:[],childClasses:[]}
    childStyles.forEach((style)=>{
        if(styleObject[style.field]!==undefined){
            const obj = {selector:style.child||"",property:style.property||"",value:styleObject[style.field]} as ChildStyle
                res.childStyles.push(obj)
        }
    })
    childClasses.forEach((style)=>{
        if(styleObject[style.field]!==undefined){
            const obj = {selector:style.child||"",value:styleObject[style.field]||""}
            if(obj.selector){
                res.childClasses.push(obj)
            }
        }
    })
    return res
}