import { Field } from "@/types/Section"
import { DynamicObject } from "@/types/generic"

export const addToFields = ({fields,newField}:{fields:Field[],newField:Field})=>{
    const copiedFields = [...fields]
    const match = copiedFields.find(field=>field.key===newField.key)
    if(match){
        match.value = newField.value
    }else{
        copiedFields.push(newField)
    }
    return copiedFields
}

const childValues = ["--headingColor","--headingFont"]

const valueMap = [
    {name:"--headingColor",
    element:"heading",
    key:"color"
},
{name:"--headingFont",
element:"heading",
key:"fontFamily"
},

]

export const getChildStyles = (string:string)=>{
    const childStyles:any = {}
    
    const items = string.split(" ")
    for (let item of items){
        let [key,value] = item.split(":")
        if(!value){
            [key,value] = item.split("xx")
        }
        if(childValues.includes(key)){
            const obj = valueMap.find(el=>el.name===key)
            if(!obj){
                continue
            }
            if(!childStyles[obj.element]){
                childStyles[obj.element] = {}
                
            }
            childStyles[obj.element][obj.key] = value
        }
    }
    return childStyles
}