import { GenericSubsectionDocument } from "@/types/Documents"
import { CSSField } from "@/types/css"
import { CustomElementType } from "@/types/sectionTemplate"

export const fixBRs = (text:string)=>{
    return text.replaceAll("$<$br$>$","\n")
}

type Props = {
    element:CustomElementType
    cssArray:CSSField[]
    subsection:GenericSubsectionDocument
}

export const checkIfSubParentFlex = ({element,cssArray,subsection}:Props)=>{
    
    

    if(!subsection){
        return false
    }

    return true
}

export const hasBlockTag = (tag:string)=>{
    const blockTags = [
        "header","footer","div"
    ]
    return blockTags.includes(tag)
}