
    import "./index.css"
    import React from "react"
import { t } from "@/utils/stringFormatting"

    const FormErrors = ({formErrors}:{formErrors:string[]})=>{

        return(
            <div>
                {formErrors.map((e:string,i:number)=>{
                    return(<div className="form-error" key={i}>{t(e)}</div>)
                })}
            </div>
        )
    }
    export default FormErrors
    
    
    