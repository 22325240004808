import { CSSLineType } from "@/components/CSSEditor"
import { DynamicAnyObject } from "@/types/generic"

type Args = {
    stylesObject:DynamicAnyObject,
    customCSSArray:CSSLineType[]
    sectionElement:any
}

const renderCustomTemplateStyles = ({stylesObject,customCSSArray,sectionElement}:Args)=>{
    for (let property in stylesObject){
        const value = stylesObject[property]
        const match = customCSSArray.find(c=>c.variable===property)
        if(match){

            const els = Array.from((sectionElement as any).querySelectorAll(match.selector))
            els.forEach((el)=>{
                (el as any).style[match.property] = value
            })
        }
            
    }
}

export default renderCustomTemplateStyles