
    import "./index.scss"
    import React from "react"
import { Children } from "./JSX"

    const TextEditorHeader = ({children}:{children:Children})=>{
        return(
            <header className="text-editor-header">
                <div className="input-cont">

                {children}
                </div>
            </header>
        )
    }
    export default TextEditorHeader
    
    
    