import { useEffect, useRef } from 'react';



const useHideMenuButtons = (disable?:boolean) => {
  const sectionElement = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const closestSection:(HTMLElement|null) = (sectionElement.current as HTMLElement).closest('nav');
    if (closestSection && !disable) {
      const navButtons:HTMLElement[] = Array.from(closestSection.querySelectorAll(".inline-button"))
      navButtons.forEach(nb=>{
        nb.style.display = "none"
      })
    }

    return () => {
      if (closestSection && !disable) {
        const navButtons:HTMLElement[] = Array.from(closestSection.querySelectorAll(".inline-button"))
        navButtons.forEach(nb=>{
          nb.style.display = "inline-block"
        })
      }
    };
  }, []);

  return sectionElement;
};

export default useHideMenuButtons;
