export const getScreenDimensions = ()=>{
    const dimensions = {width:window.innerWidth,height:window.innerHeight}
    return dimensions
}

export const isMobile = ()=>{
    if(getScreenDimensions().width<800){
        return true
    }
    return false
}

export const getTopBottom = ()=>{
    const top = window.pageYOffset;

    const height = window.innerHeight;
    const bottom = top+height;
    return {top,bottom}
}