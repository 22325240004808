
    import { FaArrowLeft } from "react-icons/fa"
import AddSectionConfirm from "../AddSectionConfirm"
import SectionChooser from "../SectionChooser"
import SectionThumbnail from "../SectionThumbnail"
import "./index.scss"
    import React, { useContext, useState } from "react"
import BackButtonCont from "../BackButtonCont"
import { PageContextType } from "@/types/Context"
import { PageContext } from "@/pages/Standard/context"

    type Props = {
        sections:string[]
        close:()=>void
        handleAdd:(sectionName:string)=>void
    }

    const AddSectionView = ({sections,close,handleAdd}:Props)=>{


        const [hoveredSection,setHoveredSection] = useState<null|string>(null)
        const [selected,setSelected] = useState<null|string>(null)



        return(
            <div className="add-section-view">
                <SectionChooser setSelected={setSelected} setHoveredSection={setHoveredSection} sections={sections}></SectionChooser>
                <div className="add-section-right">
                <BackButtonCont
                close={close}
                ></BackButtonCont>
                <SectionThumbnail sectionName={hoveredSection||selected}></SectionThumbnail>
                <div className="add-section-confirm-cont">
                    {selected&&<AddSectionConfirm selected={selected}
                    handleAdd={handleAdd}
                    ></AddSectionConfirm>}
                </div>
                </div>
            </div>
        )
    }
    export default AddSectionView
    
    
    