export const getParsedValue = (value:string)=>{
    const splitString = value.split(":")
    if(splitString.length>1){
        return splitString[1]
    }
    return splitString[0]
}

export const setStylesWithParentId = ({styles,parentId}:any)=>{
    const parent = document.getElementById(parentId)
    if(!parent){
        return
    }
    styles.forEach((style:any)=>{
        const {selector,property,value} = style
        if(selector!==""){
            try{
                const parsedValue = getParsedValue(value)
                const matches = Array.from(parent.querySelectorAll(selector))
                for (let match of matches){
                    match.style[property] = parsedValue
                }
            }catch{

            }
        }
    })
}