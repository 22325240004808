const createEmbedObject = (elements:string) => {
    const asyncScripts:string[] = [];
    const syncScripts:string[] = [];
    const scriptRegex = /<script\b[^>]*>([\s\S]*?)<\/script>/gi;
    const scripts = elements.match(scriptRegex) || [];
    const html = elements.replace(scriptRegex, '');
  
    scripts.forEach((script:string) => {
      if (script.match(/src=/i)) {
        asyncScripts.push(script);
      } else {
        syncScripts.push(script);
      }
    });
  
    return { html, asyncScripts, syncScripts };
  };

export default createEmbedObject