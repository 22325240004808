import { APIFailure } from "@/types/API"
import { DynamicObject } from "@/types/generic"
import { PopulatedPage } from "@/types/Page"
import { getSlug } from "../pages/Standard/helpers"
import { fexDelete, fexGet, fexPost, fexPut } from "../utils/fex"
import getJWT from "@/utils/getJWT"

export type GetPageSuccess = PopulatedPage

export type GetPageResponse = (APIFailure | GetPageSuccess)
export type CreatePageResponse = (APIFailure | PopulatedPage)

export const getPage = async ({host}:{host:string}):Promise<GetPageResponse>=>{
    try{
        const data:GetPageResponse = await fexGet(`api/page/${host||getSlug()}`)
        return data

    }catch(err){
        let message = "Unknown Error"
        if(err instanceof Error){
            message = err.message
        }
        return {error:true,message}
    }
}

export type CreatePageBody = {
    userId:string,
    pageName:string,
    template:string,
    recaptchaToken:string
}

export const createPage = async (body:CreatePageBody):Promise<CreatePageResponse>=>{
    try{
        localStorage.setItem("geeee",JSON.stringify(body))
        const jwt = getJWT()
        const data:CreatePageResponse = await fexPost("api/page",body)
        if("isOn" in data && data.isOn){
            window.location.reload()
        }
        return data
    }catch(err){
        let message = "Unknown Error"
        if(err instanceof Error){
            message = err.message
        }
        return {error:true,message}
    }
}

export type DeletePageResponse = (APIFailure | {success:true})

export const deletePage = async (pageId:string):Promise<DeletePageResponse>=>{
    const resp:DeletePageResponse = await fexDelete(`api/page/${pageId}`,{})
    return resp
}

export const setInstructionsToFalse = ({pageId}:{pageId:string})=>{
    fexPut("api/page",{pageId,changes:{instructions:false}})
}

export const editPageAdditionalFields = async ({pageName,fields}:{pageName:string,fields:DynamicObject})=>{
    try{
        const resp = await fexPut("api/page/fields/"+pageName,{fields})

    }catch(err){

    }
}

export const paginate = async ({pageId}:{pageId:string})=>{
    try{
        const resp = await fexPut("api/page/paginate/"+pageId,{})
        return resp
    }catch(err){
        return err
    }
}

export const unpaginate = async ({pageId}:{pageId:string})=>{
    try{
        const resp = await fexPut("api/page/unpaginate/"+pageId,{})
        return resp

    }catch(err){

    }
}