import { DynamicObject } from "@/types/generic"

const explanations:DynamicObject = {}

explanations.Twitter = "Enter your twitter name (i.e. handle handle) into the input bar and it will populate (may take a couple seconds)."

explanations.TikTok = "To add a video, paste in the video id or the url for the video into the input bar and press the plus button."

const getExplanation = (topic:string)=>{
    const explanation = explanations[topic]
    return explanation
}


export default getExplanation