
    import "./index.scss"
    import React from "react"
import useMainZIndex from "@/hooks/useMainZIndex"
import CloseButton from "@/generics/CloseButton"

    type Props = {
        children:JSX.Element|JSX.Element[]
        setIsOpen:React.Dispatch<boolean>
        additionalClass?:string
    }

    const FullPageMiddleScroller = ({children,setIsOpen,additionalClass}:Props)=>{
        const mainZIndexHelper = useMainZIndex();


        
        return(
            <main ref={mainZIndexHelper} className={`full-page-middle-scroller ${additionalClass}`}>
                <section>
                {children}
                </section>
                <CloseButton
                setIsOpen={setIsOpen}
                ></CloseButton>
            </main>
        )
    }
    export default FullPageMiddleScroller
    
    
    