import { getChild, getElementHeight } from "@/utils/elementProperties"
import { getStyleValue } from "@/utils/theme"

export const getDimensions = ({section}:{section:HTMLElement})=>{
    const sectionHeight = getElementHeight(section)
    const textCont = getChild({element:section,selector:".text-cont"})
    const textHeight = getElementHeight(textCont)
    const dimensions = {sectionHeight,textHeight}
    return dimensions
}

type Dimensions = {
    sectionHeight:number,
    textHeight:number
}

type Props = {
    dimensions:Dimensions,
    currentScale:number,
    proportion:number
}

export const calculateNewFontScale = ({dimensions,currentScale,proportion}:Props)=>{
    const currentProportion = (dimensions.textHeight-window.innerWidth*.02)/(dimensions.sectionHeight-window.innerWidth*.02);
    const multiplier = proportion/currentProportion

    return multiplier
}

export const calculateHeightBasedOnRow = ({scale,headingRows
    ,subRows}:{scale:number,headingRows
    :number,subRows:number})=>{
    const headingHeight = 2*scale*(headingRows)
    const headingMargin = 2*scale*.5
    const subHeight = scale*(subRows)
    const subMargin = subRows?scale*.5:0
    const containerPadding = 2
    return (headingHeight+headingMargin+subHeight+subMargin+containerPadding)*window.innerWidth/100
}

export const getSetScale = (style:string)=>{
    const res = getStyleValue({styleString:style,field:"--textScale"})
    return parseFloat(res)
}

type CalculateNumRowsProps = {
    string:string
    elementWidthVw:number
    elementFontFamily:string
    elementFontSizeVw:number
}

export const calculateNumRows = ({string, elementWidthVw, elementFontFamily,elementFontSizeVw}:CalculateNumRowsProps)=> {
    const words = string.split(" ");
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  // Get the viewport width in pixels
  const viewportWidth = window.innerWidth;

  // Convert the element width and font size from vw to pixels
  const elementWidth = (elementWidthVw / 100) * viewportWidth;
  const elementFontSize = (elementFontSizeVw / 100) * viewportWidth;
  // Set the canvas font based on the element font size and family
  if(!ctx){
    return
  }
  ctx.font = `${elementFontSize}px ${elementFontFamily}`;

  let currentLineLength = 0;
  let numRows = 1;

  for (let i = 0; i < words.length; i++) {
    const wordWidth = ctx.measureText(words[i]).width;
    if (currentLineLength + wordWidth <= elementWidth) {
      // Add the word to the current line
      currentLineLength += wordWidth;
    } else {
      // Start a new line with the current word
      currentLineLength = wordWidth;
      numRows++;
    }
  }

  return numRows;
}