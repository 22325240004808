
    import "./index.css"
    import React, { useState } from "react"
import ExpanderButton from "../buttons/ExpanderButton"

    const Expander = ({header,body}:{header:string,body:string})=>{

        

        const [isExpanded,setIsExpanded] = useState(false)

        return(
            <div className="expander">
            <header>
            <h3 onClick={(()=>setIsExpanded(!isExpanded))}>{header}</h3>
            <ExpanderButton
            isExpanded={isExpanded}
            setIsExpanded={setIsExpanded}

            ></ExpanderButton>
            </header>
            <main className={isExpanded?"expanded":""}>
            <p>{body}</p>
            </main>
            </div>
        )
    }
    export default Expander
    
    
    