
    import Stretcher from "@/generics/Stretcher"
import { getParsedValue, setStylesWithParentId } from "./helpers"
import "./index.scss"
    import React, { useEffect, useRef, useState } from "react"
import createResponsiveStyles from "./createResponsiveStyles"
import ReactDOM from "react-dom"
import Carousel from "@/generics/Carousel"
import parse from 'html-react-parser';

    type Props = {
        html:string
        styles:any
        frameHeight?:string
        parentId?:string
        specifiedWidth?:number|null
        children?:any
    }

    const SectionTemplatePreview = ({html,children,styles,frameHeight,parentId,specifiedWidth}:Props)=>{
        const previewRef = useRef<HTMLElement>(null)
        const [previewWidth,setPreviewWidth] = useState<null|number>(null)

        useEffect(()=>{
            if(specifiedWidth){
                setPreviewWidth(specifiedWidth)
            }
        },[specifiedWidth])


        useEffect(()=>{
            if(parentId){
                setStylesWithParentId({styles,parentId})
                return
            }
            const responsiveStyles = previewWidth?createResponsiveStyles({styles,previewWidth}):styles
            responsiveStyles.forEach((style:any)=>{
                const {selector,property,value} = style
                if(selector!==""){
                    try{
                        const parsedValue = getParsedValue(value)
                        const matches = Array.from(document.querySelectorAll(selector))
                        for (let match of matches){
                            match.style[property] = parsedValue
                        }
                    }catch(err){
                        console.log({err})
                    }
                }
            })
        },[html,styles,specifiedWidth,previewWidth])


        // this mounts the custom components

        useEffect(() => {
            if (previewRef.current) {
              const mountPoint = (previewRef as any).current.querySelector('#carousel-mount');
              if (mountPoint) {
                ReactDOM.render(<Carousel
                // timer={3000}
                >
                    {children.carousel.map((c:string)=>{
                        return parse(c)})}
                </Carousel>, mountPoint);
              }
            }
          }, [html,children]);

        return(
            <Stretcher frameHeight={frameHeight||"80vh"}
            specifiedWidth={specifiedWidth} 
            setPreviewWidth={setPreviewWidth}           
            >
            <section className="preview section-preview" ref={previewRef} dangerouslySetInnerHTML={{__html:html}}></section>
            </Stretcher>
        )
    }
    export default SectionTemplatePreview
    
    
    