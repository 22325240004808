
    import "./index.css"
    import React, { ChangeEvent, useContext, useEffect, useState } from "react"
import { SectionType } from "@/types/Section"
import * as VideoComp from "@/generics/Video"
import { PageContextType } from "@/types/Context"
import { PageContext } from "@/pages/Standard/context"
import { firstLastCheck, makeFieldObject, reorderSections } from "@/utils/arrays"
import Section from "@/generics/Section"
import { DynamicObject } from "@/types/generic"
import { useDebounce } from "use-debounce"
import { changeIsOn, editSectionFields } from "@/api/section"
import { getEditOrdinal } from "@/utils/pageManipulation"
import ToggleOn from "@/components/widgets/ToggleOn"
import ReorderArrows from "@/components/widgets/ReorderArrows"
import SectionHeading from "@/generics/SectionHeading"
import LayoutModal from "@/components/LayoutModal"
const VideoGeneric = VideoComp.default

    type Props = {
        section:SectionType
    }

    const Video = ({section}:Props)=>{
        const pageContext:PageContextType = useContext(PageContext)
        const {isEditing} = pageContext
        const [fields,setFields]:[any,any] = useState(null)
        const {editOrdinals,setEditOrdinals} =pageContext
        const ordinal = getEditOrdinal({sectionId:section._id,editOrdinals})
        useEffect(()=>{
            if(section.fields){
                setFields(makeFieldObject(section.fields))
            }
        },[section])
        if(!fields){
            return<></>
        }
        if(isEditing){
            return <EditSection section={section} initialFields={fields} sectionId={section._id}></EditSection>
        }

        if(!section.isOn){
            return<></>
        }

        const {url,heading} = fields
        return(
            <Section  ordinal={ordinal}  id="Video" styleString={section.style} sectionName="video">
            {!heading?<></>:<SectionHeading text={heading}></SectionHeading>}

            <VideoGeneric src={url}></VideoGeneric>
            </Section>
        )
    }
    export default Video
    
    type EditSectionProps = {
        initialFields:DynamicObject,
        sectionId:string,
        section:SectionType
    }
    
    function EditSection({initialFields,sectionId,section}:EditSectionProps){
        const [fields,setFields] = useState(initialFields)
        const [initialized,setInitialized] = useState(false)
        const [isOn,setIsOn] = useState(section.isOn)
        const [delayedFields] = useDebounce(fields,1000)
        const [layoutModalOpen,setLayoutModalOpen] = useState(false)
        const [style,setStyle] = useState(section.style)

        const handleChange = (e:ChangeEvent<(HTMLInputElement|HTMLTextAreaElement)>)=>{
            const {name,value} = e.target
        
            const newFields = {...fields}
            newFields[name] = value;

            setFields({...newFields})
        }

        useEffect(()=>{
            if(!initialized){
                setInitialized(true)
                return
            }
            changeIsOn({sectionId:section._id,isOn})
        },[isOn])

        useEffect(()=>{
            if(fields===initialFields){
                return
            }
            const changedFields:DynamicObject = {}
            for(let fieldKey in delayedFields){
                if(delayedFields[fieldKey]!==initialFields[fieldKey]){
                    changedFields[fieldKey] = delayedFields[fieldKey]
                }
            }
            const data = editSectionFields({sectionId,changedFields})
        },[delayedFields])



        const {editOrdinals,setEditOrdinals}:PageContextType = useContext(PageContext)
        const ordinal = getEditOrdinal({sectionId:section._id,editOrdinals})
        const {first,last} = firstLastCheck({ordinal,length:editOrdinals.length})

        const handleEditScreen = (direction:string)=>{
            const newEditOrdinals = reorderSections({editOrdinals,sectionId:section._id,direction})
            setEditOrdinals(newEditOrdinals)
        }


        const {url,heading} = fields

        return(
            <Section id="Video"  styleString={style}   ordinal={ordinal} sectionName={"video "+(isOn?"":"isOff editing")+(layoutModalOpen?" z-top":"")}>
            <ToggleOn isOn={isOn} setIsOn={setIsOn}></ToggleOn>
            {!isOn?<></>:<LayoutModal
            style={style}
            setStyle={setStyle}
            sectionType={section.sectionForm}
            sectionId={section._id}
            setLayoutModalOpen={setLayoutModalOpen}
            sectionName="Video Section"
                ></LayoutModal>  }
            <ReorderArrows first={first} last={last} section={section} handleEditScreen={handleEditScreen}></ReorderArrows>
            {!isOn?<div className="off-label">Video Section</div>:<>
            {!heading?<></>:<SectionHeading text={heading}></SectionHeading>}
            <VideoGeneric src={url.toString()}></VideoGeneric>
            <input value={url} name="url" onChange={handleChange}></input>
            </>}
            </Section>
        )
    }