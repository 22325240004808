import privateView from "@/api/privateView"
import { getOpacityBaseTen } from "@/components/ColorSelector/ColorPickerOptions/helpers"
import { makeFieldObject } from "@/utils/arrays"
import { makeFullStyleObject } from "@/utils/theme"
import { EditOrdinalsType } from "./context"
import { CalculatedStateAction } from "./valuesReducer"
import { PageDetailsType } from "@/types/Page"
import { SectionType } from "@/types/Section"

export const getSlug = ()=>{
    const slug = window.location.pathname.replace("/","")
    return slug
}



export const getPrivateView = async (setOwnPage:React.Dispatch<boolean>)=>{
    const res = await privateView()
    if(res){
        setOwnPage(res)
    }
}

export const checkEditOrdinals = ({editOrdinals}:{editOrdinals:EditOrdinalsType[]})=>{
    const length = editOrdinals.length;
    for(let i = 0;i<length;i++){
        const found = editOrdinals.find((o)=>{
           return(o.ordinal===i)
        })
        if(!found){
            return false
        }
    }
    return true    
}



type NavReducerActionProps = {
    navStyle:string
    calculatedValuesDispatch:React.Dispatch<CalculatedStateAction>
}

export const navReducerAction = ({navStyle,calculatedValuesDispatch}:NavReducerActionProps)=>{
    const fields = makeFullStyleObject(navStyle) 
    if(fields.backgroundState==="off"){
        calculatedValuesDispatch({type:'setHeaderOn',payload:false})
    }
    if(fields.background){
        const opacity = getOpacityBaseTen(fields.background)
        calculatedValuesDispatch({type:"setHeaderOpacity",payload:opacity})
    }
}

export const checkIfHeroImage = (page:PageDetailsType)=>{
    const hero = page.sections.find((section)=>{
        return section.sectionForm==="Hero"})
    if (hero?.isOn&&hero?.fields){
        const image = hero.fields.find(f=>f.key==="image")
        if(image){
            return true
        }
    }
    return false
}

export const logOutTokenInfo = ()=>{
    const sessionStorageCred =JSON.parse( sessionStorage?.getItem("the_one_cred")||"{}")
    if(sessionStorageCred===""){
        return
    }
    const token = sessionStorageCred.accessToken
}

export const getBodyWidth = ()=>{
    const body = document.querySelector("body")
    return body?.getBoundingClientRect().width
}



export const checkIsFullHeight = ()=>{
    const body = document.querySelector("body")
    const bodyHeight =  body?.getBoundingClientRect().height
    if(!bodyHeight){
        return false
    }
    const viewportHeight = window.innerHeight
    return viewportHeight<bodyHeight
}