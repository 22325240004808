import "./index.scss"
    import React, { ChangeEventHandler, useContext, useEffect, useState } from "react"
import { EventType } from "../../types/Event"
import { EventWithParsedDateType, parseDate } from "../../utils/timeStrings"
import { PageContext } from "@/pages/Standard/context"
import { PageContextType } from "@/types/Context"
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import DeleteCircle from "@/generics/DeleteCircle"
import { deleteEvent, editEvent } from "@/api/event"
import { EventsContext } from "@/sections/Events/context"
import { useDebounce } from "use-debounce"
import { changeDate, changeTime, convertToMiltaryTime, setDateValue } from "./helpers"
import { DynamicObject } from "@/types/generic"
import { fixHref } from "@/utils/links"
import { SectionContext } from "../PuxMod"
import { getStyleValue, makeFullStyleObject } from "@/utils/theme"
import { adjustColorBrightness } from "@/utils/color"
import SideButtons from "@/generics/SideButtons"
import Modal from "@/generics/Modal"
import { SectionState } from "@/types/Section"
import { extractDigits, longDateFormat } from "@/utils/stringFormatting"
import { FaLandmark, FaMapMarker, FaMarker } from "react-icons/fa"

    const Event = ({event,sectionId}:{event:EventType,sectionId:string,type?:string})=>{
        const {isEditing}:PageContextType = useContext(PageContext)
        const {date} = event
        const parsedEvent={...event,...parseDate(date)}
        const [isModalOpen,setIsModalOpen] = useState(false)
        const {events,setEvents}:{events:EventType[],setEvents:React.Dispatch<EventType[]>} = useContext(EventsContext)

        const handleDelete:React.MouseEventHandler<HTMLButtonElement> = async (e)=>{
            const eventId = event._id
            if(!eventId){
                return
            }
            const filteredEvents = [...events].filter(e=>{
                return e._id!==eventId})
    
            setEvents(filteredEvents)
            const data = await deleteEvent({sectionId,eventId})
        }

        return<div className="event-cont">
            <EventPublic
            parsedEvent={parsedEvent}
            date={date}
            ></EventPublic>
            {isEditing&&<SideButtons
            handleDelete={handleDelete}
            handleEdit={()=>setIsModalOpen(true)}
            ></SideButtons>}
            {isModalOpen&&<EditModal
            setIsModalOpen={setIsModalOpen}
            event={event}
            />}
        </div>
    }
    export default Event

type Props = {
    setIsModalOpen:React.Dispatch<boolean>
    event:EventType
}
    
function EditModal({setIsModalOpen,event}:Props){
    const {events,setEvents}:{events:EventType[],setEvents:React.Dispatch<EventType[]>} = useContext(EventsContext)
    const [currentEvent,setCurrentEvent] = useState(event)
    const [delayedEvent]:[any,any] = useDebounce(currentEvent,1000)
    const {date} = currentEvent
    const fixedDate = (typeof date)==="string"?date:date.toString()
    const parsedEvent={...currentEvent,...parseDate(fixedDate),date}
    const [time,setTime] = useState(convertToMiltaryTime(parsedEvent?.time||""))

    useEffect(()=>{
        if(currentEvent===event){
            return
        }
        const changedFields:DynamicObject = {}
        for(let fieldKey in delayedEvent){
            if(delayedEvent[fieldKey]!==event[fieldKey as keyof EventType]){
                changedFields[fieldKey] = delayedEvent[fieldKey]
            }
        }
        const newEvents = [...events].map(e=>{
            if(e._id!==currentEvent._id){
                return e
            }else return currentEvent
        })
        setEvents(newEvents)
        if(!currentEvent._id){
            return
        }
        const data = editEvent({eventId:currentEvent._id,changedFields})
    },[delayedEvent])

    const handleInputChange:ChangeEventHandler = (e:React.ChangeEvent<HTMLInputElement>)=>{
        if(!(e.target instanceof HTMLInputElement)){
            return
        }
        const target:HTMLInputElement = e.target as HTMLInputElement
        const {value} = target
        const name = target.name
        const changedEvent:EventType = {...currentEvent}
        if(!(name in changedEvent)){
            return
        }
        changedEvent[name as keyof EventType] = value;
        setCurrentEvent(changedEvent)
    }

    const handleDateChange = (calendarInput:Date)=>{
        const newDate = changeDate(date,calendarInput)
        const fixedDate = newDate.toString()
        setCurrentEvent({...currentEvent,date:fixedDate})
    }

    const handleTimeChange:ChangeEventHandler<HTMLInputElement> = (e)=>{
        const clockInput = e.target.value;
        setTime(clockInput)
    }
    useEffect(()=>{
        if(!time){
            return
        }
        const timeRegex = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;
        if(time.search(timeRegex)>-1){
            const newDate = changeTime(date,time)
            const fixedDate:string = newDate
            setCurrentEvent({...currentEvent,date:fixedDate})
        }
    },[time])


    return<Modal setIsModalOpen={setIsModalOpen}>
        <div>
        <div className="event">
            <input placeholder="Show Name (optional)" onChange={handleInputChange} name="title" className="two-col h2" value={parsedEvent.title}></input>
            <h2>{parsedEvent.day}</h2>
            <div className="input-cont">
            <label>Venue</label>
            <input onChange={handleInputChange} name="venue" className="h2" value={parsedEvent.venue}></input>
            </div>
            <div className="input-cont">
            <label>Date</label>
            <div className="date-cont">
            <DatePicker
            selected={setDateValue(date)}
            onChange={(date:Date) => handleDateChange(date)}></DatePicker>
            </div>
            </div>            
            <div className="input-cont">
                <label>City</label>
                <input onChange={handleInputChange} name="city" className="h3" value={parsedEvent.city}></input>
                </div>
            <div className="input-cont">
                <label>Start Time</label>
                <input onChange={handleTimeChange} type="time" value={time}></input>
                </div>
            <div className="input-cont">
                <label>Event Details/Ticket Link</label>
                <input onChange={handleInputChange} name="link" className="h4" value={parsedEvent.link}></input>
                </div>
        </div>
        <button className="save-btn" onClick={()=>setIsModalOpen(false)}>Save</button>
        </div>
    </Modal>
}

type EventPublicType = {
    parsedEvent:EventWithParsedDateType,
    date:string
}

function EventPublic({parsedEvent,date}:EventPublicType){
    const sectionC:SectionState = useContext(SectionContext)
    const eventDisplay = getStyleValue({styleString:sectionC.style,field:"eventDisplay"})
    const backgroundColor = getStyleValue({styleString:sectionC.style,field:"background"})
    let linkStyle:DynamicObject = {}
    if(backgroundColor){
        const contrastColor = adjustColorBrightness({color:backgroundColor})
        if(contrastColor){
            linkStyle.color = contrastColor
        }
    }
    if(eventDisplay==="oneLine"){
        return <div className="one-line-event">
            <h3>{parsedEvent.date} - {parsedEvent.venue}, {parsedEvent.city} <a style={linkStyle} href={parsedEvent.link}>Tickets</a></h3>
        </div>
    }

    const monthAbbrev = parsedEvent.date.substring(0,3)
    const dateNumber = extractDigits(parsedEvent.date)

    if(eventDisplay==="dateOnSide"){
        return<div className="date-on-side">
            <div className="left">
                <div className="month">
                    {monthAbbrev.toUpperCase()}
                </div>
                <div className="date">
                    {dateNumber}
                </div>
            </div>
            <div className="right">
                <h3>{longDateFormat(date)}</h3>
                {parsedEvent.title&&parsedEvent.title.length>0&&<h3 className="title">{parsedEvent.title}</h3>}
                <h5>{parsedEvent.time}</h5>
                <h5 className="location"><FaMapMarker></FaMapMarker>{parsedEvent.venue}, {parsedEvent.city}</h5>
                <div>
                <a href={parsedEvent.link}>Tickets/Details</a>
                </div>
            </div>
        </div>
    }

    const navigate = ()=>{
        window.location.href = parsedEvent.link||""
    }

    if(eventDisplay==="wideWithButton"){
        return <div className="wide-with-button">
            <div className="left">
                <h2>{parsedEvent.date}</h2>
                <h4>{parsedEvent.time}</h4>
            </div>
            <div className="middle">
            <h2>{parsedEvent.city}</h2>
                <h4>{parsedEvent.venue}</h4>
            </div>
            <div className="right">
                {parsedEvent.link&&<button onClick={navigate}>Details/Tickets</button>}
            </div>
            {/* <h3>{parsedEvent.date} - {parsedEvent.venue}, {parsedEvent.city} <a style={linkStyle} href={parsedEvent.link}>Tickets</a></h3> */}
        </div>
    }



    return(
        <div className="event">
            {parsedEvent.title&&(parsedEvent.title.length>0)?<h2 className="two-col">{parsedEvent.title}</h2>:<div className="two-col"></div>}
            <h3>{parsedEvent.day}</h3>
            <h3>{parsedEvent.venue}</h3>
            <h4>{parsedEvent.date}</h4>
            <h4>{parsedEvent.city}</h4>
            <h4>{parsedEvent.time}</h4>
            {parsedEvent.link&&parsedEvent.link.length>0?<a href={fixHref(parsedEvent.link)}>
                <h4>Event Details</h4>
                </a>:<></>}
        </div>
    )
}