export default     {
    prompt:"Casing?",
    field:"textTransform",
    type:"style",
    // default:"unset",
    options:[
        {value:"unset",text:"Default"},
        {value:"lowercase",text:"Lowercase"},
        {value:"uppercase",text:"Uppercase"}
    ]
}