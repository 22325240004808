
    import Socials from "@/generics/Socials"
import "./index.css"
    import React, { useEffect, useState } from "react"
import { getSectionFields } from "@/api/section"

    type Props = {
        socialsId:string
    }

    const EPKSocials = ({socialsId}:Props)=>{
        const [socials,setSocials] = useState({})
        const [fetched,setFetched] = useState(false)

        useEffect(()=>{
            const f = async ()=>{
                const socialSectionFields = await getSectionFields({sectionId:socialsId})
                setSocials(socialSectionFields)
                setFetched(true)
                }
            f()
        },[])

        if(!fetched){
            return<></>
        }

        return(
            <Socials
            socials={socials}
            isEditing={false}
            ></Socials>
        )
    }
    export default EPKSocials
    
    
    