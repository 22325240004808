import "./index.scss"
import AddCreditCard from "@/generics/AddCreditCard"
import { useContext, useEffect, useState } from "react"
import { getUsersPaymentMethods } from "@/api/stripe"
import { Context, ContextValueType } from "@/context"
import PaymentMethod from "@/generics/PaymentMethod"
import { PaymentMethod as PaymentMethodType } from "@stripe/stripe-js"

    const PaymentMethods = ({setSubscriptionPaymentMethod,starting_pm_id,setIsChangingPaymentMethod}:   any)=>{
        const [isAdding,setIsAdding] = useState(false)
        const [selectedIndex,setSelectedIndex] = useState<null|number>(null)
        const [paymentMethods,setPaymentMethods] = useState<any>([])
        const [added,setAdded] = useState(false)
        const {credentials}:ContextValueType = useContext(Context)
        const userId = credentials.user._id
        useEffect(()=>{
            const fetchPaymentMethods = async (userId:string)=>{
                const data = await getUsersPaymentMethods(userId)
                if("error" in data && data.error){
                    return
                }
                if(!Array.isArray(data)){
                    return
                }
                if(data.length===0){
                    setIsAdding(true)
                }
                setPaymentMethods(data)
            }
            fetchPaymentMethods(userId)
        },[])

        useEffect(()=>{

            if(paymentMethods&&starting_pm_id){
                paymentMethods.forEach((pm:PaymentMethodType,index:number)=>{
                    if(pm.id===starting_pm_id){

                        setSelectedIndex(index)
                    }
                })
            }
        },[paymentMethods])

        useEffect(()=>{
            if(selectedIndex===null||!paymentMethods[selectedIndex]){
                return
            }
            setSubscriptionPaymentMethod(paymentMethods[selectedIndex])

            // setIsChangingPaymentMethod(false)
        },[paymentMethods,selectedIndex])

        useEffect(()=>{
            if(added){
                setSelectedIndex(paymentMethods.length-1)
                setSubscriptionPaymentMethod(paymentMethods[paymentMethods.length-1])

            }
        },[paymentMethods])

        const handleClick = (index:number)=>{
            setSelectedIndex(index)
        }

        const addCardCallback = ()=>{
            setAdded(true)
        }

        return(<div className="payment-methods">
        {!isAdding&&<>
            <h3>Choose a Previous Payment Method</h3>
        {paymentMethods.map((p:PaymentMethodType,index:number)=>{
            return(<PaymentMethod handleClick={()=>{handleClick(index)}} selected={selectedIndex===index} paymentMethod={p} key={index}></PaymentMethod>)
        })}
        </>
        }
            {isAdding?<AddCreditCard
            paymentMethods={paymentMethods}
            setPaymentMethods={setPaymentMethods}
            setIsAdding={setIsAdding}
            addCardCallback={addCardCallback}
            ></AddCreditCard>:<button onClick={()=>setIsAdding(true)}>Add Card</button>}
        </div>

        )
    }
    export default PaymentMethods
    
    
    