import { DynamicObject } from "@/types/generic"

type StyleObject = {
    class:string,
    styles:DynamicObject
}

export const parseStyles = (styleString:string) => {

    const styleObject:StyleObject = {class:"",styles:{}}
    const separated = styleString.split(" ")
    const classStrings = separated.filter((text:string)=>{
        return (text.indexOf("xx")>-1)
    }).map((string)=>{
        const [front,back] = string.split("xx")
        return `${back}__${front}`
    })
    const styleStrings = separated.filter((text,index)=>{
        return (text.indexOf(":")>-1)
    }).forEach((string)=>{
        const [front,back] = string.split(":")
        styleObject.styles[front] = back
    })
    styleObject.class = classStrings.join(" ")
    return styleObject
}

export const stringifyStyles = (styleObject:StyleObject)=>{
    let resString = ""
    styleObject.class.split(" ").forEach((text,index)=>{
        const [value,key] = text.split("__")
        const subString = `${key}xx${value}`
        resString+=subString+" "
    })
    for (let key in styleObject.styles){
        const subString = `${key}:${styleObject.styles[key]}`
        resString += subString+" "
    }
    if(resString[resString.length-1]===" "){
        return resString.slice(0,-1)
    }
    return resString
}

export const makeFullStyleObject = (styleString:string)=>{
    const fields = (styleString||"").split(" ")
    const obj:DynamicObject = {}
    for(let field of fields){
        if(field.includes("xx")){
            const [key,value] = field.split("xx")
            obj[key] = value
        }
        else if(field.includes(":")){
            const [key,value] = field.split(":")
            obj[key] = value
        }
    }
    return obj
}

type GetStyleValueArgs = {
    styleString:string
    field:string
}

export const getStyleValue = ({styleString,field}:GetStyleValueArgs)=>{
    const styleObj = makeFullStyleObject(styleString)
    return styleObj[field]
}