import "./index.scss"
import {useState,useContext} from "react"
import BlogEditor from "@/generics/BlogEditor"
import { PageContext } from "@/pages/Standard/context"
import { PageContextType } from "@/types/Context"
import { SectionType } from "@/types/Section"
import PuxMod from "@/components/PuxMod"
import BlogEntry from "@/generics/BlogEntry"
import { Procrast } from "@/types/procrast"

    const Blog = ({section}:{section:SectionType})=>{

        const [isOn,setIsOn] = useState(section.isOn)
        const {isEditing}:PageContextType = useContext(PageContext)
        const [blogEntries,setBlogEntries] = useState(
            section.genericSubsections)
        
        const [isEditingPost,setIsEditingPost] = useState(false)
        const [selectedEntry,setSelectedEntry] = useState<null|any>(null)

        const openEditor = (_id:string)=>{

            const entry = blogEntries.find((a)=>{
                return a._id===_id})
            setIsEditingPost(true)
            setSelectedEntry(entry)
        }

        const reorderedBlogEntries:Procrast = blogEntries.sort((a,b)=>{
            return (new Date(b.createdAt) as unknown as number) - (new Date(a.createdAt) as unknown as number);
        })

        if(!isOn&&!isEditing){
            return<></>
          }

        return(
            <PuxMod
            section={section}
            >
            {!isEditing?<></>:<button className="mb-3" onClick={()=>setIsEditingPost(true)}>Add New Post</button>}
                {reorderedBlogEntries.map((b:Procrast,i:number)=>{
                    return(
                        <BlogEntry
                        key={i}
                        blogDetails={b}
                        openEditor={openEditor}
                        blogEntries={blogEntries}
                        setBlogEntries={setBlogEntries}
                        ></BlogEntry>
                    )
                })}
                            {isEditingPost?<BlogEditor
                setIsEditing={setIsEditingPost}
                sectionId={section._id}
                blogEntries={blogEntries}
                setBlogEntries={setBlogEntries}
                postDetails={selectedEntry}
                subsectionId={selectedEntry?._id||""}

                />:<></>}
            </PuxMod>
        )

        
    }
    export default Blog
