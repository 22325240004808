
    import { getColorGrid } from "@/utils/color"
import ColorGrid from "../ColorGrid"
import "./index.css"
    import React from "react"
import { DynamicObjectWithNumber } from "@/types/generic"


    type Props = {
        handleChange:(arg0:string)=>void
        opacity:number
    }

    const StandardColors = ({handleChange,opacity}:Props)=>{

        const colors = getColorGrid(15,15)

        return(
            <ColorGrid colors={colors} 
            handleChange={handleChange}
            columns={15}
            size={20}
            opacity={opacity}
            ></ColorGrid>
        )
    }
    export default StandardColors
    
    
    