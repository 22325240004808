
    import TemplateLister from "@/components/TemplateLister"
import "./index.css"
    import React from "react"

    const PremiumSectionTemplates = ()=>{
        return(

<TemplateLister></TemplateLister>        )
    }
    export default PremiumSectionTemplates
    
    
    