import imageAndTextLayout from "../fields/imageAndTextLayout";
import paragraphFontSize from "../fields/paragraphFontSize";
import generics from "./generics";

const fields = [
    imageAndTextLayout,
    ...generics,
    paragraphFontSize

]

export default fields 
