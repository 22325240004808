import { EditOrdinalsType } from "@/pages/Standard/context"
import { DynamicObject } from "@/types/generic"

// this one will require some thought

export const convertArrayIntoObject = (array: any[],key:string)=>{
    const object:  any = {}
    for (let element of array){
        const fieldName = element[key]
        if(!fieldName){
            throw new Error(`Key '${key}' not found in all array elements.`)
        }
        object[fieldName] = element
    }
    return object
}

export const makeFieldObject = (fields:{key:string,value:(string|number)}[])=>{
    if(!fields){
        return {}
    }
    const fieldsObject:   any = {}
    for (let field of fields){
        if(!field.key){
            throw new Error("Key missing in object.")
        }
        if(field.value===undefined){
            fieldsObject[field.key] = ""
            // throw new Error(`Value missing for ${field.key} key.`)
        }
        fieldsObject[field.key] = field.value
    }
    return fieldsObject
}

export const firstLastCheck = ({ordinal,length}:{ordinal:number,length:number})=>{
    const res = {first:false,last:false}
    if(ordinal===0){
        res.first = true
    }
    if(ordinal===(length-1)){
        res.last = true
    }
    return res
}

type ReorderOpArg = {
    editOrdinals:EditOrdinalsType[],
    sectionId:string,
    direction:string
}

export const reorderSections = ({editOrdinals,sectionId,direction}:ReorderOpArg)=>{
    let obj = editOrdinals.find((obj:EditOrdinalsType) => obj.sectionId === sectionId);
    if(obj===undefined){
        return editOrdinals
    }
    const oldOrdinal = obj.ordinal;
    const newOrdinal = direction==="up"?oldOrdinal-1:oldOrdinal+1
    const newArray = [...editOrdinals]
    obj = newArray.find((obj:EditOrdinalsType) => obj.sectionId === sectionId)
    const objectToChange = newArray.find((obj:EditOrdinalsType) => obj.ordinal === newOrdinal)
    if(direction==="up"&&!(obj?.ordinal===undefined||objectToChange?.ordinal===undefined)){
        obj.ordinal--
        objectToChange.ordinal++
    }
    else if(direction==="down"&&!(obj?.ordinal===undefined||objectToChange?.ordinal===undefined)){
        obj.ordinal++
        objectToChange.ordinal--
    }

  // Return the updated objects array
  return newArray;
}

export const paginate = ({array,pageSize}:{array:any[],pageSize:number})=>{
    const result = []
    while(array.length>pageSize){
        result.push(array.splice(0,pageSize))
    }
    if(array.length>0){
        result.push(array)
    }
    return result
}

export const removeNthElement = (arr:any[], n:number)=>{
    if (arr.length <= n) {
      return arr; 
    }
    arr.splice(n, 1);
    return arr;
}


export const convertObjectIntoArray = (object:any)=>{
    const res:{key:string,value:string}[] = []
    for(let key in object){
        const element = {key,value:object[key]}
        res.push(element)
    }
    return res
}


export function pairwiseArray(arr: any[]): any[][] {
    const result: any[][] = [];
    
    for (let i = 0; i < arr.length; i += 2) {
      result.push([arr[i], arr[i + 1]]);
    }
    
    return result;
  }

  export function tripletArray(arr: any[]): any[][] {
    const result: any[][] = [];
    
    for (let i = 0; i < arr.length; i += 3) {
      result.push([arr[i], arr[i + 1],arr[i+2]]);
    }
    
    return result;
  }

