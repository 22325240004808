export default     {
    prompt:"Image Size?",
    field:"--teamImageSize",
    type:"childClass",
    child:"img",
    default:"img-size-set-medium",
    options:[
        {value:"img-size-set-small",text:"Small"},
        {value:"img-size-set-medium",text:"Medium"},
        {value:"img-size-set-large",text:"Large"}
    ]
}