
    import SectionTemplateTable from "../SectionTemplateTable"
import "./index.scss"
    import React from "react"

    const TemplateTable = ()=>{
        return(
            <section className="template-section">
            <SectionTemplateTable></SectionTemplateTable>
            </section>
        )
    }
    export default TemplateTable
    
    
    