import "./index.scss"
import React, { ChangeEvent, useEffect, useState } from "react"
import { DynamicObject, DynamicObjectWithNumber } from "@/types/generic"
import ColorPickerOptions from "./ColorPickerOptions"
import OpacitySlider from "./OpacitySlider"
import { getHexValue, getOpacityBaseTen, replaceOpacity } from "./ColorPickerOptions/helpers"
import { getNestedModalTranslate } from "@/utils/positioning"
import { ColorObjectType } from "@/types/Color"

    type Props = {
        value:string,
        setValue:React.Dispatch<string>,
        setSelectorOpen?:React.Dispatch<any>
        prompt?:string,
        backgroundColor?:string,
        color?:string,
        layout?:string
        reposition?:boolean
        positioning?:string
        inline?:boolean
        initialOpen?:boolean
    }

    const ColorSelector = ({prompt,value,setValue,color="#000000ff",backgroundColor="#ffffffff",reposition,positioning,setSelectorOpen,layout,inline,initialOpen}:Props)=>{
        const [isModalOpen,setIsModalOpen] = useState(initialOpen||false)
        const [pickerWidth,setPickerWidth] = useState("250px")
        const [modalStyle,setModalStyle] = useState<DynamicObjectWithNumber>({})
        const [inputValue,setInputValue] = useState(value.slice(1,7))
        useEffect(()=>{
            if(!inline||!isModalOpen){
                return
            }
            setSelectorOpen&&setSelectorOpen(layout)
        },[isModalOpen])

        useEffect(()=>{
                setPickerWidth("250px")
        },[])
        
        useEffect(()=>{

        },[])


        const handleColorChange = (color:string,field:string,opacity:number)=>{
            const opacityHex = Math.round(opacity*255).toString(16)
            const fullColor = color+opacityHex
            setValue(fullColor)
            setInputValue(value.slice(1,7))
        }

        const handleOpacityChange = (opacity:number)=>{

            const opacityHex = getHexValue(opacity)

            const fullColor = replaceOpacity({color:value,opacity:opacityHex})

            setValue(fullColor)
        }

        const style:DynamicObjectWithNumber = {
        }
        if(isModalOpen){
            style.zIndex = 100000
        }   

        const pickerProps = {
        width:pickerWidth,
        height:"200px",
        color:value,
        onChange:(color:ColorObjectType)=>handleColorChange(color.hex,"backgroundColor",(color?.hsl?.a||1))
        }

        const handleButton = (e:React.MouseEvent<HTMLButtonElement>)=>{
            setIsModalOpen(true)
            if(!reposition){
                return
            }
            if(positioning!=="header"){
                const translate = getNestedModalTranslate({element:(e.target as HTMLElement),parentModalSelector:".layout-modal"})
                setModalStyle(translate||{})
            }
        }


        const handleHexInputChange = (e:ChangeEvent<HTMLInputElement>)=>{
            const newValue = e.target.value
            const parsed = newValue.replaceAll("#","")
            if(parsed.length>6){
                return
            }
            setInputValue(parsed)
            const end = value.substring(value.length-2)
            const fullColor = `#${parsed}${end}`
            setValue(fullColor)
        }

        
        

        return(
            <div style={style} className="color-selector">
                {!isModalOpen&&<button className="color-btn" style={{"backgroundColor":value}}  onClick={handleButton}>
                    <span>
                        {prompt}
                    </span>
                </button>}
                {isModalOpen&&<div className="color-selector-body">
                    <>
                    {setSelectorOpen&&<button className="back-btn" onClick={()=>setSelectorOpen(null)}>Back</button>}
                    <ColorPickerOptions
                    pickerProps={pickerProps}
                    ></ColorPickerOptions>
                    <div className="bottom-opt">

                    <OpacitySlider
                    handleOpacityChange={handleOpacityChange}
                    initialValue={(100-getOpacityBaseTen(value))}
                    />
                    <div className="hex-cont">

                    <input onChange={handleHexInputChange} value={inputValue}></input>
                    <label>Hex #</label>
                    </div>
                    </div>
                    </>
                    </div>}
            </div>
        )
    }
    export default ColorSelector
    
    
    