
    import { ChangeEventHandler } from "react"
import "./index.scss"

    type Props = {
        prompt:string
        field:string
        value:string
        handleInputChange:ChangeEventHandler<HTMLTextAreaElement>
    }

    const TextAreaWithPrompt = ({prompt,field,value,handleInputChange}:Props)=>{
        return(
            <div className="text-area-with-prompt-cont">
                <label>
                    {prompt}
                </label>
                
                <textarea name={field} value={value} onChange={(e)=>handleInputChange(e)}></textarea>
            </div>
        )
    }
    export default TextAreaWithPrompt
    
    
    