import { CSSField } from "@/types/css"

type Args = {
    element:any
    cssArray:CSSField[]
}

const checkIfFlex = ({element,cssArray}:Args)=>{
    const elementClass = element.class||element.classString?.replace("sub-section ","");
    const classSelector = `.${elementClass}`;
    // iterate through cssArray to check if there's a css rule with display:flex for this element
    for (const cssRule of cssArray) {
        if (cssRule.selector === classSelector && cssRule.property === 'display' && cssRule.value === 'flex') {
            return true;
        }
    }
    
    return false;
}

export default checkIfFlex