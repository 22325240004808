
    import React from "react"

    const Copyright = ({year,entity}:{year:number,entity:string})=>{
        return(
            <span className="copyright">Copyright {year} by {entity}</span>
        )
    }
    export default Copyright
    
    
    