import "./index.scss"
    import React,{useContext, useEffect, useId, useState} from "react"
import { Context, ContextValueType } from "../../../context"
import LogoutButton from "../../../components/LogoutButton"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCog, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FaAcquisitionsIncorporated, FaCode, FaEnvelope } from "react-icons/fa"
import useIsAdmin from "@/hooks/useIsAdmin"
import Envelope from "@/components/Envelope"
import HeaderTokens from "@/components/HeaderTokens"
import Experimental from "@/wrappers/Experimental"

    const HomepageHeader = ({additionalClass,opaque}:{additionalClass?:string,opaque?:boolean})=>{
        const context:ContextValueType = useContext(Context)
        
        const {setModal,credentials} = context
        const [scrolled,setScrolled] = useState(false)
        
        const inSettings = window.location.href.includes("user-settings")


        const handleScroll = ()=>{
            const top = !(window.scrollY>0)
            if(!scrolled&&!top){
                setScrolled(true)
            }
            else if(top){
                setScrolled(false)
            }

        }

        useEffect(()=>{
            window.addEventListener("scroll",handleScroll)
        },[])

        return(
            <header className={`main-header ${scrolled?"scrolled":""} ${additionalClass||""} ${opaque?"filled-in":""}`}>
                <div className="header-left">
                <a href="/">
                    <img className="header-logo" src="/images/logo.png"></img>
                </a>
                </div>
                <HeaderRight credentials={credentials}></HeaderRight>
            </header>
        )
    }
    export default HomepageHeader
    
    type Props = any

    function HeaderRight({credentials}:Props){
        const isAdmin = useIsAdmin()
        const inSettings = window.location.href.includes("user-settings")
        const hasMessages = true;

        if(inSettings){
            return<div className="header-right">
                <Link to="/">
                <button className="smaller">Back to Dashboard</button>
                </Link>
            </div>
        }

        if(credentials){
            return<div className="header-right">

            {/* {!(hasMessages&&isAdmin)?<></>:<Envelope></Envelope>
            } */}
            <HeaderTokens></HeaderTokens>
            <Link to="/user-settings">
            <FontAwesomeIcon icon={faCog} size="2x"></FontAwesomeIcon>
            </Link>
            <Experimental>
                <Link to={"/dev-center"}>
                    <div className="tooltip down" data-tooltip="Development Center">
                    <FaCode size="35"></FaCode>
                    </div>
                </Link>
            </Experimental>
            <div title="help">

            <Link target="_blank" to="/help-page">
            <FontAwesomeIcon icon={faQuestionCircle} size="2x"></FontAwesomeIcon>
            </Link>
            </div>
            <LogoutButton></LogoutButton>
        </div>
        }


        return                    <div className="header-right">
        <Link to="/login">
        <button className="login-btn">Login</button>
        </Link>
        <Link to="/register">
        <button className="">Register</button>
        </Link>
        </div>
    }