import { fexGet, fexPost } from "@/utils/fex"

type RequestSectionArgs = {
    sectionRequestTitle:string
    sectionContent:string
    deadline:string
    assets:string[]
    pageId:string
    budgetDollars:number
}

export const requestSection = async ({sectionRequestTitle,sectionContent,deadline,assets,pageId,budgetDollars}:RequestSectionArgs)=>{
    const res = await fexPost("api/section-request",{
        sectionRequestTitle,sectionContent,deadline,assets,pageId,budgetDollars
    })
    return res
} 

export const getSectionJobs = async ()=>{
    const res = await fexGet("api/section-request")
    return res
}

export const getMySectionRequests = async ({userId}:{userId:string})=>{
    const res = await fexGet(`api/section-request/${userId}`)
    return res
}

export const getSectionRequestPrivate = async ({ requestId}:{requestId:string})=>{
    const res = await fexGet(`api/section-request/private/${requestId}`)
    return res
}