import { EditOrdinalsType } from "@/pages/Standard/context"
import { NavSectionType } from "@/types/Context"
import { SectionType } from "@/types/Section"

type Args = {
    sections:NavSectionType[]
    ordinals:EditOrdinalsType[]
}

const getSectionsArrayFroNavBar = ({sections,ordinals}:Args)=>{
    const filtered = sections.filter((s)=>(s.isOn&&s.inMenu))
    const withOrdinals = filtered.map((section)=>{
        const match = ordinals.find((o)=>o.sectionId===section.sectionId)
        const res = {...section,ordinal:match?.ordinal}
        return res
    })

    const res = withOrdinals.sort((a,b)=>{
        
        return (a?.ordinal||0)-(b.ordinal||0)
    })
    return res
}

export default getSectionsArrayFroNavBar