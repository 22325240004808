
    import "./index.scss"
    import React, { ChangeEvent, FormEvent, useState } from "react"
import Section from "@/generics/Section"
import InputCont from "@/generics/InputCont"
import { addArticle } from "@/api/article"

    const ArticleEdit = ()=>{

        const [form,setForm] = useState({title:"",body:""})

        const handleChange = (e:ChangeEvent<(HTMLInputElement|HTMLTextAreaElement)>)=>{
            const {value,name} = e.target
            const newForm = {...form}
            if(name!=="title"&&name!=="body"){
                return
            }
            newForm[name] = value
            setForm(newForm)
        }

        const handleSubmit = (e:FormEvent<HTMLFormElement>)=>{
            e.preventDefault()
            addArticle(form)
        }

        return(
            <Section sectionName="article-edit">
            <form onSubmit={handleSubmit}>
                <div>
                </div>
                <InputCont
                label="Title"
                value={form.title}
                handleChange={handleChange}
                name="title"
                />
                <div>
                <div className="input-cont">
                <label>Article</label>
                <textarea onChange={handleChange} value={form.body} name="body"/>
                </div>
                </div>
                <input type="submit"/>    
            </form>
            </Section>
        )
    }
    export default ArticleEdit
    
    
    