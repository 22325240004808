const getWidth = ({frameRef}:{frameRef:React.MutableRefObject<null>})=>{
    if(!frameRef){
        return 400
    }
    const element = frameRef.current as unknown as HTMLDivElement
    const width = element?.getBoundingClientRect()?.width||400
    return width
}

export default getWidth

