import { MouseEventHandler } from "react"
import "./index.css"

    type Props = {
        text?:string,
        buttonText?:string,
        buttonHandler:MouseEventHandler<HTMLButtonElement>
    }

    const CallToAction = ({text,buttonText,buttonHandler}:Props)=>{
        return(
            <>
            <h3 className="call-to-action-heading">{text}</h3>
            <button className="call-to-action-btn" onClick={buttonHandler}>{buttonText}</button>
            </>
        )
    }
    export default CallToAction
    
    
    