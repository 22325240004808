
    import "./index.scss"
    import React from "react"

    const FullPageLoader = ({text}:{text:string})=>{
        return(
            <div className="full-screen">
                <div className="center">
                    <div className="wave"></div>
                    <div className="wave"></div>
                    <div className="wave"></div>
                    <div className="wave"></div>
                    <div className="wave"></div>
                    <div className="wave"></div>
                    <div className="wave"></div>
                    <div className="wave"></div>
                    <div className="wave"></div>
                    <div className="wave"></div>
                </div>
                <div className="title">
                <h1>
                {text}
                </h1>
                </div>
            </div>
        )
    }
    export default FullPageLoader
    
    
    