import { LayoutProperty } from "@/types/LayoutProperty"
import { CSSField } from "@/types/css"
import { DynamicAnyObject } from "@/types/generic"
import { hyphenateCamelcase } from "@/utils/stringFormatting"

type Args = {
    cssArray:CSSField[]
}

const convertToLayoutObject = ({cssField}:{cssField:CSSField})=>{
    const {property,variable,value} = cssField
    const layoutObject:LayoutProperty = {
        prompt:hyphenateCamelcase(variable||""),
        field:variable||"",
        type:"customTemplateStyle",
        options:[],
        fontSelector:property.includes("fontFamily"),
        default:value,
        colorPicker:["background","color","backgroundColor"].includes(property),
        property
    }

    return layoutObject
}


const getCustomLayoutOptions = ({cssArray}:Args)=>{
    return cssArray.map((cssField:CSSField)=>{
        return convertToLayoutObject({cssField})
    })
}

export default getCustomLayoutOptions