export default     {
    prompt:"Footer Overlap?",
    field:"footerOverlap",
    type:"childClass",
    default:"position-set-overlap",
    child:"footer",
    valueType:"string",
    property:"fontSize",
    options:[
        {value:"position-set-overlap",text:"overlap"},
        {value:"position-set-unset",text:"no overlap"},
    ]
}