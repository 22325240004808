
    import "./index.css"
    import React from "react"

    const NoPage = ()=>{

        const split = window.location.href.split("/")
        const pageName = split[split.length-1]

        return(
            <main className="no-page-main">
                <h1>No Page Found!</h1>
                <h3>If you wish to own <em>www.puxhub.com/{pageName}</em>,<br></br> <a  href="/login">login</a>  and create a page.</h3>
            </main>
        )
    }
    export default NoPage
    
    
    