import { fexPost } from "@/utils/fex"

export type ContactFormSubmission = {
    name:string,
    email:string,
    message:string
}

type Arguments = {
    pageId:string,
    formSubmission:ContactFormSubmission
}

export const sendMessage = async ({pageId,formSubmission}:Arguments)=>{
    try{
        const res = await fexPost("api/contact/"+pageId,formSubmission)
        return res
    }catch(err){
        return err
    }
}