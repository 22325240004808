import { useEffect, useRef } from 'react';

const useSectionZIndex = () => {
  const sectionElement = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const closestSection:(HTMLElement|null) = (sectionElement.current as HTMLElement).closest('section.section');
    const header:(HTMLElement|undefined|null) = (sectionElement.current as HTMLElement)?.closest('#root')?.querySelector("header.standard.private");
    if (closestSection) {
      closestSection.style.zIndex = '2';
      if(header?.style){
        header.style.display = "none"
      }
    }

    return () => {
      if (closestSection) {
        closestSection.style.zIndex = '0';
        if(header?.style){
          header.style.display = "flex"
        }
      }
    };
  }, []);

  return sectionElement;
};

export default useSectionZIndex;
