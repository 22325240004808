import { SectionType } from "@/types/Section"
import { DynamicObject } from "@/types/generic"

export const getSocials = (section:SectionType)=>{
    const fields = section.fields
    if(!fields){
        return {}
    }
    const socials:DynamicObject = {}
    const socialsKeys = [
        "facebook","instagram","twitter","tiktok","youtube","twitch","website"
    ]
    fields.forEach(({key,value}:{key:string,value:string})=>{
        if(socialsKeys.includes(key)){
            socials[key]=value
        }
    })
    return socials
}