import { DynamicAnyObject, DynamicObject } from "@/types/generic"

type AddDefaultProps = {
    object:DynamicObject,
    params:string[]
}

export const addDefaultFields = ({object,params}:AddDefaultProps)=>{
    const newObject:DynamicObject = {}
    params.forEach((param)=>{
        if(object[param]===undefined){
            newObject[param] = ""
        }else{
            newObject[param] = object[param]
        }

    })
    return newObject
}

type MakeChangesObjectArgs = {
    newObject:DynamicAnyObject,
    oldObject:DynamicAnyObject
}

export const makeChangesObject = ({newObject,oldObject}:MakeChangesObjectArgs)=>{
    const changesObject:DynamicAnyObject={}
    for (let key in newObject){
        if(!(newObject[key]===oldObject[key])){
            changesObject[key]= newObject[key]
        }
    }
    return changesObject
}