import { GenericSubsectionDocument } from "@/types/Documents";
import { getElementObjectsFromString } from "@/utils/DOM";
import { loggo } from "@/utils/dev";
import handleCustomElements from "@/utils/replaceCustomElements";

interface Field {
    key: string;
    value: string;
    _id?: string;
  }
  
  interface Template {
    html: string;
    fields: Field[];
}

function replaceCountValue(htmlString: string): string {
  // Regular expression pattern to match count="number"
  const pattern: RegExp = /count="(\d+)"/g;

  // Replace the matched pattern with count=number (without quotes)
  return htmlString.replace(pattern, 'count=$1');
}

function findYouTubeUrls(element: string): string[] {
  const regex = /<(youtube)>(.*?)<\/\1>/g;
  const matches: string[] = [];
  let match;

  while ((match = regex.exec(element)) !== null) {
    matches.push(match[2]);
  }

  return matches;
}


const createSubsectionContainerHTML = ({subsections,className,innerHTML}:{
  elementString:string,subsections:GenericSubsectionDocument[],className:string,innerHTML:string
})=>{
    const cont = document.createElement("div")
    cont.className = `${className}-sub custom-subsection`
    let newInnerHTML = ""
    const filteredSubsections = subsections.filter(sub=>{
      return sub.className===className
    })
    filteredSubsections.forEach((sub)=>{
      
      let innerSubHTML = innerHTML
      for (const field of sub.fields) {
        const fieldKey = `{{${field.key}}}`;
        const fieldValue = field.value;
        
        innerSubHTML = innerSubHTML.replace(fieldKey, fieldValue);
        const youtubeMatches = findYouTubeUrls(innerSubHTML)
        youtubeMatches.forEach(match=>{
          const variableName = match.split("::")[0]
          if(variableName===field.key){
            innerSubHTML = innerSubHTML.replace(match,field.value)
          }
        })
      }
      
      const subsectionHTML = `<div class="${className}">
      ${innerSubHTML}
      </div>`
      newInnerHTML+=subsectionHTML.replaceAll("$<$br$>$","\n")
    })

    cont.innerHTML = newInnerHTML
    return cont.innerHTML
}


const handleSubsections = ({html,subsections}:{html:string,subsections:GenericSubsectionDocument[]})=>{
  const subsectionsConts = getElementObjectsFromString({html,selector:"subsections"})
  let res = html
  subsectionsConts.forEach((subsectionCont)=>{
    const elementString = replaceCountValue(subsectionCont.elementString)
    const subsectionContainerHTML = createSubsectionContainerHTML({elementString,subsections,className:subsectionCont.className,innerHTML:subsectionCont.innerHTML})
    const regex = /<subsections[^>]*>([\s\S]*?)<\/subsections>/;
    const fixedElementString = res.match(regex)?.find((string)=>string.includes(subsectionCont.className))||"null"
    res = res.replace(fixedElementString,subsectionContainerHTML)
  })
  return res
}

function renderTemplate({html,fields,subsections}: {html:string,fields:Field[],subsections:GenericSubsectionDocument[]}): string {
    let renderedHtml = html;
    renderedHtml = handleSubsections({html:renderedHtml,subsections})
    for (const field of fields) {
      const fieldKey = `{{${field.key}}}`;
      const fieldValue = field.value;
  
      renderedHtml = renderedHtml.replace(fieldKey, fieldValue);
    }
    

    renderedHtml = renderedHtml.replaceAll("$<$br$>$","<br>").replaceAll("<$br$>$","<br>")
    
    const {newHTML,children} = handleCustomElements({html:renderedHtml})
    renderedHtml = newHTML
    return renderedHtml;
  }

export default renderTemplate