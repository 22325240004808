import "./index.scss"
import { editSectionFields } from "@/api/section"
import PuxMod from "@/components/PuxMod"
import EditableTextOnPicture from "@/generics/EditableTextOnPicture"
import { PageContext } from "@/pages/Standard/context"
import { PageContextType } from "@/types/Context"
import { DynamicObject } from "@/types/generic"
import { SectionType } from "@/types/Section"
import { makeFieldObject } from "@/utils/arrays"
import { useContext, useEffect, useState } from "react"
import { useDebounce } from "use-debounce"

const NewHero = ({section}:{section:SectionType})=>{
    const {isEditing}:PageContextType = useContext(PageContext)
    const initialFields = makeFieldObject(section.fields);
    const [fields,setFields] = useState(initialFields)
    const [delayedFields] = useDebounce(fields,1000)
    const [sectionRef,setSectionRef] = useState(null)

    useEffect(()=>{
        if(fields===initialFields){
            return
        }
        const changedFields:DynamicObject = {}
        for(let fieldKey in delayedFields){
            if(delayedFields[fieldKey]!==initialFields[fieldKey]){
                changedFields[fieldKey] = delayedFields[fieldKey]
            }
        }
        const data = editSectionFields({sectionId:section._id,changedFields})
    },[delayedFields])




    const handleChange = (fields:DynamicObject)=>{
        setFields(fields)
    }

    return(
    <PuxMod setSectionRef={setSectionRef} section={section} headless={true}>
        <EditableTextOnPicture
        section={section}
        fields={fields}
        setFields={setFields}
        isEditing={isEditing}
        handleContainerFieldChange={handleChange}
        sectionRef={sectionRef}
        ></EditableTextOnPicture>
    </PuxMod>
    )

}

export default NewHero

