
import "./index.scss"
import React, { ChangeEventHandler, useEffect, useRef, useState } from "react"
import Video from "../Video"
import DeleteCircle from "../DeleteCircle"
import {  FaImage } from "react-icons/fa"
import { handleAddPictureForm, handleDragEnter, handleDragLeave, handleDragOver, handleDrop } from "./handlers"
import Modal from "../Modal"
import { Credentials } from "@/types/credentials"
import PreloadedImageSelector from "../PreloadedImageSelector"
import ChooseAnImageFile from "./ChooseAnImageFile"
import StockImageSelector from "../PreloadedImageSelector copy"
import setImageDetailStyles from "@/components/EditableComponent/EImage/setImageDetailStyles"
import { useDebounce } from "use-debounce"
import useSkipFirstMutation from "@/hooks/useSkipFirstMutation"
import Experimental from "@/wrappers/Experimental"
import Pin from "@/wrappers/Pin"
import ImageDetailsEditor from "@/components/ImageDetailsEditor"
import EditButtonCont from "./EditButtonsCont"

type Props = {
    credentials?:Credentials,
    isSection?:boolean,
    field:string,
    route:string,
    handleEdit:(arg0:string)=>void,
    url:string,
    sectionId:string,
    isVideo?:boolean,
    className?:string
    isLayoutElement?:boolean
    optional?:boolean
    replace?:boolean
    alreadyOpen?:boolean
    imageDetails?:any
    setImageDetails?:any
}

const ImageEditor = ({credentials,field,handleEdit,url,isVideo,sectionId,route,className,isSection,optional,isLayoutElement,replace,alreadyOpen,imageDetails,setImageDetails}:Props)=>{

    const [isModalOpen,setIsModalOpen] = useState(false)
    const [imageUrl,setImageUrl] = useState(url)
    const [visibleImageUrl,setVisibleImageUrl] = useState(url)
    const [isDropOpen,setIsDropOpen] = useState(alreadyOpen||false)
    const [usePlaceholder,setUsePlaceholder] = useState(false)
    const [detailsEditorOpen,setDetailsEditorOpen] = useState(false)
    const imageRef = useRef(null)
    const imageElement = imageRef.current

    const handleUrlChange = (url:string)=>{
        setImageUrl(url)
        setIsDropOpen(false)
    }

    useEffect(()=>{
        if(url===""&&!optional){
            setIsDropOpen(true)
        }
    },[])

    useSkipFirstMutation(()=>{
        setVisibleImageUrl(imageUrl)
        handleEdit(imageUrl)
        setUsePlaceholder(false)
    },[imageUrl])

    useEffect(()=>{
        if(!imageElement){
            return
        }
        if(imageElement&&imageDetails){
            setTimeout(()=>{
                setImageDetailStyles({imageElement,imageDetails})

            },100)
        }
    },[imageElement,imageDetails,imageRef])


    if(isVideo){
        return(
            <div className="edit-img-cont">
            <Video src={visibleImageUrl}></Video>
            <input placeholder="You can paste an image url here." onChange={(e)=>handleUrlChange(e.target.value)} value={imageUrl}></input>
            </div>
        )
    }
    const modifiedImageURL = !visibleImageUrl?"":visibleImageUrl.indexOf("docUpload:")===0?`${route}/${visibleImageUrl.replace("docUpload:","")}`:visibleImageUrl

    const placeholderImage = "images/hero-bg.png"

    const handleDetailsButton = ()=>{
        setDetailsEditorOpen(true)
    }

    return(
        <div 
        
        
        className={`edit-img-cont dropable ${modifiedImageURL!==""?"loaded":""} ${imageDetails?"no-max":""}`}>
        <EditButtonCont
            optional={optional}
            modifiedImageURL={modifiedImageURL}
            handleDelete={()=>setIsDropOpen(true)}
            replace={replace}
            imageDetails={imageDetails}
            detailsEditorOpen={detailsEditorOpen}
            handleDetailsButton={handleDetailsButton}
        ></EditButtonCont>
        {/* {(!optional||modifiedImageURL!=="")&&<DeleteCircle
        _id={"delete"}
        handleDelete={()=>setIsDropOpen(true)}
        tooltip="Replace Image"
        replace={replace}
        ></DeleteCircle>}
        {imageDetails&&!detailsEditorOpen&&<Experimental>
            <button onClick={handleDetailsButton}>Edit Details</button>
            </Experimental>
            } */}
        {(optional&&modifiedImageURL==="")&&<button
        className="add-logo-button tooltip"
        onClick={()=>setIsDropOpen(true)}
        data-tooltip="Add Logo"
        >+<FaImage></FaImage></button>}
        <div className="overlay"></div>
        {(!optional||modifiedImageURL!=="")&&<div>
            <img 
        onError={(()=>setUsePlaceholder(true))}
        ref={imageRef} 
        className={className||"buck"} src={usePlaceholder?placeholderImage:modifiedImageURL}></img></div>}
        {isDropOpen&&<>
        <Modal
        additionalClass="image-drop-modal"
        setIsModalOpen={setIsDropOpen}
        >
        <>
        <div className="drop-area"
        onDragEnter={handleDragEnter} 
        onDragLeave={handleDragLeave} 
        onDragOver={handleDragOver} 
        onDrop={(e)=>{handleDrop({e,handleEdit,setImageUrl,setIsDropOpen,credentials,field,route,sectionId,isSection})}}
        >
            <div className="dotted-div"></div>
            <FaImage size={140} className="file-icon"></FaImage>
            <h2>You can drag and drop a jpg or png here.</h2>
            </div>
            {isModalOpen&&(
                <ChooseAnImageFile
                {...{handleAddPictureForm,isSection,sectionId,setIsDropOpen,credentials,field,route,setIsModalOpen,setImageUrl,handleEdit,isLayoutElement}}
                ></ChooseAnImageFile>
                )
            }
            <div className="bottom">
            <div>
            <button className="open-file-modal-btn" onClick={()=>{setIsModalOpen(true)}}>Upload From Computer</button>    
                        <StockImageSelector urlHandler={handleUrlChange}></StockImageSelector>
            </div>
            <div className="url-div">
            <label>Or paste a url:</label>
                        <input placeholder="Otherwise, paste an image url here." onChange={(e)=>handleUrlChange(e.target.value)} value={imageUrl}></input>
            </div>
                        {optional&&<button onClick={()=>{setImageUrl("")
                        setIsDropOpen(false)}} className="danger">Delete</button>}
            </div>
            </>
            </Modal>
        </>

            }
            {detailsEditorOpen&&<ImageDetailsEditor
            imageDetails={imageDetails}
            setImageDetails={setImageDetails}
            setIsOpen={setDetailsEditorOpen}
            
            ></ImageDetailsEditor>}
        </div>
    )
}
export default ImageEditor


