import { DynamicObject } from "@/types/generic"

const thumbnails:DynamicObject = {
    Agreement:"images/sections/agreement.png",
    Blog:"images/sections/Blog.png"
}

const defaultImage = "image.jpg"

export default (sectionName:string)=>{
    const res = thumbnails[sectionName]||defaultImage
    return res
}