import { SectionContext } from "@/components/PuxMod"
import { Context, ContextValueType } from "@/context"
import { PageContext } from "@/pages/Standard/context"
import { PageContextType } from "@/types/Context"
import { SectionState } from "@/types/Section"
import { useContext } from "react"

type IdsType = {
    userId:null|string
    pageId:null|string
    sectionId:null|string
}

export default ()=>{
    const ids:IdsType = {userId:null,pageId:"64063e3e4af93255fc12a55f",sectionId:null}
    const mainContext:ContextValueType = useContext(Context)
    const pageContext:PageContextType = useContext(PageContext)
    const sectionContext:SectionState = useContext(SectionContext)
    if(mainContext&&mainContext?.credentials?.user?._id){
        ids.userId = mainContext.credentials.user._id
    }
    if(pageContext&&pageContext?.pageId){
        ids.pageId = pageContext.pageId
    }
    if(sectionContext&&sectionContext?.sectionId){
        ids.sectionId = sectionContext.sectionId
    }
    return [ids]
}