import { DynamicAnyObject, DynamicObject } from "@/types/generic"
import { fexPost } from "@/utils/fex"

export const submitCustomForm = async ({pageId,formSubmission}:{pageId:string,formSubmission:DynamicAnyObject})=>{
    try{
        const res = await fexPost("api/custom-form/"+pageId,formSubmission)
        return res
    }catch(err){
        return err
    }
}