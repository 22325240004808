
    import { editSectionFields } from "@/api/section"
import Section from "@/generics/Section"
import { SectionType } from "@/types/Section"
import { makeFieldObject } from "@/utils/arrays"
import PodcastEpisodes from "../PodcastEpisodes"
import "./index.css"
import { log } from "console"

    const Episodes = ({section}:{section:SectionType})=>{
        const {rssFeed} = makeFieldObject(section.fields)

        const handleChange = async (feedURL:string)=>{
            const resp = await editSectionFields(
                {sectionId:section._id,changedFields:{
                    rssFeed:feedURL
                }}
            )
        }

        return(
            <PodcastEpisodes
            section={section}
            rssFeed={rssFeed}
            handleAPI={handleChange}
            />
        )
    }
    export default Episodes
    
    
    