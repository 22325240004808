import { fexGet, fexPost, fexPut } from "@/utils/fex"


export const getMessages = async ({pageId}:{pageId:string})=>{
    const messages = await fexGet(`api/message/${pageId}`)
    return messages
}

export const sendMessage = async ({pageId,text,name,senderId}:{pageId:string,text:string,name?:string,senderId?:string})=>{
    const resp = await fexPost(`api/message/${pageId}`,{
        text,
        name,
        isPuxhub:false,
        senderId
    })
}

export const getAllConversations = async ({userId}:{userId:string})=>{
    const res = await fexGet("api/conversations/all/"+userId)
    return res
}

export const getUnreadCount = async ({userId}:{userId:string})=>{
        const res:number = await fexGet("api/conversations/unread-count/"+userId)
        return res
}

export const markAsRead = async ({userId,conversationId}:{userId:string,conversationId:string})=>{
    const res = await fexPut("api/conversations/mark-read/"+conversationId,{
        userId
    })    
    return res
}