
    import "./index.scss"
    import React from "react"
import useSectionZIndex from "@/hooks/useSectionZIndex"

    type Props = {
        children:JSX.Element|JSX.Element[]
    }

    const MobileFrame = ({children}:Props)=>{

        const ref = useSectionZIndex()

        return(
            <div ref={ref} className="mobile-frame">
                {children}
            </div>
        )
    }
    export default MobileFrame
    
    
    