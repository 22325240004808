import "./index.scss"
import { login, LoginResponse } from "../../api/user"
import { Context, ContextValueType } from "../../context"
import React, { useContext, useState } from "react"
import { validateLogin } from "../../utils/yup"
import Form from "../../generics/Form"
import GoogleOAuth from "../GoogleOAuth"
import { LoginAPISuccessResponse } from "@/api/googleAuth"
import { APIFailure } from "@/types/API"
import { isMobile } from "@/utils/screen"
import GIS from "../GIS"

    const Login = ()=>{
        type ErrorsType = {field:string,statement:string}[]
        const [errors,setErrors]:[ErrorsType,React.Dispatch<ErrorsType>] = useState<ErrorsType>([])
        const {setCredentials,setModal,setSpinner}:ContextValueType = useContext(Context)
        const handleSubmit = async (formValues:{email:string,password:string})=>{
            try{
                const validation = await validateLogin(formValues)
                if(validation.isError){
                    setErrors(validation.errors.map((error:string)=>{
                        return {field:error.split(" ")[0],statement:error}
                    }))
                    return
                }
                setSpinner(true)
                const data = await login({credentials:formValues,setCredentials})
  
                if('error' in data && data.error){

                    setErrors([data.error as unknown as {field:string,statement:string}])
                    setSpinner(false)
                    return data
                }else{
                    setCredentials(data)
                }
                setModal({name:"none",state:"none"})
                setSpinner(false)
                window.location.href = "/"
            }catch(err){
                setErrors([])
                console.error(err)
            }
        }   


        return(<div className="login">
            <div className="authentication-services">

            <GIS></GIS>
            </div>
            <div>

            <Form
            fields={[{name:"email",type:"email"},
            {name:"password",type:"password"},]}
            errors={errors}
            inline={isMobile()}
            submitName="Login"
            buttonClass="primary"
            handleSubmit={handleSubmit}
            ></Form>
            <h3>Forgot Password?&nbsp;&nbsp;<a href="/forgot-password">Click Here</a></h3>
            </div>
            </div>
        )
    }
    export default Login
    
    
    