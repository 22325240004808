import { DynamicObject } from "@/types/generic"
import { fexDelete, fexPost, fexPut } from "@/utils/fex"

type EGSArguments = {
    subsectionId:string,
    changedFields:DynamicObject,
    type:string
}

export const editGenericSubsection = async ({subsectionId,changedFields,type}:EGSArguments)=>{
    // We are passing the type, because Events and Testimonials are handled differently.
    const body = {...changedFields,type}
    const resp = await fexPut(`api/generic-subsection/${subsectionId}`,body)
    return resp
}

type AGSArguments = {
    sectionId:string,
    fields:DynamicObject,
    type:string
}

export const addGenericSubsection = async ({sectionId,fields,type}:AGSArguments)=>{

    // We are passing the type, because Events and Testimonials are handled differently.
    try{

        const body = {...fields,type}
        const resp = await fexPost(`api/generic-subsection/${sectionId}`,body)
        return resp
    }catch(err){
        return err
    }
}

export const deleteGenericSubsection = async ({subsectionId}:{subsectionId:string})=>{
    const resp = await fexDelete(`api/generic-subsection/${subsectionId}`,{})
    return resp
}

type MoveProps = {
    subsectionId:string
    newOrdinal:number
    direction:string
}

export const moveSubsection = async ({subsectionId,newOrdinal,direction}:MoveProps)=>{
    const resp = await fexPut(`api/generic-subsection/change-ordinal/${subsectionId}`,{
        newOrdinal,direction
    })
}

type PutStringArrayProps = {
    subsectionId:string
    stringArray:string[]
}

export const putSubsectionStringArray = async ({subsectionId,stringArray}:PutStringArrayProps)=>{
    const resp = await fexPut("api/generic-subsection/string-array/"+subsectionId,{stringArray})
    return resp
}