
    import { FaArrowLeft } from "react-icons/fa"
import "./index.scss"
    import React from "react"

    type Props = {
        direction:string
        handleClick:()=>void
    }

    const ArrowButton = ({direction,handleClick}:Props)=>{
        return(
            <button onClick={handleClick} className={`arrow-btn round ${direction}`}><FaArrowLeft></FaArrowLeft></button>
        )
    }
    export default ArrowButton
    
    
    