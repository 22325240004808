export default     {
    prompt:"Text Size",
    field:"--textScale",
    type:"class",
    default:.5,
    options:[
        { value: 0.1, text: 1 },
        { value: 0.2, text: 2 },
        { value: 0.3, text: 3 },
        { value: 0.4, text: 4 },
        { value: 0.5, text: 5 },
        { value: 0.6, text: 6 },
        { value: 0.7, text: 7 },
        { value: 0.8, text: 8 },
        { value: 0.9, text: 9 },
        { value: 1, text: 10 }
      ]
}