import { NavSectionType } from "@/types/Context";
import { SectionType } from "@/types/Section";
import { makeFieldObject } from "./arrays";
import { Procrast } from "@/types/procrast";

export const makeNavSections = ({sections}:{sections:SectionType[]})=>{
    const navSections = sections.map((section)=>{
        const fields = makeFieldObject(section.fields)
        return {
            sectionId:section._id,
            heading:fields.sectionHeading||fields.heading||section?.heading,
            sectionName:section.sectionName,
            sectionForm:section.sectionForm,
            isOn:section.isOn,
            inMenu:section.inMenu,
            ordinal:section.ordinal,
            navName:section.navName
        }
    })
    return navSections
}

type ChangeProps = {
    navSections:NavSectionType[]
    setNavSections:React.Dispatch<NavSectionType[]>
    sectionId:string
    field:keyof NavSectionType
    value:(string|number|boolean)
}

export const changeNavSectionField = ({navSections,setNavSections,sectionId,field,value}:ChangeProps)=>{
    const newNavSections = [...navSections]
    const section:Procrast = newNavSections.find(s=>s.sectionId===sectionId)
    if(!section){
        return
    }
    if(!(field in section)){
        return
    }
    section[field] = value
    setNavSections(newNavSections)
}