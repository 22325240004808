import { SectionType } from "@/types/Section"
import { DynamicObject } from "@/types/generic"

const map = new Map()

map.set("HeroComedian","")
map.set("Hero","")
map.set("TextBlock","")
map.set("ImageAndText","Bio")
map.set("EventsComedian","Events")
map.set("ContactForm","Contact")






const lib = (string:string)=>{
    let result = string;
    if(map.has(string)){
        return map.get(string)
    }
    return result
}

export const getSectionHeading = (fields:DynamicObject,section:SectionType)=>{
    
    if (!(fields.sectionHeading===undefined)){
        return fields.sectionHeading
    }
    else return lib(section.sectionName)||lib(section.sectionForm)
}