
    import "./index.scss"
    import { SectionType } from "@/types/Section"
import PuxMod from "@/components/PuxMod"
import ETextArea from "@/components/EditableComponent/ETextArea"

    const Text = ({section}:{section:SectionType})=>{
        return(
            <PuxMod section={section} headless={true}>
                <main className="text-main-centerer">

                <ETextArea
                field="heading"
                ></ETextArea>
                <ETextArea
                field="text"
                classString="text_class"
                ></ETextArea>
                </main>
            </PuxMod>
        )
    }
    export default Text
    
    
    