
    import { PopulatedSectionSubmission } from "@/types/SectionSubmission"
import "./index.scss"
    import React, { useContext } from "react"
import { getEarnings } from "./helpers"
import { Context, ContextValueType } from "@/context"


    type Props = {
        submissions:PopulatedSectionSubmission[]
    }

    const SectionSubmissionEarnings = ({submissions}:Props)=>{

        const earnings = getEarnings(submissions)
        const {tokens}:ContextValueType = useContext(Context)

        return(
        <div className="earnings-div">
                    <h3>Total Earnings: ${earnings}</h3>
                    <h3>Amount in Account: ${tokens}</h3>
                    {(tokens>0)&&<a href="/payout">Get Payout</a>}
        </div>
            )
    }
    export default SectionSubmissionEarnings
    
    
    