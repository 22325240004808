
    import { SectionRequest } from "@/types/SectionRequest"
import "./index.scss"
    import React from "react"

    const MySectionRequestCard = ({sectionRequest}:{sectionRequest:SectionRequest})=>{

        const hasSubmissions = sectionRequest.submissions&&sectionRequest.submissions.length>0
        const submissionsNumber = sectionRequest?.submissions?.length||0

        const handleClick = ()=>{
            window.location.href = `/my-section-requests/${sectionRequest._id}`
        }
        const {budgetDollars,deadline} = sectionRequest
        const developerCount = sectionRequest?.developers?.length||0
        const pendingCount = developerCount-submissionsNumber

        return<div className="section-request-card">
            <div>
            <h3>{sectionRequest.sectionRequestTitle}</h3>
            <h5>for {sectionRequest.pageName}</h5>
            </div>
            <div className="right">
            <div className="budget">Budget: {budgetDollars} tokens</div>
            <div className="deadline">Deadline: {deadline}</div>
            </div>
            <div className="description">
            
            <p><strong>
                Description:  {"     "}
                </strong>
                 {sectionRequest.sectionContent}</p>
            </div>
            
            <footer>
                {hasSubmissions?
                    <p><a onClick={handleClick}>You have {submissionsNumber} submissions</a></p>: 
                    <p>You have no submissions</p>
                
                }
                <div className="pending-count">{pendingCount} developers currently are working on this.</div>

                
            </footer>


        </div>
    }
    export default MySectionRequestCard
    
    
    