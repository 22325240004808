import footerOverlap from "../fields/footerOverlap";
import imagefooterbackground from "../fields/imagefooterbackground";
import linkSize from "../fields/linkSize";
import linkSizeAsChild from "../fields/linkSizeAsChild";
import linkedGridImageHeight from "../fields/linkedGridImageHeight";
import generics from "./generics";


export default [
    ...generics,
    linkSizeAsChild,
    linkedGridImageHeight,
    imagefooterbackground,
    footerOverlap
]