
type GetLeftRightArg = {
    frameRef:React.RefObject<HTMLDivElement>
    specifiedWidth:number
}

export const getLeftRightFromWidth = ({frameRef,specifiedWidth}:GetLeftRightArg)=>{
    const frameWidth = frameRef.current?.getBoundingClientRect().width
    if(!frameWidth){
        return {error:true,message:"no frame"}
    }
    const delta = frameWidth-specifiedWidth
    return {left:(delta/2)+"px",right:delta/2+"px"}
    
}