
    import "./index.css"
    import React, { MouseEventHandler } from "react"
import { PaymentMethod as PaymentMethodType } from "@stripe/stripe-js"

    type Props = {
        paymentMethod:PaymentMethodType,
        selected:boolean,
        handleClick:MouseEventHandler
    }

    const PaymentMethod = ({paymentMethod,selected,handleClick}:Props)=>{
        if(!paymentMethod.card){
            return<></>
        }
        const {brand,exp_month,exp_year,last4} = paymentMethod.card
        return (
            <div onClick={handleClick} className={`${selected?"selected":""} payment-method`}>

                <span className="right">
                <span className="brand">{brand.toUpperCase()}</span>
                <span>ending in {last4}</span>
                </span>
                <span className="left">Exp. {`${exp_month}/${exp_year}`}</span>
                </div>
        )
        }
    export default PaymentMethod
    
    
    