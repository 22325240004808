import { fexPut } from "@/utils/fex"

type BuyTokensArgs = {
    userId:string
    tokenAdd:number
    paymentMethodId:string
}

export const buyTokens = async ({userId,tokenAdd,paymentMethodId}:BuyTokensArgs)=>{
    const res = await fexPut(`api/tokens/buy/${userId}`,{tokenAdd,paymentMethodId})
    return res
}