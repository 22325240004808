import { isMobile } from "@/utils/screen"

export default async ({imageElement,imageDetails}:{
    imageElement:any,imageDetails:string
})=>{
    if(!imageElement||!imageDetails){
        return
    }
    const styleProperties = [
        "height"
    ]
    if(isMobile()){
        return
    }
    for (let prop of styleProperties){
        imageElement.style[prop] = imageDetails[prop as any]
    }

}