import { addLink } from "@/api/link"
import PuxMod from "@/components/PuxMod"
import BigMiddleButton from "@/generics/BigMiddleButton"
import { PageContext } from "@/pages/Standard/context"
import { PageContextType } from "@/types/Context"
import { SectionType } from "@/types/Section"
import { useContext, useState } from "react"
import Link from "../../subsections/Link"
import { Link as LinkType } from "../../types/Event"
import "./index.scss"


    const Links = ({section}:{section:SectionType})=>{
        const {isEditing}:PageContextType = useContext(PageContext)
        const [links,setLinks] = useState(section?.links||[])

        const handleAddLink = async ()=>{
            const res = await addLink({sectionId:section._id})
            if(!("data" in res) ||!res?.data){
                return
            }
            setLinks([...links,res.data])
        }

        return<PuxMod section={section}>
            <div data-iterable={true} className="links-cont">
                    {links.map((link:LinkType,index:number)=>{
                        return(<Link key={index} section={section} link={link}></Link>)
                    })}
                {!isEditing?<></>:<BigMiddleButton text="Add Link" handleClick={handleAddLink}></BigMiddleButton>}
            </div>
        </PuxMod>

    }
    export default Links
    
    
    