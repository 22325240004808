type Args = {
    section:any
    tag:string
    selector:string
    property:string
    value:string|number
}


const handleContainerStyles = ({section,tag,selector,property,value}:Args)=>{
    
    if(tag==="button"&&property==="width"){
        const parents = Array.from(section.querySelectorAll(selector+"-cont"))
        parents.forEach((el)=>{
            (el as any).style[property] = value
        })
    }
}

export default handleContainerStyles