    import "./index.css"
    import GenericSubsection, { ElementType } from "@/components/GenericSubsection"
import Section from "@/generics/Section"
import { SectionType } from "@/types/Section"
import { GenericSubsectionDocument } from "@/types/Documents"
import PuxMod from "@/components/PuxMod"


    const ThreeGrid = ({section}:{section:SectionType})=>{

        return <PuxMod section={section}>
        <Consumer genericSubsections={section.genericSubsections}></Consumer>
        </PuxMod>

    }
    export default ThreeGrid
    

    function Consumer({genericSubsections}:{genericSubsections:GenericSubsectionDocument[]}){

        const elements:ElementType[] = genericSubsections.map((e:GenericSubsectionDocument)=>{
            const obj = {_id:e._id,heading:e.heading,image:e.image,paragraph:e.paragraph||""}
            return obj
        })

        return(<>
            <Section sectionName="three-grid">
                {elements.map((element:ElementType,key:number)=>{
                return(<GenericSubsection element={element} key={key}></GenericSubsection>)})}
            </Section>
            </>)
    }
    
    