
    import font from "@/utils/sectionEditorUtils/fields/font"
import { findLongestString, getFontProperties, getTextWidth } from "./helpers"
import "./index.scss"
    import React, { useEffect, useRef, useState } from "react"

    type Dimensions= {
        width:string
        height:string
    }

    type Props = {
        initialText:string
        handleChange:(arg0:string)=>void
        additionalClass?:string
    }

    const FullFlexTextarea = ({initialText,handleChange,additionalClass}:Props)=>{

        const [dimensions,setDimensions] = useState<Dimensions>({width:"30px",height:"40px"})
        const [text,setText] = useState(initialText.replaceAll("<br>","\n"))
        const lines = text.split("\n")
        const longestLine = findLongestString(lines)
        
        const textAreaRef = useRef(null)
        const style = {
            width:dimensions.width,
            height:dimensions.height
        }

        useEffect(()=>{
            if(!textAreaRef.current){
                return
            }
            const style = window.getComputedStyle(textAreaRef.current)
           const {newWidth} = getTextWidth(longestLine||"",style.fontFamily,style.fontSize,style.fontWeight)
           const lineHeight = style.lineHeight==="normal"?1.4:parseFloat(style.lineHeight)
           const newDimensions = {...dimensions}
           newDimensions.width = `${Math.max(newWidth*1.2,20)}px`
           newDimensions.height = `${(parseInt(style.fontSize.replace("px",""))*(lines.length||1))*lineHeight}px`
           setDimensions(newDimensions)
           handleChange(text.replaceAll("\n","<br>"))
        },[text])



        return(
            <textarea ref={textAreaRef} value={text} onChange={(e)=>setText(e.target.value)} style={style} className={`full-flex ${additionalClass||""}`}></textarea>
        )
    }
    export default FullFlexTextarea
    
    
    