import { useContext } from "react"
import { Context } from "../../context"
import { ContextType } from "../../types/Context"

    const LogoutButton = ()=>{

        const {credentials,setCredentials}:ContextType = useContext(Context)

        const handleClick = ()=>{
            window.sessionStorage.setItem("the_one_cred","null")

            setCredentials(null)
        }

        return(
            <button onClick={handleClick} className="danger">Logout</button>
        )
    }
    export default LogoutButton
    
    
    