import { PageDetailsType } from "@/types/Page";



export const filterNames = (pages:PageDetailsType[])=>{
    const names = ["joecomedy","bobsakamento","jokequest200","comedypage","flashentertainmentdj","podcastpage","sandbox","comedian","podcast","discjockey","joechristianson","nathanfulsebakke"]

    const filteredNames = pages.filter((page:PageDetailsType)=>{
        return !names.includes(page.pageName)
    })
    return filteredNames

}