
    import { Children } from "@/types/JSX"
import "./index.scss"
    import React from "react"

    type Props = {
        children:Children
        className:string
    }

    const MiddleStack = ({children,className}:Props)=>{
        return(
            <div className={`middle-stack ${className}`}>
                {children}
            </div>
        )
    }
    export default MiddleStack
    
    
    