import { DynamicAnyObject, DynamicObject } from "@/types/generic"
import { getChildrenAsHTML, getInnerHTML, getInnerHTMLFromString } from "@/utils/DOM"
import { loggo } from "@/utils/dev"

type ElementSpec = {
    elementTag:string
    regex:RegExp
    template:string
    replacers:{match:string,replacement:string}[]
    trimInnerHTML?:boolean
}

const youtube:ElementSpec = {
    elementTag:"youtube",
    regex: /<youtube[\s\S]*?<\/youtube>/,
    template: `<iframe class="video" src="{{innerHTML}}"></iframe>`,
    trimInnerHTML:true,
    replacers:[]
}

const carousel:ElementSpec = {
    elementTag:"carousel",
    regex: /<carousel[\s\S]*?<\/carousel>/,
    template: `<div id="carousel-mount" class="carousel-mount">
    </div>`,
    trimInnerHTML:true,
    replacers:[]
}

const contact:ElementSpec = {
    elementTag:"contact",
    regex: /<contact[\s\S]*?<\/contact>/,
    template: `
    <form>
    <label>Name</label>
    <input>
    <label>Email</label>
    <input>
    <label>Message</label>
    <textarea></textarea>
    <button>Submit</button>
    </form>
    
    
    `,
    trimInnerHTML:true,
    replacers:[]
}

interface Resolver {
    customElements:ElementSpec[]
    children: DynamicAnyObject
}

class Resolver {
    constructor(){
        this.customElements = [carousel,contact,youtube]
        this.children = {}
    }
    replaceSingleElement({html,elementTag}:{html:string,elementTag:string}){
        let revisedHTML = html
        const spec = this.customElements.find(c=>c.elementTag===elementTag)
        if(!spec){
            return
        }
        let searchExhausted = false;
        let replacements = 1000
        while (!searchExhausted&&replacements){
            replacements--
            const match = revisedHTML.match(spec?.regex)?.[0]
            if(!match){
                searchExhausted = true
                continue
            }            
            const innerRegEx = /<[^>]+>([^<]+)<\/[^>]+>/;
            const children = getChildrenAsHTML({html,selector:elementTag})
            const innerHTML = (match as unknown as string).match(innerRegEx)?.[1]
            let innerHTMLValue = innerHTML?.split("::")?.[1]||innerHTML||""  
            if(spec.trimInnerHTML){
                innerHTMLValue = innerHTMLValue?.replaceAll(" ","")
            }
            const replacement = spec.template.replace(`{{innerHTML}}`,innerHTMLValue||"")
            
            revisedHTML = revisedHTML.replace(spec?.regex,replacement)
            this.children[elementTag] = children            
        }
        return revisedHTML
    }
    replaceCustomElements({html}:{html:string}){
        let revisedHTML = html;
        const children = {}
        this.customElements.forEach((customElement:ElementSpec)=>{
            revisedHTML = this.replaceSingleElement({html:revisedHTML,elementTag:customElement.elementTag})||revisedHTML
        })

        return revisedHTML
    }
}



const handleCustomElements = ({html}:{html:string}):{newHTML:string,children:DynamicAnyObject}=>{
    const customElementResolver = new Resolver()
    const newHTML = customElementResolver.replaceCustomElements({html})
    return {newHTML,children:customElementResolver.children}
}

export default handleCustomElements