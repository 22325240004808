import { CSSField } from "@/types/css"
import { CSSLineType } from "../CSSEditor"
import { getScreenDimensions } from "@/utils/screen"
import findCSSValueInArray from "@/utils/findCSSValueInArray"

type Args = {
    element:any
    cssArray:CSSField[]
}

const getSpecifiedMaxWidth = ({element,cssArray}:Args)=>{
    const elementClass = element.class||element.classString?.replace("sub-section ","");
    const classSelector = `.${elementClass}`;
    // iterate through cssArray to check if there's a css rule with display:flex for this element
    let width = findCSSValueInArray({classString:elementClass,property:'maxWidth',cssArray})
    if(width?.includes("vw")){
        const screenWidth = getScreenDimensions().width
        const perc = parseFloat(width.replace("vw",""))
        return screenWidth*perc/100
    }
    return width?parseInt(width?.replaceAll("px","")):null
}

export default getSpecifiedMaxWidth