
    import "./index.scss"
    import React, { useState } from "react"
import { Episode as EpisodeType } from "@/sections/PodcastEpisodes"
import { FaCalendar, FaChevronDown, FaChevronUp, FaPeopleArrows, FaPlay } from "react-icons/fa"
import { isMobile } from "@/utils/screen"

    type Props = {
        episode:EpisodeType
        image:string
        handlePlay:(arg0:EpisodeType)=>void
    }

    const Episode = ({episode,handlePlay,image}:Props)=>{

        const [isExpanded,setIsExpanded] = useState(false)
        return(
        <>
        <section className={`short-cont ${isMobile()&&"mobile"}`}>
            <div className="top">
                <img src={image}></img>
                <div className="mid-top">
                    {isMobile()||<div className="date">
                        <span>
                            <FaCalendar></FaCalendar>
                            {episode.pubDate}
                        </span>
                        <span>
                            <FaPeopleArrows></FaPeopleArrows>
                            {episode.author}
                        </span>
                    </div>  }
                    <h3>{episode.title}</h3>
                </div>
                <button data-file={episode.file} onClick={() => handlePlay(episode)}>
                    <FaPlay size={16}></FaPlay>
                </button>
                {/* expander button */}
                {isExpanded||<FaChevronDown onClick={()=>setIsExpanded(true)} size={25} className="expand-btn"></FaChevronDown>}
                {isExpanded&&<FaChevronUp onClick={()=>setIsExpanded(false)} size={25} className="expand-btn"></FaChevronUp>}

            </div>
            <div className={`expansion ${isExpanded&&"expanded"}`}>
                <div className="description" dangerouslySetInnerHTML={{__html:episode.description}}></div>
            </div>
        </section>






        {/* <section className="episode-cont">
            {mobile?<></>:<div className="img-cont">
                <img src={image}></img>
            </div>}
            <div className="episode-details">
                <div className="top">
                    <div>
                        <FaCalendar></FaCalendar>
                        {episode.pubDate}
                        </div>
                    <div>
                        <FaPeopleArrows></FaPeopleArrows>
                        {episode.author}</div>
                </div>
                <main>
                    <h3>{episode.title}</h3>
                    <div className="description" dangerouslySetInnerHTML={{__html:episode.description}}></div>
                </main>
                <button data-file={episode.file} onClick={() => handlePlay(episode)}>
                    <FaPlay size={16}></FaPlay>
                </button>
            </div>
        </section> */}
        </>
        )
    }
    export default Episode
    
    
    