
    import "./index.scss"
    import React, { useEffect, useState } from "react"
import getSlug from "@/utils/getSlug"
import { getArticle } from "@/api/article"
import ParagraphDivider from "@/generics/ParagraphDivider"
import Footer from "@/generics/Footer"
import CTASection from "@/components/CTASection"
import HomepageHeader from "@/layout/headers/HomepageHeader"
import { ArticleType } from "@/types/Article"

    const Article = ()=>{
        const [article,setArticle] = useState<(null|ArticleType)>(null)
        const slug = getSlug().replace("/","")
        
        useEffect(()=>{
            const handleFetch = async ()=>{
                const res = await getArticle({slug})
                setArticle(res)
            }
            handleFetch()
        },[])

        if(!article){
            return<></>
        }


        return(
            <>
            <HomepageHeader additionalClass="article-header"/>
            <main className="article-main">
                <h1>{article.title}</h1>
                <ParagraphDivider text={article.text}></ParagraphDivider>
            </main>
            <CTASection
            
            
            />
            <Footer  socials={{}}>

            </Footer>
            </>
        )
    }
    export default Article
    
    
    