import imageandtext from "./sections/imageandtext"
import video from "./sections/video"
import events from "./sections/events"
import textblock from "./sections/textblock"
import socials from "./sections/socials"
import contactform from "./sections/contactform"
import links from "./sections/links"
import hero from "./sections/hero"
import twitter from "./sections/twitter"
import instagram from "./sections/instagram"
import tiktok from "./sections/tiktok"
import team from "./sections/team"
import blog from "./sections/blog"
import subscribe from "./sections/subscribe"
import { LayoutProperty } from "@/types/LayoutProperty"
import { DynamicObject } from "@/types/generic"
import videos from "./sections/videos"
import mailinglist from "./sections/mailinglist"
import episodes from "./sections/episodes"
import navigation from "./sections/navigation"
import videoWithText from "./sections/videoWithText"
import carousel from "./sections/carousel"
import agreement from "./sections/agreement"
import contactinfo from "./sections/contactinfo"
import buttonwithtext from "./sections/buttonwithtext"
import simpleembed from "./sections/simpleembed"
import linkedimagegrid from "./sections/linkedimagegrid"
import threeitems from "./sections/threeitems"
import custom from "./sections/custom"
import customform from "./sections/customform"

const sections:any = {
    agreement,
    blog,
    buttonwithtext,
    carousel,
    contactinfo,
    custom,
    customform,
    episodes,
    hero,
    navigation,
    textblock,
    imageandtext,
    linkedimagegrid,
    video,
    events,
    socials,
    contactform,
    links,
    twitter,
    instagram,
    mailinglist,
    tiktok,
    simpleembed,
    subscribe,
    team,
    threeitems,
    videos,
    videowithtext:videoWithText
}




export const getLayoutOptions = (sectionType:string)=>{
    const layoutOptions:LayoutProperty[] = (sections?.[sectionType.toLowerCase()]||[])
    return layoutOptions
}