
    import "./index.scss"
    import React from "react"

    type Props = {
        purchaseAmount:number
    }

    const TokenCostCalcTable = ({purchaseAmount}:Props)=>{
        return(
            <table className="cost-calc-table">
<td>
                Tokens:
                </td>
                <td>
                    {purchaseAmount}
                </td>
<td>
                Cost ($1/token):
                </td>
                <td>
                    ${(purchaseAmount).toFixed(2)}
                </td>
            <td>
                Fees (10%):
                </td>
                <td>
                    ${(purchaseAmount*.1).toFixed(2)}
                </td>
            <hr></hr>
            <div style={{display:"none"}}></div>
            <td>Total</td>
            <td>${(purchaseAmount*1.1).toFixed(2)}</td>
        </table>
        )
    }
    export default TokenCostCalcTable
    
    
    