import Modal from "@/generics/Modal"
import { handleAddPictureForm, handleDragEnter, handleDragLeave, handleDragOver} from "../ImageEditorNew/handlers"
import { FaImage } from "react-icons/fa"
import { FormEventHandler, useState } from "react"
import handleDrop from "./handleDrop"
import handleImageFormSubmit from "./handleImageFormSubmit"
import "./index.scss"

type Props = {
    setIsDropOpen:React.Dispatch<boolean>
    handleSubmit:any
}

const ImageDropModal = ({setIsDropOpen,handleSubmit}:Props)=>{

    const [isModalOpen,setIsModalOpen] = useState(false)
    const [imageUrl,setImageUrl] = useState<null|string>(null)    

    // if(imageUrl){
    //     return<div>{imageUrl}</div>
    // }

    return<Modal
    additionalClass="image-drop-modal"
    setIsModalOpen={setIsDropOpen}
    >
        <>
        <div className="drop-area"
        onDragEnter={handleDragEnter} 
        onDragLeave={handleDragLeave} 
        onDragOver={handleDragOver} 
        onDrop={(e)=>handleDrop(e,handleSubmit)}
        >
            <div className="dotted-div"></div>
            <FaImage size={140} className="file-icon"></FaImage>
            <h2>You can drag and drop a jpg or png here.</h2>
            </div>
            {isModalOpen&&(
                <ChooseAnImageFile
                handleSubmit={(e)=>handleImageFormSubmit(e,handleSubmit)}
                setIsModalOpen={setIsModalOpen}
                ></ChooseAnImageFile>
                )
            }
            <div className="bottom">
            <div>
            <button className="open-file-modal-btn" onClick={()=>{setIsModalOpen(true)}}>Upload From Computer</button>    
                        {/* <StockImageSelector urlHandler={setImageUrl}></StockImageSelector> */}
            </div>
            <div className="url-div">
            <label>Or paste a url:</label>
                        <input placeholder="Otherwise, paste an image url here." onChange={(e)=>setImageUrl(e.target.value)} value={imageUrl||""}></input>
            </div>
                        {/* {optional&&<button onClick={()=>{setImageUrl("")
                        setIsDropOpen(false)}} className="danger">Delete</button>} */}
            </div>
            {imageUrl&&imageUrl?.length>0&&<button onClick={()=>handleSubmit(imageUrl)}>Submit URL</button>}
            </>
            </Modal>
}

export default ImageDropModal

type ChooseImageProps = {
    handleSubmit:FormEventHandler
    setIsModalOpen:React.Dispatch<boolean>
}


const ChooseAnImageFile = ({handleSubmit,setIsModalOpen}:ChooseImageProps)=>{

    return(
            <Modal
            modalTitle="Choose a File"
            setIsModalOpen={setIsModalOpen}
            additionalClass="file-chooser-modal generic"
            >
            <form onSubmit={handleSubmit}>
            <input  onChange={(e)=>console.log(e)} type="file"></input>
            <button className="btn-primary">Submit Picture</button>
            </form>
            </Modal>
    )
}