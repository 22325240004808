export const returnStatus = ({isOn,isPaidFor}:{isOn:boolean,isPaidFor:boolean})=>{
    if(!isPaidFor){
        return "notLive"
    }
    if(!isOn){
        return "notLive"
    }
    else{
        return "live"
    }
}