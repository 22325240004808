import React, { useEffect, useState } from "react"
import createEmbedObject from "./helpers/createEmbedObject"
import { loadAsyncScript, loadScript } from "./helpers/loadScript"
import { Helmet } from 'react-helmet';
import getSrcAttribute from "./helpers/getScriptStringSrc";
import getInnerScript from "./helpers/getInnerScript";
import "./index.scss"

type Props = {
    embedString:string
    parentId:string
}
    
    
    
    const EmbedWidget =  ({embedString,parentId}:Props)=>{
    
        const embedObject = createEmbedObject(embedString)
        const [allLoaded,setAllLoaded] = useState(false)
    
        useEffect(()=>{
            embedObject.asyncScripts.forEach((script,index)=>{
                loadAsyncScript(script,parentId,index)
            })
            embedObject.syncScripts.forEach((script,index)=>{
                loadScript(script,parentId,(index+100))
            })
    
        },[])
    
        useEffect(()=>{
        },[document.head])
    
        const handleLoad = ()=>{
            setAllLoaded(true)
        }
    
        useEffect(()=>{
            const clearInterval = setInterval(()=>setAllLoaded(true),1000)
            
        },[])
    
    
        return(
            <>
            <div className="widget-cont" dangerouslySetInnerHTML={{__html:embedObject.html}}></div>
                <>
                {embedObject.asyncScripts.map((s,i)=>{
                    return<Helmet key={i}>
                    <script type="text/javascript" key={i} onLoad={handleLoad} src={`${getSrcAttribute(s)||""}?t=<%= new Date().getTime() %>`}></script>
                    </Helmet>
                })}
                {allLoaded&&embedObject.syncScripts.map((s,i)=>{
                    const innerScript = getInnerScript(s)
                    if(innerScript===""){
                        return<></>
                    }
                    return<Helmet key={i}>
    
                    <script onError={()=>console.log("error")} type="text/javascript">
                        {innerScript}
                    </script>
                    </Helmet>
                })}
                </>
            </>
        )
    }
    export default EmbedWidget
    
    
    