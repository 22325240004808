
    import HomepageHeader from "@/layout/headers/EmptyHeader"
import "./index.scss"
    import React, { useContext } from "react"
import { Context, ContextValueType } from "@/context"
import PayoutForm from "@/components/PayoutForm"
import RedirectButton from "@/generics/RedirectButton"

    const Payout = ()=>{

        const context:ContextValueType = useContext(Context)

        const tokens = context?.tokens||0

        return(
            <div className="payout">
                <HomepageHeader additionalClass="filled-in">
                    <h1>Payout</h1>
                </HomepageHeader>
                <main>
                    <h3>You currently have {tokens} tokens, which you can cash out.</h3>
                     <PayoutForm tokenCount={tokens}></PayoutForm>
                    <RedirectButton to="/dev-center">Back to Development Center</RedirectButton>
                </main>
            </div>
        )
    }
    export default Payout
    
    
    