import "./index.scss"
import {ReactElement, useContext,useState} from "react"
import ParagraphDivider from "../ParagraphDivider"
import DeleteCircle from "../DeleteCircle"
import { deleteGenericSubsection } from "@/api/genericSubsection"
import { PageContextType } from "@/types/Context"
import { PageContext } from "@/pages/Standard/context"
import { reformatDateAndTime } from "@/utils/stringFormatting"

    type BlogDetails = {
        heading:string
        subheading:string
        paragraph:string
        _id:string
        createdAt:string}

    type Props = {
        blogDetails:BlogDetails
        openEditor:Function
        blogEntries:BlogDetails[]
        setBlogEntries:React.Dispatch<BlogDetails[]>
    }

    const BlogEntry = ({blogDetails,openEditor,blogEntries,setBlogEntries}:Props):JSX.Element=>{

        const {heading,subheading,paragraph,_id,createdAt} = blogDetails
        const {isEditing}:PageContextType = useContext(PageContext)
        const [expanded,setExpanded] = useState(false)

        const handleButton = ()=>{
            openEditor(_id)
        }

        const handleDelete = async()=>{
            setBlogEntries([...blogEntries].filter(b=>{
                return b._id!==_id
            }))
            const res = await deleteGenericSubsection({subsectionId:_id})
            
        }

        return(
            <div className="blog-entry">
                <header>
                <h2>{heading||""}</h2>
                <span>{reformatDateAndTime(createdAt)}</span>
                <h5>{subheading||""}</h5>
                </header>
                <main className={expanded?"expanded":"preview"}>
                <div className="content" dangerouslySetInnerHTML={{__html:paragraph}}></div>
                </main>
                {!expanded?<div className="read-more" onClick={()=>setExpanded(true)}>Read More</div>:<div onClick={()=>setExpanded(false)} className="read-more">Collapse</div>}
                {isEditing&&<button onClick={handleButton}>Edit</button>}
                {isEditing&&<DeleteCircle
                _id={_id}
                handleDelete={handleDelete}
                />}
            </div>
        )
    }
    export default BlogEntry
    
    
    