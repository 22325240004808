
    import "./index.scss"
    import { ChangeEventHandler, useContext } from "react"
    import { SectionContext } from "@/components/PuxMod"
import { PageContextType } from "@/types/Context"
import { PageContext } from "@/pages/Standard/context"
import Breaker from "@/generics/Breaker"
import { SubsectionContext } from "@/components/SubPuxMod"
import AutoResizeTextArea from "@/generics/AutoResizeTextArea"
import { SectionState } from "@/types/Section"
import { DynamicObject } from "@/types/generic"
import { SubsectionState } from "@/hooks/useSubsectionState"

    const ETextArea = ({field,subsection,classString}:{field:string,subsection?:boolean,classString?:string})=>{

        const {isEditing}:PageContextType = useContext(PageContext)
        const subsectionContext:any = useContext(SubsectionContext)
        const sectionContext:SectionState = useContext(SectionContext)
        const {fields,handleInputChange}:{fields:DynamicObject,handleInputChange:ChangeEventHandler<HTMLTextAreaElement>} = subsection?subsectionContext:sectionContext
        let style:DynamicObject = {}
        if(field&&sectionContext?.childStyles?.[field]){
            style = field&&sectionContext?.childStyles?.[field]
        }

        if(isEditing){
            return(
                <div className={field+"_class e-textarea"}>
                <AutoResizeTextArea
                name={field}
                style={style} 
                value={(fields[field]||"").replaceAll("<br>","\n").replaceAll("$<$br$>$","\n").replaceAll("<$br$>$","\n")}
                onChange={handleInputChange}
                data-break={true}
                className={"transparent "+classString?classString:""}
                ></AutoResizeTextArea></div>
                )
        }

        const className = field+"_class e-textarea "+(classString?classString:"")

        return(
            <div style={style} className={className}><Breaker text={(fields[field]||"")}></Breaker></div>
        )
    }
    export default ETextArea