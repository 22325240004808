
    import "./index.css"
    import React, { ChangeEvent, useContext, useEffect, useState } from "react"
    import * as reactColor from 'react-color'
import { PickerProps } from "@/types/Color"
import { getCurrentColors } from "@/api/colors"
import { PageContextType } from "@/types/Context"
import { PageContext } from "@/pages/Standard/context"
import { standardColors } from "@/utils/color"

    const ColorPickerOptions = ({pickerProps}:{pickerProps:PickerProps})=>{
        const {pageId}:PageContextType = useContext(PageContext)
        const [activeColors,setActiveColors] = useState<string[]>(standardColors)
        const pickerOptions = [
            {name:"Field",picker:"sketch"},
            {name:"Circle",picker:"circle"},
            {name:"Simple Square",picker:"github"}
        ]

        const [currentPicker,setCurrentPicker] = useState(pickerOptions[1])

        
        const handleSelect = (e:ChangeEvent<HTMLSelectElement>)=>{
            const {value} = e.target
            const newPicker = pickerOptions.find(p=>p.picker===value)
            if(!newPicker){
                return
            }
            setCurrentPicker(newPicker)
        }

        useEffect(()=>{
            const f = async ()=>{
                const res = await getCurrentColors(pageId)
                setActiveColors([...res.colors,...activeColors])
            }
            f()
        },[])

        
        return(
            <>
                <div className="input-cont">

                <label>Color Picker</label>
                <select onChange={handleSelect} value={currentPicker.picker}>
                    {pickerOptions.map((picker,index)=>{
                        return(
                            <option value={picker.picker}>{picker.name}</option>
                            )
                        })}
                </select>
                </div>
                <Options activeColors={activeColors} pickerProps={pickerProps} currentPicker={currentPicker.picker}></Options>
            </>
        )

    }
    export default ColorPickerOptions
    
    
    function Options({currentPicker,pickerProps,activeColors}:{currentPicker:string,pickerProps:PickerProps,activeColors:string[]}){

        const {ChromePicker,CirclePicker,GithubPicker,SketchPicker}:  any = reactColor

        useEffect(()=>{
            switch(currentPicker){
                case "sketch":
                    const picker:(HTMLElement|null) = document.querySelector(".sketch-picker")
                    if(picker){
                        picker.style.width = "300px"
                    }
            }
        },[currentPicker])

        switch(currentPicker){
            case "circle":
                return <CirclePicker {...pickerProps} colors={activeColors}/>
            case "chrome":
                return <ChromePicker {...pickerProps} />
            case "github":
                return <GithubPicker {...pickerProps} />
            case "sketch":
                return <SketchPicker {...pickerProps} />
            default:
                return <CirclePicker {...pickerProps}  />
        }
    }