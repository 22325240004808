import { MouseEventHandler } from "react"
import Section from "../Section"
import "./index.css"

type Props = {
    image:string,
    heading:string,
    paragraph:string,
    ctaText:string,
    handleHeroCTA:MouseEventHandler,
    textAnimation?:string,
    alt?:string
}
    const Hero = ({image,heading,paragraph,ctaText,handleHeroCTA,alt,textAnimation}:Props)=>{
        return(
            <div className="hero">

            <Section sectionName="inframe">
            <img className="hero-img" alt={alt||""} src={image}></img>

            <div className="overlay"></div>
            <div className="content">
            <h1 className={`${textAnimation}`}>{heading}</h1>
            <h3 className={`${textAnimation}`}>{paragraph}</h3>
            <button onClick={handleHeroCTA}>{ctaText}</button>     
            </div>  
            </Section>
            </div>
        )
    }
    export default Hero
    
    
    