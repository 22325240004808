import "./index.scss"
import { ChangeEvent, useEffect, useState } from "react"
import { paginate } from "@/utils/arrays"
import Modal from "../Modal"
import InputAndLabelContainer from "../Containers/InputAndLabelContainer"
import { getImages } from "@/api/stockImages"
import { i } from "@/utils/images"

type Props = {
    urlHandler:(arg0:string)=>void
    open?:boolean
    initialSearch?:string
}

type ImageObj = {
    tags:string
    alt:string
    url:string
}

    const StockImageSelector = ({urlHandler,open,initialSearch}:Props)=>{

        const [freeImages,setFreeImages] = useState([])
        const [pageIndex,setPageIndex] = useState<number>(0)
        const [isOpen,setIsOpen] = useState(false||open)
        const [selected,setSelected] = useState<null|string>(null)
        const [search,setSearch] = useState(""||initialSearch)
        const parsedImages = freeImages.filter((imageObj:ImageObj)=>{
        const joined = imageObj.tags.replace(" ","")
        const joinedSearch = search?.replace(" ","")
            if(joined.includes(joinedSearch||"")){
                return true
            }
        })
        const pages = paginate({array:[...parsedImages],pageSize:9})||[]
        const page = pages.length>0?pages[pageIndex]:[]
        const numberOfPages = pages.length
        const handleSelect = (e:React.MouseEvent)=>{
            const dataImage = (e.target as HTMLElement).dataset.image
            if(!dataImage){
                return
            }
            setSelected(dataImage)
        }

        type ImageDetails = {
            _id:string
            alt:string,
            tags:string[],
            fullImageURL:string
            thumbnailURL?:string
        }

        useEffect(()=>{
            const fetchImages = async ()=>{
                const data = await getImages()
                localStorage.setItem("freeImages",JSON.stringify(data.data))
                const reformated = data.data.map((imageDetails:ImageDetails)=>{
                    return{...imageDetails,tags:imageDetails.tags.join(" ")}
                })
                setFreeImages(reformated)
            }
            fetchImages()
            
        },[])

        const handleConfirm = ()=>{
            if(!selected){
                return
            }
            urlHandler(selected)
            setIsOpen(false)
        }

        if(!isOpen){
            return <button className="stock-image-button" onClick={()=>setIsOpen(true)}>Use Stock Image Selector</button>
        }

        const handleSearch = (e:ChangeEvent<HTMLInputElement>)=>{
            setSearch(e.target.value)
        }

        return(
            <Modal additionalClass="free-image-modal"
            setIsModalOpen={setIsOpen}
            >
            <>
            <div className="free-img-cont">
                {page.map((image,key)=>{
                    return<div className={`single-img-cont ${image.fullImageURL===selected?"selected-image-cont":""}`} data-image={image.url} key={key} onClick={handleSelect}>
                        <img data-image={image.fullImageURL} key={key} src={image.thumbnailURL?i(image.thumbnailURL):image.fullImageURL}></img>
                        </div>
                })}
            </div>
            <footer className="free-images-footer">
            {selected!==null?<button onClick={handleConfirm}>Confirm Selection</button>:<div>Select an Image.</div>}
            <InputAndLabelContainer>
            <label>Search</label>
            <input value={search} onChange={handleSearch}></input>
            </InputAndLabelContainer>
            <div className="page-turner">
                {(pageIndex||"")&&<button onClick={()=>setPageIndex((pageIndex-1)%numberOfPages)}>prev</button>}
                <button onClick={()=>setPageIndex((pageIndex+1)%numberOfPages)}>next</button>
            </div>
                </footer>
            </>
            </Modal>
        )
    }
    export default StockImageSelector
    
    
    