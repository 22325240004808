export const num = (value:(string|number))=>{
    if(typeof value==="number"){
        return value
    }
    try{
        const number = parseFloat(value)
        return number
    }
    catch{
        return 0
    }
}