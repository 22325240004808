
    import HomepageHeader from "@/layout/headers/HomepageHeader"
import "./index.scss"
    import React from "react"
import EmptyHeader from "@/layout/headers/EmptyHeader"
import getHelpTopics from "./getHelpTopics"
import HelpTopicListing from "./HelpTopicListing"

    const HelpPage = ()=>{

        const helpTopics = getHelpTopics()


        return(
            <>
            <EmptyHeader
            additionalClass="filled-in help-page"
            >
                <h1> {"{      Help Center      }"}</h1>
            </EmptyHeader>
            <main className="help-page-main">
            <div className="listings">
                {helpTopics.map((helpTopic)=>{
                    return<HelpTopicListing
                    helpTopic={helpTopic}
                    ></HelpTopicListing>
                })}
            </div>
            </main>
            </>
        )
    }
    export default HelpPage
    
    
    