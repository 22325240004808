    import Header from "@/generics/Header"
    import "./index.scss"
    import React from "react"
import EPKButton from "@/components/EPKButton"
import EPKSocials from "./EPKSocials"
import Video from "@/generics/Video"

    const EPK = ()=>{

        const epk = {
            name:"Mary Upchurch",
            city:"Phoenix, AZ",
            headshot:"https://thecomiclist.com/wp-content/uploads/2021/10/9368EE00-7963-40EF-8EB0-F49F412D6035.jpeg",
            socialsId:"6467c64b858a5fd1fc759c7f",
            videos:[
                "https://www.youtube.com/watch?v=xV-5I_NsbhU",
                "https://www.youtube.com/watch?v=xV-5I_NsbhU",
                "https://www.youtube.com/watch?v=xV-5I_NsbhU"
            ],
            bio:"Originally from San Diego, Mary Upchurch has been making audiences laugh at clubs and festivals across the country. Mary is a ball of excitement, energy and enthusiasm with a pinch of self-deprecation and a dash of desperations."
            }

        const onClick = ()=>{
        }

        return(
            <>
            <Header additionalClass="epk-header">
                <h2>Electronic Press Kit</h2>
                <h1>{epk.name}</h1>
                <h3>{epk.city}</h3>
                <img src={epk.headshot}></img>
            </Header>
            <main className="epk-main">
            <section className="bio-section">
                <p>
                {epk.bio}
                </p>
            </section>
            <section className="epk-buttons">
                <EPKButton onClick={onClick} text="email"></EPKButton>
                <EPKButton onClick={onClick} text="call"></EPKButton>
                <EPKButton onClick={onClick} text="website"></EPKButton>
            </section>
            <section className="epk-socials">
                <EPKSocials socialsId={epk.socialsId}></EPKSocials>
            </section>
            <section>
                {epk.videos.map((v)=>{
                    return<Video src={v}></Video>
                })}
            </section>
            <section>
                Festivals
            </section>
            <section>
                Venues
            </section>
            <section>
                Reviews
            </section>
            </main>
            </>
        )
    }
    export default EPK
    
    
    