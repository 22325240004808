
    import "./index.scss"
    import React, { ChangeEvent, useContext, useState } from "react"
import { SectionState, SectionType } from "@/types/Section"
import PuxMod, { SectionContext } from "@/components/PuxMod"
import P from "@/components/PuxEls/P"
import InputCont from "@/generics/InputCont"
import AddInputCont from "@/generics/AddInputCont"
import { addToMailingList } from "@/api/mailinglist"
import H3 from "@/components/PuxEls/H3"
import { checkEmail } from "@/utils/regEx"
import { PageContextType } from "@/types/Context"
import { PageContext } from "@/pages/Standard/context"
import useSectionState from "@/hooks/useSectionState"
import AutoResizeTextArea from "@/generics/AutoResizeTextArea"

    const MailingList = ({section}:{section:SectionType})=>{



        return(
            <PuxMod
            section={section}
            headless={true}
            >
                <Content section={section}></Content>
                
            </PuxMod>
        )
    }
    export default MailingList
    
    function Content({section}:{section:SectionType}){

        const [value,setValue] = useState("")
        const [completed,setCompleted] = useState(false)
        const {sectionId,handleInputChange,fields}:SectionState = useSectionState({section,options:{enableAPI:true}})
        const {isEditing}:PageContextType = useContext(PageContext)
        const [isValid,setIsValid] = useState(false)
        const handleAdd = ()=>{
            if(!isValid){
                return
            }
            addToMailingList({sectionId,email:value})
            setValue("")
            setCompleted(true)
        }

        if(completed){
            return<div>Thanks for signing up.</div>
        }

        const handleChange = (e:ChangeEvent<HTMLInputElement>)=>{
            const {value} = e.target;
            setIsValid(checkEmail(value))
            setValue(value)
        }

        return(

            <>
            {isEditing&&<div className="instructions">
                <p>In order for the mailing list to work with mailchimp, you need the list id and the api key.</p>
                <form>

                <div className="input-and-button-cont">
                    <label>List ID</label>
                <input  name="listId" placeholder="enter List ID" className={isValid?"valid":"invalid"} value={fields.listId||""} onChange={handleInputChange}></input>
                </div>
                <div className="input-and-button-cont">
                    <label>API Key</label>
                <input name="apiKey"  placeholder="enter api key" className={isValid?"valid":"invalid"} value={fields.apiKey||""} onChange={handleInputChange}></input>
                </div>
                <div className="input-and-button-cont">
                    <label>Tags</label>
                <input  name="tags" data-tooltip="Single word tags, comma separated."  placeholder="Enter tags here" className={`tooltip`} value={fields.tags||""} onChange={handleInputChange}></input>
                </div>
                </form>
                
                </div>}
                <div className="mailing-list-cta-cont">
            <H3 field="cta"></H3>
            <div className="input-and-button-cont">
            <input placeholder="enter your email" className={isValid?"valid":"invalid"} value={value} onChange={handleChange}></input>
            <button disabled={!isValid} onClick={handleAdd}>Join</button>
            </div>
        </div>
        </>
    )
    }
    