export default     {
    prompt:"Button Style?",
    field:"buttonStyle",
    type:"childClass",
    child:".e-button",
    default:"box",
    options:[
        {value:"button-set-box",text:"Box"},
        {value:"button-set-solid",text:"Solid"},
    ]
}