import customSectionBackground from "../fields/customSectionBackground";
import customSectionColor from "../fields/customSectionColor";
import fontSizeWithNumber from "../fields/fontSizeWithNumber";
import headingColor from "../fields/headingColor";
import headingSize from "../fields/headingSize";
import iconColor from "../fields/iconColor";
import lineHeight from "../fields/lineHeight";

export default [
    customSectionBackground,
    headingColor,
    headingSize,
    fontSizeWithNumber,
    iconColor,
    lineHeight
]