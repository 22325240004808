
    import "./index.scss"
    import React, { useContext } from "react"
import { isMobile } from "@/utils/screen"
import { PageContextType } from "@/types/Context"
import { PageContext } from "@/pages/Standard/context"
import { Link } from "react-router-dom"
import ThemeEditor from "../ThemeEditor"
import Toggle from "@/generics/Toggle"
import LayoutModal from "../LayoutModal"
import AddSectionDropdown from "../AddSectionDropdown"
import { LayoutElementType } from "@/types/layout"
import { scrollToTop } from "@/utils/scrolling"
import { FaQuestionCircle } from "react-icons/fa"
import AssistanceButtonToggle from "../AssistanceButtonToggle"
import AcceptSubmission from "../AcceptSubmission"
import { SectionDevelopmentContext } from "@/context/SectionDeveloperContext"
import PageSettings from "../PageSettings"
import Experimental from "@/wrappers/Experimental"

    const OwnersNav = ()=>{
        const {isEditing,setIsEditing,isPaidFor,pageId,style,setStyle,navStyle,setNavStyle,page,submission,sectionRequests,editOrdinals}:PageContextType = useContext(PageContext)
        const mobile = isMobile()
        const submissionContext:any = useContext(SectionDevelopmentContext)

        const hideAddSection = submissionContext?.devSubmission?.page&&editOrdinals.length===1

        const handleToggle = ()=>{
            if(isEditing){
                window.location.reload()
            }
            setTimeout(scrollToTop,100)
            setIsEditing(!isEditing)
        }
        if(!page){
            return<></>
        }
        const headerId = page?.layoutElements?.find((l:LayoutElementType)=>l.elementName==="header")?._id||""

        const showMakeLive = !isPaidFor&&!isMobile()&&!submission
        const showAssistance = !isMobile&&!submission
        const showNavEditor = !isMobile()&&isEditing&&!submission

        const handleSectionRequestButton = ()=>{
            window.location.replace(`/my-section-requests?${page._id}`)
        }


        return(
            <nav className="owners-nav">
              <Link to="/">{mobile?<>&#x2190;</>:<h3 className="back-to-dash">Back to Dashboard</h3>}</Link>
                {/* {isEditing?<ThemePicker setTheme={setTheme} selectedTheme={theme}></ThemePicker>:<></>} */}
                <div>
                <Experimental>
                {(showNavEditor&&!submissionContext?.devSubmission)?<PageSettings></PageSettings>:<></>}
                </Experimental>
                {showNavEditor?<ThemeEditor style={style} setStyle={setStyle}></ThemeEditor>:<></>}
                {showMakeLive?<a className="make-live" href={window.location.origin+"?"+pageId}><button
                data-tooltip="Click here to purchase a subscription, which will make this page public."
                className="tooltip down blue"
                >Make Live</button></a>:<></>}
                {showNavEditor&&<>
                <LayoutModal
                            style={navStyle}
                            setStyle={setNavStyle}
                            sectionType="Navigation"
                            sectionId={headerId}
                            setLayoutModalOpen={()=>{}}
                            positioning="header"
                            buttonClass="inline-button"
                            isStatic={true}
                            header="Header Style"
                            buttonName="Nav Bar Style"
                            ></LayoutModal>
                            {!hideAddSection&&<AddSectionDropdown
                            buttonClass="inline-button"
                            
                            />}
                            
                            </>
                            
                        }
                        {submission&&<AcceptSubmission submission={submission}></AcceptSubmission>}
                        {showAssistance&&<AssistanceButtonToggle></AssistanceButtonToggle>}
                        {sectionRequests.length>0&&<button onClick={handleSectionRequestButton}>Section Requests</button>}
                        </div>
                {/* {!isMobile()&&<div className="toggle-cont">
                <div>{isEditing?"toggle to save":"toggle to edit"}</div>
                <Toggle action={handleToggle}></Toggle>
            </div> }  */}
                {!isMobile()&&<div  className="owners-header-right">
                    {isEditing?<div><button className="primary" onClick={handleToggle}>Save</button></div>:<div  className="edit-button">
                        <button className="secondary" onClick={handleToggle}>Edit</button></div>}
                <Link target="_blank" to="/help-page">
                    <FaQuestionCircle size={30}></FaQuestionCircle>
                </Link>
                </div>}
                {isMobile()&&<div>log in on a wider screen to edit</div>}

            </nav>
        )
    }
    export default OwnersNav
    
    
    