import "./index.css"
import "../../style/layout_classes.scss"
import "../../theme/basicThemes.css"
import React, {createContext, useContext, useEffect, useState } from "react"
import { getPage, setInstructionsToFalse } from "../../api/page"
import { Context, ContextValueType } from "../../context"
import StandardHeader from "../../layout/headers/StandardHeader"
import SectionResolver from "../../SectionResolver"
import { PageContext, PageContextProvider } from "./context"
import { checkIfHeroImage, getPrivateView, getSlug, logOutTokenInfo } from "./helpers"
import { getOrdinalPairs } from "@/utils/pageManipulation"
import { PageDetailsType, PopulatedPage } from "@/types/Page"
import { PageContextType } from "@/types/Context"
import { SectionType } from "@/types/Section"
import Modal from "@/generics/Modal"
import { parseStyles } from "@/utils/theme"
import NoPage from "../NoPage"
import { getTopBottom, isMobile } from "@/utils/screen"
import Notification from "@/generics/Notification"
import { DynamicObject } from "@/types/generic"
import { getElementStyle } from "@/utils/layoutElements"
import { makeNavSections } from "@/utils/navBar"
import Floater from "@/generics/Floater"
import { Link } from "react-router-dom"
import { APIFailure } from "@/types/API"
import checkIfPreview from "./checkIfPreview"
import Confirmation from "@/generics/Confirmation"
import PageNotOn from "./PageNotOn"
import { PopulatedSectionSubmission } from "@/types/SectionSubmission"
import Experimental from "@/wrappers/Experimental"
import PageFooter from "@/layout/footers/PageFooter"
import { getSectionParam } from "@/utils/url"
import { SectionDevelopmentContext } from "@/context/SectionDeveloperContext"

    const Standard = ({host,override,submission}:{host?:string,override?:string,submission?:PopulatedSectionSubmission})=>{
        
        logOutTokenInfo()
        const [page,setPage]:[any,any] = useState([])
        const [ownPage,setOwnPage] = useState(false)
        const [noPage,setNoPage] = useState<boolean>(false)
        const context:ContextValueType = useContext(Context)
        const credentials = context?.credentials
        const sectionDevelopment = useContext(SectionDevelopmentContext)
        const [previewCode,setPreviewCode] = useState("")

        useEffect(()=>{
            const getCurrentPage = async ()=>{
                const variables = {host:override||host||""}
                const {page,error,sectionRequests}:any = await getPage(variables)
                if(!override&&(!page||!("userId" in page))){
                    setNoPage(true)
                    return
                }
                if(error){
                    return
                }
                const pageUserId:string = page?.userId;
                const userId = context?.credentials?.user?._id
                if(pageUserId===userId){
                    setOwnPage(true)
                }
                getPrivateView(setOwnPage)
                setPage({...page,sectionRequests})
                const title = document.querySelector("title")
                if(title){
                    title.innerText = page.pageName
                }
                const favIcon = document.querySelector("link[rel='icon']") as any
                if(favIcon){
                    favIcon.href = "/images/emptySVG.svg" 
                }                        
            }
            getCurrentPage() 
        },[])

        useEffect(()=>{
            const userId = context?.credentials?.user?._id
            const email = context?.credentials?.user?.email
            if(!userId){
                return
            }
            if(page.userId===userId){
                setOwnPage(true)
            }
            if(email==="jcfargond@gmail.com"){
                setOwnPage(true)
            }
        },[credentials,page])

        if(noPage){
            return<NoPage></NoPage>
        }
        if(!page.sections){
            return(
                <></>
            )
        }

        const {isPaidFor,isOn} = page


        if((!isPaidFor||!isOn)&&!ownPage&&!checkIfPreview()){
            return(
                <PageNotOn pageName={page.pageName}></PageNotOn>
            )
        }

        return(
            <PageContextProvider>
            <ContextConsumer ownPage={ownPage} page={page} submission={submission}></ContextConsumer>
            </PageContextProvider>
        )
    }
    export default Standard
    
    function ContextConsumer({ownPage,page,submission}:{ownPage:boolean,page:PageDetailsType,submission?:PopulatedSectionSubmission}){
        const {setTopBottom,confirmation}:ContextValueType = useContext(Context)
        const {setPage,setIsPaidFor,theme,setTheme,setPageId,isEditing,setEditOrdinals,editOrdinals,style,setStyle,additionalFields,setNavStyle,setNavSections,imageLoaded,setSubmission,setSectionRequests,paginated,currentSubPage,isFullHeight}:PageContextType = useContext(PageContext)
        const livePage = (useContext(PageContext) as unknown as PageContextType).page
        const [display,setDisplay] = useState(true)
        useEffect(()=>{
            setTheme(page.theme)
            setPageId(page._id)
            setEditOrdinals(getOrdinalPairs(page))
            setStyle(page.style)
            const navStyle = getElementStyle({page,elementName:"header"})
            setNavStyle(navStyle)
            setPage(page)
            setIsPaidFor(page.isPaidFor)
            setSectionRequests(page.sectionRequests||[])
            setNavSections(makeNavSections({sections:page.sections}))
            if(submission){
                setSubmission(submission)
            }
        },[page])

        useEffect(()=>{
            const containsHero = checkIfHeroImage(page)
            if(containsHero){
                setDisplay(false)
            }
            setTimeout(()=>setDisplay(true),1000)
        },[])

        useEffect(()=>{
            if(imageLoaded){
                setDisplay(true)
            }
        },[imageLoaded])
        let reorderedSections = livePage?.sections.sort((a:SectionType,b:SectionType)=>(a.ordinal-b.ordinal))||[]
        if(paginated&&currentSubPage){
            reorderedSections = currentSubPage.sections
        }
        const [instructionsOpen,setInstructionsOpen] = useState(!submission&&page.instructions)
        const [notification,setNotification] = useState(false)

        useEffect(()=>{
            if(!instructionsOpen){
                setInstructionsToFalse({pageId:page._id})
            }
        },[instructionsOpen])
        
        let customStyle:DynamicObject = {}
        const styleString = style
        let customClass = ""
        if(styleString){
            const addedStyles = parseStyles(styleString)
            customStyle = {...customStyle,...parseStyles(styleString).styles}
            customClass = addedStyles.class
        }

        additionalFields.forEach((field:{key:string,value:string})=>{
            const key = `--${field.key}`
            if(key=="--backgroundImage"&&!field.value.includes("url(")){
                field.value=`url(${field.value})`
            }
            customStyle[key] = field.value
        })

        const handleScroll = ()=>{
            setTopBottom(getTopBottom())
        }
        useEffect(()=>{
            window.addEventListener("scroll",handleScroll)
        },[])

        useEffect(()=>{
            isMobile()&&isEditing&&setNotification(true)
        },[isEditing])

        const sample = window.location.search==="?sample"

        const styleWithDisplay = display?{...customStyle,opacity:1}:{...customStyle,opacity:0}
        

        // this is here for isolating sections to debug layout issues
        const sectionParam = getSectionParam()
        if(sectionParam&&reorderedSections.length>1){
            reorderedSections = [reorderedSections[sectionParam-1]]
        }

        return (
            <>
            {sample?<Floater>
                <Link to="/register">
                <div className="floater-content">Register to get your own page</div>
                </Link>
            </Floater>:<></>}
            <StandardHeader ownPage={ownPage}></StandardHeader>
            <main  style={styleWithDisplay} className={`standard ${theme} ${isEditing&&"grid"} ${customClass} ${isFullHeight?"full-height":"not-full-height"}`}>
                {reorderedSections.map((section:SectionType,key:number)=>{
                    return(
                        <SectionResolver
                        key={key}
                        section={section}
                        ></SectionResolver>
                    )})}
            </main>
            <Experimental>
                {SectionDevelopmentContext?<></>:<PageFooter></PageFooter>}
            </Experimental>
 
            {notification?<Notification
            notificationMessage={"On a mobile device, the editor is limited to changing text content, reordering sections, and turning sections on and off.<br> If you want additional functionality, open the editor on a large screen."}
            setNotificationOpen={setNotification}
            timer={10000}
            ></Notification>:<></>}
            {!confirmation?<></>:<Confirmation
            ></Confirmation>}
            {(ownPage&&instructionsOpen)&&<Instructions setInstructionsOpen={setInstructionsOpen}></Instructions>}
            {!ownPage&&<footer className="public-standard-footer">
                
                    <h3>Want your own page?
                        <a href="https://puxhub.com/register">
                             Register Here
                        </a>
                    </h3>
                </footer>}
            </>
        )
    }
    
function Instructions({setInstructionsOpen}:{setInstructionsOpen:React.Dispatch<boolean>}){
    
    
    return<Modal additionalClass="instructions-placeholder" setIsModalOpen={setInstructionsOpen}>
        <>
        <p>This page includes placeholder content.</p>
        <p>To customize your page, click the edit toggle in the top right.</p>
        </>
    </Modal>
}