
    import { getAllTemplates } from "@/api/sectionTemplate"
import "./index.scss"
    import React, { useEffect, useState } from "react"
import TemplateCard from "../TemplateCard"
import getSlug from "@/utils/getSlug"
import { stringFilterObjects } from "@/utils/search"

    const TemplateLister = ()=>{
        
        const [templates,setTemplates] = useState<any>([])
        const [templateName,setTemplateName] = useState("")
        const pageId = getSlug().substring(1)

        useEffect(()=>{

            const f = async ()=>{
                const res:any = await getAllTemplates()
                if(!res?.error){
                    setTemplates(res)
                }

            }
            f()
        },[])

        const filteredTemplates = stringFilterObjects({objectArray:templates,searchString:templateName,properties:["sectionType","templateName"]})
        
        return(
          <>
          <header className="premium-template-header">
                <h1>Premium Section Templates</h1>
                <input placeholder="filter sections" value={templateName} onChange={(e)=>setTemplateName(e.target.value)}></input>
                <div className="header-btn-cont">
                    {pageId&&<button className="danger" onClick={()=>{window.history.back()}}
>Back To Editor</button>}
                </div>
          </header>
          
          <section className="template-lister">
                {(templateName===""?templates:filteredTemplates).map((template:any,index:number)=>{
                    return<TemplateCard
                    sectionTemplate={template}
                    ></TemplateCard>
                })}
            </section>
                </>
        )
    }
    export default TemplateLister
    
    
    