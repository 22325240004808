import "./index.css"
    import React, { MouseEventHandler } from "react"

    type Props = {
        text:string,
        handleClick:MouseEventHandler,
        buttonClass?:string
    }

    const BigMiddleButton = ({text,handleClick,buttonClass}:Props)=>{
        return(
            <button onClick={handleClick} className={buttonClass}>{text}</button>
        )
    }
    export default BigMiddleButton
    
    
    