const getCredentials = ()=>{
    const json = window.sessionStorage.getItem("the_one_cred")
    if(!json){
        return
    }
    try{

        const cred = JSON.parse(json)
        if(!(cred?.message==="success")){
            return
        }
        return cred
    }catch(err){
        return
    }
}

export default getCredentials