import { PageDetailsType } from "@/types/Page"
import { makeFieldObject } from "./arrays"
import { LayoutElementType } from "@/types/layout"

export const getElementStyle = ({page,elementName}:{page:PageDetailsType,elementName:string})=>{
    const element = page.layoutElements.find((e:any)=>{
        return e.elementName===elementName
    })
    
    const value = getElementFieldValue({element,fieldName:"style"})
    return value
}

export const getElementFieldsWithPage = ({page,elementName}:{page:PageDetailsType,elementName:string})=>{
    const element = page.layoutElements.find((element)=>{
        return element.elementName===elementName
    })
    if(!element){
        return {}
    }
    const fields = makeFieldObject(element.fields)
    return fields
}



export const getElementFieldValue = ({element,fieldName}:{element:LayoutElementType,fieldName:string})=>{
    if(!element){
        return ""
    }
    const value = element.fields.find((f:any)=>f.key===fieldName)?.value||""
    return value
}

export const getElementId = ({page,elementName}:{page:PageDetailsType,elementName:string})=>{
    const element = page.layoutElements.find((e)=>{
        return e.elementName===elementName
    })
    return element._id
}