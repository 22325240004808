import { PopulatedSectionSubmission } from "@/types/SectionSubmission";

export const getStatus = (submission:PopulatedSectionSubmission)=>{
    const selectedSubmission = submission.sectionRequest?.selectedSubmission
    const submissionId = submission._id

    if(selectedSubmission===submissionId){
        return "accepted"
    }
    if(selectedSubmission&&submission.isDone){
        return "not chosen"
    }
    if(submission.isDone){
        return "submitted"
    }
    if(!submission.isDone){
        return "not submitted"
    }
}


