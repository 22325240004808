import backgroundOn from "../fields/backgroundOn";
import font from "../fields/font";
import sectionBackground from "../fields/sectionBackground";
import textAnimation from "../fields/textAnimation";
import textColor from "../fields/textColor";
import generics from "./generics";


export default [
    ...generics
]