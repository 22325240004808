import { editSectionFields } from "@/api/section"
import { addToStringArray, removeFromStringArray, replaceStringArray } from "@/api/stringArray"
import { changeSectionStyle } from "@/api/style"
import { EditOrdinalsType, EditSubOrdinalsType, PageContext } from "@/pages/Standard/context"
import {addGenericSubsection, deleteGenericSubsection} from "@/api/genericSubsection"
import { PageContextType } from "@/types/Context"
import { DynamicAnyObject, DynamicObject, DynamicObjectWithNumber } from "@/types/generic"
import { Procrast } from "@/types/procrast"
import { Field, SectionType } from "@/types/Section"
import { makeFieldObject } from "@/utils/arrays"
import { getChildStyles } from "@/utils/fields"
import { getEditOrdinal } from "@/utils/pageManipulation"
import { getSectionStyle } from "@/utils/style"
import { makeEditOrdinals } from "@/utils/subsectionOrdering"
import React, { ChangeEvent, Ref, useContext, useEffect, useState } from "react"
import { useDebounce } from "use-debounce"
import { CSSField } from "@/types/css"
import { loggo } from "@/utils/dev"
import { createCustomTemplateStyleObject } from "@/utils/custom-template/custom-template-style-object"
import { CustomTemplateType } from "@/types/sectionTemplate"
import { GenericSubsectionDocument } from "@/types/Documents"
import handleCustomTemplateStyleChange from "@/utils/custom-template/handleCustomTemplateStyleChange"
import useSkipFirstMutation from "./useSkipFirstMutation"



// we need section and useContext

type OptionsType = {
    enableAPI:true
}

type Props = {
    section:SectionType
    customCSSArray?:CSSField[]
    options?:OptionsType
}

export default ({section,customCSSArray,options}:Props)=>{
    const [initialized,setInitialized] = useState(false)
    const [isOn,setIsOn] = useState(section.isOn)
    const [inMenu,setInMenu] = useState(section?.inMenu||false)
    const [style,setStyle] = useState(getSectionStyle(section))
    const [customSectionStyleObject,setCustomSectionStyleObject] = useState<DynamicAnyObject>({})
    const [layoutModalOpen,setLayoutModalOpen] = useState(false)
    const [fields,setFields] = useState<DynamicObject|null>(makeFieldObject(section.fields))
    const [stringArray,setStringArray] = useState(section?.stringArray||[])
    const [delayedStringArray]= useDebounce(stringArray,1000) // **
    const [delayedFields]= useDebounce(fields,1000) // **
    const {editOrdinals,setEditOrdinals}:PageContextType = useContext(PageContext)
    const ordinal = getEditOrdinal({sectionId:section._id,editOrdinals})
    const [subsectionOrdinals,setSubsectionOrdinals] = useState(makeEditOrdinals({subsections:section.genericSubsections}))
    const {isEditing}:PageContextType = useContext(PageContext)
    const sectionId = section._id
    const [subsections,setSubsections] = useState(section.genericSubsections)
    const [update,setUpdate] = useState(false)
    const [customLoaded,setCustomLoaded] = useState(false)
    const [currentCustomCSSArray,setCurrentCustomCSSArray] = useState(customCSSArray||section?.sectionCSS?.cssFields||[])
    const isCustomTemplate = section.sectionForm==="CustomTemplate"
    const [sectionElement,setSectionElement] = useState<HTMLElement|null>(null)
    const [cssUpToDate,setCSSUpToDate] = useState(false)



    useEffect(()=>{
        if(!options?.enableAPI||!isEditing){
            return
        }
        if(!initialized||!delayedFields){
            setInitialized(true)
            return
        }
        editSectionFields({sectionId:section._id,changedFields:delayedFields}).then((res)=>{

        })
    },[delayedFields])

    useEffect(()=>{
       
        if(!options?.enableAPI){
            return
        }
        if(!initialized||!delayedFields){
            setInitialized(true)
            return
        }
        replaceStringArray({sectionId,newStringArray:delayedStringArray})
    },[delayedStringArray])

    useSkipFirstMutation(()=>{
        if(!isEditing){
            return
        }
        const customCSSArray = section?.template?.css

        if(!isCustomTemplate){
            changeSectionStyle({sectionId:section._id,style})
        }
        
        else if (customCSSArray){
            handleCustomTemplateStyleChange({customSectionStyleObject,style,customCSSArray,sectionElement})
        }
    },[style])

    useEffect(()=>{
        setTimeout(()=>{
            setCSSUpToDate(!cssUpToDate)
        },100)
    },[style])

    useEffect(()=>{
        if(!(section.sectionForm==="CustomTemplate")||!customCSSArray){
            return
        }
        const customSectionStyleObject = createCustomTemplateStyleObject({styleString:style,customCSSArray:customCSSArray})

        setCustomSectionStyleObject(customSectionStyleObject)
    },[])



    const handleInputChange = (e:ChangeEvent<HTMLInputElement>)=>{// **
        const dataset = e.target.dataset
        let {name,value}= e.target// **
        if(dataset.break){
            value = value.replaceAll("\n","<br>")
        }
        const newFields = {...fields}// **
        newFields[name] = value;// **
        setFields(newFields)// **
    }

    const handleStringArrayAdd = (value:string)=>{
        setStringArray([...stringArray,value])
        addToStringArray({sectionId,addedString:value})
    }

    const handleStringArrayDelete = (e:React.MouseEvent<HTMLButtonElement>)=>{
        const value = (e.target as HTMLButtonElement).dataset.id;
        
        const newStringArray = stringArray.filter(string=>{
            return string!==value
        })
        setStringArray(newStringArray)
        removeFromStringArray({sectionId,removedString:(value||"")})
    }

    const handleStringArrayChange = ({newText,index}:{newText:string,index:number})=>{
        const newArray = [...stringArray]
        newArray[index] = newText

        setStringArray(newArray)
    }

    const handleAddSubsection = async ({fields}:{fields:DynamicObject})=>{
        const resp = await addGenericSubsection({sectionId,fields,type:"normal"})
        setSubsections([...subsections,resp])
    }

    const handleDeleteSubsection = async ({subsectionId}:{subsectionId:string})=>{
        const newSubsections = [...subsections].filter(s=>s._id!==subsectionId)
        setSubsections(newSubsections)
        deleteGenericSubsection({subsectionId})
    }

    const childStyles = getChildStyles(style)

    type SectionState = {
        isOn:boolean
        setIsOn:React.Dispatch<boolean>
        style:string
        setStyle:React.Dispatch<string>
        layoutModalOpen:boolean
        setLayoutModalOpen:React.Dispatch<boolean>
        editOrdinals:EditOrdinalsType[]
        setEditOrdinals:React.Dispatch<EditOrdinalsType[]>
        ordinal:number
        isEditing:boolean
        fields:DynamicObject|null
        setFields:React.Dispatch<DynamicObject|null>
        delayedFields:DynamicObject|null
        handleInputChange:(e: ChangeEvent<HTMLInputElement>) => void
        stringArray:string[]
        setStringArray:React.Dispatch<string[]>
        delayedStringArray:string[]
        handleStringArrayAdd:(value: string) => void
        handleStringArrayDelete:(e: React.MouseEvent<HTMLButtonElement>) => void
        handleStringArrayChange:({ newText, index }: {
            newText: string;
            index: number;
        }) => void
        sectionId:string
        inMenu:boolean
        setInMenu:React.Dispatch<boolean>
        childStyles:Procrast
        subsectionOrdinals:EditSubOrdinalsType[]
        setSubsectionOrdinals:React.Dispatch<EditSubOrdinalsType[]>
        update:boolean
        setUpdate:React.Dispatch<boolean>
        customLoaded:boolean
        setCustomLoaded:React.Dispatch<boolean>
        cssFields:any
        template?:CustomTemplateType
        subsections:GenericSubsectionDocument[]
        handleAddSubsection:any
        handleDeleteSubsection:({ subsectionId }: {
            subsectionId: string;
        }) => Promise<void>
        currentCustomCSSArray:any
        setCurrentCustomCSSArray:any
        isCustomTemplate?:boolean
        setSubsections:React.Dispatch<GenericSubsectionDocument[]>
        setSectionElement:any
        cssUpToDate:boolean
        setCSSUpToDate:React.Dispatch<boolean>
    }
    const cssFields = section?.sectionCSS?.cssFields
    const template = section?.template
    const sectionState:SectionState = {
        isOn,setIsOn,style,setStyle,layoutModalOpen,setLayoutModalOpen,editOrdinals,setEditOrdinals,ordinal,isEditing,fields,setFields,delayedFields,handleInputChange,stringArray,setStringArray,delayedStringArray,handleStringArrayAdd,handleStringArrayDelete,handleStringArrayChange,sectionId,inMenu,setInMenu,childStyles,subsectionOrdinals,setSubsectionOrdinals,update,setUpdate,cssFields,template,subsections,customLoaded,setCustomLoaded,handleAddSubsection,
        handleDeleteSubsection,currentCustomCSSArray,setCurrentCustomCSSArray,isCustomTemplate,setSubsections,setSectionElement,cssUpToDate,setCSSUpToDate 
    }

    return sectionState
}