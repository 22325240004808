
    import "./index.scss"
    import React, { useContext, useState } from "react"
import { SectionType } from "@/types/Section"
import PuxMod from "@/components/PuxMod"
import EImage from "@/components/EditableComponent/EImage"
import useSectionState from "@/hooks/useSectionState"
import FullPageMiddleScroller from "@/components/FullPageMiddleScroller"
import useMainZIndex from "@/hooks/useMainZIndex"
import EditButton from "@/generics/EditButton"
import Modal from "@/generics/Modal"
import HTML from "@/generics/HTML"
import { PageContextType } from "@/types/Context"
import { PageContext } from "@/pages/Standard/context"
import CustomTextEditor from "@/components/CustomTextEditor"

    type Props = {
        section:SectionType
    }

    const Agreement = ({section}:Props)=>{

        const {isEditing}:PageContextType  = useContext(PageContext)
        const [isOpen,setIsOpen] = useState<boolean|Element>(false)
        const [isEditingText,setIsEditingText] = useState(false) 
        const {fields,setFields} = useSectionState({section,options:{enableAPI:true}})

        const handleSubmit = (text:string)=>{
            const newFields = {...fields}
            newFields.text = text
            setFields(newFields)
        }

        if(!fields){
            return<></>
        }
        return<PuxMod section={section}>
                <div onClick={()=>setIsOpen(true)}>
                <EImage field="logo"></EImage>
                </div>
                {!isOpen?<></>:<FullPageMiddleScroller setIsOpen={setIsOpen}>
                    {!isEditingText?<div className="agreement-body">
                    {isEditing&&<EditButton setIsEditing={setIsEditingText}></EditButton>}
                    <HTML html={fields?.text||""}></HTML>
                    </div>:<></>}
                    {isEditingText?
                                <CustomTextEditor
                                handleSubmit={handleSubmit}
                                value={fields.text}
                                setIsOpen={setIsEditingText}
                                
                                ></CustomTextEditor>
                    
                    :<></>}
                </FullPageMiddleScroller>}
                </PuxMod>

    }
    export default Agreement
    
    function internal(){

    }
    
    