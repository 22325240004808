import { CSSField } from "@/types/css"

type Args = {
    styles:CSSField[]
    previewWidth:number
}

const createResponsiveStyles = ({styles,previewWidth}:Args)=>{
    const newStyles:CSSField[] = []
    styles.forEach((style)=>{
        const newStyle = {...style}
        if(style.value.includes("vw")){
            const number = parseInt(style.value.replace("vw",""))
            newStyle.value = `${number/100*previewWidth}px`
        }
        newStyles.push(newStyle)
    })

    return newStyles
}

export default createResponsiveStyles