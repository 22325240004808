const testing = window.location.href.includes("localhost:3000")

const sections = [
    "Agreement","Blog","Comments","ContactForm","ContactInfo","Episodes","SimpleEmbed","Events","Hero","ImageAndText","ImageGrid","LinkedImageGrid","Instagram","Links","MailingList","Reviews","Socials","Subscribe","Team","Text","ButtonWithText","ThreeItems","TikTok","Twitter","Videos","VideoWithText"
]

const testSections:string[] = ["CustomForm"]

if(testing){
    sections.push(...testSections)
}

export default sections