type Input = {
    fields: Array<{
        key: string,
        value: string,
        _id?: string
    }>,
    html: string
};

export function replacePlaceholders(input: Input): string {
    let outputHtml = input.html;
    
    for (const field of input.fields) {
        const placeholder = `{{${field.key}}}`;
        outputHtml = outputHtml.replace(placeholder, field.value);
    }
    outputHtml = outputHtml.replaceAll("$<$br$>$","<br>").replaceAll("<$br$>$","<br>")
    return outputHtml;
}

