import backgroundOn from "../fields/backgroundOn";
import font from "../fields/font";
import headingFont from "../fields/headingFont";
import sectionBackground from "../fields/sectionBackground";
import teamImageSize from "../fields/teamImageSize";
import teamLayout from "../fields/teamLayout";
import textColor from "../fields/textColor";
import generics from "./generics";


export default [
    ...generics,
    teamLayout,
    teamImageSize
]