import "./index.scss"
import { SectionType } from "@/types/Section"
import React, { ChangeEvent, useContext, useEffect, useState } from "react"
import { PageContextType } from "@/types/Context"
import { PageContext } from "@/pages/Standard/context"
import ImageEditorNew from "@/generics/ImageEditorNew"
import route from "@/utils/route"
import AutoResizeTextArea from "@/generics/AutoResizeTextArea"
import ParagraphDivider from "@/generics/ParagraphDivider"
import PuxMod, { SectionContext } from "@/components/PuxMod"
import { i } from "@/utils/images"
import getSlug from "@/utils/getSlug"

    type Props = {
        section:SectionType
    }

    const ImageAndText = ({section}:Props)=>{
        const {isEditing}:PageContextType = useContext(PageContext)




        return <PuxMod section={section}>
            <Consumer></Consumer>
        </PuxMod>

    }


    function Consumer(){
        const sectionC:any = useContext(SectionContext)
        const {isEditing,sectionId,fields,setFields,childStyles}:any = sectionC
        let {image,paragraph,sectionHeading} = fields
        let imageField = "image"

        const handleChange = (e:ChangeEvent<(HTMLInputElement|HTMLTextAreaElement)>)=>{
            const {name,value} = e.target
            const newFields = {...fields}
            newFields[name] = value.replaceAll("\n","<br>");
            setFields(newFields)
        }

        const handleImageEdit = (img:string)=>{
            const newFields = {...fields}
            newFields[imageField] = img
            setFields(newFields)
        }

        const modifiedImageURL = i(image)
        return<>
                    {!isEditing&&<>
            <img className="bio-image" src={modifiedImageURL}></img>
                <div className={`text-cont ${sectionHeading==""?"grid-row-1-3":"normal"}`}>
                    <ParagraphDivider text={paragraph} style={childStyles.paragraph||{}} ></ParagraphDivider>
                </div>
            </>
                }
            {isEditing&&<><div className="cover bio-image">
                    <div className="image-cont">
                    <ImageEditorNew field="image" route={route('api/upload')} handleEdit={handleImageEdit} url={image} sectionId={sectionId} isSection={true}></ImageEditorNew>
                    </div>
                </div>
                <div className={`text-cont ${sectionHeading==""?"grid-row-1-3":"normal"}`}>
                    <AutoResizeTextArea style={childStyles.paragraph||{}} name="paragraph" onChange={handleChange} value={paragraph} className="p"></AutoResizeTextArea>
                </div></>}
        </>

    }

    export default ImageAndText