
    import "./index.scss"
    import React, { ChangeEvent, useState } from "react"

    type Props = {
        propertyName:string
        unit:string
        value:number
        min:number,
        max:number
        setValue:React.Dispatch<number>
        label?:string
      }


    const Slider = ({
        propertyName,
        unit,
        min,
        max,
        label,
        value,
        setValue
        
    
    }:Props)=>{

        const handleChange = (event:ChangeEvent<HTMLInputElement>) => {

          setValue(parseInt(event.target.value));
        };
      
        return (
          <div className="slider-cont">
            {label&&<label>{label}</label>}
            <input
              className="custom-slider"
              type="range"
              min={min}
              max={max}
              value={value}
              onChange={handleChange}
            />
            {!label&&<div>{propertyName}: {value} {unit}</div>}
          </div>
        );
    }
    export default Slider
    
    
    