
    import { Context, ContextValueType } from "@/context"
import "./index.scss"
    import React, { useContext, useState } from "react"
import HomepageHeader from "@/layout/headers/HomepageHeader"
import SectionHeading from "@/generics/SectionHeading"
import Experimental from "@/wrappers/Experimental"
import LoadingSpinner from "@/generics/LoadingSpinner"
import { buyTokens } from "@/api/tokens"
import { Link } from "react-router-dom"
import TokenPurchaseStripe from "@/components/TokenPurchaseStripeModal"

    
    const TokenPurchasing = ()=>{

        const {tokens,setTokens,credentials}:ContextValueType = useContext(Context)
        const [purchaseAmount,setPurchaseAmount] = useState(0)
        const [loading,setLoading] = useState(false)
        const [isCheckoutOpen,setIsCheckoutOpen] = useState(false)

        if(loading){
            return <LoadingSpinner
            message="Purchasing..."
            ></LoadingSpinner>
        }

        return(
            <Experimental>
            <HomepageHeader
            additionalClass="purchase-tokens-header"
            ></HomepageHeader>
            
            
            <main className="tokens-main">
                {isCheckoutOpen&&<TokenPurchaseStripe
                purchaseAmount={purchaseAmount}
                setIsCheckoutOpen={setIsCheckoutOpen}
                ></TokenPurchaseStripe>}
                {isCheckoutOpen||<><h2>You currently have {tokens} tokens.</h2>
                <h2>Need More? Purchase below.</h2>
                <div className="purchase-area">
                    <input value={purchaseAmount}
                    type="number"
                    step="1"
                    min={0}
                    onChange={(e)=>setPurchaseAmount(parseInt(e.target.value))}
                    ></input>
                    {purchaseAmount>0&&<button onClick={()=>setIsCheckoutOpen(true)}>Go To Checkout</button>}
                    <Link to="/">
                    <button className="back-to-dash-btn">Back to Dashboard</button>
                    </Link>
                </div></>}
            </main>
                    </Experimental>
        )
    }
    export default TokenPurchasing
    
    
    