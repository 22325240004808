
    import "./index.scss"
    import React,{useContext,useEffect,useState} from "react"
import LayoutModal from "../LayoutModal"
import { SectionType } from "@/types/Section"
import ToggleOn from "../widgets/ToggleOn"
import ReorderArrows from "../widgets/ReorderArrows"
import { firstLastCheck, reorderSections } from "@/utils/arrays"
import { PageContextType } from "@/types/Context"
import { PageContext } from "@/pages/Standard/context"
import { changeIsOn, removeSection } from "@/api/section"
import { getEditOrdinal } from "@/utils/pageManipulation"
import Toggle from "@/generics/Toggle"
import MenuToggle from "../MenuToggle"
import { Context, ContextValueType } from "@/context"
import Explanation from "../Explanation"
import CustomLayoutModal from "../CustomLayoutModal"

type Props = {
    section:SectionType,
    isOn:boolean,
    setIsOn:React.Dispatch<boolean>,
    style:string,
    setStyle:React.Dispatch<string>,
    layoutModalOpen:boolean,
    setLayoutModalOpen:React.Dispatch<boolean>,
    inNav?:boolean,
    sectionName?:string
}


    const EditSectionHeader = ({    
        section,
        isOn,
        setIsOn,
        style,
        setStyle,
        layoutModalOpen,
        setLayoutModalOpen,
        sectionName,
        inNav
    }:Props)=>{
        const {setConfirmation}:ContextValueType = useContext(Context)
        const [initialized,setInitialized] = useState(false)
        const {editOrdinals,setEditOrdinals,deleteSection,calculatedValuesState}:PageContextType = useContext(PageContext)
        const {pageId}:PageContextType = useContext(PageContext)
        const handleEditScreen = (direction:string)=>{
            const newEditOrdinals = reorderSections({editOrdinals,sectionId:section._id,direction})
            setEditOrdinals(newEditOrdinals)
        }
        const ordinal = getEditOrdinal({sectionId:section._id,editOrdinals})
        const {first,last} = firstLastCheck({ordinal,length:editOrdinals.length})
        const isCustom = section?.sectionForm === "CustomTemplate"


        useEffect(()=>{
            if(!initialized){
                setInitialized(true)
                return
            }
            changeIsOn({sectionId:section._id,isOn})
        },[isOn])

        const handleDeleteButton = ()=>{
            const cb = ()=>{
                deleteSection(section._id)
                removeSection({sectionId:section._id,pageId})
                setConfirmation(null)
            }
            setConfirmation({callback:cb,cancelCallback:()=>{setConfirmation(null)},message:"Confirm Delete Section?"})
        }

        const top = ordinal>0?"0px":`${calculatedValuesState.headerBuffer}px`

        const explanation = "this is a section"

        return(<div style={{top}} className={`edit-section-header ${(layoutModalOpen||!isOn)?"style-open":"style-closed"}`}>
            {/* col 1 */}
            <div>
            <ReorderArrows first={first} last={last} section={section} handleEditScreen={handleEditScreen}></ReorderArrows>

            </div>
            {/* col 2 */}
            <div>
            {isOn?<></>:<button onClick={handleDeleteButton} className="delete-section-btn danger">Delete Section</button>}
            </div>
            <div className="toggles-cont">
            {/* col 3 */}
            <ToggleOn isOn={isOn} setIsOn={setIsOn}></ToggleOn>
            {!isOn||isCustom?<></>:<LayoutModal
            style={style}
            setStyle={setStyle}
            sectionType={section.sectionForm}
            sectionId={section._id}
            setLayoutModalOpen={setLayoutModalOpen}
            sectionName={sectionName}
            ></LayoutModal>}
            {!isOn||!isCustom?<></>:<CustomLayoutModal
            style={style}
            setStyle={setStyle}
            sectionType={section.sectionForm}
            sectionId={section._id}
            setLayoutModalOpen={setLayoutModalOpen}
            sectionName={sectionName}
            ></CustomLayoutModal>}
            {isOn&&<MenuToggle></MenuToggle>}
            {explanation&&<Explanation
            topic={section.sectionForm}
            ></Explanation>}
            </div>
            </div>
        )
    }
    export default EditSectionHeader
    
    
    