
    import { Children } from "@/types/JSX"
import "./index.css"
    import React from "react"

    type Props = {
        children:string
        to:string
    }

    const RedirectButton = ({children,to}:Props)=>{

        const handleClick = ()=>{
            window.location.replace(to)
        }

        return(
            <button onClick={handleClick}>{children}</button>
        )
    }
    export default RedirectButton
    
    
    