import { DynamicObject } from "@/types/generic";

function getSlug(){
    const href = window.location.href;
    const split = href.split("/")
    if(split[split.length-2]===""){
        return ""
    }
    const res = "/"+split[split.length-1];
    return res
}

export function getPageName(){
    return getSlug().substring(1)
}

export function getDirectory(){
    const href = window.location.href;
    const split = href.split("/")
    if(split[split.length-2]===""){
        return ""
    }
    const res = "/"+split[split.length-2];
    return res
}

export function getUser(){
    const href = window.location.href;
    const split = href.split("/")
    if(split[split.length-2]===""){
        return ""
    }
    const res = split[split.length-1];
    return res
}

export function getParams(){
    const paramsString = window.location.href.split("?")[1]
    if(!paramsString){
        return {}
    }
    let params:string[] = paramsString.split("&")
    const obj:DynamicObject = {}
    params.forEach((param:string)=>{
        const split = param.split("=")
        obj[split[0]] = split[1]
    })
    return obj;
}
export default getSlug

