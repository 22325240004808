
    import getSlug from "@/utils/getSlug"
import SectionTemplateBuilder from "../SectionTemplateBuilder"
import "./index.css"
    import React, { useEffect, useState } from "react"
import { getSingleTemplate } from "@/api/sectionTemplate"
import addValuesToVariables from "./addValuesToVariables"
import { CSSField } from "@/types/css"
import removeHTMLContainer from "./removeHTMLContainer"

    const SectionTemplateVariation = ()=>{
        
        const templateId = getSlug().substring(1)

        const [template,setTemplate] = useState<any>(null)
        
        useEffect(()=>{
            const f = async ()=>{
                const res = await getSingleTemplate({templateId})
                if(!res.error){
                    setTemplate(res)
                }
            }
            f()
        },[])

        if(!template){
            return<></>
        }

        const {html,fields} = template

        const htmlWithValues = addValuesToVariables({html,fields})

        const css = template.css.map((css:CSSField)=>{
            return {selector:css.selector,property:css.property,value:css.value}
        })

        return(
            <SectionTemplateBuilder
            copiedHTML={htmlWithValues}
            copiedStyles={css}
            
            ></SectionTemplateBuilder>
        )
    }
    export default SectionTemplateVariation
    
    
    