import { PageDetailsType } from "@/types/Page"

type Args = {
    page:PageDetailsType
}

const getFooter = ({page}:Args)=>{
    const footer = page.layoutElements.find(el=>el.elementName==="footer")
    return footer
}

export default getFooter