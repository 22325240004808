
    import "./index.scss"
    import React from "react"
import { SectionType } from "@/types/Section"
import PuxMod from "@/components/PuxMod"
import EButton from "@/components/EditableComponent/EButton"
import ETextArea from "@/components/EditableComponent/ETextArea"

    const ButtonWithText = ({section}:{section:SectionType})=>{
        
        return(
            <PuxMod section={section} headless={true}>
                <div className="content">
                <ETextArea
                field="text"
                ></ETextArea>
                <EButton
                field="buttonText"
                ></EButton>
                </div>
            </PuxMod>
        )
    }
    export default ButtonWithText
    
    
    