
    import { SectionType } from "@/types/Section"
import "./index.scss"
import EEmbed from "@/components/EditableComponent/EEmbed"
import PuxMod from "@/components/PuxMod"

    type Props = {
        section:SectionType
    }

    const SimpleEmbed = ({section}:Props)=>{


        return(
            <PuxMod section={section} headless={false}>
            <EEmbed field={"embeddedHTML"}></EEmbed>
            </PuxMod>
        )
    }
    export default SimpleEmbed
    
    
    