import { editGenericSubsection, putSubsectionStringArray } from "@/api/genericSubsection"
import { PageContext } from "@/pages/Standard/context"
import { PageContextType } from "@/types/Context"
import { GenericSubsectionDocument } from "@/types/Documents"
import { DynamicObject } from "@/types/generic"
import { makeFieldObject } from "@/utils/arrays"
import { ChangeEvent, useContext, useEffect, useState } from "react"
import { useDebounce } from "use-debounce"
import useSkipFirstMutation from "./useSkipFirstMutation"
import { removeUnrelatedFields } from "@/sections/CustomForm/helpers"
import { editSubsectionImageDetails } from "@/api/subsectionImageDetails"
import { Field } from "@/types/Section"
import { ImageDetails } from "@/types/Images"


// we need section and useContext

type OptionsType = {
    enableAPI:true
}

type Props = {
    subsection:GenericSubsectionDocument
    options?:OptionsType
}

export type SubsectionState = {
    isEditing:boolean
    fields:DynamicObject|null
    setFields:React.Dispatch<DynamicObject|null>
    delayedFields:DynamicObject|null
    handleInputChange:(e: ChangeEvent<HTMLInputElement>) => void
    subsectionId:string
    handleSetField:({ name, value }: {
        name: string;
        value: string;
    }) => void
    stringArray:string[]
    setStringArray:React.Dispatch<string[]>
    imageDetails:ImageDetails
    setImageDetails:React.Dispatch<ImageDetails>
    subsection:GenericSubsectionDocument
    isDeleted:boolean
    setIsDeleted:React.Dispatch<boolean>
}

export default ({subsection,options}:Props)=>{
    const [initialized,setInitialized] = useState(false)
    const [fields,setFields] = useState<DynamicObject|null>({...removeUnrelatedFields(subsection),...makeFieldObject(subsection.fields)})
    const [delayedFields]= useDebounce(fields,1000)
    const [stringArray,setStringArray] = useState(subsection.stringArray||[])
    const {isEditing}:PageContextType = useContext(PageContext)
    const subsectionId = subsection._id
    const [delayedStringArray] = useDebounce(stringArray,2000)
    const [imageDetails,setImageDetails] = useState(subsection.imageDetails||(subsection.className?{}:{height:"30vw"}))
    const [isDeleted,setIsDeleted] = useState(false)
    useSkipFirstMutation(()=>{
        putSubsectionStringArray({subsectionId:subsection._id,stringArray})
    },[delayedStringArray])

    useSkipFirstMutation(()=>{
        editSubsectionImageDetails({subsectionId,imageDetails})
    },[imageDetails])

    useEffect(()=>{
        if(!options?.enableAPI||!isEditing){
            return
        }
        if(!initialized||!delayedFields){
            setInitialized(true)
            return
        }
        delete delayedFields.__v
        editGenericSubsection({subsectionId,changedFields:delayedFields,type:"generic"}).then((res)=>{
        })
    },[delayedFields])

    const handleInputChange = (e:ChangeEvent<HTMLInputElement>)=>{// **
        setInitialized(true)
        const dataset = e.target.dataset
        let {name,value}= e.target// **
        if(dataset.break){
            value = value.replaceAll("\n","<br>")
        }
        const newFields = {...fields}// **
        newFields[name] = value;// **
        setFields(newFields)// **
    }

    const handleSetField = ({name,value}:{name:string,value:string})=>{
        const currentFields = {...fields}
        currentFields[name] = value;
        setFields(currentFields)
    }

    const subsectionState:SubsectionState = {
        isEditing,fields,setFields,delayedFields,handleInputChange,subsectionId,handleSetField,stringArray,setStringArray,imageDetails,setImageDetails,subsection,isDeleted,setIsDeleted
    }
    return subsectionState
}