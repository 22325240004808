import { CSSLineType } from "@/components/CSSEditor"

type Args = {
    classString:string
    property:string
    cssArray:CSSLineType[]
}

const findCSSValueInArray = ({classString,property,cssArray}:Args)=>{
    if(!classString){
        return null
    }
    const classSelectors = classString?.split(" ").map(c=>`.${c}`)
    for (let classSelector of classSelectors){
        const match = cssArray.find((cssLine:CSSLineType)=>{
            const selectorMatch = cssLine.selector===classSelector
            const propertyMatch = cssLine.property===property
            return selectorMatch&&propertyMatch
        })
        if(match){
            return match.value
        }
    }
}

export default findCSSValueInArray