import "../style/mixins.scss"


import { Context, ContextValueType } from "@/context"
import Agreement from "@/pages/Agreement"
import AnimationTests from "@/pages/AnimationTests"
import ConfirmRegistration from "@/pages/ConfirmRegistration"
import ForgotPassword from "@/pages/ForgotPassword"
import Login from "@/pages/Login"
import Register from "@/pages/Register"
import ResetPassword from "@/pages/ResetPassword"
import TestPage from "@/pages/TestPage"
import Themes from "@/pages/Themes"
import UserSettings from "@/pages/UserSettings"
import { useContext } from "react"
import { Route, Routes } from "react-router-dom"
import Home from "../pages/Home"
import Standard from "../pages/Standard"
import Article from "@/pages/Article"
import ArticleEdit from "@/pages/ArticleEdit"
import ArticlesList from "@/pages/ArticlesList"
import Spotlight from "@/pages/Spotlight"
import TestPagesLinks from "@/pages/TestPagesLinks"
import HelpPage from "@/pages/HelpPage"
import EPK from "@/pages/EPK"
import Messages from "@/pages/Messages"
import SectionRequest from "@/pages/SectionRequest"
import SectionJobs from "@/pages/SectionJobs"
import FreelanceDev from "@/pages/FreelanceDev"
import DevCenter from "@/pages/DevCenter"
import MySectionRequests from "@/pages/MySectionRequests"
import MySectionRequest from "@/pages/MySectionRequest"
import SectionTemplateBuilder from "@/pages/SectionTemplateBuilder"
import TokenPurchasing from "@/pages/TokenPurchasing"
import SectionSubmissionEvaluation from "@/pages/MySectionRequest/SectionSubmissionEvaluation"
import SectionSubmitSuccess from "@/pages/SectionSubmitSuccess"
import Payout from "@/pages/Payout"
import DevelopersSplash from "@/pages/DevelopersSplash"
import PremiumSectionTemplates from "@/pages/PremiumSectionTemplates"
import SectionTemplateVariation from "@/pages/SectionTemplateVariation"
// MAKE SURE WITH EACH ROUTE ADDED YOU ADD IT TO THE RESERVED NAMES ON THE SERVERSIDE
// TO doubly ensure that there is no conflict, put a hyphen in the slug
// server\src\routes\pageRoutes\helpers.ts
    const Router = ()=>{

        const {loading}:ContextValueType  = useContext(Context)
        const host = process.env.MOCK_HOST ||window.location.host
        if(!["localhost:3000","www.puxhub.com",'puxhub.herokuapp.com','puxhubtest.herokuapp.com'].includes(host)){
            return<Standard host={host}></Standard>

        }
        

        return(
            <>
            <Routes>
                {/* <Route path={"/develop"} element={<SocialsSection></SocialsSection>}></Route> */}
                <Route path={"/confirm-registration/*"} element={<ConfirmRegistration></ConfirmRegistration>}></Route>
                <Route path={"/e-presskit/*"} element={<EPK></EPK>}></Route>
          
                <Route path={"/help-page"} element={<HelpPage></HelpPage>}></Route>
                <Route path={"/login"} element={<Login></Login>}></Route>
                <Route path={"/register"} element={<Register></Register>}></Route>
                <Route path={"/forgot-password/*"} element={<ForgotPassword></ForgotPassword>}></Route>
                <Route path={"/freelance-dev/*"} element={<FreelanceDev></FreelanceDev>}></Route>
                <Route path={"/test-page/*"} element={<TestPage></TestPage>}></Route>
                <Route path={"/reset-password/*"} element={<ResetPassword></ResetPassword>}></Route>
                <Route path={"/themes-page/"} element={<Themes></Themes>}></Route>
                <Route path={"/animation-tests"} element={<AnimationTests></AnimationTests>}></Route>
                <Route path={"/user-agreement"} element={<Agreement></Agreement>}></Route>
                <Route path={"/user-settings/"} element={<UserSettings></UserSettings>}></Route>
                <Route path={"/"} element={<Home></Home>}></Route>
                <Route path={"/_article/*"} element={<Article></Article>}></Route>
                <Route path={"/_article_edit/*"} element={<ArticleEdit></ArticleEdit>}></Route>
                <Route path={"/_articles/*"} element={<ArticlesList></ArticlesList>}></Route>
                <Route path={"/spot-light/*"} element={<Spotlight></Spotlight>}></Route>
                <Route path="test-links" element={<TestPagesLinks></TestPagesLinks>}></Route>
                <Route path="message-center" element={<Messages></Messages>}></Route>
                <Route path="/section-request" element={<SectionRequest></SectionRequest>}></Route>
                <Route path={"/section-jobs"} element={<SectionJobs></SectionJobs>}></Route>
                <Route path={"/dev-center"} element={<DevCenter></DevCenter>}></Route>
                <Route path={"/my-section-requests"} element={<MySectionRequests></MySectionRequests>}></Route>
                <Route path={"/my-section-requests/*"} element={<MySectionRequest></MySectionRequest>}></Route>
                <Route path={"/section-template-builder/*"} element={<SectionTemplateBuilder></SectionTemplateBuilder>}></Route>
                <Route path={"/purchase-tokens"} element={<TokenPurchasing></TokenPurchasing>}></Route>
                <Route path={"/submission-success"} element={<SectionSubmitSuccess></SectionSubmitSuccess>}></Route>
                <Route path={"/payout"} element={<Payout></Payout>}></Route>
                <Route path={"/developers"} element={<DevelopersSplash></DevelopersSplash>}></Route>
                <Route path={"/premium-section-templates/*"} element={<PremiumSectionTemplates></PremiumSectionTemplates>}></Route>
                <Route path={"/section-template-brancher/*"} element={<SectionTemplateVariation></SectionTemplateVariation>}></Route>
                <Route path={"/*"} element={<Standard></Standard>}></Route>
                
            </Routes>
            {/* {loading.isLoading&&<LoadingSpinner message={loading.message}/>} */}
            </>
        )
    }
    export default Router
    
    
    