import Testimonials from "@/components/Testimonials"
import Blog from "@/sections/Blog"
import Episodes from "@/sections/Episodes"
import ImageAndText from "@/sections/ImageAndText"
import ImageGrid from "@/sections/ImageGrid"
import Instagram from "@/sections/Instagram"
import MailingList from "@/sections/MailingList"
import SocialsSection from "@/sections/Socials"
import Subscribe from "@/sections/Subscribe"
import Team from "@/sections/Team"
import ButtonWithText from "@/sections/ButtonWithText"
import TextBlock1 from "@/sections/TextBlock"
import ThreeGrid from "@/sections/ThreeGrid"
import TikTok from "@/sections/TikTok"
import Twitter from "@/sections/Twitter"
import Video from "@/sections/Video"
import Videos from "@/sections/Videos"
import { SectionType } from "@/types/Section"
import ContactForm from "../sections/ContactForm"
import Events from "../sections/Events"
import Links from "../sections/Links"
import Text from "@/sections/Text"
import VideoWithText from "@/sections/VideoWithText"
import ThreeItems from "@/sections/ThreeItems"
import SimpleEmbed from "@/sections/SimpleEmbed"
import ContactInfo from "@/sections/ContactInfo"
import Agreement from "@/sections/Agreement"
import Reviews from "@/sections/Reviews"
import NewHero from "@/sections/NewHero"
import LinkedImageGrid from "@/sections/LinkedImageGrid"
import Custom from "@/sections/Custom"
import CustomForm from "@/sections/CustomForm"
import Comments from "@/sections/Comments"

    const SectionResolver = ({section}:{section:SectionType})=>{

        const {sectionForm} = section
        switch(sectionForm){
            case "Agreement":
                return<Agreement key={section._id}  section={section}></Agreement>
            case "Blog":
                return<Blog key={section._id}   section={section}></Blog>
            case "ButtonWithText":
                return<ButtonWithText key={section._id}  section={section}></ButtonWithText>
            case "Hero":
                return(<NewHero key={section._id}  section={section}></NewHero>)
            case "Comments":
                return<Comments key={section._id}  section={section}></Comments>
            case "ContactForm":
                return<ContactForm key={section._id}  section={section}></ContactForm>
            case "ContactInfo":
                return<ContactInfo key={section._id}  section={section}></ContactInfo>
            case "CustomForm":
                return<CustomForm key={section._id}  section={section}></CustomForm>

                case "Links":
                return<Links key={section._id}  section={section}></Links>

                case "Episodes":
                return<Episodes key={section._id}   section={section}></Episodes>
            case "Events":
                return<Events key={section._id}  section={section}></Events>
            case "ImageAndText":
                return<ImageAndText key={section._id}  section={section}></ImageAndText>
            case "ImageGrid":
                return<ImageGrid key={section._id}  section={section}/>    
                case "LinkedImageGrid":
                    return<LinkedImageGrid key={section._id}  section={section}/>        
                case "Instagram":
                    return<Instagram key={section._id}  section={section}></Instagram>
                case "MailingList":
                    return<MailingList key={section._id}  section={section}></MailingList>
                case "Reviews":
                return<Reviews key={section._id}  section={section}></Reviews>
                case "SimpleEmbed":
                    return<SimpleEmbed key={section._id}  section={section}></SimpleEmbed>
                case "Socials":
                return<SocialsSection key={section._id}  section={section}></SocialsSection>
            case "Subscribe":
                return<Subscribe key={section._id}  section={section}></Subscribe>
                case "Team":
            return<Team key={section._id}  section={section}></Team>
            case "TextBlock":
                return<TextBlock1 key={section._id}  section={section}></TextBlock1>
            case "Text":
                return<Text key={section._id}  section={section}></Text>
            case "ThreeItems":
                    return<ThreeItems key={section._id}  section={section}></ThreeItems>
            case "ThreeGrid":
                return<ThreeGrid key={section._id}  section={section}></ThreeGrid>
            case "Testimonials":
                return<Testimonials key={section._id}  section={section}></Testimonials>
            case "TikTok":
                return<TikTok key={section._id}  section={section}></TikTok>
                case "Twitter":
                    return<Twitter key={section._id}  section={section}></Twitter>
                case "Video":
                return<Video key={section._id}  section={section}></Video>
                case "VideoWithText":
                    return<VideoWithText key={section._id}  section={section}></VideoWithText>
                case "Videos":
                    return<Videos key={section._id}  section={section}></Videos>
                case "CustomTemplate":
                    return<Custom key={section._id}  section={section}></Custom>
                    default:
                return<div>{sectionForm} not FOUND!</div>
        }
    }
    export default SectionResolver
    
    
    