
    import "./index.scss"
    import * as VideoComp from "@/generics/Video"
import DeleteableCont from "@/generics/DeleteableCont"
import { ChangeEvent, useContext } from "react"
import { SectionContext } from "@/components/PuxMod"
import { PageContext } from "@/pages/Standard/context"
import { PageContextType } from "@/types/Context"
import { SectionState } from "@/types/Section"
    const VideoGeneric = VideoComp.default

    const DynamicYoutube = ({url,index}:{url:string,index:number})=>{
        
        const {isEditing}:PageContextType = useContext(PageContext)
        const {handleStringArrayDelete,handleStringArrayChange}:SectionState = useContext(SectionContext)
        
        const handleChange = (e:ChangeEvent<HTMLInputElement>)=>{
            const {value} = e.target;
            handleStringArrayChange({newText:value,index})

        }

        return(
            <DeleteableCont _id={url} handleDelete={handleStringArrayDelete}
            buttonPosition="top-right"            
            >
            <div className={isEditing?"edit-video-cont":""}>
            {isEditing&&<input placeholder="paste in youtube url" value={url} name="url" onChange={handleChange}></input>}
            {url===""?<></>:<VideoGeneric src={url.toString()}></VideoGeneric>}
            </div>
            </DeleteableCont>
            
        )
    }
    export default DynamicYoutube
    
    
    