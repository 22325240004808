
    import "./index.css"
    import React from "react"

    const Themes = ()=>{

        const fonts = ["georgia","monospace","arial","verdana","tahoma","trebuchet","times","lucida","courier","impact","roboto","open-sans","montserrat","lato","oswald","raleway","source-sans-pro","pt-sans","ubuntu","slabo-27px","playfair-display",'lora','merriweather','noto','pt-serif','crimson-text','josefin-sans','bitter','arimo','vollkorn']
        


        return(
            <section className="themes-section">
            <h1>Fonts</h1>
            <div>
                {fonts.map((font,index)=>{
                    return(
                        <div className="font-cont" key={index}>
                            <span  className={`${font}-font`}>{font}</span>
                            <span className={`${font}-font`}>How now, brown cow?</span>
                        </div>
                    )
                })}
            </div>
            </section>
        )
    }
    export default Themes
    
    
    