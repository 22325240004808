import { CSSLineType } from "@/components/CSSEditor"

type Args = {
    html:string
    cssArray:CSSLineType[]
}

const saveProgress = ({html,cssArray}:Args)=>{
    const obj = {savedHTML:html,savedCSSArray:cssArray}
    const string = JSON.stringify(obj)
    localStorage.setItem("sectionTemplate",string)
}

export default saveProgress