import { APIFailure } from "@/types/API"
import { Credentials, InputCredentials } from "@/types/credentials"
import { UserDocument } from "@/types/Documents"
import { PageDetailsType } from "@/types/Page"
import React from "react"
import { fexGet, fexPost } from "../utils/fex"

type LoginArguments = {
    credentials: InputCredentials,
    setCredentials:React.Dispatch<InputCredentials>
}

type LoginResponseSuccess = {
    message:"success",user:UserDocument,accessToken:string
}

export type LoginResponse = (APIFailure | LoginResponseSuccess)

export const login = async ({credentials,setCredentials}:LoginArguments):Promise<LoginResponse>=>{
        const data:LoginResponse = await fexPost("api/auth/login",credentials)
        return data
}

type RegisterArguments = {
    credentials: InputCredentials,
    recaptchaToken:string
}

type RegisterResponseSuccess = {
    error:false,
    message:"User created",
    data:UserDocument
}

interface RegisterAPIFailure extends APIFailure {
    errorType:{
        field:string
        statement:string
    }
}

type RegisterResponse = RegisterResponseSuccess | RegisterAPIFailure

export const register = async ({credentials,recaptchaToken}:RegisterArguments):Promise<RegisterResponse>=>{
        const data:RegisterResponse = await fexPost("api/auth",{credentials,recaptchaToken})
        return data
}

type GetPagesResponse = PageDetailsType[] | APIFailure

export const getUsersPages = async ({credentials}:{credentials:Credentials}):Promise<GetPagesResponse>=>{
    
    const route = `api/user/${credentials.user._id}/pages/`
    const pages:GetPagesResponse = await fexGet(route)
    return pages
}