
    import { changeSubscriptionPaymentMethod, GetSubResponse, getSubscriptionDetails, unsubscribe } from "@/api/stripe"
import { Context, ContextValueType } from "@/context"
import Card from "@/generics/Card"
import Modal from "@/generics/Modal"
import Notification from "@/generics/Notification"
import Toggle from "@/generics/Toggle"
import { APIFailure } from "@/types/API"
import { PageDetailsType } from "@/types/Page"
import { CardDetailsType, PaymentMethod, SubscriptionDetails } from "@/types/Stripe"
import { returnStatus } from "@/utils/pageStatus"
import { t } from "@/utils/stringFormatting"
import { useContext, useEffect, useState } from "react"
import DomainNameSettings from "../DomainNameSettings"
import PaymentMethods from "../PaymentComponents/PaymentMethods"
import PromoCodeBox, { PromoType } from "../PaymentComponents/PromoCodeBox"
import SubscriptionBox from "../PaymentComponents/SubscriptionBox"
import "./index.scss"

type Props = {
    setIsModalOpen:React.Dispatch<boolean>,
    page:PageDetailsType,
    subState:boolean
}

    const PageStatusPopup = ({setIsModalOpen,page,subState}:Props)=>{

        const [currentPageDetails,setCurrentPageDetails] = useState(page)
        const {status}:PageDetailsType  = currentPageDetails
        const {setConfirmation,credentials}:ContextValueType = useContext(Context)
        const [subDetails,setSubDetails] = useState<SubscriptionDetails|null>(null)
        const [isChangingPaymentMethod,setIsChangingPaymentMethod]= useState(false)
        const [cardDetails,setCardDetails] = useState<CardDetailsType|{brand:null,last4:null,pm_id:null}>({brand:null,last4:null,pm_id:null})
        const [promo,setPromo] = useState<PromoType>({isActive:false})

        // REMOVE_THIS
        const [isSubscribeBoxOpen,setIsSubscribeBoxOpen] = useState(true)
        // const [isSubscribeBoxOpen,setIsSubscribeBoxOpen] = useState(false)

        const confirmUnsubscribe = async ()=>{
            await unsubscribe({userId:credentials.user._id,pageId:page._id})
            window.location.reload()
        }

        const handleCancelClick = ()=>{
            const cancelCallback = ()=>{setConfirmation(null)}
            setConfirmation({message:"Do you truly wish to cancel your subscription and take your page offline?",callback:confirmUnsubscribe,cancelCallback})

        }

        useEffect(()=>{
            const getSubDetails = async ()=>{
                if(!subState){
                    return
                }
                const data  = (await getSubscriptionDetails(page._id) as GetSubResponse|APIFailure)
                if(data?.error){
                    return
                }
                setSubDetails(data)

                const {paymentMethod,subscription} = (data as GetSubResponse)

                const pm_id = (paymentMethod.id as string)
                const {brand,last4} = (paymentMethod.card as any)
                if(!brand||!last4){
                    return
                }
                setCardDetails({brand,last4,pm_id})
            }
            getSubDetails()
        },[])

        
        
        if(subState){
            // if(!subDetails?.paymentMethod?.card||!cardDetails){
            //     return<></>
            // }
    
            const {brand,last4,pm_id} = cardDetails
            return(
                <Modal additionalClass="sidebar page-status" setIsModalOpen={setIsModalOpen}>
                <>
                <header>
                <h1>{page.pageName} page settings</h1>
                </header>

                <DomainNameSettings page={page}/>


                <div className="subscription-box-cont">

                <div className="status">
                You are currently subscribed, for $5/month, which makes the page publically visible.
                </div>
                <div>
                <h4>Current Payment Method:</h4>
                     <p>{brand?.toUpperCase()||""} xxxx-xxxx-xxxx-{last4}</p>
                {!isChangingPaymentMethod?<button onClick={()=>setIsChangingPaymentMethod(true)}>Change Payment Method</button>:
                    <PaymentMethodsWrapper setIsChangingPaymentMethod={setIsChangingPaymentMethod} pm_id={pm_id} page={page} setCardDetails={setCardDetails}></PaymentMethodsWrapper>
                }
                </div>
                <button onClick={handleCancelClick} className="danger space-above">Unsubscribe</button>
                </div>
                </>
                </Modal>
            )
        }

        return(
            <Modal additionalClass={`sidebar page-status `} setIsModalOpen={setIsModalOpen}>
                <>
                <header>
            <h1>{page.pageName} page settings</h1>
                </header>
            <div>

            {!isSubscribeBoxOpen&&<div className="flex-row">

            {returnStatus(status)==="notLive"&&<>
            <button onClick={()=>setIsSubscribeBoxOpen(true)}>Purchase Subscription</button>
            </>}
            <h5>{t(returnStatus(status))}</h5>
            </div>}
            </div>
                {isSubscribeBoxOpen&&<div className="subscription-box-cont">
                    <h2>Not Currently Subscribed</h2>
                    <p>Subscription is required for page to be live to the public. Complete actions below to begin subscription. Promo code not necessary.</p>
                    <PromoCodeBox
                    promo={promo}
                    setPromo={setPromo}
                    ></PromoCodeBox>
                    <SubscriptionBox promo={promo} page={page}/>
                    </div>
                    }
                </>
            </Modal>
        )
    }
    export default PageStatusPopup

    type WrapperProps = {
        page:PageDetailsType
        pm_id:string
        setCardDetails:React.Dispatch<CardDetailsType|{brand:null,last4:null,pm_id:null}>
        setIsChangingPaymentMethod:React.Dispatch<boolean>
    }
    
    function PaymentMethodsWrapper({page,pm_id,setCardDetails,setIsChangingPaymentMethod}:WrapperProps){

        const [notificationOpen,setNotificationOpen] = useState<string|false>(false)
        const [currentPM,setCurrentPM] = useState(pm_id)

        const setSubscriptionPaymentMethod = async (paymentMethod:PaymentMethod)=>{
            const pm_id = paymentMethod.id
            const {brand,last4} = paymentMethod.card
            const resp = await changeSubscriptionPaymentMethod({pageId:page._id,pm_id})
            if(resp.error){
                return
            }
            setCardDetails({brand,last4,pm_id})
            if(pm_id!==currentPM){
                setCurrentPM(pm_id)
                setNotificationOpen("Payment Method Changed")
                setIsChangingPaymentMethod(false)
            }
        }




        return<>
            <PaymentMethods
            setSubscriptionPaymentMethod={setSubscriptionPaymentMethod}
            starting_pm_id={pm_id}
            setIsChangingPaymentMethod={setIsChangingPaymentMethod}
            ></PaymentMethods>
            {notificationOpen&&<Notification
            setNotificationOpen={setNotificationOpen}
            notificationMessage={notificationOpen}
            timer={1500}
            ></Notification>}
            </>
    }
    