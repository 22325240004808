
    import { MessageType } from "../PageMessenger"
import "./index.scss"
    import React from "react"

    type Props = {
        message:MessageType
    }

    const Message = ({message}:Props)=>{
        
        const {name,text} = message

        return(
            <div className="message">
                <h4>{name}</h4>
                <p>{text}</p>
            </div>
        )
    }
    export default Message
    
    
    