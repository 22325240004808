
    import "./index.scss"
    import React from "react"
import { SectionType } from "@/types/Section"
import PuxMod from "@/components/PuxMod"
import ETextArea from "@/components/EditableComponent/ETextArea"

    const ContactInfo = ({section}:{section:SectionType})=>{
        return(
            <PuxMod section={section} headless={true}>
                <div className="contact-info-cont">

                  <ETextArea field="phone"></ETextArea>
                  <ETextArea field="email"></ETextArea>
                  <ETextArea field="address"></ETextArea>
                  <ETextArea field="city"></ETextArea>
                </div>
            </PuxMod>
        )

    }
    export default ContactInfo
    
    
    