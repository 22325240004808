
    import Typer from "@/generics/Typer"
import "./index.scss"
    import React, { useEffect, useState } from "react"

    type Props = {
        message:string
        redirect:string
    }

    const FullPageSuccess = ({message,redirect}:Props)=>{

        const [visibleLetters,setVisibleLetters] = useState("")
        const length = visibleLetters.length

        useEffect(()=>{
            if(visibleLetters===message){
                return
            }
            setTimeout(()=>{
                const current = message.substring(0,(length+1))
                setVisibleLetters(current)
            },100)
        },[visibleLetters])

        const doneTypingHandler = ()=>{
            window.location.replace(redirect)
        }

        return(
            <main className="full-page-success">
            <h1><Typer
            string={message}
            msSpeed={80}
            doneTypingHandler={doneTypingHandler}
            ></Typer></h1>
            </main>
        )
    }
    export default FullPageSuccess
    
    
    