
    import "./index.scss"
    import React, { useState } from "react"
import { PageDetailsType } from "@/types/Page"
import { addDomain, removeDomain } from "@/api/domains"
import HelpLink from "@/generics/HelpLink"

    const DomainNameSettings = ({page}:{page:PageDetailsType})=>{

        const [domainName,setDomainName] = useState("")
        const [domainAdded,setDomainAdded] = useState(page.domainNames?.[0]||null)

        const handleButton = async ()=>{
            const resp = await addDomain({pageId:page._id,domainName})
            if(resp){
                setDomainAdded(domainName)
            }
        }

        const handleRemoveButton = async ()=>{
            if(!domainAdded){
                return
            }
            const resp = await removeDomain({pageId:page._id,domainName:(domainAdded)})
            if(resp){
                setDomainAdded(null)
            }
        }

        if(domainAdded){
            return(<div className="current-domain-cont">
                <h1>Current Domain</h1>
                {domainAdded}
                <button onClick={handleRemoveButton}>Remove</button>
                </div>)
        }

        return(
            <>
            <div className="domain-add-cont">
            <input placeholder="Enter your domain here" value={domainName} onChange={(e)=>setDomainName(e.target.value)}></input>
            <button onClick={handleButton}>Add Domain</button>
            
            <HelpLink topicId="topic_1"></HelpLink>
            </div>
            </>
        )
    }
    export default DomainNameSettings
    
    
    