import { APIFailure, BasicResponse } from "@/types/API"
import { Procrast } from "@/types/procrast"
import { fexGet, fexPost, fexPut } from "@/utils/fex"
import { PaymentMethod } from "@stripe/stripe-js"
import { string } from "yup"

export const addPaymentMethodToUser = async ({paymentMethodId,userId}:{paymentMethodId:string,userId:string}):Promise<BasicResponse>=>{
        const resp:BasicResponse = await fexPost("api/stripe/addPaymentMethod",{paymentMethodId,userId})
        return resp
}


// This i'll have to go back and fix
type GetUsersPaymentMethodsSuccess = any[]

export type GetUsersPaymentMethodsResponse = (APIFailure | GetUsersPaymentMethodsSuccess)

export const getUsersPaymentMethods = async (userId:string):Promise<GetUsersPaymentMethodsResponse>=>{
    const data:GetUsersPaymentMethodsResponse = await fexGet(`api/stripe/paymentMethods/${userId}`)
    return data
}

export type SubscribeVariables = {
    userId:string,
    pageId:string,
    pm_id:string,
    promoCode?:string
}

export const subscribe = async ({userId,pageId,pm_id,promoCode}:SubscribeVariables):Promise<BasicResponse>=>{
    const data:BasicResponse = await fexPost(`api/stripe/addSubscription/${pageId}`,{userId,pm_id,promoCode})
    return data
}

export const unsubscribe = async ({userId,pageId}:{userId:string,pageId:string}):Promise<BasicResponse>=>{
    const data:BasicResponse = await fexPut(`api/stripe/removeSubscription/${pageId}`,{userId})
    return data
}

export type GetSubResponse = {
    error:false
    subscription:Procrast
    paymentMethod:PaymentMethod
}

export const getSubscriptionDetails = async (pageId:string)=>{
    try{
        const resp:(GetSubResponse|APIFailure) = await fexGet("api/stripe/subscription/"+pageId)
        return resp
    }catch(err){
        return err
    }
}

export const changeSubscriptionPaymentMethod = async ({pageId,pm_id}:{pageId:string,pm_id:string})=>{
    try{
        const resp = await fexPut("api/stripe/paymentMethod/"+pageId,{pm_id})
        return resp
    }catch(err){
        return err
    }
}

