import "./index.css"
import BigQuote from "@/generics/BigQuote"
import GenericSubsection, { ElementType } from "../GenericSubsection"
import Section from "@/generics/Section"
import { TestimonialDocument } from "@/types/Documents"
import { SectionType } from "@/types/Section"

    const Testimonials = ({section}:{section:SectionType})=>{
        const {testimonials} = section

        return(
            <>
                        <Section sectionName="testimonials">
                        {testimonials.map((element:ElementType,key:number)=>{
                            return(<GenericSubsection
                            type="testimonial" element={element} key={key}></GenericSubsection>)})}
                    </Section>
            </>
        )
    }
    export default Testimonials
    
    
    