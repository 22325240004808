import { pairwiseArray, tripletArray } from "@/utils/arrays";
import { Image } from ".";

export const addMasonryWidth = (imageObjects:Image[])=>{
    const rowWidth = document.body.clientWidth;
    const screenWidth = rowWidth-10
    const widths:number[] = []
    if(rowWidth<600){
        for (let i of imageObjects){
            widths.push(screenWidth)
        }
    }
    // this will change to 900
    else if(rowWidth<1000){
        const pairs = pairwiseArray(imageObjects)
        pairs.forEach(([first,second])=>{
            if(!first||!second){
                widths.push(screenWidth*.5)
                widths.push(screenWidth*.5)
            }else{

                const pairWidths =[first.width,(second.ratio/first.ratio)*first.width]
                const totalWidth = pairWidths.reduce((a,b)=>a+b,0)
                const screenReduction = screenWidth/totalWidth
                widths.push(pairWidths[0]*screenReduction)
                widths.push(pairWidths[1]*screenReduction)
            }
        })
    }
    else{
        const triplets = tripletArray(imageObjects)
        triplets.forEach(([first,second,third])=>{
            if(!first||!second||!third){
                widths.push(screenWidth*.5)
                widths.push(screenWidth*.5)
                widths.push(screenWidth*.5)

            }else{   
                const tripletWidths =[first.width,(second.ratio/first.ratio)*first.width,(third.ratio/first.ratio)*first.width]
                const totalWidth = tripletWidths.reduce((a,b)=>a+b,0)
                const screenReduction = screenWidth/totalWidth
                widths.push(tripletWidths[0]*screenReduction)
                widths.push(tripletWidths[1]*screenReduction)
                widths.push(tripletWidths[2]*screenReduction)
            }
        })
    }

    const imagesWithNewWidths = imageObjects.map((image,num)=>{
        return {...image,newWidth:widths[num]}
    })    
    return imagesWithNewWidths
}