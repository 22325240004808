export default     {
    prompt:"Button Border?",
    field:"buttonBorderRadius",
    type:"childStyle",
    child:".e-button",
    valueType:"string",
    default:"soft",
    options:[
        {value:"10px",text:"Soft"},
        {value:"0px",text:"Boxy"},
        {value:"2em",text:"Rounded"}
    ],
    property:"borderRadius",
}