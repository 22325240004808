
    import "./index.scss"
    import React from "react"

    type Props = {
        setIsOpen:React.Dispatch<boolean>
    } 

    const CloseButton = ({setIsOpen}:Props)=>{
        return(
            <span onClick={()=>{setIsOpen(false)}} className="close-button">&times;</span>
        )
    }
    export default CloseButton
    
    
    