
    import Modal from "@/generics/Modal"
import "./index.scss"
    import React, { useContext, useState } from "react"
import { SectionContext } from "../PuxMod"
import { SectionDevelopmentContext } from "@/context/SectionDeveloperContext"
import LoadingSpinner from "@/generics/LoadingSpinner"
import { submitSectionSubmission } from "@/api/sectionSubmission"

    const SectionDevManager = ()=>{

        const [confirm,setConfirm] = useState(false)
        const {sectionId}:any = useContext(SectionContext)
        const {devSubmission,markAsSubmitted}:any = useContext(SectionDevelopmentContext)
        const [isLoading,setIsLoading] = useState(false)

        if(devSubmission.isDone){
            return<></>
        }

        if(!confirm){

            return<button onClick={()=>setConfirm(true)} className="blue section-dev-btn">Submit Section</button>
        }

        const handleSubmit = async ()=>{
                setIsLoading(true)
                const res = await submitSectionSubmission({submissionId:devSubmission._id,sectionId})
                !res?.error&&markAsSubmitted()
                
            }

        if(isLoading){
            return<LoadingSpinner></LoadingSpinner>
        }

        return(
            <Modal>
                <div className="section-submit-confirmation">
                <div>
                    <h2>Are you ready?</h2>
                    <p>

                    Submitting this section will make it available to the person who made the request. You will be notified if they choose it or have additional specifications for it.
                    </p>
                    <p>
                        You may make changes to this section while it is submitted, and those changes will be reflected in your submission. Also, you may retract this submission in the future.
                    </p>
                    <p>You may only submit one section per request.</p>
                </div>
                <footer>
                    <button onClick={handleSubmit}>Confirm</button>
                    <button onClick={()=>setConfirm(false)} className="danger">Cancel</button>
                </footer>
                </div>
            </Modal>
        )
    }
    export default SectionDevManager
    
    
    