
    import "./index.scss"
    import React from "react"
import Section from "@/generics/Section"
import Logo from "@/generics/Logo"
import CallToAction from "@/generics/CallToAction"
import { toRegister } from "@/utils/navigation"

    const CTASection = ()=>{


        return(
            <Section sectionName="cta">
                <div className="cta-grid">
                    <CallToAction
                    text="Want your own comedy site?"
                    buttonText="Start Here"
                    buttonHandler={toRegister}
                    />
                </div>
            </Section>
        )
    }
    export default CTASection
    
    
    