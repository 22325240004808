import "./index.scss"
import {FaFacebook, FaInstagram, FaTiktok, FaTree, FaTwitch, FaTwitter, FaUser, FaYoutube} from "react-icons/fa"
import { Context, ContextValueType } from "@/context"
import { useContext, useEffect, useState } from "react"
import { SocialsType } from "@/types/Socials"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import HoverEditor from "../HoverEditor"
import { editGenericSubsection } from "@/api/genericSubsection"
import { editSectionFields } from "@/api/section"
import { changeLayoutElementFields } from "@/api/layoutElement"
import { DynamicAnyObject } from "@/types/generic"


    const hasCharacters = (string:(string|undefined))=>{
        if(!(typeof string === "string")){
            return false
        }
        if(string.length<1){
            return false
        }
        return true
    }


    type Props = {socials:SocialsType,size?:number,isEditing?:boolean,subsectionId?:string,sectionId?:string,layoutId?:string}

    const Socials = ({socials,size=60,isEditing,subsectionId,sectionId,layoutId}:Props)=>{
        const [currentSocials,setCurrentSocials] = useState<DynamicAnyObject>({})
        const handleChange = ({field,value}:{field:string,value:string})=>{
            const newSocials = {...currentSocials}
            newSocials[field as keyof SocialsType] = value
            setCurrentSocials(newSocials)
            if(layoutId){
                changeLayoutElementFields({elementId:layoutId,fields:{...newSocials}})
            }
            if(subsectionId){
                editGenericSubsection({subsectionId:(subsectionId||""),changedFields:{...newSocials},type:"generic"})
                return
            }
            if(sectionId){
                editSectionFields({sectionId,changedFields:{...newSocials},})
            }

        }
        
        useEffect(()=>{
            setCurrentSocials({...socials})
        },[socials])

        return(
        <div className="socials">
        <HoverEditor isEditing={isEditing} condition={hasCharacters(currentSocials?.facebook)}
        initialValue={currentSocials?.facebook||""}
        externalSetter={handleChange}
        field="facebook"
        ><a aria-disabled={true} target="_blank" href={getSocialURL(currentSocials?.facebook||"")}><FaFacebook size={size} className="social-icon i-tag"></FaFacebook></a></HoverEditor>

        <HoverEditor isEditing={isEditing} condition={hasCharacters(currentSocials?.instagram)}
        initialValue={currentSocials?.instagram||""}
        externalSetter={handleChange}
        field="instagram"
        ><a aria-disabled={true} target="_blank" href={getSocialURL(currentSocials?.instagram||"")}><FaInstagram size={size} className="social-icon  i-tag"></FaInstagram></a></HoverEditor>

        <HoverEditor isEditing={isEditing} condition={hasCharacters(currentSocials?.twitter)}
        initialValue={currentSocials?.twitter||""}
        externalSetter={handleChange}
        field="twitter"
        ><a aria-disabled={true} target="_blank" href={getSocialURL(currentSocials?.twitter||"")}><FaTwitter size={size} className="social-icon  i-tag"></FaTwitter></a></HoverEditor>

        <HoverEditor isEditing={isEditing} condition={hasCharacters(currentSocials?.youtube)}
        initialValue={currentSocials?.youtube||""}
        externalSetter={handleChange}
        field="youtube"
        ><a aria-disabled={true} target="_blank" href={getSocialURL(currentSocials?.youtube||"")}><FaYoutube size={size} className="social-icon  i-tag"></FaYoutube></a></HoverEditor>

        <HoverEditor isEditing={isEditing} condition={hasCharacters(currentSocials?.tiktok)}
        initialValue={currentSocials?.tiktok||""}
        externalSetter={handleChange}
        field="tiktok"
        ><a aria-disabled={true} target="_blank" href={getSocialURL(currentSocials?.tiktok||"")}><FaTiktok size={size} className="social-icon  i-tag"></FaTiktok></a></HoverEditor>
        <HoverEditor isEditing={isEditing} condition={hasCharacters(currentSocials?.twitch)}
        initialValue={currentSocials?.twitch||""}
        externalSetter={handleChange}
        field="twitch"
        ><a aria-disabled={true} target="_blank" href={getSocialURL(currentSocials?.twitch||"")}><FaTwitch size={size} className="social-icon  i-tag"></FaTwitch></a></HoverEditor>
        <HoverEditor isEditing={isEditing} condition={hasCharacters(currentSocials?.linkTree)}
        initialValue={currentSocials?.linkTree||""}
        externalSetter={handleChange}
        field="linkTree"
        ><a aria-disabled={true} target="_blank" href={getSocialURL(currentSocials?.linkTree||"")}><FaTree size={size} className="social-icon  i-tag"></FaTree></a></HoverEditor>
        
        <HoverEditor isEditing={isEditing} condition={hasCharacters(currentSocials?.website)}
        initialValue={currentSocials?.website||""}
        externalSetter={handleChange}
        field="website"
        ><a aria-disabled={true} target="_blank" href={getSocialURL(currentSocials?.website||"")}><FaUser size={size} className="social-icon  i-tag"></FaUser></a></HoverEditor>
        </div>
        )




    }
    function getSocialURL(url:string){

        if(url.indexOf("://")>0){
            return url
        }else{
            return `http://${url}`
        }
    }
    export default Socials
    
    
    