
    import "./index.scss"
    import React, { CSSProperties, useContext, useEffect, useState } from "react"
import LittleCircleButton from "@/generics/buttons/LittleCircleButton"
import { FaMobile } from "react-icons/fa"
import FullPageMiddleScroller from "../FullPageMiddleScroller"
import MobileFrame from "@/generics/MobileFrame"
import { SectionContext } from "../PuxMod"
import { makeFieldObject } from "@/utils/arrays"
import useSectionZIndex from "@/hooks/useSectionZIndex"
import Slider from "@/generics/Slider"
import { makeFullStyleObject } from "@/utils/theme"
import { getHorizontalAlignment, getVerticalAlignment } from "./helpers"
import OptionButtonsCont from "@/generics/buttons/OptionButtonsCont"
import { DynamicObjectWithNumber } from "@/types/generic"
import { changeSectionStyle } from "@/api/style"
import { makeStyleString } from "../LayoutModal/helpers"
import { i } from "@/utils/images"
import { SectionState } from "@/types/Section"

    const MobileEditor = ()=>{

        const [isOpen,setIsOpen] = useState(false)
        const sectionContext:SectionState = useContext(SectionContext)
        const {setFields,fields,sectionId} = sectionContext
        const {image,heading,subheading,fontScale,mobileScale}:SectionState = sectionContext.fields
        const styleObj = makeFullStyleObject(sectionContext.style)
        const [position,setPosition] = useState(sectionContext.fields.position||50)
        const [scale,setScale] = useState(mobileScale||fontScale)

        const initialStateObject = {
            verticalAlignment:styleObj.mobileText||styleObj.text,
            horizontalAlignment:styleObj.mobileTextHor||styleObj.textHor
        }

        const [stateObject,setStateObject] = useState<DynamicObjectWithNumber>(initialStateObject)

        useEffect(()=>{
            if(stateObject.verticalAlignment){
                styleObj.mobileText = stateObject.verticalAlignment.toString()
            }
            if(stateObject.horizontalAlignment){
                styleObj.mobileTextHor = stateObject.horizontalAlignment.toString()
            }
            
            const newStyleString = makeStyleString(styleObj)
            changeSectionStyle({sectionId,style:newStyleString})
        },[stateObject])


        const {horizontalAlignment,verticalAlignment} = stateObject

        const buttonHandler = ()=>{
            setIsOpen(true)
        }
        
        useEffect(()=>{
                    const newFields = {...fields}
                    newFields.position = position
                    setFields(newFields)
        },[position])
        useEffect(()=>{
            const newFields = {...fields}
            newFields.mobileScale = scale
            setFields(newFields)
        },[scale])

        if(!isOpen){
            return<LittleCircleButton buttonHandler={buttonHandler} bottom="10px" left="10px">
                <div data-tooltip="Edit Hero Mobile Appearance" className="tooltip right">
                <FaMobile></FaMobile>
                </div>
            </LittleCircleButton>
        }

        const style = {
            objectPosition:`${position}%`
        }


        const textContStyle = {
            color:styleObj.color,
            justifyContent:getVerticalAlignment(verticalAlignment.toString())
        }

        const textAlign = getHorizontalAlignment(horizontalAlignment.toString())
        
        const h1Styles: React.CSSProperties = {
            fontSize:`${scale*2/100*375}px`,
            textAlign
        }

        const h2Styles: React.CSSProperties = {
            fontSize:`${(scale||1)*1/100*375}px`,
            textAlign
        }

        return(
            <FullPageMiddleScroller setIsOpen={setIsOpen
            }>
                <div className="mobile-editor-cont">

                <MobileFrame>
                    <div className="image-and-text-cont">
                    <img style={style} src={i(image)}></img>
                    <div style={textContStyle} className="text-cont mobile-frame-text-cont">
                    <h1 style={h1Styles} className="h1" dangerouslySetInnerHTML={{__html:heading}}></h1>
                    <h2 style={h2Styles} className="h2" dangerouslySetInnerHTML={{__html:subheading}}></h2>
                    </div>
                    </div>
                <Slider
                    label="Image Horizontal Position"
                    propertyName="mobile-hero-position"
                    unit={"%"}
                    value={position}
                    setValue={setPosition}
                    min={0}
                    max={100}
                ></Slider>
                <Slider
                    label="Text Size"
                    propertyName="mobile-font-scale"
                    unit={"%"}
                    value={scale}
                    setValue={setScale}
                    min={0}
                    max={10}
                ></Slider>
                <OptionButtonsCont
                stateObject={stateObject}
                setStateObject={setStateObject}
                
                ></OptionButtonsCont>
                </MobileFrame>
                </div>
            </FullPageMiddleScroller>
            // <h1>MobileEditor Component Goes Here</h1>
        )
    }
    export default MobileEditor
    
    
    