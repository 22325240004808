import "./index.scss"
import { SectionType } from "@/types/Section"
import PuxMod from "@/components/PuxMod"
import P from "@/components/PuxEls/P"

   const TextBlock = ({section}:{section:SectionType})=>{

        return(
            <PuxMod section={section}
             headless={true}
             >
                <P
                field="text"
                ></P>
            </PuxMod>
        )
    }
    export default TextBlock
 