import PuxMod from "@/components/PuxMod"
import InputCont from "@/generics/InputCont"
import TwitterFeed from "@/generics/TwitterFeed"
import useSectionState from "@/hooks/useSectionState"
import { SectionType } from "@/types/Section"
import { useEffect, useState } from "react"
import "./index.scss"


    const Twitter = ({section}:{section:SectionType})=>{
        const {fields,delayedFields,isOn,isEditing,handleInputChange} = useSectionState({section,options:{enableAPI:true}})
        const [feedOn,setFeedOn] = useState(true) 

        useEffect(()=>{
            if(!feedOn){
                setTimeout(()=>setFeedOn(true),2000) 
            }
        },[feedOn])

        useEffect(()=>{
            setFeedOn(false)
        },[fields])
       
        return<PuxMod section={section}>
            {isEditing&&isOn?<InputCont
            label="Twitter Name"
            name="userName"
            value={fields?.userName||""}
            handleChange={handleInputChange}
            
            ></InputCont>:<></>}
            {!(feedOn&&isOn)?<></>:<TwitterFeed userName={delayedFields?.userName||""}/>}
        </PuxMod>
    }
    export default Twitter
    
    
    