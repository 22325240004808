export const getURLParam = (term:string)=>{
    var result = null,
    tmp = [];
    window.location.search
    .substring(1)
    .split("&")
    .forEach(function (item) {
      tmp = item.split("=");
      if (tmp[0] === term) result = decodeURIComponent(tmp[1]);
    });
    return result;
}

export const isEditingURL = ()=>getURLParam("editing")==="true"||window.location.href.includes("/freelance-dev/");

export const getSectionParam = ()=>{
    const sectionParam = getURLParam("section")
    return sectionParam?parseInt(sectionParam):null
}

