export default {prompt:"Text Alignment Within Picture?",
field:"text",
type:"class",
default:"middle",
condition:{field:"stack",value:"overlay"},
options:[
    {value:"top",text:"Top"},
    {value:"middle",text:"Middle"},
    {value:"bottom",text:"Bottom"}
]
}