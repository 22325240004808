
    import "./index.css"
    import React, { useEffect, useState } from "react"
import Slider from "@/generics/Slider"
import useSkipFirstMutation from "@/hooks/useSkipFirstMutation"

    type Props = {
        handleOpacityChange:(arg0:number)=>void
        initialValue:number
    }

    const OpacitySlider = ({handleOpacityChange,initialValue}:Props)=>{
        const [value,setValue] = useState(100-initialValue)

        useSkipFirstMutation(()=>{
            handleOpacityChange(100-value)
        },[value])

        return(
            <Slider
            propertyName={"Transparency"}
            unit={"%"}
            value={value}
            setValue={setValue}
            min={0}
            max={100}
            ></Slider>
        )
    }
    export default OpacitySlider
    
    
    