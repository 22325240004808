
type GetNestedModalTranslateProps = {
    element:HTMLElement
    parentModalSelector:string
}

export const getNestedModalTranslate = ({element,parentModalSelector}:GetNestedModalTranslateProps)=>{
    const parent = element.closest(parentModalSelector)
    if(!(parent instanceof HTMLElement)){
        return
    }
    const {top,left} = parent.getBoundingClientRect()
    const {offsetHeight,offsetWidth} = parent
    const parentAbsoluteLeft = left-offsetWidth/2
    const resultTop = -top+80
    const result = {top:resultTop+"px",left:-left+"px",borderRadius:"0px"}
    return result
}

export const setToTop = ({element}:{element:HTMLElement|null})=>{
    if(element===null){
        return
    }
    const {top} = element.getBoundingClientRect()
    element.style.transform = `translateY(-${top}px)`
}