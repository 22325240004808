
    import { t } from "@/utils/stringFormatting"
import "./index.scss"
    import React, { useContext, useState } from "react"
import { PageContextType } from "@/types/Context"
import { PageContext } from "@/pages/Standard/context"
import Experimental from "@/wrappers/Experimental"
import { SectionDevelopmentContext } from "@/context/SectionDeveloperContext"


    type Props = {
        sections:string[]
        setHoveredSection:React.Dispatch<string|null>
        setSelected:React.Dispatch<string|null>
    }


    const SectionChooser = ({sections,setHoveredSection,setSelected}:Props)=>{
        const submissionContext:any = useContext(SectionDevelopmentContext)
        const isDev = submissionContext?.devSubmission
        const sectionSubmissionId = isDev?._id
        const {pageId}:PageContextType = useContext(PageContext)
        const [filter,setFilter] = useState("")
        const filteredSections = filter===""?sections:sections.filter(s=>{
            const normalizedName = s.toLowerCase().replaceAll(" ","")
            const normalizedFilter = filter.toLowerCase().replaceAll(" ","")            
            return normalizedName.includes(normalizedFilter)
        })

        const handleHover = (sectionName:string)=>{
            setHoveredSection(sectionName)
        }

        const handleMouseLeave = ()=>{
            setHoveredSection(null)
        }

        const handleClick = (sectionName:string)=>{
            setSelected(sectionName)
        }

        return(
            <div className="section-chooser">
                    <Experimental>
                    <header className="add-section-header">
                        <p>
                        The sections below can be customized in various ways, and you should be able to typically get the look you want. But if you want a developer to handle it instead, <a href={`/section-request?${pageId}`}>
                        click here to make a request.
                        </a>
                        </p>
                        </header>
                    </Experimental>
                <div  className="filter">
                <input value={filter} onChange={(e)=>setFilter(e.target.value)} placeholder="filter"></input>
                </div>
                <div className="section-list">
                {filteredSections.sort().map((s:string,i:number)=>{
                    return<div onClick={()=>handleClick(s)} onMouseLeave={handleMouseLeave} onMouseOver={()=>handleHover(s)} className="choice">{t(s)}</div>
                })}
                </div>
                <Experimental>

                <footer>You can also create your own section template &nbsp;
{sectionSubmissionId?<a href={`/section-template-builder?pageId=${pageId}&submissionId=${sectionSubmissionId}`}>
                    here</a>:<a href={`/section-template-builder?pageId=${pageId}`}>
                    here</a>}
                    
                    .&nbsp;
                    
                     (Will require knowledge of HTML and CSS)
                <p style={{marginTop:"1rem"}}>
                        <a href={`/premium-section-templates/${pageId}`}>Premium User Submitted Templates</a>
                </p>
                    </footer>
                </Experimental>
            </div>            
        )
    }
    export default SectionChooser
    
    
    