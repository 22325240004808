    import PuxMod, { SectionContext } from "@/components/PuxMod"
    import { SectionState, SectionType } from "@/types/Section"
import "./index.scss"
    import React, { FormEvent, FormEventHandler, useContext, useState } from "react"
import EFormInput from "@/components/EditableComponent/EFormInput"
import { makeFieldObject } from "@/utils/arrays"
import SubPuxMod from "@/components/SubPuxMod"
import FormEditor, { FieldObject, FormObject } from "@/components/FormEditor"
import { PageContextType } from "@/types/Context"
import { PageContext } from "@/pages/Standard/context"
import Form from "@/generics/Form"
import { convertFieldsArrayForForm } from "./helpers"
import EditButton from "@/generics/EditButton"
import ETextArea from "@/components/EditableComponent/ETextArea"
import { DynamicAnyObject } from "@/types/generic"
import { submitCustomForm } from "@/api/customForm"

    type Props = {
        section:SectionType
    }

    const CustomForm = ({section}:Props)=>{
        
        
        return(
            <PuxMod section={section} headless={false}>
                <CustomFormInterior
                section={section}
                ></CustomFormInterior>
            </PuxMod>
        )
    }
    export default CustomForm
    

    
    const CustomFormInterior =({section}:Props)=> {

        const {isEditing,pageId}:PageContextType = useContext(PageContext)
        const {handleInputChange}:SectionState = useContext(SectionContext)
        const sectionFields = makeFieldObject(section.fields)

        const [inputArray,setInputArray] = useState(section.genericSubsections.map((g)=>{
            const fieldObj = {...makeFieldObject(g.fields),options:g.stringArray};
            fieldObj.type = fieldObj.inputType;
            return fieldObj
        }))
        const [modalOpen,setModalOpen] = useState(false)

        const formObject:FormObject = {
            name:sectionFields.name||"",
            description:sectionFields.description||"",
            fields:inputArray
        }

        const handleFormChange = (newForm:any)=>{

            setInputArray(newForm.fields)
        }

        const handleSubmit = (formSubmission
            :DynamicAnyObject)=>{
            submitCustomForm({pageId,formSubmission})
        }

        

        if(isEditing&&modalOpen){
            return<FormEditor
            formObject={formObject}
            handleFormChange={handleFormChange}
            handleHeaderChange={handleInputChange}
            setModalOpen={setModalOpen}
            ></FormEditor>
        }

        return<div className="form-cont">
            {isEditing&&<EditButton
            setIsEditing={setModalOpen}
            tooltip="Edit Form Fields"
            ></EditButton>}
            <ETextArea
            field="description"
            ></ETextArea>
        <Form
        fields={convertFieldsArrayForForm(inputArray)}
        handleSubmit={handleSubmit}
        ></Form>
        </div>
       
    }
    

type InputProps = {
    inputObj:FieldObject
}

export const Input = ({inputObj}:InputProps)=>{
    const {inputType,options} = inputObj
    
    if(!["dropdown","multiple-choice"].includes(inputType)){
        return<input type={inputType}></input>
    }

    return <select>
        {options.map((o,i)=>{
            return<option>{o}</option>
        })}
    </select>
}