
    import "./index.scss"
    import React from "react"

    type Props = {
        open:boolean
        setIsOpen:React.Dispatch<boolean>
    }

    const Hamburger = ({open,setIsOpen}:Props)=>{
        return(
            <button onClick={()=>setIsOpen(!open)} className={`${open&&"open"} hamburger-button no-translate-animation`}>
                <span className="hamburger-line"></span>
                <span className="hamburger-line"></span>
                <span className="hamburger-line"></span>
            </button>
        )
    }
    export default Hamburger
    
    
    