import { CSSLineType } from "@/components/CSSEditor";


function parseCSS(cssString: string): CSSLineType[] {
  const cssRules: CSSLineType[] = [];
  const lines = cssString.split('\n').map((line) => line.trim());

  let currentSelector = '';

  for (const line of lines) {
    if (line.endsWith('{')) {
      // Extract the selector by removing the curly brace
      currentSelector = line.replace('{', '').trim();
    } else if (line.startsWith('}')) {
      // Reset the selector when the closing curly brace is encountered
      currentSelector = '';
    } else if (currentSelector && line.includes(':')) {
      const [property, value] = line.split(':').map((item) => item.trim());
      const camelCaseProperty = property
        .replace(/-./g, (match) => match.charAt(1).toUpperCase());
      
      cssRules.push({
        selector: currentSelector,
        property: camelCaseProperty,
        value:value.replaceAll(";",""),
      }); 
    }
  }

  const cssRulesWithoutVariables = cssRules.filter((rule)=>{
    return !rule.selector.includes(":root")
  })
  const cssVariables = cssRules.filter((rule)=>{
    return rule.selector.includes(":root")
  })
  cssVariables.forEach(cssVariable=>{
      cssRulesWithoutVariables.forEach(c=>{
      if(c.value.includes(cssVariable.property)){
        c.value = `${cssVariable.property}:${cssVariable.value}`
      }
  })})
  return cssRulesWithoutVariables;
}

  export default parseCSS