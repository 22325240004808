
    import "./index.scss"
    import React, { useState } from "react"
import OptionButtons from "./OptionButtons"
import { DynamicObjectWithNumber } from "@/types/generic"

    export type LabelAndValue = {
        label:string
        value:string
    }

    export type ButtonsObject = {
        key:string
        options:LabelAndValue[]
    }

    type Props = {
        stateObject:DynamicObjectWithNumber,
        setStateObject:React.Dispatch<DynamicObjectWithNumber>
    }

    const OptionButtonsCont = ({stateObject,setStateObject}:Props)=>{
        
        const buttonsArray:ButtonsObject[] = [
            {key:"verticalAlignment",options:[{label:"top",value:"top"},{label:"middle",value:"middle"},{label:"bottom",value:"bottom"}]},
            {key:"horizontalAlignment",options:[{label:"left",value:"left"},{label:"center",value:"center"},{label:"right",value:"right"}]}
        ]
        
        const handleClick = ({key,value}:{key:string,value:string})=>{
            const newStateObject = {...stateObject}
            newStateObject[key] = value;
            setStateObject(newStateObject)        
        }

        return(
            <div className="option-buttons-cont">
                {buttonsArray.map((buttonsObject:ButtonsObject,index:number)=>{
                    return<OptionButtons stateObject={stateObject} buttonsObject={buttonsObject} key={index} handleClick={handleClick}></OptionButtons>
                })}
            </div>
        )
    }
    export default OptionButtonsCont
    
    
    