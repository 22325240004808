
import './App.scss';
import { ContextProvider } from './context';
import Router from './router';
import { BrowserRouter } from 'react-router-dom';

function App() {
  return (
<ContextProvider>
  <BrowserRouter>
  <Router></Router>
  </BrowserRouter>
</ContextProvider>
  );
}
// 
export default App;
