export default     {
    prompt:"Vertical Image Clip?",
    field:"verticalImageClip",
    type:"class",
    default:"none",
    options:[
        {value:"none",text:"No Clip"},
        {value:"topHalf",text:"topHalf"},
        {value:"bottomHalf",text:"bottomHalf"},
    ]
}