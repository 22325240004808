import { loggo } from "@/utils/dev"
import "./index.css"
import { useEffect, useRef, useState } from "react"
import { DynamicAnyObject } from "@/types/generic"
    type Props = {
        src:string
        setHeight?:boolean
    }

const Video = ({src,setHeight}:Props)=>{
        let embedURL = src.replaceAll(" ","")
        const iRef = useRef(null)
        const [videoContStyle,setVideoContStyle] = useState<DynamicAnyObject>({})
        useEffect(()=>{
            if(!setHeight||!iRef?.current){
                return
            }
            const {height,width} = (iRef?.current as any).getBoundingClientRect()
            const videoContStyle:DynamicAnyObject = {}
            if(setHeight){
                videoContStyle.paddingBottom = "unset"
                videoContStyle.height = `${height}px`
                videoContStyle.width = `${width}px`

            }
            setVideoContStyle(videoContStyle)
        },[iRef.current])

        if(!src){
            return (<div className="centerer">

            <div className="video-cont">
            <iframe className="video" src={embedURL}></iframe>
            </div>
            </div>)
        }

        if(embedURL.includes('watch?v=')){
            const urlSplit = src.split('watch?v=')
            embedURL = urlSplit[0]+"embed/"+urlSplit[1]
        }
        if(embedURL.includes('youtu.be')){
            embedURL = embedURL.replace("youtu.be","youtube.com/embed")
        }
        if(embedURL.includes("/shorts/")){
            const urlSplit = src.split('shorts')
            embedURL = urlSplit[0]+"embed/"+urlSplit[1]
        }
        const endParams = "?modestbranding=1;controls=0;showinfo=0;rel=0;fs=1"
        embedURL+=endParams

        return(
            <div className="centerer">
            <div style={videoContStyle} className="video-cont">
            <iframe ref={iRef} className="video" src={embedURL}></iframe>
            </div>
            </div>
        )
}

export default Video
    
    
    