
    import "./index.scss"
    import React, { useState } from "react"
import Modal from "../Modal"

    type Props = {
        children:JSX.Element|JSX.Element[],
        condition:boolean
        initialValue:string
        externalSetter:({field,value}:{field:string,value:string})=>void
        isEditing?:boolean
        field:string
    }

    const HoverEditor = ({children,condition,isEditing,initialValue,externalSetter,field}:Props)=>{

        const [editorOpen,setEditorOpen] = useState(false)
        const [value,setValue] = useState(initialValue)
        const [heldValue,setHeldValue] = useState(initialValue)
        const [conditionOverride,setConditionOverride] = useState(condition)

        if(!condition&&!isEditing){
            return<></>
        }
        
        const handleContextMenu = (event:React.MouseEvent) => {
            if(!isEditing){
                return
            }
            event.preventDefault(); // Prevent the default context menu from appearing

            setEditorOpen(true)
        };
        
        const handleSave = ()=>{
            if(value===""||!value){
                setConditionOverride(false)
            }else{
                setConditionOverride(true)
            }
            externalSetter({field,value})
            setEditorOpen(false)
            setHeldValue(value)
        }
        
        const handleCancel = ()=>{
            setValue(heldValue)
            setEditorOpen(false)
        }


        let tooltip = conditionOverride?"Right click to change url":"No URL. Logo will not display. Right click to add url."



        return(
            <div onContextMenu={handleContextMenu} data-tooltip={tooltip} className={`hover-editor ${isEditing&&"tooltip"} ${(conditionOverride)?"":"empty"}`}>
                {children}
                {editorOpen&&<Modal
                setIsModalOpen={setEditorOpen}
                ><div>
                    <input value={value} onChange={(e)=>setValue(e.target.value)} ></input>
                    <button onClick={handleSave}>Save</button>
                    <button onClick={handleCancel} className="danger">Cancel</button>
                </div>
                    </Modal>}
            </div>
        )
    }
    export default HoverEditor
    
    
    