
    import { countSubstring } from "@/utils/stringUtils"
import "./index.css"
    import React from "react"

    const Tabber = ({text,maxWidth}:{text:string,maxWidth?:number})=>{

        const tabCount = countSubstring(text,"<tab>")
        const withoutTabs = text.replaceAll("<tab>","")
        const tabs:number[] = []
        for (let i=0;i<tabCount;i++){
            tabs.push(1)
        }

        return(
            <div className="tabbed-line">
            <span style={{width:(tabCount*30)+"px"}}></span>
            <span style={{maxWidth:(maxWidth||1000)-(30*tabCount)+"px",wordWrap:"break-word"}}>
            {withoutTabs}
            </span>
            {/* {
                tabs.map((tab)=>{
                    return<div className="tab">___</div>
                })
            }
            {withoutTabs} */}
            </div>
        )
    }
    export default Tabber
    
    
    