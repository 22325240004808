
    import { GenericSubsectionDocument } from "@/types/Documents"
import "./index.css"
    import React, { useContext, useEffect, useState } from "react"
import EImageWithLink from "@/components/EditableComponent/EImageWithLink"
import SubPuxMod from "@/components/SubPuxMod"
import DeleteCircle from "@/generics/DeleteCircle"
import { SectionContext } from "@/components/PuxMod"
import { SectionState } from "@/types/Section"
import { deleteGenericSubsection } from "@/api/genericSubsection"
import { getOrdinal, removeFromOrdinals } from "@/utils/subsectionOrdering"
import { PageContextType } from "@/types/Context"
import { PageContext } from "@/pages/Standard/context"

    type Props = {
        linkedImageDoc:GenericSubsectionDocument
    }

    const LinkedImageSubsection = ({linkedImageDoc}:Props)=>{
        const {isEditing}:PageContextType = useContext(PageContext)
        const {subsectionOrdinals,setSubsectionOrdinals,update,setUpdate}:SectionState= useContext(SectionContext)
        const [isDeleted,setIsDeleted] = useState(false)

        const handleDelete = async  (e:React.MouseEvent<HTMLButtonElement>)=>{

            const resp = await deleteGenericSubsection({subsectionId:linkedImageDoc._id})
            setIsDeleted(true)
            removeFromOrdinals({subsectionOrdinals,setSubsectionOrdinals,_id:linkedImageDoc._id})
            setUpdate(!update)
        }

        useEffect(()=>{
            const ordinal = getOrdinal({_id:linkedImageDoc._id,ordinals:subsectionOrdinals})
        },[update])
    
        if(isDeleted){
            return<></>
        }
        
        return(
            <SubPuxMod subsection={linkedImageDoc}>
            <div style={{position:"relative"}}>
            {isEditing&&<DeleteCircle _id={linkedImageDoc._id} handleDelete={handleDelete}></DeleteCircle>}
            <EImageWithLink/>
            </div>
            </SubPuxMod>
            
        )
    }
    export default LinkedImageSubsection
    
    
    