
    import "./index.scss"
    import React, { useContext, useState } from "react"
import { SectionType } from "@/types/Section"
import PuxMod from "@/components/PuxMod"
import { PageContextType } from "@/types/Context"
import { addReview } from "./helpers"
import { PageContext } from "@/pages/Standard/context"
import Review from "@/subsections/Review"

    type Props = {
        section:SectionType
    }

    const Reviews = ({section}:Props)=>{
        const [reviewArray,setReviewArray] = useState(section.genericSubsections)
        const {isEditing}:PageContextType = useContext(PageContext)

        const handleAdd = async ()=>{
            const resp = await addReview({sectionId:section._id})
            const newArray = [...reviewArray]
            newArray.push(resp)
            setReviewArray(newArray)
        }

        return(
            <PuxMod section={section}>
            <div className="all-reviews-cont">
            {(reviewArray || []).map((review: any,index:number) => {
                return <Review key={index} review={review}/>;
            })}
            </div>
            {isEditing?<button className="mb-3" onClick={handleAdd}>Add Review</button>:<></>}
            </PuxMod>
        )
    }
    export default Reviews
    
    
    