
    import "./index.css"
    import React from "react"

    const AnimationTests = ()=>{
        return(
            <section className="in-frame animation-tests">

            {/* <h1 className="typing-effect">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Odit cupiditate, perspiciatis ullam odio nam deleniti suscipit distinctio molestias! Sit, doloribus! Accusantium explicabo officiis voluptates. Esse nulla aut culpa sit iusto!</h1> */}
            {/* <h1 className="slide-up">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Odit cupiditate, perspiciatis ullam odio nam deleniti suscipit distinctio molestias! Sit, doloribus! Accusantium explicabo officiis voluptates. Esse nulla aut culpa sit iusto!</h1> */}
            {/* {/* <h1 className="slide-left">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Odit cupiditate, perspiciatis ullam odio nam deleniti suscipit distinctio molestias! Sit, doloribus! Accusantium explicabo officiis voluptates. Esse nulla aut culpa sit iusto!</h1> */}
            {/* <h1 className="slide-right">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Odit cupiditate, perspiciatis ullam odio nam deleniti suscipit distinctio molestias! Sit, doloribus! Accusantium explicabo officiis voluptates. Esse nulla aut culpa sit iusto!</h1> */}
            <h1 className="flip-down">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Odit cupiditate, perspiciatis ullam odio nam deleniti suscipit distinctio molestias! Sit, doloribus! Accusantium explicabo officiis voluptates. Esse nulla aut culpa sit iusto!</h1>

            </section>
        )
    }
    export default AnimationTests
    
    
    