
    import "./index.scss"
    import React, { ChangeEvent, ChangeEventHandler, useContext, useEffect, useRef, useState } from "react"
import { PageContextType } from "@/types/Context"
import { SubsectionContext } from "@/components/SubPuxMod"
import { SectionContext } from "@/components/PuxMod"
import { PageContext } from "@/pages/Standard/context"
import { Field, SectionState } from "@/types/Section"
import { DynamicObject } from "@/types/generic"
import { useDebounce } from "use-debounce"
import EmbedWidget from "@/generics/EmbedWidget"
import TextAreaWithPrompt from "@/generics/TextAreaWithPrompt"

    const EEmbed = ({field,subsection}:{field:string,subsection?:boolean})=>{

        const [key,setKey] = useState(0)
        const {isEditing}:PageContextType = useContext(PageContext)
        const subsectionContext = useContext(SubsectionContext)
        const sectionContext:SectionState = useContext(SectionContext)

        const {fields,handleInputChange}:{fields:DynamicObject,handleInputChange:ChangeEventHandler<HTMLTextAreaElement>} = subsection?subsectionContext:sectionContext
        const [delayedFields] = useDebounce(fields,2000)

        useEffect(()=>{
            setKey(key+1)
        },[delayedFields])

        


        let style = {}
        if(field&&sectionContext?.childStyles?.[field]){
            style = field&&sectionContext?.childStyles?.[field]
        }

        if(isEditing){
            return(
                <div className="embed-editor">
            <EmbedWidget
            parentId="Embed"
             embedString={delayedFields[field].replaceAll("<script","<script data-embed='true'")}></EmbedWidget>
                <TextAreaWithPrompt
                    prompt="Add/Edit Embed Code Here"
                    field={field}
                    value={fields[field]}
                    handleInputChange={handleInputChange}    
                ></TextAreaWithPrompt>
                
                </div>
                )
        }

        return(
            <>
            <EmbedWidget parentId="Embed" embedString={delayedFields[field].replaceAll("<script","<script data-embed='yuk'")}></EmbedWidget>
            <div></div>
            </>
        )
    }
    export default EEmbed
    
    
    