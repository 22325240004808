import ELink from "../ELink"
import "./index.scss"
    import React, { useContext, useState } from "react"
import { PageContextType } from "@/types/Context"
import { SubsectionContext } from "@/components/SubPuxMod"
import { DynamicObject } from "@/types/generic"
import { editGenericSubsection } from "@/api/genericSubsection"
import { makeFieldObject } from "@/utils/arrays"
import ETextArea from "../ETextArea"
import { PageContext } from "@/pages/Standard/context"
import EImage from "../EImage"

    const EImageWithLink = ()=>{

        const {isEditing}:PageContextType = useContext(PageContext)
        const subsectionContext:any = useContext(SubsectionContext)
        const [fields,setFields] = useState(subsectionContext.fields)
        const handleFieldSave = async (newFields:DynamicObject)=>{
            const newFieldsCorrectProps = {linkURL:newFields.href,linkText:newFields.linkText}
            setFields({...fields,...newFieldsCorrectProps})
            await editGenericSubsection({subsectionId:subsectionContext.subsectionId,changedFields:newFieldsCorrectProps,type:"linkedImage"})
        }

        const {imageURL,linkURL,linkText} = fields

        const handleImageClick = ()=>{
            window.open(linkURL,"_blank")
        }

        if(!isEditing){

            return(
        <div className="image-link-container">
            <ETextArea field="subheading"
            subsection={true}            
            ></ETextArea>
                    <EImage field="imageURL"
        subsection={true}
        handleClick={handleImageClick}
        ></EImage>
            <ELink 
            initialFields={{href:linkURL,linkText}}
            handleFieldSave={handleFieldSave}
            ></ELink>
            </div>)
        }
                
        return(
        <div className="image-link-container">
            <ETextArea field="subheading"
            subsection={true}
        ></ETextArea>
        <div>
        <EImage field="imageURL"
        subsection={true}
        ></EImage>
        </div>
        <ELink 
            initialFields={{href:linkURL,linkText}}
            handleFieldSave={handleFieldSave}
        ></ELink>
        </div>

        )
    }
    export default EImageWithLink
    
    
    