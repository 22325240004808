
    import "./index.css"
    import React from "react"
    import Tabber from "@/components/Tabber"
    const Breaker = ({text,tabs,maxWidth}:{text:string,tabs?:boolean,maxWidth?:number})=>{

        const split = text.split("<br>")
        return(
          <>
            {split.map((text:string,index:number)=>{
              return <React.Fragment key={index}>
                {tabs?<Tabber text={text} maxWidth={maxWidth}></Tabber>:text}
                {((index+1)<split.length)&&!tabs&&<br></br>}
              </React.Fragment>
            })}
          </>
        )
      }
    export default Breaker
    
    
    