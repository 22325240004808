
    import "./index.css"
    import React, { useEffect, useState } from "react"
import useTimeoutState from "@/hooks/useTimeoutState"
import Modal from "../Modal"
import ParagraphDivider from "../ParagraphDivider"

    type Props = {
        notificationMessage:string,
        timer:number
        setNotificationOpen:React.Dispatch<any>
    }

    const Notification = ({notificationMessage,setNotificationOpen,timer}:Props)=>{

        const [message,setMessage] = useTimeoutState(false,timer)
        const [initialized,setInitialized] = useState(false)

        useEffect(()=>{
            setMessage(notificationMessage)
        },[])

        useEffect(()=>{
            if(!initialized){
                setInitialized(true)
                return
            }
            if(!message){
                setNotificationOpen(false)
            }
        },[message])

        if(!message){
            return<></>
        }



        return(
            <Modal additionalClass="notification"
            setIsModalOpen={setNotificationOpen}
            >
                <div className="notification-content">
                    <ParagraphDivider text={message}></ParagraphDivider>
                    </                div>
            </Modal>
        )
    }
    export default Notification
    
    
    