
    import HomepageHeader from "@/layout/headers/HomepageHeader"
import "./index.scss"
    import React, { useContext, useEffect, useState } from "react"
import { Context } from "@/context"
import useGetIds from "@/hooks/useGetIds"
import { getAllConversations } from "@/api/messages"
import MessageList from "@/components/MessageList"
import { Conversation } from "@/types/Conversation"
import CurrentConversation from "@/components/CurrentConversation"
import { isMobile } from "@/utils/screen"

    const Messages = ()=>{

        const [ids] = useGetIds()
        const {userId} = ids
        const [currentConversation,setCurrentConversation] = useState<null|Conversation>(null)
        const [conversations,setConversations]= useState(null)
        const [mobileView,setMobileView] = useState("list")

        useEffect(()=>{
            const f = async ()=>{
                if(!userId){
                    return
                }
                const convos = await getAllConversations({userId})
                
                setConversations(convos)
                if(convos[0]&&!isMobile()){
                    setCurrentConversation(convos[0])
                }
            }
            f()
        },[userId])

  
        const handleMobileBack = ()=>{
            setMobileView("list")
        }

        return(
        <>
        <HomepageHeader
        additionalClass="normal-flow dark-blue "
        ></HomepageHeader>
        <div className="message-center">
            {conversations&&<MessageList
            conversations={conversations}
            currentConversation={currentConversation}
            setCurrentConversation={setCurrentConversation}
            hidden={isMobile()&&mobileView!=="list"}
            setMobileView={setMobileView}
            ></MessageList>}
            {currentConversation&&<CurrentConversation
            handleMobileBack={handleMobileBack}
            currentConversation={currentConversation}
            hidden={isMobile()&&mobileView!=="conversation"}
            ></CurrentConversation>}
        </div>
        </>
        )
    }
    export default Messages
    
    
    